<script setup lang="ts">
import { computed } from 'vue';
import { BORDER, PRIMARY, WHITE } from '../../business-logic/mapping/color';

const props = withDefaults(
  defineProps<{
    size?: number;
    color?: string;
    backgroundColor?: string;
    borderColor?: string;
    tooltip?: string;
  }>(),
  {
    size: 24,
    color: WHITE,
    backgroundColor: PRIMARY,
    borderColor: BORDER,
    tooltip: '',
  }
);

const cssSize = computed(() => `${props.size}px`);
</script>

<template>
  <div
    class="d-flex justify-content-center align-items-center icon-button"
    :title="tooltip"
  >
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.icon-button {
  width: v-bind(cssSize);
  height: v-bind(cssSize);
  border: 1px solid v-bind('props.borderColor');
  border-radius: 50%;
  cursor: pointer;
  color: v-bind('props.color');
  background-color: v-bind('props.backgroundColor');
  transition: all 0.2s ease-in-out;

  &:hover {
    color: v-bind('props.backgroundColor');
    background-color: v-bind('props.color');
  }
}
</style>
