<template>
  <div>
    <div
      class="section-header p-3 d-flex align-items-center justify-content-between bg-light mb-0 clickable"
      @click.prevent="toggle"
    >
      <h6 class="mb-0">{{ title }}</h6>

      <a href="#" class="text-dark p-1">
        <i
          class="fas"
          :class="{
            'fa-chevron-down': !value,
            'fa-chevron-up': value,
          }"
        ></i>
      </a>
    </div>

    <div
      class="pt-3"
      :class="{
        'px-3': mobileSize,
      }"
      v-show="show"
    >
      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResponsiveOrSlideUp',
  props: ['title', 'mobileSize', 'value'],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(updated) {
        this.$emit('input', updated);
      },
    },
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>
