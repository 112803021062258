<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { loadProjectDetails } from './api';
import { Project } from '@component-library/project';
import ProjectTable from './components/ProjectTable.vue';
import Breadcrumb from '@/js/components/Breadcrumb.vue';
import { useProjectStore } from '@component-library/store/project';
import { useRouter } from 'vue-router/composables';
import { useToastStore } from '@component-library/store/toasts';
import axios from 'axios';

const projectStore = useProjectStore();
const toast = useToastStore();
const router = useRouter();
const projects = ref<Project[]>([]);
const isLoading = ref(false);

function openProject(project) {
  isLoading.value = true;
  loadProjectDetails(project.project_id)
    .then((response) => {
      isLoading.value = false;
      projectStore.updateProject(response.data.project);
      router.push(`/${project.project_id}/map`, () => {});
    })
    .catch((err) => {
      isLoading.value = false;
      console.error(err);
      toast.unexpected();
    });
}

function loadProjects() {
  isLoading.value = true;
  axios.get('/api/project/list').then((response) => {
    isLoading.value = false;
    projects.value = response.data.projects;
  });
}

onMounted(loadProjects);
</script>

<template>
  <div class="container-fluid">
    <Breadcrumb pageTitle="Projects Overview" class="mt-3" />

    <ProjectTable
      class="mt-3"
      @openProject="openProject"
      :projects="projects"
      :isLoading="isLoading"
    />
  </div>
</template>
