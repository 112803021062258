import {
  normalizeLongitude,
  normalizeLatitude,
  normalizeElevation,
} from '@component-library/business-logic/number';

export async function getGpsPointDataInfo() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords, timestamp }) => {
        const { latitude, longitude, altitude: elevation } = coords;
        resolve({
          latitude: normalizeLongitude(longitude),
          longitude: normalizeLatitude(latitude),
          elevation: normalizeElevation(elevation ?? 0),
          timestamp,
        });
      },
      ({ code }) => {
        let message =
          'Failed to get the current position, please try it later.';
        switch (code) {
          case 1:
            message =
              'Permission denied. Please grant Gather the Location permission.';
            break;
          case 2:
            message = 'The current position is not available.';
            break;
          case 3:
            message = 'Timed out to get the current position.';
            break;
        }

        reject({ code, message });
      },
      {
        timeout: 5 * 60 * 1000,
      }
    );
  });
}
