const getSampleVersioning = (sample_id) => {
  return axios.get('/api/project/version-control/' + sample_id);
};

const getTemplateTab = (tab_id) => {
  return axios.get('/api/template/tab/' + tab_id);
};

const revertValuesToVersion = (data) => {
  return axios.post('/api/project/version-control/revert-values', data);
};

export default {
  getSampleVersioning,
  getTemplateTab,
  revertValuesToVersion,
};
