<script setup lang="ts">
import { ref } from 'vue';

defineProps<{
  isActive?: boolean;
  hasPadding?: boolean;
}>();

const showDropdown = ref(false);
</script>
<template>
  <li class="nav-item dropdown" v-click-outside="() => (showDropdown = false)">
    <a
      class="btn btn-header dropdown-toggle"
      href="#"
      @click.prevent.stop="showDropdown = !showDropdown"
      :class="{
        active: isActive,
      }"
    >
      <slot name="triggerBtn"></slot>
    </a>
    <div
      class="dropdown-menu mt-2"
      :class="[
        {
          show: showDropdown,
          'has-padding': hasPadding,
        },
      ]"
      style="right: 0"
    >
      <slot name="dropdownItems"></slot>
    </div>
  </li>
</template>
<style scoped lang="scss">
::v-deep {
  .navbar-nav .nav-link {
    display: flex;
    align-items: center;
  }

  .dropdown-menu.has-padding {
    padding: 0.5rem !important;
  }

  .dropdown-item i {
    margin-right: 8px;
    opacity: 0.5 !important;
    font-size: 16px !important;
  }

  .dropdown-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .dropdown-item:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .nav-item .btn {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .nav-item .btn.active {
    background: #fcfefd;
  }

  .dropdown-item {
    padding: 8px 10px;
    margin-bottom: 3px;
  }

  .user-information {
    padding: 0.75em;
    border-radius: 1rem;
    border: 1px solid #f4f4f4;
    margin-bottom: 0.75em;
  }

  @media all and (max-width: 992px) {
    .btn-header {
      display: none;
    }

    .nav-item {
      margin-right: 0px;
    }

    .nav-item a {
      padding: 1rem 1rem;
    }

    .nav-item:last-child {
      border-bottom: none;
    }

    .nav-item .btn.dropdown-toggle {
      display: none;
    }

    .navbar-nav {
      width: 100%;
      overflow-y: auto;
    }

    .nav-item .btn.active {
      background: none;
    }

    .dropdown-menu.has-padding {
      padding: 8px 0px !important;
    }
    .dropdown-menu {
      margin-top: 0px !important;
      background: none;
      display: block !important;
      box-shadow: none;
      border: none;
      border-bottom: 1px solid #f4f4f4 !important;
    }

    .dropdown-item {
      font-weight: 500;
      color: #212121;
      padding: 1em !important;
    }

    .dropdown-item i {
      font-size: 15px;
    }

    .user-information {
      margin-bottom: 1em;
    }
  }
}
</style>
