<template>
  <modal :show="show" @close="close" :half="true">
    <template v-slot:header> App Preview </template>
    <div class="modal-body" v-if="show && sections">
      <form-section
        v-for="section of sections"
        :key="section.id"
        :allFields="allFields"
        :section="section"
        :samples="[]"
        :inputValues="[]"
        :shouldEmit="false"
        :previewForm="true"
      />
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary w-100" @click="close">
        Close
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '@/js/components/Modal.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import FormSection from '@component-library/form/FormSection.vue';

export default {
  name: 'TemplatePreviewModal',
  props: ['show', 'dataTab'],
  components: {
    Modal,
    ButtonSpinner,
    FormSection,
  },
  data: () => ({
    sections: [],
  }),
  computed: {
    allFields() {
      return this.sections.reduce((accu, item) => {
        return [...accu, ...item.template_fields];
      }, []);
    },
  },
  watch: {
    show(updated) {
      if (updated) {
        this.sections = structuredClone([...this.dataTab.sections]);
      } else {
        this.sections = [];
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
