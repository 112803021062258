<template>
  <div>
    <div
      class="modal fade isVideo"
      id="videoModal"
      aria-hidden="true"
      aria-labelledby="videoModalLabel"
      tabindex="-1"
    >
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-body bg-dark">
            <div class="row h-100 align-items-center">
              <div class="col h-100">
                <button
                  class="btn btn-danger top-right"
                  data-bs-dismiss="modal"
                  data-bs-target="#videoModal"
                  aria-label="Close"
                  @click.prevent="close"
                >
                  <i class="fas fa-times"></i>
                </button>
                <div class="video">
                  <video
                    id="video"
                    playsinline
                    width="100%"
                    height="auto"
                    muted
                  >
                    Video stream not available.
                  </video>
                  <span
                    class="dot"
                    :class="{
                      'bg-secondary': !mediaRecorder,
                      'bg-danger': !!mediaRecorder,
                    }"
                    @click="processVideo()"
                    ><i class="fas fa-video fa-3x text-white-50 pt-2"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal as BootstrapModal } from 'bootstrap';

export default {
  data: () => ({
    width: 1200,
    height: 0,
    streaming: false,
    video: null,
    photo: null,
    modal: null,
    stream: null,
    tracks: null,
    mediaRecorder: null,
    recordedChunks: [],
    isSafari: false,
  }),
  mounted() {
    this.isSafari =
      navigator.userAgent.indexOf('Safari') > -1 &&
      navigator.userAgent.indexOf('Chrome') <= 0;
    this.modal = BootstrapModal.getOrCreateInstance('#videoModal');
    this.modal.show();
    this.startup();
    this.$nextTick(() => {
      this.requestvideo();
    });
  },
  beforeDestroy() {
    this.stopRecorder();
  },
  methods: {
    stopRecorder() {
      try {
        this.mediaRecorder?.stop();
        this.stream.getTracks()[0]?.stop();
      } catch (e) {
        console.warn('media recorder already stopped!', e);
      }
    },
    close() {
      this.stopRecorder();
      this.$emit('close');
    },
    requestvideo() {
      return navigator.mediaDevices
        .getUserMedia({
          video: {
            width: 1280,
            height: 720,
            facingMode: 'environment',
          },
          audio: true,
        })
        .then((stream) => {
          this.tracks = stream.getVideoTracks();
          this.stream = stream;
          this.video.srcObject = stream;
          this.video.play();
          this.video.addEventListener(
            'canplay',
            () => {
              if (!this.streaming) {
                this.streaming = true;
              }
            },
            false
          );
        })
        .catch((err) => {
          throw err;
        });
    },
    startup() {
      this.video = document.getElementById('video');
      this.photo = document.getElementById('photo');
    },
    processVideo() {
      if (!this.mediaRecorder) {
        this.takeVideo();
        return;
      }
      if (this.mediaRecorder) this.stopRecorder();
    },
    takeVideo() {
      const options = { mimeType: this.isSafari ? 'video/mp4' : 'video/webm' };
      this.recordedChunks = [];
      this.mediaRecorder = new MediaRecorder(this.stream, options);
      this.mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          this.recordedChunks.push(e.data);
        }
        const blob = new Blob([...this.recordedChunks], { type: e.data.type });
        this.$emit('setVideo', {
          src: URL.createObjectURL(blob),
          blob,
          type: this.isSafari ? 'mp4' : 'webm',
        });
        this.modal.hide();
        this.$nextTick(() => {
          this.close();
        });
      };
      this.mediaRecorder.start();
    },
  },
};
</script>

<style scoped>
#video {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  pointer-events: none;
  z-index: 10 !important;
}

.video {
  width: 100%;
  height: 100%;
  max-height: 100%;
  text-align: center;
}

.dot {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  bottom: 6.9em;
  border-radius: 50%;
  width: 4.5em;
  height: 4.5em;
  cursor: pointer;
  z-index: 200 !important;
}

.modal-dialog {
  padding: 0;
}

.modal-content {
  max-height: 100%;
}

.modal-content,
.modal-body {
  max-height: none;
}

.top-right {
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 300 !important;
}
</style>

