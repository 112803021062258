<script setup lang="ts">
import { OfflineProject } from '@component-library/offline-data';
import { User } from '@component-library/user';
import { computed } from 'vue';

const props = defineProps<{
  project: OfflineProject;
  selectedOfflineProjectId?: Number | null;
  user: User;
  hasInvalidatedRequests?: boolean;
  isMissingLocally?: boolean;
}>();

const projectSamples = computed(() => {
  return props.project?.samples || [];
});

const getOfflineEditedSamples = computed(() => {
  return projectSamples.value.filter((sample) => sample.is_edited_offline)
    .length;
});

const getOfflinePreviewSamples = computed(() => {
  return projectSamples.value.filter(
    (sample) =>
      sample.offline_user_id !== props.user.user_id &&
      !sample.is_created_offline
  ).length;
});

const getOfflineCreatedSamples = computed(() => {
  return projectSamples.value.filter((sample) => sample.is_created_offline)
    .length;
});
</script>
<template>
  <div
    class="d-flex align-items-center w-100 list-group-item"
    :class="{
      'list-group-item-action clickable': !isMissingLocally,
    }"
  >
    <div v-if="!isMissingLocally" class="form-check me-2">
      <input
        class="form-check-input"
        type="radio"
        :checked="selectedOfflineProjectId === project.project_id"
      />
    </div>

    <div class="pe-3 flex-fill overflow-hidden">
      <span class="d-block fw-medium overflow-hidden mb-0">
        {{
          project.project_number +
          (project.project_name ? ' - ' + project.project_name : '')
        }}
        - {{ project.project_id }}
      </span>
      <small class="d-block">{{ project.project_client }}</small>

      <small v-if="getOfflinePreviewSamples > 0" class="d-block">
        <i class="fas fa-fw fa-play text-muted"></i>
        You have access to read-only
        <b>
          {{ getOfflinePreviewSamples }}
        </b>
        locations while offline.
      </small>

      <small v-if="isMissingLocally" class="text-danger mt-1">
        <i class="fal fa-fw fa-exclamation-triangle me-1"></i>
        Does not exist on your device anymore. You can download it again or
        invalidate it via the "offline manager".
      </small>
      <div v-else class="d-flex flex-column mt-1">
        <small v-if="hasInvalidatedRequests" class="text-danger">
          <i class="fal fa-fw fa-exclamation-triangle me-1"></i>
          Data inside this request has been invalidated.
        </small>
        <small
          v-if="
            projectSamples.filter(
              (s) => s.is_edited_offline || s.is_created_offline
            ).length === 0
          "
          class="text-warning"
        >
          <i class="fal fa-fw fa-exclamation-triangle me-1"></i>
          You have not added/edited any offline locations yet.
        </small>
        <template v-else>
          <small v-if="getOfflineCreatedSamples > 0">
            <i class="fal fa-fw fa-map-marker-alt me-1"></i>
            You added
            <b>{{ getOfflineCreatedSamples }}</b> locations while offline.
          </small>

          <small v-if="getOfflineEditedSamples > 0">
            <i class="fal fa-fw fa-wifi-slash me-1"></i>
            You edited
            <b>{{ getOfflineEditedSamples }}</b> existing locations while
            offline.
          </small>
        </template>
      </div>
    </div>
  </div>
</template>
