<script>
export default {
  name: 'DateTestValueInput',
};
</script>

<script setup>
import { padValue } from '@component-library/business-logic/expression';
import moment from 'moment';
import { computed } from 'vue';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
  value: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['input']);

const moment_ = new moment();

const date = computed({
  get() {
    return props.value.value;
  },
  set(value) {
    emit('input', { value, value2: props.value.value2 });
  },
});
const time = computed({
  get() {
    return props.value.value2;
  },
  set(value) {
    emit('input', { value: props.value.value, value2: value });
  },
});

const yearRange = computed(() => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear - 20; i < currentYear + 19; i++) {
    years.push(i);
  }
  return years;
});

const year = computed({
  get() {
    return date.value ? date.value.split('-')[0] : null;
  },
  set(value) {
    const items = date.value.split('-');
    items.splice(0, 1, value);
    date.value = items.join('-');
  },
});

const month = computed({
  get() {
    return date.value ? date.value.split('-')[1] : null;
  },
  set(value) {
    const items = date.value.split('-');
    items.splice(1, 1, value);
    date.value = items.join('-');
  },
});

const type = computed(() => {
  return props.field.options.type ?? 'datetime';
});

const format = computed(() => {
  return props.field.options.format ?? 'YYYY-MM-DD';
});

const isDatetime = computed(() => {
  return type.value === 'datetime';
});

const isDate = computed(() => {
  return type.value === 'date';
});

const isDateInputVisible = computed(() => {
  return (
    isDatetime.value ||
    (isDate.value &&
      ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'].includes(format.value))
  );
});

const isMonthInputVisible = computed(() => {
  return isDate.value && format.value === 'month';
});

const isYearInputVisible = computed(() => {
  return isDate.value && ['month', 'year'].includes(format.value);
});

const isTimeInputVisible = computed(() => {
  return ['time', 'datetime'].includes(type.value);
});
</script>

<template>
  <div class="row">
    <div
      v-if="isDateInputVisible"
      class="form-group mb-2 col-12"
      :class="{
        'col-md-6': isDatetime,
      }"
    >
      <input type="date" class="form-control" v-model="date" name="date" />
    </div>

    <div
      v-if="isMonthInputVisible || isYearInputVisible"
      class="form-group mb-2 d-flex flex-fill"
    >
      <select
        v-if="isMonthInputVisible"
        v-model="month"
        class="form-control flex-fill"
      >
        <option v-for="n in 12" :key="`month-${n}`" :value="padValue(n)">
          {{ moment_.month(n - 1).format('MMMM') }}
        </option>
      </select>

      <select
        v-if="isYearInputVisible"
        v-model="year"
        class="form-control flex-fill"
      >
        <option v-for="aYear of yearRange" :key="aYear" :value="aYear">
          {{ aYear }}
        </option>
      </select>
    </div>

    <div
      v-if="isTimeInputVisible"
      class="form-group mb-2 col-12"
      :class="{
        'col-md-6': isDatetime,
      }"
    >
      <input type="time" class="form-control" v-model="time" name="time" />
    </div>
  </div>
</template>
