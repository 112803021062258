<script lang="ts" setup>
import { computed } from 'vue';
import Spinner from './Spinner.vue';

const props = defineProps({
  progress: {
    type: Number,
    max: 1,
    min: 0,
  },
});

const internalProgress = computed(() => props.progress ?? 0);

const adjustedProgress = computed(() => {
  if (props.progress == 0) {
    return props.progress * 100;
  }
  return Math.max(2, internalProgress.value * 100);
});
</script>

<template>
  <div class="d-flex align-items-center justify-content-center w-100">
    <div class="progress w-100 position-relative" style="height: 30px">
      <div
        class="progress-value fw-bold"
        :class="{ 'text-light': internalProgress > 0.5 }"
      >
        <slot> Loading <Spinner /> </slot>
      </div>
      <div
        class="progress-bar"
        :class="{
          'bg-primary': internalProgress < 1,
          'bg-success': progress === 1,
        }"
        :style="{
          width: adjustedProgress + '%',
        }"
      />
    </div>
  </div>
</template>
