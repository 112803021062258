import Hazard from './Hazard.js';
// import axios from 'axios';

// SafetyCategory model
export default class Category {
  id;
  nano_id;
  name;
  hazards = [];

  constructor({ id = null, name = '', hazards = [], nano_id = null }) {
    this.id = id;
    this.nano_id = nano_id;
    this.name = name;
    this.setHazards(hazards);
  }

  static get() {
    return axios.get('/safety/category').then(({ data }) => {
      return data.data.map((category) => {
        return new Category(category);
      });
    });
  }

  setHazards(hazards) {
    this.hazards = hazards.map(
      (aHazard) => new Hazard({ 
        ...aHazard, 
        category_name: this.name, 
        category: this 
      })
    );
  }
}
