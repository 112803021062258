<template>
  <div
    class="
      py-4
      d-flex
      align-items-center
      justify-content-center
      flex-column
      text-center
      w-100
    "
  >
    <i class="spinner-border spinner-border-lg"></i>
    <small class="text-muted mt-2 d-block">Loading Version Control...</small>
  </div>
</template>
<script>
export default {};
</script>
