<script lang="ts" setup>
import makeId from '@component-library/local-id.mjs';
import { computed, ref } from 'vue';

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
});

const id = ref(makeId());
const emit = defineEmits(['input']);

const field = computed({
  get() {
    return props.value;
  },
  set(updated) {
    emit('input', updated);
  },
});

const displayAsButtons = computed({
  get() {
    return field.value.options?.display_as_buttons ?? false;
  },
  set(value) {
    const options = { ...field.value.options, display_as_buttons: value };
    emit('input', { ...props.value, options });
  },
});
</script>

<template>
  <div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        :id="id"
        v-model="displayAsButtons"
      />
      <label class="form-check-label" :for="id">
        Do you want to display as
        {{ displayAsButtons ? 'radio button' : 'buttons' }}?
      </label>
    </div>
  </div>
</template>
