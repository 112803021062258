<script lang="ts" setup>
import { computed, onMounted, nextTick } from 'vue';
import { FieldTypeIds } from '@component-library/fields';
import { getDefaultValuePair } from '@component-library/business-logic/expression';

const props = defineProps<{
  value: string;
}>();

const emit = defineEmits(['input']);

const date = computed({
  get() {
    return props.value.split(' ')[0] ?? null;
  },
  set(value) {
    emit('input', `${value} ${props.value.split(' ')[1] ?? ''}`.trim());
  },
});
const time = computed<string | null>({
  get() {
    return props.value.split(' ')[1] ?? null;
  },
  set(value) {
    emit('input', `${date.value} ${value}`);
  },
});
onMounted(async () => {
  const { value, value2 } = getDefaultValuePair({
    field_type_id: FieldTypeIds.DATE,
  });
  if (!date.value) {
    date.value = value;
  }
  await nextTick();
  if (!time.value) {
    time.value = value2;
  }
});
</script>

<template>
  <div class="row">
    <div class="form-group mb-2 col-12 col-md-6">
      <input type="date" class="form-control" v-model="date" name="date" />
    </div>
    <div class="form-group mb-2 col-12 col-md-6">
      <input type="time" class="form-control" v-model="time" name="time" />
    </div>
  </div>
</template>
