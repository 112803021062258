const getCocs = () => {
  return axios.get('/api/project/coc');
};

const getCoc = (id) => {
  return axios.get(`/api/project/coc/${id}`);
};

const getHasCoc = () => {
  return axios.get(`/api/project/coc/has`);
};

const createCoc = (data) => {
  return axios.post(`/api/project/coc`, data);
};

const updateCoc = (data) => {
  return axios.put(`/api/project/coc/${data.id}`, data);
};

const deleteCoc = (id) => {
  return axios.delete(`/api/project/coc/${id}`);
};

const generateCoc = (id, data) => {
  return axios.post(`/api/project/coc/${id}/generate-pdf`, data);
};

const sendCocToLab = (id, data) => {
  return axios.post(`/api/project/coc/${id}/send-to-lab`, data);
};

const updateCocPublished = (id, data) => {
  return axios.post(`/api/project/coc/${id}/publish`, data);
};

export default {
  getCocs,
  getCoc,
  getHasCoc,
  createCoc,
  updateCoc,
  deleteCoc,
  generateCoc,
  sendCocToLab,
  updateCocPublished,
};
