<script setup lang="ts">
import { BLACK } from '../../business-logic/mapping/color';

import DropdownMenu from '../DropdownMenu.vue';

import { computed, ref } from 'vue';
import { ColorPicker } from 'vue-color-gradient-picker';
import 'vue-color-gradient-picker/dist/index.css';

import {
  colorToGradientPickerColor,
  rgbaToHex,
} from '../../business-logic/gradient-color-picker';

import useAuth from '../../composables/useAuth';
import { Project } from '../../project';
import { useProjectStore } from '../../store/project';

import ButtonSpinner from '../ButtonSpinner.vue';

import _debounce from 'lodash/debounce';

import { watch } from 'vue';

const auth = useAuth();
const projectStore = useProjectStore();

const project = computed(() => {
  if (!auth?.user()) {
    return null;
  }
  return projectStore.currentProject as Project;
});

const props = withDefaults(
  defineProps<{
    label?: string;
    value?: string;
    disabled?: boolean;
    title?: string;
    isAlphaChangeable?: boolean;
  }>(),
  {
    label: 'Colour',
    value: BLACK,
    disabled: false,
    title: '',
    isAlphaChangeable: false,
  }
);

const emit = defineEmits(['input']);

enum Tab {
  SAVED,
  COMPANY,
}

const isRemoving = ref(false);
const isAdding = ref(false);

const tab = ref(Tab.SAVED);
const color = ref(colorToGradientPickerColor(props.value));

const projectColors = computed(() => {
  return project.value ? project.value?.map_color_palette || [] : [];
});

const companyColors = computed(() => {
  return auth?.user ? auth.user().company?.map_color_palette || [] : [];
});

const getGridColors = computed(() => {
  if (tab.value === Tab.SAVED) {
    return projectColors.value || [];
  }

  return companyColors.value || [];
});

const canRemoveColor = computed(() => {
  return (
    tab.value === Tab.SAVED &&
    (projectColors.value || []).includes(rgbaToHex(color.value))
  );
});

const hasEyeDropper = computed(() => {
  return 'EyeDropper' in window;
});

const onChange = _debounce(function (c) {
  if (!props.isAlphaChangeable) {
    // The ColorChooser doesn't support hiding the Alpha component.
    // So if alpha is not changeable that always keep it as 1.
    const { red, green, blue } = c;
    const alpha = 1;
    c = {
      ...c,
      alpha,
      style: `rgba(${red}, ${green}, ${blue}, ${alpha})`,
    };
  }

  emit('input', rgbaToHex(c));

  color.value = {
    ...c,
  };
}, 300);

function setColor(c: string) {
  onChange(colorToGradientPickerColor(c));
}

async function removeColor() {
  isRemoving.value = true;
  await projectStore.toggleProjectMapColor(rgbaToHex(color.value));
  isRemoving.value = false;
}

async function addColor() {
  isAdding.value = true;
  await projectStore.toggleProjectMapColor(rgbaToHex(color.value));
  isAdding.value = false;
}

const toggleEyeDropper = async () => {
  const eyeDropper = new (window as any).EyeDropper();

  const { sRGBHex } = await eyeDropper.open();

  color.value = colorToGradientPickerColor(sRGBHex);

  emit('input', rgbaToHex(color.value));
};

watch(
  () => props.value,
  () => {
    color.value = colorToGradientPickerColor(props.value);
  }
);
</script>

<template>
  <div class="position-relative color-chooser" style="max-width: 350px">
    <label class="form-label">
      {{ label }}
      <sup class="text-danger">*</sup>
      <i
        v-if="hasEyeDropper"
        class="fal fa-eye-dropper clickable"
        @click="toggleEyeDropper"
      />
    </label>

    <DropdownMenu ref="dropdown" customWidth="100%" padding="p-3">
      <template #button="{ toggled, toggle }">
        <div
          class="btn btn-custom-color w-100 py-3"
          :style="{
            background: color.style,
          }"
          :class="{ toggled }"
          @click="toggle"
        />
      </template>
      <template v-slot:items="{ toggled }">
        <ColorPicker
          v-if="toggled"
          :color="color"
          :onStartChange="(c) => onChange(c)"
          :onChange="(c) => onChange(c)"
          :onEndChange="(c) => onChange(c)"
        />

        <div class="d-flex justify-content-between align-items-center my-2">
          <ul class="nav nav-underline">
            <li class="nav-item me-1">
              <a
                href="#"
                class="nav-link py-1"
                :class="{
                  active: tab === Tab.SAVED,
                }"
                @click.prevent="tab = Tab.SAVED"
              >
                Saved
              </a>
            </li>
            <li v-if="companyColors.length > 0" class="nav-item">
              <a
                href="#"
                class="nav-link py-1"
                :class="{
                  active: tab === Tab.COMPANY,
                }"
                @click.prevent="tab = Tab.COMPANY"
              >
                Company
              </a>
            </li>
          </ul>
          <div v-if="tab === Tab.SAVED" class="btn-group btn-group-sm">
            <ButtonSpinner
              v-if="canRemoveColor"
              type="submit"
              class="btn btn-light"
              :is-loading="isRemoving"
              @click.native.prevent="removeColor"
            >
              <i class="fal fa-minus fa-fw" />
            </ButtonSpinner>
            <ButtonSpinner
              v-if="!canRemoveColor"
              type="submit"
              class="btn btn-light"
              :is-loading="isAdding"
              @click.native.prevent="addColor"
            >
              <i class="fal fa-plus fa-fw" />
            </ButtonSpinner>
          </div>
        </div>

        <ul class="btn-list mb-0">
          <li
            v-for="color in getGridColors"
            class="btn btn-custom-color border"
            :style="{
              background: color,
            }"
            @click="setColor(color)"
          />
        </ul>
      </template>
    </DropdownMenu>
  </div>
</template>
