<script>
export default {
  name: 'CheckValueContentInput',
};
</script>

<script setup>
import { computed, onMounted } from 'vue';

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['input']);

const value_ = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit('input', value);
  },
});
const isYes = computed(() => {
  return value_.value?.toLowerCase() === 'yes';
});
const isNo = computed(() => {
  return value_.value?.toLowerCase() === 'no';
});

onMounted(() => {
  if (!value_.value) {
    value_.value = 'yes';
  }
});
</script>

<template>
  <div class="form-group">
    <div class="btn-group btn-group-toggle">
      <button
        type="button"
        class="btn btn-outline-dark"
        :class="{
          active: isYes,
        }"
        @click="() => (value_ = 'yes')"
      >
        Yes
      </button>
      <button
        type="button"
        class="btn btn-outline-dark"
        :class="{
          active: isNo,
        }"
        @click="() => (value_ = 'no')"
      >
        No
      </button>
    </div>
  </div>
</template>
