export function sortFigures(figures) {
  const splitFigureNo = (figureNo) => figureNo.split('.');
  const normalizeValue = (v) =>
    !Number.isNaN(parseInt(v, 10)) ? parseInt(v, 10) : v ?? 0;

  const splittedFigureNos = figures.map((f) => splitFigureNo(f.figure_no));
  const max = Math.max(...splittedFigureNos.map((sfno) => sfno.length));

  return figures.sort((f1, f2) => {
    const splittedFigureNo1 = splitFigureNo(f1.figure_no);
    const splittedFigureNo2 = splitFigureNo(f2.figure_no);

    for (let i = 0; i < max; i++) {
      let value1 = normalizeValue(splittedFigureNo1[i]);
      let value2 = normalizeValue(splittedFigureNo2[i]);

      let result;
      if (typeof value1 === 'number' && typeof value2 === 'number') {
        result = value1 - value2;
      } else {
        result = String(value1).localeCompare(String(value2));
      }
      if (result !== 0) {
        return result;
      }
    }

    return f1.title.localeCompare(f2.title);
  });
}

export function getFigureTitle(figure) {
  const { is_basemap, figure_no, title } = figure;
  return !is_basemap ? `${figure_no}. ${title}` : title;
}
