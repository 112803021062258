import axios from 'axios';
import type { Ref } from 'vue';
import { ref, watch } from 'vue';
import type { Item } from '../gather';
import { captureException } from '../sentry';

export enum Context {
  Gather = 1,
  DataManager = 2,
  PublicForm = 3,
}

function getApiUrl(context: Context): string {
  switch (context) {
    case Context.Gather:
    case Context.PublicForm:
      return '/api/project/items-from-app-title';
    case Context.DataManager:
      return '/project/items-from-app-title';
  }
}

export default function useItemsFromAppTitle(
  context: Context,
  appTitle?: string | null
): {
  isLoading: Ref<boolean>;
  items: Ref<Item[]>;
} {
  const isLoading = ref<boolean>(false);
  const items = ref<Item[]>([]);

  watch(
    () => appTitle,
    async (newValue) => {
      if (!newValue) {
        isLoading.value = false;
        items.value = [];
        return;
      }

      const apiUrl = getApiUrl(context);
      isLoading.value = true;
      try {
        const {
          data: { items: _items },
        } = await axios.post(apiUrl, {
          appTitle: newValue,
        });
        items.value = _items;
      } catch (e) {
        captureException(e);
        console.error(e);
      } finally {
        isLoading.value = false;
      }
    },
    {
      immediate: true,
    }
  );

  return {
    isLoading,
    items,
  };
}
