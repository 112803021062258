import { Collection, Feature, MapBrowserEvent } from 'ol';
import MapBrowserEventType from 'ol/MapBrowserEventType';
import { doubleClick } from 'ol/events/condition';
import { Point } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { Draw, Modify, Snap } from 'ol/interaction';
import { fromLonLat, toLonLat } from '../common/coordinate';
import { getStoreApi } from '../common/store-api';
import { Event } from '../event/types';
import { getModelId } from '../layer/utils';
import InteractionManager from './InteractionManager';
import createDrawStyle from './createDrawStyle';
import type {
  PoiAddEventPayload,
  PoiDeleteEventPayload,
  PoiLonLatChangeEventPayload,
} from '../event/types';

// An edit for Point of Interest
export default function createPoiEdit(im: InteractionManager) {
  const map = im.getMap();
  const proj = map.getView().getProjection();
  const storeApi = getStoreApi(map);
  const poiFeatures = new Collection<Feature<Point>>();

  const newPoiFeatures = new Collection<Feature<Point>>();
  newPoiFeatures.on('add', ({ element: newPoiFeature }) => {
    const point = newPoiFeature.getGeometry();
    const coordinate = point.getCoordinates();
    const lonLat = toLonLat(coordinate, proj);
    im.dispatchEvent(new Event<PoiAddEventPayload>('poi-add', lonLat));
  });
  const draw = new Draw({
    type: GeometryType.POINT,
    features: newPoiFeatures,
    style: createDrawStyle(map, {
      getPointerTip: () => 'Click to place a data point.',
    }),
  });

  const modify = new PoiModify(
    {
      features: poiFeatures,
      style: createDrawStyle(map, {
        getPointerTip: () =>
          'Drag to modify, or double-click to delete this data point.',
      }),
      deleteCondition: doubleClick,
    },
    draw
  );
  modify.addEventListener('poi-lonlat-change', (event) => {
    im.dispatchEvent(event as Event<PoiLonLatChangeEventPayload>);
  });
  modify.addEventListener('poi-delete', (event) => {
    im.dispatchEvent(event as Event<PoiDeleteEventPayload>);
  });

  const snap = new Snap({ features: new Collection() });

  const interactions = [draw, modify, snap];

  let _feature;

  return {
    selectFeature(feature) {
      _feature = feature;
      snap.addFeature(_feature);
      this.updatePoiFeatures();
    },
    getFeature() {
      return _feature;
    },
    activate(map) {
      interactions.forEach((interaction) => {
        map.addInteraction(interaction);
      });
    },
    destroy(map) {
      interactions.forEach((interaction) => {
        map.removeInteraction(interaction);
      });
    },
    checkIsWorking() {
      return true;
    },
    getState() {
      const { layerManager } = im;
      const feature = this.getFeature();
      const layer = layerManager.findLayerByFeature(feature);

      return {
        layerModelId: getModelId(layer),
      };
    },
    updatePoiFeatures() {
      poiFeatures.clear();

      const polySample = storeApi.findSampleById(_feature.getId());
      const pois = polySample?.points_of_interest ?? [];
      pois.forEach((poi, index) => {
        const { longitude, latitude } = poi;
        const coordinate = fromLonLat({ longitude, latitude }, proj);
        const poiFeature = new Feature(new Point(coordinate));
        poiFeature.set('poiIndex', index);
        poiFeatures.push(poiFeature);
      });
    },
  };
}

class PoiModify extends Modify {
  draw: Draw;
  constructor(options, draw: Draw) {
    super(options);
    this.draw = draw;
    this.on('modifyend', (event) => {
      const [poiFeature] = event.features.getArray() as Feature<Point>[];
      const index = poiFeature.get('poiIndex');
      if (event.mapBrowserEvent.type !== 'dblclick') {
        const coordinate = (poiFeature.getGeometry() as Point).getCoordinates();
        const lonLat = toLonLat(
          coordinate,
          this.getMap().getView().getProjection()
        );
        this.dispatchEvent(
          new Event<PoiLonLatChangeEventPayload>('poi-lonlat-change', {
            index,
            lonLat,
          })
        );
      } else {
        this.dispatchEvent(
          new Event<PoiDeleteEventPayload>('poi-delete', index)
        );
      }
    });
  }
  handleEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
    const result = super.handleEvent(mapBrowserEvent);

    if (
      !mapBrowserEvent.map.getView().getInteracting() &&
      mapBrowserEvent.type == MapBrowserEventType.POINTERMOVE &&
      !this.handlingDownUpSequence
    ) {
      const hasVertexFeature =
        this.getOverlay().getSource().getFeatures().length === 1;
      this.draw.setActive(!hasVertexFeature);
    }

    return result;
  }
}
