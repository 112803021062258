<script lang="ts" setup>
import { ref, watch } from 'vue';

const isOpen = ref(false);
const startY = ref(0);
const endY = ref(0);
const height = ref<string>('0 !important');
const isDragging = ref(false);
defineExpose({
  close() {
    isOpen.value = false;
  },
});

watch(isOpen, (newVal) => {
  if (newVal) {
    height.value = '60vh';
  } else {
    height.value = '0 !important';
  }
});

function toggleDrawer() {
  isOpen.value = !isOpen.value;
}

function onTouchStart(event: MouseEvent | TouchEvent) {
  endY.value = startY.value =
    (event as MouseEvent).clientY ?? (event as TouchEvent).touches[0].clientY;
  isDragging.value = true;
}

function onTouchEnd(event: MouseEvent | TouchEvent) {
  const diffY = endY.value - startY.value;
  height.value = isOpen.value ? '60vh' : '0';
  isDragging.value = false;

  const tolerance = 40;
  if (Math.abs(diffY) < tolerance) {
    return;
  }
  // Swipe up to open, down to close; adjust sensitivity as needed
  if (diffY < -tolerance && !isOpen.value) {
    toggleDrawer();
  } else if (diffY > tolerance && isOpen.value) {
    toggleDrawer();
  }
}

function onTouchMove(event: MouseEvent | TouchEvent) {
  if (!isDragging.value) {
    return;
  }
  endY.value =
    (event as MouseEvent).clientY ?? (event as TouchEvent).touches[0].clientY;
  const diffY = endY.value - startY.value;
  if (!isOpen.value) {
    height.value = 'min(60vh, ' + -diffY + 'px)';
  } else {
    height.value = 'calc(60vh - ' + (diffY + 50) + 'px) !important';
  }
}
</script>

<template>
  <div>
    <div class="drawer-container" :class="{ open: isOpen }">
      <div
        class="drawer-content cursor-pointer"
        style="max-height: 60vh; height: auto"
        :style="isOpen ? 'background-color: white' : ''"
        :class="{ 'bg-dark text-white': !isOpen }"
      >
        <div
          class="p-4 pb-3"
          style="user-select: none"
          @click="toggleDrawer"
          @mousedown.stop="onTouchStart"
          @mouseup.stop.prevent="onTouchEnd"
          @mouseleave.stop="onTouchEnd"
          @mousemove.stop="onTouchMove"
          @touchstart.stop="onTouchStart"
          @touchend.stop="onTouchEnd"
          @touchmove.stop="onTouchMove"
        >
          <div class="handle"></div>
          <slot name="header">Default Header</slot>
        </div>
        <div
          :style="{
            height,
            overflow: isOpen ? 'auto' : 'hidden',
            cursor: 'default',
          }"
          class="bg-white"
          @touchstart.stop
        >
          <slot />
        </div>
      </div>
    </div>
    <div class="overlay" v-show="isOpen" @click="toggleDrawer"></div>
  </div>
</template>

<style scoped>
.drawer-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: -1px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: visibility 0s, opacity 0.3s ease;
  z-index: 1001;
}

.drawer-container.open {
  visibility: visible;
  opacity: 1;
}

.drawer-content {
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-sizing: border-box;
  position: relative;
  transition: background-color 0.3s ease;
}

.drawer-content .handle {
  width: 50%;
  height: 4px;
  background-color: #ccc;
  border-radius: 2px;
  position: absolute;
  top: 8px;
  left: 25%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  z-index: 1000;
}
</style>
