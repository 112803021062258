<script setup lang="ts">
import useFigureStore from '@/js/stores/figure';
import {
  sortFigures,
  getFigureTitle,
} from '@component-library/business-logic/mapping/figure';
import { Project } from '@component-library/project';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

defineProps<{
  project: Project;
}>();

const toggleFigureDropdown = ref(false);
const figureStore = useFigureStore();
const { figures, selectedFigureId } = storeToRefs(figureStore);
const { getSelectedFigure, setSelectedFigureId } = figureStore;
const selectedFigure = computed(() => getSelectedFigure()!);

const sortedFigures = computed(() => {
  return sortFigures([...figures.value]);
});

function handleSelectedFigureChange(id: number) {
  setSelectedFigureId(id);

  toggleFigureDropdown.value = false;
}
</script>

<template>
  <div
    v-if="project"
    class="view-header"
    v-click-outside="() => (toggleFigureDropdown = false)"
  >
    <div
      :class="[
        'flex-grow-1 d-flex align-items-center justify-content-between w-100 p-2 dropdown-toggle',
        { 'basemap-figure-bg': selectedFigure?.is_basemap ?? false },
      ]"
      style="box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 6%)"
      @click="toggleFigureDropdown = !toggleFigureDropdown"
    >
      <div class="fw-medium">
        {{ project.project_name }} |
        {{ selectedFigure ? getFigureTitle(selectedFigure) : '' }}
      </div>
    </div>

    <transition name="dropdown">
      <div
        class="dropdown-menu box-shadow action-menu p-2 show w-100 mt-1"
        v-show="toggleFigureDropdown"
      >
        <div class="gap-2 d-flex flex-column">
          <div
            v-for="figure in sortedFigures"
            class="dropdown-item text-center p-2 fw-medium"
            :class="{
              'active text-white': figure.id === selectedFigureId,
              'basemap-figure-bg': figure.is_basemap,
            }"
            @click="handleSelectedFigureChange(figure.id)"
          >
            {{ getFigureTitle(figure) }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.dropdown-leave-to,
.dropdown-leave-active {
  display: flex !important;
}
</style>
