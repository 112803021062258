<script setup lang="ts">
import { useStore } from '@/js/store';
import { StackableModalType } from '@/js/types/modal-stack';
import IconButton from '@component-library/components/IconButton.vue';
import type { AppLinkConfigContext } from '@component-library/gather';
import { checkIsNewItem } from '@component-library/gather';
import { getSampleTitle } from '@maps/lib/olbm';
import type { Sample } from '@maps/lib/olbm/layer/sample/types';
import { computed, inject } from 'vue';

const props = defineProps<{ item: Sample; isLinked: boolean }>();

const store = useStore();

const { app, item, iconButtonContainer, hasChangedInputValues } =
  inject<AppLinkConfigContext>('appLinkConfigContext')!;
const isNewItem = computed<boolean>(() => {
  return checkIsNewItem(item);
});

const title = computed<string>(() => {
  return getSampleTitle(props.item);
});

const isEditDisabled = computed<boolean>(() => {
  return isNewItem.value || hasChangedInputValues.value;
});

const reasonForDisabledEdit = computed<string | undefined>(() => {
  if (isNewItem.value) {
    return `The current ${app.title} item is a new one. Please save it before editing the ${title.value} item.`;
  } else if (hasChangedInputValues.value) {
    return `There are changes in the current ${app.title} item. Please save it before editing the ${title.value} item.`;
  }

  return undefined;
});

function handleEdit() {
  store.dispatch('pushToModalStack', {
    type: StackableModalType.SampleModal,
    payload: props.item.id,
  });
}
</script>

<template>
  <div
    :class="[
      'd-flex justify-content-between align-items-center p-2',
      {
        'linked-item': isLinked,
        'linking-pending-item': !isLinked,
      },
    ]"
  >
    <div>{{ title }}</div>
    <div>
      <IconButton
        title="Edit"
        :container="iconButtonContainer"
        :action="handleEdit"
        :is-disabled="isEditDisabled"
        :reason="reasonForDisabledEdit"
        ><i class="fas fa-pencil-alt"></i>
      </IconButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.linked-item {
  background-color: var(--linked-item-bg-color) !important;
}
.linking-pending-item {
  background-color: var(--linking-pending-item-bg-color) !important;
}
</style>
