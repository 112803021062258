<script>
export default {
  name: 'DropdownTestValueInput',
};
</script>

<script setup>
import InputTag from '@component-library/components/InputTag.vue';
import { computed, nextTick, onMounted, ref } from 'vue';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
  value: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['input']);

const isListVisible = ref(false);
const dropdownInput = ref(null);
const searchInput = ref(null);
const search = ref(null);
const searchResult = ref([]);

const isSearchable = computed(() => {
  return props.field.options.options?.length > 5;
});

const value_ = computed({
  get() {
    const { has_multiple } = props.field.options;
    return has_multiple ? JSON.parse(props.value.value) : props.value.value;
  },
  set(value) {
    const { has_multiple } = props.field.options;
    emit('input', {
      value: has_multiple ? JSON.stringify(value ?? []) : value,
      value2: props.value.value2,
    });
  },
});

function hideList() {
  isListVisible.value = false;
}
function removeFocus() {
  dropdownInput.value.blur();
}
async function toggleList() {
  isListVisible.value = !isListVisible.value;
  if (isListVisible.value) {
    await nextTick();
    searchInput.value?.focus();
  }
}
function setValue(value) {
  value_.value = value;
  hideList();
  search.value = null;
  searchOptions();
}
function searchOptions() {
  const { options = [] } = props.field.options;
  if (search.value) {
    searchResult.value = options.filter((item) =>
      item.toLowerCase().includes(search.value.toLowerCase())
    );
  } else {
    searchResult.value = options;
  }
}
function handleClickOption(option) {
  const { has_multiple } = props.field.options;
  if (has_multiple) {
    if (!value_.value.includes(option)) {
      value_.value = [...value_.value, option];
    } else {
      value_.value = value_.value.filter((item) => item !== option);
    }
  } else {
    value_.value = option;
    hideList();
  }
}

onMounted(() => {
  searchOptions();
});
</script>

<template>
  <div v-click-outside="hideList" class="form-group position-relative">
    <div class="input-group flex-nowrap">
      <input
        v-if="!field.options.has_multiple"
        type="text"
        class="form-control bg-white cursor-pointer"
        ref="dropdownInput"
        :value="value_"
        autocomplete="off"
        @focus="removeFocus"
        @click.prevent="toggleList"
      />
      <InputTag
        v-else
        class="flex-grow-1"
        :value="value_"
        :readOnly="true"
        @click="toggleList"
      />

      <button
        class="btn btn-danger"
        type="button"
        @click.prevent="setValue(null)"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div v-if="isListVisible" class="dropdown-content">
      <input
        type="text"
        class="form-control border-0"
        v-model="search"
        placeholder="Search options..."
        v-if="isSearchable"
        ref="searchInput"
        @input="searchOptions"
        @keyup.enter="searchOptions"
      />
      <div
        class="d-flex align-items-center dropdown-option"
        v-for="(option, optionIndex) in searchResult"
        :key="optionIndex"
      >
        <input
          v-if="field.options.has_multiple"
          type="checkbox"
          class="me-2"
          :value="option"
          v-model="value_"
        />
        <a
          class="flex-grow-1"
          href="#"
          @click.prevent="handleClickOption(option)"
          >{{ option }}</a
        >
      </div>
      <a v-if="searchResult && !searchResult.length" class="text-muted">
        No options for "{{ search }}"
      </a>
    </div>
  </div>
</template>

<style scoped>
/* Dropdown Content*/
.dropdown-content {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 200;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  max-height: 25rem;
  overflow-y: scroll;
}

/* Links inside the dropdown */
.dropdown-content .dropdown-option {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: #000 !important;
}

/* Change color of dropdown links on hover */
.dropdown-content .dropdown-option:hover {
  background-color: #ddd;
  color: #000 !important;
}
</style>
