<script setup lang="ts">
import useMapsApi from '@/js/composables/useMapsApi';
import type {
  App,
  AppLinkConfig,
  GatherField,
} from '@component-library/gather';
import makeId from '@component-library/local-id.mjs';
import * as mpConstants from '@component-library/widgets/marker-picker/constants';
import MarkerPicker from '@component-library/widgets/marker-picker/index.vue';
import { produce } from 'immer';
import { computed, onMounted, ref } from 'vue';

const DEFAULT_COLOR = '#000000';

const props = defineProps<{
  isExpanded: boolean;
  app: App;
  linkField: GatherField;
  linkedApp: App;
}>();

const emit = defineEmits<{
  (event: 'toggle', value: boolean): void;
  (event: 'change', value: AppLinkConfig): void;
}>();

const { generateMarkerIdentifier } = useMapsApi();
const isEmbeddedId = ref(makeId());
const maxNumberOfItemsId = ref(makeId());
const header = ref<HTMLDivElement | null>(null);
const maxNumberOfItemsInput = ref<HTMLInputElement | null>(null);

const isConfigured = computed(() => {
  return !!props.linkedApp.link_configs?.some(
    (lc) => lc.linkFieldId === props.linkField.id
  );
});
const linkConfig = computed<AppLinkConfig>({
  get() {
    return (
      props.linkedApp.link_configs?.find(
        (lc) => lc.linkFieldId === props.linkField.id
      ) ?? {
        linkFieldId: props.linkField.id,
        isEmbedded: false,
        maxNumberOfItems: 1,
        icon: 0,
        color: DEFAULT_COLOR,
      }
    );
  },
  set(value) {
    emit('change', value);
  },
});
const isEmbedded = computed<boolean>({
  get() {
    return linkConfig.value.isEmbedded;
  },
  set(value) {
    linkConfig.value = produce(linkConfig.value, (draft) => {
      draft.isEmbedded = value;
    });
  },
});

const maxNumberOfItems = computed<number | string>({
  get() {
    return linkConfig.value.maxNumberOfItems;
  },
  set(value) {
    value = Math.max(
      1,
      Math.abs(
        Math.round(typeof value === 'string' ? parseFloat(value) : value)
      )
    );
    if (Number.isNaN(value)) {
      value = 1;
      setTimeout(() => {
        maxNumberOfItemsInput.value?.select();
      }, 0);
    }
    linkConfig.value = produce(linkConfig.value, (draft) => {
      draft.maxNumberOfItems = value as number;
    });
  },
});

const isIconAvailable = computed<boolean>(() => {
  const { drawing_type: appDrawingType } = props.app;
  const { drawing_type: linkedAppDrawingType } = props.linkedApp;
  return (
    ['any', 'non-spatial'].includes(appDrawingType) &&
    ['any', 'point'].includes(linkedAppDrawingType)
  );
});

const selectedMarkerId = computed<number>({
  get() {
    return linkConfig.value.icon + 1;
  },
  set(value) {
    linkConfig.value = produce(linkConfig.value, (draft) => {
      draft.icon = value - 1;
    });
  },
});

const isColorChangeable = ref<boolean>(true);
const color = computed<string>({
  get() {
    return linkConfig.value.color;
  },
  set(value) {
    linkConfig.value = produce(linkConfig.value, (draft) => {
      draft.color = value;
    });
  },
});
const colorTitle = computed<string>(() => {
  return isIconAvailable.value && !isColorChangeable.value
    ? 'The colour of the selected marker is not changeable.'
    : '';
});

function handleHeaderClick() {
  emit('toggle', !props.isExpanded);
}

function handleMarkerSelect(value) {
  selectedMarkerId.value = value.id;
  isColorChangeable.value = !!value.is_color_changeable;
}

onMounted(async () => {
  if (isConfigured.value) {
    return;
  }

  const { icon: newIcon, color: newColor } = await generateMarkerIdentifier();
  const newLinkConfig = produce(linkConfig.value, (draft) => {
    draft.icon = newIcon;
    draft.color = newColor;
  });
  linkConfig.value = newLinkConfig;
});
</script>

<template>
  <div>
    <div
      ref="header"
      :class="[
        'd-flex align-items-center justify-content-between p-2 bg-light dropdown-toggle',
        { toggled: isExpanded },
      ]"
      @click="handleHeaderClick"
    >
      {{ linkedApp.title }}
    </div>
    <div v-if="isExpanded" class="mt-2">
      <div class="form-check">
        <input
          :id="isEmbeddedId"
          class="form-check-input"
          type="checkbox"
          v-model="isEmbedded"
        />
        <label class="form-check-label" :for="isEmbeddedId">
          Embed <b>{{ app.title }}</b> items in a
          <b>{{ linkedApp.title }}</b> item
        </label>
      </div>

      <div class="form-group mb-2">
        <label :for="maxNumberOfItemsId" class="form-label">
          Max number of <b>{{ app.title }}</b> items
        </label>
        <input
          :id="maxNumberOfItemsId"
          ref="maxNumberOfItemsInput"
          type="number"
          class="form-control form-control-sm"
          v-model.number="maxNumberOfItems"
        />
      </div>
      <div v-if="isIconAvailable" class="form-group mb-3">
        <label class="form-label">
          <b>{{ app.title }}</b> Icon
        </label>
        <MarkerPicker
          :appId="mpConstants.APP_GATHER"
          :selectedMarkerId="selectedMarkerId"
          @markerSelect="handleMarkerSelect"
        />
      </div>
      <div v-if="isIconAvailable" class="form-group position-relative mb-3">
        <label class="form-label">
          <b>{{ app.title }}</b> Colour {{ `(${color ?? DEFAULT_COLOR})` }}
        </label>

        <input
          class="form-control form-control-sm"
          type="color"
          :disabled="!isColorChangeable"
          :title="colorTitle"
          v-model="color"
        />
      </div>
    </div>
  </div>
</template>
