<script>
export default {
  name: 'TextTestValueInput',
};
</script>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
  value: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['input']);

const text = computed({
  get() {
    return props.value.value;
  },
  set(value) {
    emit('input', {
      value,
      value2: props.value.value2,
    });
  },
});
</script>

<template>
  <input type="text" class="form-control form-control-sm" v-model="text" />
</template>
