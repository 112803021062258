<template>
  <modal :show="show" @close="close">
    <template v-slot:header> Generate Chain of Custody </template>
    <div class="modal-body">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="send_to_lab"
          v-model="sendToLaboratory"
        />
        <label class="form-check-label" for="send_to_lab">
          Would you like your CoC sent directly to the laboratory?
        </label>
      </div>
    </div>
    <div class="modal-footer">
      <button-spinner
        type="submit"
        :is-loading="generating"
        class="btn btn-primary"
        @click.native="generateCoc"
      >
        <i class="fas fa-print"></i> Generate CoC
      </button-spinner>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';

import Modal from '@/js/components/Modal.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';

export default {
  name: 'TabImporterModal',
  props: ['show', 'generating'],
  data: () => ({
    sendToLaboratory: false,
  }),
  components: {
    Modal,
    ButtonSpinner,
  },
  methods: {
    close() {
      this.$emit('close');
    },
    generateCoc() {
      this.$emit('generateCoc', this.sendToLaboratory);
    },
  },
};
</script>
