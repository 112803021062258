<script setup lang="ts">
import {
  STROKE_PATTERN_DATA,
  STROKE_PATTERN_SOLID,
} from '../business-model/common';
import StrokePattern from './StrokePattern.vue';
import Tick from './Tick.vue';

withDefaults(
  defineProps<{
    label?: string;
    selectedStrokePattern?: number;
    strokePatterns?: number[];
  }>(),
  {
    selectedStrokePattern: STROKE_PATTERN_SOLID,
    strokePatterns: () =>
      Object.keys(STROKE_PATTERN_DATA).map((key) => parseInt(key, 10)),
    label: 'Stroke pattern',
  }
);

const emit = defineEmits(['change']);

function handleClick(value) {
  emit('change', value);
}
</script>

<template>
  <div class="form-group stroke-pattern-chooser">
    <label class="form-label"> {{ label }}</label>
    <div class="d-flex stroke-pattern-row">
      <StrokePattern
        v-for="sp in strokePatterns"
        :key="`stroke-pattern-${sp}`"
        :value="sp"
        :title="STROKE_PATTERN_DATA[sp].title"
        :color="STROKE_PATTERN_DATA[sp].color"
        @click="handleClick"
      >
        <Tick v-if="selectedStrokePattern === sp" />
      </StrokePattern>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stroke-pattern-chooser {
  .stroke-pattern-row {
    gap: 10px;
  }
}
</style>
