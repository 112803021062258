<script setup lang="ts">
import type { StylingRuleOnGatherApp as StylingRule } from '@component-library/business-logic/mapping/styling-rule';
import type { App } from '@component-library/gather';
import useStylingRules from '../../../composables/useStylingRules';
import Target from './Target.vue';

const props = defineProps<{ app: App }>();

const emit = defineEmits(['add', 'edit', 'delete']);

const { tempStylingRules, getConditionDescription } = useStylingRules(
  props.app
);

function getCondition(stylingRule: StylingRule) {
  const { condition } = stylingRule;
  const { content } = getConditionDescription(condition);
  return content;
}

async function handleAdd() {
  emit('add');
}

function handleEdit(stylingRule: StylingRule) {
  emit('edit', stylingRule);
}

function handleDelete(stylingRule: StylingRule) {
  emit('delete', stylingRule);
}
</script>

<template>
  <div>
    <div>
      <div class="table-responsive default-styling">
        <table class="table">
          <thead>
            <tr class="heading-row">
              <th>Name</th>
              <th>Target</th>
              <th>Condition</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="tsr in tempStylingRules"
              :key="`temp-styling-rule-${tsr.id}`"
            >
              <td>
                {{ tsr.name }}
              </td>
              <td><Target :app="app" :styling-rule="tsr" /></td>
              <td v-html="getCondition(tsr)"></td>
              <td class="text-center">
                <div class="btn-group btn-group-sm">
                  <button
                    type="button"
                    class="btn btn-light"
                    @click="handleEdit(tsr)"
                  >
                    <i class="fas fa-pencil-alt fa-fw" />
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    @click="handleDelete(tsr)"
                  >
                    <i class="far fa-trash-alt fa-fw" />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="!tempStylingRules.length" class="text-center py-2">
          No data found
        </div>
      </div>

      <button
        class="btn btn-primary btn-lg py-2 w-100 fw-bold mt-2"
        @click="handleAdd"
      >
        <i class="fas fa-plus"></i>
        Create Styling Rule
      </button>
    </div>
  </div>
</template>
