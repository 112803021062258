<template>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table">
          <thead class="bg-primary text-white">
            <th class="text-center fw-bold">
              <input
                class="form-check-input"
                type="checkbox"
                id="selectAll"
                @change="selectAllSamples"
                :class="{
                  'bg-light': samplesToGenerate.length != samples.length,
                }"
              />
            </th>
            <th class="fw-bold">Name</th>
            <th class="fw-bold">Date</th>
            <th class="fw-bold">Time</th>
            <th class="fw-bold">
              <div class="position-relative">
                <div
                  class="dropdown-toggle"
                  @click="() => (showMatrixDropdown = !showMatrixDropdown)"
                >
                  Type
                </div>
                <div
                  class="dropdown-menu table-dropdown-menu"
                  :class="{
                    'd-block': showMatrixDropdown,
                  }"
                >
                  <div class="px-3 py-1">
                    <select class="form-control" v-model="matrix">
                      <option :value="0">Soil</option>
                      <option :value="1">Water</option>
                      <option :value="2">Leachate</option>
                      <option :value="3">Soil Gas</option>
                    </select>

                    <div
                      class="d-flex justify-content-end align-items-center mt-2"
                    >
                      <button
                        class="btn btn-sm btn-dark"
                        @click="setMatrixColumn"
                      >
                        Set Column
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th class="fw-bold">
              <div class="position-relative">
                <div
                  class="dropdown-toggle"
                  @click="
                    () => (showTestRequiredDropdown = !showTestRequiredDropdown)
                  "
                >
                  Tests Required
                </div>
                <div
                  class="dropdown-menu table-dropdown-menu"
                  :class="{
                    'd-block': showTestRequiredDropdown,
                  }"
                >
                  <div class="px-3 py-1">
                    <textarea class="form-control" v-model="testsRequired" />

                    <div
                      class="d-flex justify-content-end align-items-center mt-2"
                    >
                      <button
                        class="btn btn-sm btn-dark"
                        @click="setTestRequiredColumn"
                      >
                        Set Column
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </th>
          </thead>
          <tbody>
            <tr v-for="sample in samples" :key="sample.id">
              <td class="text-center">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="'sample-' + sample.id"
                  v-model="samplesToGenerate"
                  :value="sample.id"
                />
                <label class="form-check-label" :for="sample.id"></label>
              </td>
              <td>
                {{ sample.name }}
              </td>
              <td>
                {{ getRowDate(sample.sample_created) }}
              </td>
              <td>
                {{ getRowTime(sample.sample_created) }}
              </td>
              <td>
                <select class="form-control" v-model="sample.matrix">
                  <option :value="0">Soil</option>
                  <option :value="1">Water</option>
                </select>
              </td>
              <td>
                <textarea
                  class="form-control"
                  v-model="sample.tests_required"
                  cols="30"
                  rows="2"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

import useDate from '@/js/composables/useDate.js';

export default {
  props: {
    samples: Array,
  },
  data: () => ({
    samplesToGenerate: [],
    showMatrixDropdown: false,
    showTestRequiredDropdown: false,

    matrix: 0,
    testsRequired: null,
  }),
  methods: {
    setTestRequiredColumn() {
      this.samples.forEach((sample) => {
        sample.tests_required = this.testsRequired;
      });

      this.showTestRequiredDropdown = false;
    },
    setMatrixColumn() {
      this.samples.forEach((sample) => {
        sample.matrix = this.matrix;
      });

      this.showMatrixDropdown = false;
    },
    selectAllSamples(e) {
      if (e.target.checked) {
        this.samplesToGenerate = this.samples.map((s) => s.id);
        return;
      }

      this.samplesToGenerate = [];
    },
    getRowDate(date) {
      const { convertDate } = useDate();
      return convertDate(date);
    },
    getRowTime(date) {
      if (!date) {
        return '';
      }

      return moment.utc(date).local().format('hh:mm A');
    },
  },
  mounted() {
    this.samplesToGenerate = this.samples
      .filter((s) => !s.exported)
      .map((s) => s.id);
  },
};
</script>
<style scoped>
.table-responsive {
  max-height: 75vh;
}
</style>
