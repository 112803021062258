import { Map, Overlay } from 'ol';
import DragOverlay from 'ol-ext/interaction/DragOverlay';
import { Coordinate } from 'ol/coordinate';
import { getStoreApi } from '../../common/store-api';
import type { RotationListeners } from '../../common/utils';
import { appendAsterisk, enableRotation } from '../../common/utils';
import LayerManager from '../../layer/LayerManager';
import {
  ICON_SIZE_ADJUST_FACTOR,
  LABEL_SIZE_ADJUST_FACTOR,
} from '../../layer/sample/getDefaultSampleStyle';
import getSampleStyle from '../../layer/sample/getSampleStyle';
import type {
  Sample,
  SampleLayer,
  SampleStyle,
} from '../../layer/sample/types';
import {
  checkIsGlued,
  getOverlaySize,
  getSampleIcon,
} from '../../layer/sample/utils';
import { getLayoutZoom } from '../../measurement/layout';
import applyStyle from '../../style/applyStyle';
import { createTextShadow } from '../../style/text';

/**
 * A component used to edit a sample on the map.
 *
 * A sample on the map consists of an icon overlay and a label overlay.
 * The icon overlay always exists, however, when the sample is a composite,
 * there is no icon in the icon overlay. The label overlay could be null
 * because the label of a sample could be hidden.
 *
 *
 * Moving the icon overlay always causes the label overlay to move too.
 *
 * When the icon overlay and the label overlay stay together(glued), they
 * share the sample position as their position.
 * -------------------------------------------------------
 * |                        .Sample Position             | Icon Overlay
 * |-----------------------------------------------------|
 * |                                                     | Label Overlay
 * -------------------------------------------------------
 *
 * When the icon overlay and the label overlay stay apart, the position of the
 * icon overlay is the sample position. The position of the label overlay changes
 * to be one right under the sample position. When moving the label overlay,
 * the icon overlay doesn't move.
 * -------------------------------------------------------
 * |                        .Sample Position             | Icon Overlay
 * |------------------------.Label Overlay Position------|
 * |                                                     | Label Overlay
 * -------------------------------------------------------
 *
 */
export default class EditableSample {
  private dragging: Overlay | boolean;
  map: Map;
  layerManager: LayerManager;
  sample: Sample;
  isNew: boolean;
  active: boolean;
  drag: DragOverlay;
  iconOverlay: Overlay | null = null;
  labelOverlay: Overlay | null = null;
  position: Coordinate | null = null;
  labelPosition: Coordinate | null = null;
  sampleLayer: SampleLayer | null = null;
  angle: number;

  constructor(
    map: Map,
    layerManager: LayerManager,
    sample: Sample,
    isNew: boolean = true
  ) {
    this.map = map;
    this.layerManager = layerManager;
    this.sample = sample;
    this.isNew = isNew;
    this.active = false;
    this.dragging = false;
    this.angle = sample.icon_rotation;

    this.drag = new DragOverlay({ overlays: [], centerOnClick: false });
    this.drag.on('dragstart', ({ overlay, coordinate }) => {
      if (overlay === this.labelOverlay) {
        if (!this.checkIsGlued()) {
          return;
        }

        this.labelPosition = getDefaultLabelPosition(
          this.map,
          this.iconOverlay!
        );
        this.refresh();
      }
    });
    this.drag.on('dragging', ({ overlay, coordinate }) => {
      if (overlay === this.iconOverlay) {
        this.setPosition(coordinate);
      } else if (overlay === this.labelOverlay) {
        this.labelPosition = coordinate;
        // Check whether the overlays should be glued.
        const startLabelPosition = getDefaultLabelPosition(
          this.map,
          this.iconOverlay!
        );
        const [startX, startY] = this.map.getPixelFromCoordinate(
          startLabelPosition!
        );
        const endLabelPosition = this.labelOverlay!.getPosition()!;
        const [endX, endY] = this.map.getPixelFromCoordinate(endLabelPosition);
        const offsetX = Math.abs(endX - startX);
        const offsetY = Math.abs(endY - startY);
        const threshold = 10 * getLayoutZoom(map);
        if (offsetX <= threshold && offsetY <= threshold) {
          this.labelPosition = this.position;
        }
        this.refresh();
      }
    });
  }
  getIconOverlayHeight(): number | undefined {
    return this.iconOverlay ? getOverlaySize(this.iconOverlay)[1] : undefined;
  }
  checkIsGlued() {
    return checkIsGlued(this.map, {
      position: this.position!,
      labelPosition: this.labelPosition!,
    });
  }
  activate(edit) {
    if (this.active) {
      return;
    }

    this.sampleLayer = this.layerManager.findSampleLayerBySampleId(
      this.sample.id
    )!;
    edit.layer = this.sampleLayer;

    this.position = this.sampleLayer.getPosition(this.sample)!;
    // The newly created sample should use the sample position as its label position.
    this.labelPosition =
      (this.isNew ? null : this.sampleLayer.getLabelPosition(this.sample)) ??
      this.position;

    this.map.addInteraction(this.drag);

    const storeApi = getStoreApi(this.map);
    const figure = storeApi.getSelectedFigure()!;
    const sampleStyle = getSampleStyle(this.map, figure, this.sample);

    this.iconOverlay = createIconOverlay(this.map, this.position, sampleStyle, {
      onRotateStart: () => {
        this.dragging = this.drag._dragging;
        this.drag._dragging = false;
      },
      onRotate: (angle) => {
        this.angle = angle;
      },
      onRotateEnd: () => {
        this.drag._dragging = this.dragging;
        this.dragging = false;
      },
    });
    this.map.addOverlay(this.iconOverlay);
    this.drag.addOverlay(this.iconOverlay);

    if (!sampleStyle.isLabelHidden && sampleStyle.title?.trim()) {
      this.labelOverlay = createLabelOverlay(
        this.map,
        this.position,
        sampleStyle
      );
      this.map.addOverlay(this.labelOverlay);
      this.drag.addOverlay(this.labelOverlay);
    }

    this.active = true;
  }
  deactivate() {
    if (!this.active) {
      return;
    }

    this.map.removeInteraction(this.drag);

    this.map.removeOverlay(this.iconOverlay!);
    this.drag.removeOverlay(this.iconOverlay);
    this.iconOverlay = null;

    if (this.labelOverlay) {
      this.map.removeOverlay(this.labelOverlay);
      this.drag.removeOverlay(this.labelOverlay);
      this.labelOverlay = null;
    }

    this.sampleLayer = null;
    this.position = null;
    this.labelPosition = null;
    this.active = false;
  }
  /**
   *
   * @param {ol.Coordinate} position The position of the sample.
   */
  setPosition(position) {
    if (!this.checkIsGlued()) {
      const oldPixel = this.map.getPixelFromCoordinate(this.position!);
      const pixel = this.map.getPixelFromCoordinate(position);
      const offsetX = pixel[0] - oldPixel[0];
      const offsetY = pixel[1] - oldPixel[1];
      const oldLabelPixel = this.map.getPixelFromCoordinate(
        this.labelPosition!
      );
      const labelPixel = [
        oldLabelPixel[0] + offsetX,
        oldLabelPixel[1] + offsetY,
      ];
      this.labelPosition = this.map.getCoordinateFromPixel(labelPixel);
    } else {
      this.labelPosition = position;
    }
    this.position = position;

    this.refresh();
  }
  refresh() {
    if (!this.active) {
      return;
    }

    // Set the position of the icon overlay.
    if (this.iconOverlay) {
      this.iconOverlay.setPosition(this.position!);
      const [ioWidth, ioHeight] = getOverlaySize(this.iconOverlay);
      this.iconOverlay.setOffset([-(ioWidth / 2), -(ioHeight / 2)]);
    }

    // Set the position of the label overlay.
    if (this.labelOverlay) {
      this.labelOverlay.setPosition(this.labelPosition!);
      const [labelWidth] = getOverlaySize(this.labelOverlay);
      if (this.checkIsGlued()) {
        const ioHeight = this.getIconOverlayHeight();
        this.labelOverlay.setOffset([
          -(labelWidth / 2),
          ioHeight !== undefined ? ioHeight / 2 : 0,
        ]);
      } else {
        this.labelOverlay.setOffset([-(labelWidth / 2), 0]);
      }
    }
  }
  invalidate(edit) {
    if (!this.active) {
      return;
    }

    this.deactivate();
    this.isNew = false;
    this.activate(edit);
    this.refresh();
  }
}

/**
 * When the overlays are not glued, the label overlay acts like a
 * satellite of the icon overlay.
 * ----------------
 * |     icon     |
 * -------.--------
 *       The returned position
 * @param {*} map
 * @param {*} iconOverlay
 * @returns
 */
function getDefaultLabelPosition(map: Map, iconOverlay: Overlay): Coordinate {
  const position = iconOverlay.getPosition()!;
  const pixel = map.getPixelFromCoordinate(position);
  const [, ioHeight] = getOverlaySize(iconOverlay);
  const labelPixel = [pixel[0], pixel[1] + ioHeight / 2];
  return map.getCoordinateFromPixel(labelPixel);
}

function createIconOverlay(
  map: Map,
  position: Coordinate,
  sampleStyle: SampleStyle,
  listeners?: RotationListeners
) {
  const {
    id,
    isDefault,
    width,
    isIconVisible,
    iconSize,
    icon,
    color,
    iconRotation,
  } = sampleStyle;
  const element = document.createElement('div');
  element.setAttribute(
    'class',
    'editable-sample-icon-container d-flex flex-column justify-content-center align-items-center'
  );
  element.setAttribute('data-sample-id', String(id!));
  element.setAttribute('data-is-default', String(isDefault!));
  element.style.cursor = 'move';

  let elementStyle: any = {
    width: `${width!}px`,
    wordBreak: 'break-word',
  };
  if (!isIconVisible) {
    // A composite item doesn't have an icon, so height is required
    elementStyle = {
      ...elementStyle,
      height: `${Math.round(iconSize * ICON_SIZE_ADJUST_FACTOR)}px`,
    };
  }
  applyStyle(element, elementStyle);

  // Only none-composite item has an icon
  if (isIconVisible) {
    const iconEl = document.createElement('img');
    const {
      src,
      size: [width, height],
    } = getSampleIcon(icon, color, iconSize, iconSize);
    iconEl.src = src;
    iconEl.width = width;
    iconEl.height = height;
    iconEl.draggable = false;
    element.appendChild(iconEl);
    enableRotation(iconEl, iconRotation, listeners);
  }

  const overlay = patchOverlay(
    map,
    new Overlay({
      element,
      position,
      className: 'figure-marker',
      stopEvent: false,
    })
  );

  return overlay;
}

function createLabelOverlay(map, position, sampleStyle: SampleStyle) {
  const {
    id,
    isDefault,
    width,
    isIconVisible,
    exceedanceColor,
    labelColor,
    labelShadowColor,
    isLabelUnderlined,
    isLabelAsteriskAppended,
    labelSize,
    title,
    duplicate,
  } = sampleStyle;
  const element = document.createElement('div');
  element.setAttribute(
    'class',
    'editable-sample-label-container d-flex flex-column align-items-center'
  );
  element.setAttribute('data-sample-id', String(id!));
  element.setAttribute('data-is-default', String(isDefault!));
  element.style.cursor = 'move';

  const elementStyle = {
    width: `${width}px`,
    wordBreak: 'break-word',
  };
  applyStyle(element, elementStyle);

  // Handle the text element
  const titleEl = document.createElement('span');
  titleEl.setAttribute('id', 'sample-title');
  titleEl.setAttribute('class', `custom-marker__title mt-1`);

  let titleStyle = {
    color: !isIconVisible && exceedanceColor ? exceedanceColor : labelColor,
    textShadow: createTextShadow(labelShadowColor),
    fontSize: `${Math.round(labelSize * LABEL_SIZE_ADJUST_FACTOR)}px`,
    width: `${width}px`,
    textDecoration: isLabelUnderlined ? 'underline' : 'none',
    textUnderlinePosition: 'under',
  };
  applyStyle(titleEl, titleStyle);

  const titleContentEl = document.createTextNode(
    isLabelAsteriskAppended ? appendAsterisk(title!) : title!
  );
  titleEl.appendChild(titleContentEl);

  if (duplicate) {
    const { custom_title: duplicateCustomTitle, lab_title: duplicateLabTitle } =
      duplicate;
    const duplicateTitleEl = document.createElement('span');
    duplicateTitleEl.setAttribute('class', 'duplicate');
    duplicateTitleEl.innerHTML = duplicateCustomTitle || duplicateLabTitle;
    titleEl.appendChild(duplicateTitleEl);
  }

  element.appendChild(titleEl);

  const overlay = patchOverlay(
    map,
    new Overlay({
      element,
      position,
      className: 'figure-marker',
      stopEvent: false,
    })
  );

  return overlay;
}

function patchOverlay(map: Map, overlay: Overlay): Overlay {
  //@ts-ignore
  overlay.updateRenderedPosition = function (pixel, mapSize) {
    // @ts-ignore
    const style = this.element.style;
    const offset = this.getOffset();

    // @ts-ignore
    this.setVisible(true);

    const x = Math.round(pixel[0] + offset[0]) + 'px';
    const y = Math.round(pixel[1] + offset[1]) + 'px';

    style.transformOrigin = 'left top';

    const transform = `translate(${x}, ${y}) scale(${getLayoutZoom(map)})`;
    // @ts-ignore
    if (this.rendered.transform_ != transform) {
      // @ts-ignore
      this.rendered.transform_ = transform;
      style.transform = transform;
      // @ts-ignore IE9
      style.msTransform = transform;
    }
  };

  return overlay;
}
