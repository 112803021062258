<template>
  <div>
    <div class="form-group mb-3">
      <label class="form-label">Type</label>

      <select
        class="form-control"
        v-model="type"
        @keypress.enter="$emit('blur')"
      >
        <option value="image">Image</option>
        <option value="audio">Audio</option>
        <option value="video">Video</option>
      </select>
    </div>

    <template v-if="isImage">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="track_direction"
          v-model="trackDirection"
          :disabled="hasMultiple"
          @keypress.enter="$emit('blur')"
        />
        <label class="form-check-label" for="track_direction">
          Do you want users to specify the image direction?
        </label>
      </div>
      <div class="mb-2">
        <InputCheckbox
          label="Allow renaming file names"
          v-model="allowRenaming"
        />
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="has_multiple"
          v-model="hasMultiple"
          @keypress.enter="$emit('blur')"
        />
        <label class="form-check-label" for="has_multiple">
          Do you want users to upload multiple images?
        </label>
      </div>
    </template>

    <VideoOptions v-if="isVideo" v-model="options" />
  </div>
</template>

<script>
import InputCheckbox from '@component-library/components/InputCheckbox.vue';
import VideoOptions from './Video.vue';
import FileUploader from '@component-library/components/FileUploader.vue';

export default {
  name: 'MediaOptions',
  components: {
    FileUploader,
    VideoOptions,
    InputCheckbox,
  },
  props: {
    value: Object,
  },
  computed: {
    field() {
      return this.value;
    },
    options: {
      get() {
        const { options } = this.field;
        if (!options.type) {
          return {
            type: 'image',
          };
        }

        return options;
      },
      set(value) {
        this.$emit('input', { ...this.field, options: value });
      },
    },
    trackDirection: {
      get() {
        return this.options.track_direction || false;
      },
      set(value) {
        this.options = { ...this.options, track_direction: value };
      },
    },
    hasMultiple: {
      get() {
        return this.options.has_multiple || false;
      },
      set(value) {
        this.options = { ...this.options, has_multiple: value };
      },
    },
    allowRenaming: {
      get() {
        return this.options.allow_renaming || false;
      },
      set(value) {
        this.options = { ...this.options, allow_renaming: value };
      },
    },
    type: {
      get() {
        return this.options.type.toLowerCase();
      },
      set(value) {
        this.options = { ...this.options, type: value };
      },
    },
    isImage() {
      return this.type === 'image';
    },
    isVideo() {
      return this.type === 'video';
    },
    defaultValue: {
      get() {
        return this.options.default;
      },
      set(value) {
        this.options = { ...this.options, default: value };
      },
    },
  },
  watch: {
    isImage(value) {
      if (!value) {
        this.options = {
          ...this.options,
          has_multiple: false,
          track_direction: false,
        };
      }
    },
    isVideo(value) {
      if (!value) {
        this.options = {
          ...this.options,
          default: null,
        };
      }
    },
    hasMultiple(value) {
      if (value) {
        this.options = { ...this.options, track_direction: false };
      }
    },
  },
};
</script>
