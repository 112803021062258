<script lang="ts" setup>
import { useStore } from '@/js/store';
import type { AlertUnsavedItemModalPayload } from '@/js/types/modal-stack';
import Modal from '@component-library/components/Modal.vue';

const props = defineProps<{ payload: AlertUnsavedItemModalPayload }>();

const store = useStore();

function handleGotoClick() {
  const { projectId } = props.payload;
  window.open(`${import.meta.env.VITE_APP_URL}/#/${projectId}/map/`, '_blank');
}

function handleDiscardClick() {
  store.dispatch('updatePersistence', {
    sampleIdentifier: null,
    sampleData: null,
    sampleTabId: null,
    inputValues: [],
    projectId: null,
    projectTitle: null,
  });
  store.dispatch('popFromModalStack');
}
</script>

<template>
  <Modal :closable="false">
    <div>
      <p>
        An unsaved item <b>{{ payload.unsavedItem.title }}</b> with
        <b>{{ payload.unsavedItem.numberOfValues }}</b> values is found in
        another project <b>{{ payload.projectTitle }}</b
        >. You need to handle that item before making any changes to the items
        in this project, otherwise the unsaved item could be lost.
      </p>
      <p class="mb-0">
        If you have already saved the item in another tab, please try to refresh
        this page.
      </p>
    </div>
    <template #footer>
      <div class="flex-grow-1 d-flex flex-column gap-2">
        <button
          v-if="payload.isAuthorized"
          class="btn btn-primary flex-grow-1"
          @click="handleGotoClick"
        >
          Go to project with the unsaved item
        </button>
        <button
          class="btn btn-danger d-block flex-grow-1"
          @click="handleDiscardClick"
        >
          Discard the unsaved item
        </button>
      </div>
    </template>
  </Modal>
</template>
