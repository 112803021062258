export const canDeleteSample = (created_at) => {
  if (!created_at) {
    return true;
  }

  return (
    new Date(created_at).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
  );
};

export const normalizeSampleDataToModify = function (value) {
  let { id, sample_id } = value;
  id = id ?? sample_id;
  sample_id = sample_id ?? id;

  if (id !== sample_id) {
    throw `The sampleDataToModify is illegal: id is ${id}, sample_id is ${sample_id}`;
  }

  return {
    ...value,
    id,
    sample_id,
  };
};
