<script setup>
import { ref, computed, watch } from 'vue';
import * as cl_bm from '@component-library/business-model';
import CheckboxValueContentInput from './value-content-inputs/Checkbox.vue';
import DateValueContentInput from './value-content-inputs/Date.vue';
import DateTimeValueContentInput from './value-content-inputs/DateTime.vue';
import NumberValueContentInput from './value-content-inputs/Number.vue';
import TextValueContentInput from './value-content-inputs/Text.vue';
import TimeValueContentInput from './value-content-inputs/Time.vue';
import YearValueContentInput from './value-content-inputs/Year.vue';
import YearMonthValueContentInput from './value-content-inputs/YearMonth.vue';
import makeId from '@component-library/local-id.mjs';

const emit = defineEmits(['add']);
const id = makeId();

const value = ref({
  type: cl_bm.expression.VALUE_TYPE_TEXT,
  content: '',
});
const valueContentType = computed(() => {
  switch (value.value.type) {
    case cl_bm.expression.VALUE_TYPE_TEXT:
    case cl_bm.expression.VALUE_TYPE_EMPTY_TEXT:
      return TextValueContentInput;
    case cl_bm.expression.VALUE_TYPE_NUMBER:
      return NumberValueContentInput;
    case cl_bm.expression.VALUE_TYPE_DATE:
      return DateValueContentInput;
    case cl_bm.expression.VALUE_TYPE_YEAR_MONTH:
      return YearMonthValueContentInput;
    case cl_bm.expression.VALUE_TYPE_YEAR:
      return YearValueContentInput;
    case cl_bm.expression.VALUE_TYPE_DATE_TIME:
      return DateTimeValueContentInput;
    case cl_bm.expression.VALUE_TYPE_TIME:
      return TimeValueContentInput;
    case cl_bm.expression.VALUE_TYPE_YES_NO:
      return CheckboxValueContentInput;
    default:
      return TextValueContentInput;
  }
});

const isEmptyText = computed(
  () => value.value.type === cl_bm.expression.VALUE_TYPE_EMPTY_TEXT
);

watch(
  () => value.value.type,
  () => {
    value.value.content = '';
  }
);

function handleAdd() {
  emit('add', {
    type: cl_bm.expression.INPUT_TYPE_VALUE,
    value: value.value.content,
    valueType: value.value.type,
  });
}
</script>

<template>
  <div class="d-flex flex-column">
    <div class="mb-3">
      <label class="form-label" :for="id">Type</label>
      <select class="form-control" :id="id" v-model.number="value.type">
        <option
          v-for="item in Object.keys(cl_bm.expression.VALUE_TYPE_OPTIONS)"
          :key="`valueType-${item}`"
          :value="item"
        >
          {{ cl_bm.expression.VALUE_TYPE_OPTIONS[item].text }}
        </option>
      </select>
    </div>

    <div v-if="!isEmptyText" class="mb-3">
      <label class="form-label">Content</label>
      <component :is="valueContentType" v-model="value.content" />
    </div>

    <div class="d-flex">
      <button
        class="btn btn-outline-dark btn-sm ms-1 flex-grow-1"
        :disabled="!value.content && !isEmptyText"
        @click="handleAdd"
      >
        <i class="fas fa-plus" />
      </button>
    </div>
  </div>
</template>
