export function copyToClipboard(str) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(str);
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = str;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
}
