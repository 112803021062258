<script setup lang="ts">
import { Sample } from '../../enviro';
import { OfflineSample } from '../../offline-data';

import useAuth from '../../composables/useAuth';

const auth = useAuth();

const props = defineProps<{
  samples: (Sample | OfflineSample)[];
  selectedSampleIds: (number | string)[];
  isLoading?: boolean;
  isUpload?: boolean;
}>();

const emit = defineEmits<{
  (e: 'toggleSample', sample: Sample | OfflineSample): void;
}>();

const isSampleToggled = (id: number | string) => {
  return props.selectedSampleIds.includes(id);
};

const hasSampleBeenModifiedOffline = (sample: Sample | OfflineSample) => {
  return (
    (sample as OfflineSample).is_edited_offline ||
    (sample as OfflineSample).is_created_offline
  );
};

const isSampleInvalidated = (sample: Sample | OfflineSample) => {
  return (sample as OfflineSample).is_invalidated;
};
</script>
<template>
  <div class="list-group custom-scrollbar" style="max-height: 350px">
    <div v-if="isLoading" class="list-group-item">
      <i class="spinner-border spinner-border-sm"></i>
    </div>
    <div v-else-if="samples.length === 0" class="text-danger fw-medium ms-3">
      There are no samples in this app.
    </div>
    <template v-else>
      <div
        v-for="sample in samples"
        :key="sample.id"
        class="list-group-item list-group-item-action d-flex align-items-center justify-content-between"
        @click="emit('toggleSample', sample)"
      >
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <input
              class="form-check-input me-2 mt-0"
              type="checkbox"
              :checked="isSampleToggled(sample.id)"
            />
            <label class="fw-medium">
              {{ sample.custom_title }}
            </label>
          </div>

          <div v-if="!isUpload">
            <small
              v-if="sample.offline_user_id === auth.user().user_id"
              class="ms-1 text-muted"
            >
              You have already taken this offline
            </small>
            <template v-else>
              <small v-if="sample.offline_user_id" class="ms-1 text-muted">
                Already offline, you'll have read only access
              </small>
            </template>
          </div>
          <div v-else>
            <i
              v-if="isSampleInvalidated(sample)"
              class="ms-1 fal fa-fw fa-exclamation-triangle text-warning"
              v-tooltip="'This item has been invalidated'"
            />
            <i
              v-if="hasSampleBeenModifiedOffline(sample)"
              class="ms-1 fal fa-fw fa-check text-success"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
