import makeId from '@component-library/local-id.mjs';

export type AreaFigureLayer = {
  id: string;
  geojson: any;
};

export default function (areaGeojson: any, title: string): AreaFigureLayer {
  const type = areaGeojson.geometry.type.toLowerCase();
  return {
    id: makeId(),
    geojson: {
      ...areaGeojson,
      properties: {
        ...areaGeojson.properties,
        title,
        type: type === 'linestring' ? 'polyline' : type,
      },
    },
  };
}
