<script setup lang="ts">
import useMapsApi from '@/js/composables/useMapsApi';
import { useStore } from '@/js/store';
import useFigureStore from '@/js/stores/figure';
import useLayerModelStore from '@/js/stores/layer-model';
import {
  getFigureTitle,
  sortFigures,
} from '@component-library/business-logic/mapping/figure';
import InfoButton from '@component-library/components/InfoButton.vue';
import RenderableNonSpatialSampleGroupFilter from '@component-library/components/mapping/linking/RenderableNonSpatialSampleGroupFilter.vue';
import { Context as UseItemsFromAppTitleContext } from '@component-library/composables/useItemsFromAppTitle';
import type {
  App,
  RenderableNonSpatialSampleGroup,
} from '@component-library/gather';
import {
  checkIsBufferLayerModel,
  checkIsFeatureServerFolderLayerModel,
  checkIsMapServerFolderLayerModel,
  checkIsPlainFolderLayerModel,
  checkIsSampleGroup,
  checkIsVectorTileServerFolderLayerModel,
  checkIsWfsFolderLayerModel,
  checkIsWmsFolderLayerModel,
  checkIsWmtsFolderLayerModel,
} from '@maps/lib/olbm';
import LoadManager from '@maps/lib/olbm/common/LoadManager';
import { checkIsCalloutLayerModel } from '@maps/lib/olbm/layer/call-out/utils';
import { LayerUsageByType } from '@maps/lib/olbm/layer/constants';
import { BufferLayerModel } from '@maps/lib/olbm/layer/shape/types';
import { getLayerTitle } from '@maps/lib/olbm/layer/utils';
import type {
  Id,
  LayerModel,
  Pid,
  SampleGroup,
  SampleScopeStatistic,
  SubFolder,
} from '@maps/lib/olbm/types';
import { LayerType } from '@maps/lib/olbm/types';
import { produce } from 'immer';
import LiquorTree from 'liquor-tree';
import _isEqual from 'lodash/isEqual';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import Vue, { computed, inject, onMounted, ref, watch } from 'vue';
import TextHighlight from 'vue-text-highlight';
import Map from './Map.vue';
import makeId from '@component-library/local-id.mjs';

const props = defineProps<{
  apps: App[];
  setTab: (tab: string) => void;
}>();
const figureSelectId = makeId();
const isFinalizedSwitchId = makeId();
const figureInputId = makeId();

const emit = defineEmits(['hiddenSubFoldersChange']);

const map: Ref<typeof Map> = inject('map')!;
const tree: Ref<typeof LiquorTree> = ref(undefined);
const sampleScopeStatistics: Ref<SampleScopeStatistic[]> = ref([]);

const { loadSampleScopeStatistics, saveLayerModel } = useMapsApi();

const store = useStore();

const layerModelStore = useLayerModelStore();
const {
  checkIsLayerModelHidden,
  checkIsRenderableNonSpatialSampleGroup,
  findLayerModelById,
  toggleLayerModelVisibility,
  toggleSubFolderVisibility,
  getBufferLayerModel: _getBufferLayerModel,
  updateLayerModel,
} = layerModelStore;
const { layerModels, subFolders } = storeToRefs(layerModelStore);

const figureStore = useFigureStore();
const { figures, selectedFigureId } = storeToRefs(figureStore);
const {
  getSelectedFigure,
  setSelectedFigureId,
  getLockableLayerModelIds,
  getLockedByFigure,
  modifyFigure,
} = figureStore;
const sortedFigures = computed(() => {
  return sortFigures([...figures.value]);
});
const selectedFigure = computed(() => getSelectedFigure()!);
const isFinalizedSwitchVisible = computed(
  () => getLockableLayerModelIds(selectedFigure.value.id).length > 0
);
const lockedLayerModelIds = computed({
  get() {
    return selectedFigure.value.locked_layer_ids ?? [];
  },
  async set(value) {
    await modifyFigure(selectedFigure.value.id, value);
  },
});
const isFinalized = computed(() => lockedLayerModelIds.value.length > 0);
async function handleIsFinalizedChange(event) {
  const value = (event.target as HTMLInputElement).checked;
  lockedLayerModelIds.value = value
    ? getLockableLayerModelIds(selectedFigure.value.id)
    : [];
}
const getLockTitle = (layerModelId: Pid): string | null => {
  const lockedByFigure = getLockedByFigure(layerModelId);
  return lockedByFigure ? `Locked by ${lockedByFigure.title} figure` : null;
};

const searchKeyword = ref<string>('');
const treeOptions = ref({
  dnd: false,
});
const treeData = computed(() => {
  return toTreeDataNodes(layerModels.value);
});
const layerVisibilityByLayerModelId = computed(() =>
  toLayerVisibilityByLayerModelId(layerModels.value)
);
const isTogglingTreeNodeVisibility = ref<Record<Id, boolean>>({});
const selectedTreeNode = ref<TreeNode | undefined>(undefined);

type TreeDataNode = {
  id: Id;
  text: string;
  data: Object;
  children: TreeDataNode[];
  state: Object;
};

type TreeNode = {
  id: Id;
  data: {
    text: string;
    properties: unknown;
  };
  parent?: TreeNode;
};

const checkIsSearchKeywordMatched = (param: LayerModel | string): boolean => {
  const isSubFolderName = typeof param === 'string';
  const text = isSubFolderName ? param : getLayerTitle(param);

  if (
    !searchKeyword.value.trim() ||
    new RegExp(searchKeyword.value, 'i').test(text)
  ) {
    return true;
  }

  if (isSubFolderName) {
    return false;
  }

  let result = false;
  const children = checkIsSampleGroup(param)
    ? subFolders.value
        .filter((subFolder) => subFolder.sampleGroupId === param.id)
        .map((subFolder) => subFolder.name)
    : param.children;
  for (let i = 0; i < children.length; i++) {
    result = checkIsSearchKeywordMatched(children[i]);
    if (result) {
      break;
    }
  }
  return result;
};

type ToTreeDataNodes = {
  (layerModels: LayerModel[]): TreeDataNode[];
  (subFolders: SubFolder[]): TreeDataNode[];
};

function checkIsLayerModels(value): value is LayerModel[] {
  return Array.isArray(value) && value.length > 0 && 'id' in value[0];
}

function checkIsSubFolders(value): value is SubFolder[] {
  return (
    Array.isArray(value) && value.length > 0 && 'sampleGroupId' in value[0]
  );
}

const toTreeDataNodes: ToTreeDataNodes = (
  param: LayerModel[] | SubFolder[]
): TreeDataNode[] => {
  const result: TreeDataNode[] = [];

  if (checkIsLayerModels(param)) {
    for (let i = 0; i < param.length; i++) {
      const layerModel = param[i];

      if (
        checkIsRenderableNonSpatialSampleGroup(layerModel.id) ||
        checkIsBufferLayerModel(layerModel) ||
        (checkIsCalloutLayerModel(layerModel) &&
          (layerModel.geojson.properties.isBuiltin ||
            layerModel.geojson.properties.usage ===
              LayerUsageByType[LayerType.CALL_OUT].SHOW_MAP))
      ) {
        continue;
      }

      if (checkIsSearchKeywordMatched(layerModel)) {
        const treeDataNode = {
          id: layerModel.id,
          text: getLayerTitle(layerModel),
          data: {
            properties: layerModel.geojson.properties,
            isVisibleInBasemapFigure: layerModel.is_visible_in_basemap_figure,
          },
          children: checkIsSampleGroup(layerModel)
            ? toTreeDataNodes(
                subFolders.value.filter(
                  (subFolder) => subFolder.sampleGroupId === layerModel.id
                )
              )
            : toTreeDataNodes(layerModel.children),
          state: {
            expanded: !!searchKeyword.value.trim(),
          },
        };

        result.push(treeDataNode);
      }
    }
  } else if (checkIsSubFolders(param)) {
    for (let i = 0; i < param.length; i++) {
      const subFolder = param[i];
      if (checkIsSearchKeywordMatched(subFolder.name)) {
        const treeDataNode = {
          id: makeId(),
          text: subFolder.name,
          data: {
            properties: {
              sampleGroupId: subFolder.sampleGroupId,
            },
          },
          children: [],
          state: {
            expanded: !!searchKeyword.value.trim(),
          },
        };

        result.push(treeDataNode);
      }
    }
  }

  return result;
};

function toLayerVisibilityByLayerModelId(
  _layerModels: LayerModel[]
): Record<Id, boolean | Id[]> {
  // For a ESRI MapServer layer, its visibility is decided by the visibility of sub layers.
  let result: Record<Id, boolean | Id[]> = {};

  for (let i = 0; i < _layerModels.length; i++) {
    const layerModel = _layerModels[i];
    if (
      checkIsPlainFolderLayerModel(layerModel) ||
      checkIsFeatureServerFolderLayerModel(layerModel) ||
      checkIsVectorTileServerFolderLayerModel(layerModel) ||
      checkIsWfsFolderLayerModel(layerModel) ||
      checkIsWmsFolderLayerModel(layerModel) ||
      checkIsWmtsFolderLayerModel(layerModel)
    ) {
      result = {
        ...result,
        ...toLayerVisibilityByLayerModelId(layerModel.children),
      };
    } else {
      result = {
        ...result,
        [layerModel.id]: checkIsMapServerFolderLayerModel(layerModel)
          ? !layerModel.visible
            ? []
            : layerModel.children
                .filter((child) => child.visible)
                .map((child) => child.id)
          : !checkIsLayerModelHidden(layerModel.id),
      };
    }
  }
  return result;
}

type TreeNodeIcon = { icon: string | null | undefined };

function getTreeNodeIcon(node: TreeNode): TreeNodeIcon {
  const layerModel = findLayerModelById(node.id);
  return layerModel ? map.value.getLayerModelIcon(layerModel) : { icon: null };
}

function getShortenedTitle(node: TreeNode): string {
  const layerModel = findLayerModelById(node.id);
  const title = layerModel ? getLayerTitle(layerModel) : node.data.text;
  const maxLen = 140;

  return title.length > maxLen ? `${title.substring(0, maxLen)}...` : title;
}

function findSubFolder(
  sampleGroupId: number,
  name: string
): SubFolder | undefined {
  return subFolders.value.find(
    (subFolder) =>
      subFolder.sampleGroupId === sampleGroupId && subFolder.name === name
  );
}

function getIsTreeNodeVisible(
  node: TreeNode,
  isForBuffer: boolean = false
): boolean {
  let model = findLayerModelById(node.id);

  if (!model) {
    // The tree node is based on a sub folder.
    const { sampleGroupId } = node.data.properties as { sampleGroupId: number };
    const name = node.data.text;
    const subFolder = findSubFolder(sampleGroupId, name)!;
    const sampleGroup = findLayerModelById(
      subFolder.sampleGroupId
    )! as SampleGroup;
    return sampleGroup.hidden_sub_folders.indexOf(subFolder.name) === -1;
  }

  if (isForBuffer) {
    model = _getBufferLayerModel(model)!;
  }

  return model.visible;
}

function checkIsTreeNodeVisibilityToggable(node: TreeNode): boolean {
  const model = findLayerModelById(node.id);

  if (!model) {
    // The tree node is based on a sub folder.
    return true;
  }

  const parentModel = model.parent_id
    ? findLayerModelById(model.parent_id)
    : undefined;

  return !parentModel || parentModel.children.length !== 1;
}

function getBufferLayerModel(node: TreeNode): BufferLayerModel | undefined {
  const model = findLayerModelById(node.id)!;
  return model ? _getBufferLayerModel(model) : undefined;
}

function checkHasBuffer(node: TreeNode): boolean {
  return !!getBufferLayerModel(node);
}

function checkIsPlainShape(node: TreeNode): boolean {
  const layerModel = findLayerModelById(node.id);

  if (!layerModel) {
    return false;
  }

  const { type: layerType } = layerModel.geojson.properties;
  if (
    [
      LayerType.POLYLINE,
      LayerType.ARROW,
      LayerType.POLYGON,
      LayerType.RECTANGLE,
      LayerType.CIRCLE,
      LayerType.HEDGE,
    ].includes(layerType)
  ) {
    const polySample = map.value.findPolySampleByLayerModelId(node.id);
    return !polySample;
  } else if (layerType === LayerType.SITE_BOUNDARY) {
    return true;
  }

  return false;
}

function checkIsSampleScopeBased(node: TreeNode): boolean {
  const model = findLayerModelById(node.id);
  //when model is null the tree node is based on a sub folder.
  return !model ? true : checkIsSampleGroup(model);
}

function checkIsDefaultSampleGroup(node: TreeNode): boolean {
  const model = findLayerModelById(node.id);
  return (
    !!model &&
    checkIsSampleGroup(model) &&
    (model.geojson.properties.default ?? false)
  );
}

function getSampleCount(node: TreeNode): number {
  let sampleGroup: SampleGroup;
  let subFolder: SubFolder | undefined;

  const model = findLayerModelById(node.id);

  if (!model) {
    // The tree node is based on a sub folder.
    const { sampleGroupId } = node.data.properties as { sampleGroupId: number };
    sampleGroup = findLayerModelById(sampleGroupId)! as SampleGroup;
    const name = node.data.text;
    subFolder = findSubFolder(sampleGroupId, name)!;
  } else if (!checkIsSampleGroup(model)) {
    return 0;
  } else {
    sampleGroup = model as SampleGroup;
  }

  const statistic = sampleScopeStatistics.value.find(
    (statistic) => statistic.sampleGroupId === sampleGroup.id
  )!;

  if (!statistic) {
    // The statistic is not loaded.
    return 0;
  }

  return !subFolder
    ? statistic.sampleCount
    : statistic.subFolders.find((item) => item.name === subFolder?.name)!
        .sampleCount;
}

function getRenderableNonSpatialSampleGroup(
  node: TreeNode
): RenderableNonSpatialSampleGroup {
  const model = findLayerModelById(node.id) as SampleGroup;
  const { unrenderableAppLinkConfigs, filteredUnrenderableItemIds } =
    model.geojson.properties;
  return {
    layerModelId: model.id as number,
    unrenderableAppLinkConfigs: unrenderableAppLinkConfigs ?? [],
    filteredUnrenderableItemIds: filteredUnrenderableItemIds ?? [],
  };
}

function handleSelectedFigureChange(event: Event) {
  const id = parseInt((event.target as HTMLInputElement).value, 10);
  setSelectedFigureId(id);
}

async function handleTreeNodeVisibilityToggle(
  node: TreeNode,
  isForBuffer: boolean = false
): Promise<void> {
  let model = findLayerModelById(node.id);
  let subFolder;

  if (!model) {
    const sampleGroupId = (node.data.properties as { sampleGroupId: number })
      .sampleGroupId;
    model = findLayerModelById(sampleGroupId);
    subFolder = node.data.text;
  } else if (isForBuffer) {
    model = _getBufferLayerModel(model!);
  }

  isTogglingTreeNodeVisibility.value = {
    ...isTogglingTreeNodeVisibility.value,
    [node.id]: true,
  };

  try {
    if (!subFolder) {
      await toggleLayerModelVisibility(selectedFigureId.value!, model!.id);
    } else {
      await toggleSubFolderVisibility(
        selectedFigureId.value!,
        model!.id,
        subFolder
      );
      emit('hiddenSubFoldersChange', { sampleGroupId: model!.id, subFolder });
    }
  } finally {
    isTogglingTreeNodeVisibility.value = {
      ...isTogglingTreeNodeVisibility.value,
      [node.id]: false,
    };
  }
}

function handleTreeNodeClicked(node) {
  selectedTreeNode.value = node;
}

function handleIsLoadingChange(value: boolean) {
  const loadManager = LoadManager.getInstance();
  if (value) {
    loadManager.start();
  } else {
    loadManager.end();
  }
}

async function handleSelectedItemChange({ layerModelId, selectedItemId }) {
  let layerModel = findLayerModelById(layerModelId)! as SampleGroup;
  layerModel = produce(layerModel, (draft) => {
    draft.geojson.properties.filteredUnrenderableItemIds = [selectedItemId];
  });
  await saveLayerModel(selectedFigureId.value, layerModel);
  updateLayerModel(layerModelId, { geojson: layerModel.geojson });
}

// https://github.com/amsik/liquor-tree/issues/4
watch(treeData, (newValue) => {
  const internalTree = tree.value.tree;
  const model = internalTree.parse(newValue);
  Vue.set(tree.value, 'model', model);
  selectedTreeNode.value = undefined;
});

watch(layerVisibilityByLayerModelId, async (newValue, oldValue) => {
  const layerManager = map.value.getLayerManager();
  const layerModelIds = Object.keys(newValue).map((key) => parseInt(key, 10));

  for (let i = 0; i < layerModelIds.length; i++) {
    const id = layerModelIds[i];

    if (_isEqual(newValue[id], oldValue[id])) {
      continue;
    }

    let layerModel = findLayerModelById(id)!;
    const isVisible =
      (typeof newValue[id] === 'boolean' && newValue[id]) ||
      (Array.isArray(newValue[id]) && (newValue[id] as Id[]).length > 0);

    if (!isVisible || checkIsMapServerFolderLayerModel(layerModel)) {
      const layer = layerManager.findLayerByModelId(id);
      layerManager.removeLayer(layer);
    }

    if (isVisible) {
      if (layerModel.geojson.properties.type === LayerType.SERVICE) {
        layerModel = findLayerModelById(layerModel.parent_id!)!;
      }
      const layers = await layerManager.createLayers(layerModel);
      layers.forEach((layer) => layerManager.addLayer(layer));
    }
  }

  layerManager.sort();
});

onMounted(async () => {
  sampleScopeStatistics.value = await loadSampleScopeStatistics();
});
</script>

<template>
  <div class="position-relative layer-manager">
    <!-- Figure dropdown -->
    <div v-if="store.state.isOnline" class="form-group mb-3">
      <label class="form-label" :for="figureSelectId">Figure</label>
      <select
        :class="[
          'form-control',
          { 'basemap-figure-bg': selectedFigure.is_basemap },
        ]"
        :id="figureSelectId"
        :value="selectedFigureId"
        @change="handleSelectedFigureChange"
      >
        <option
          v-for="figure in sortedFigures"
          :key="`figure-${figure.id}`"
          :value="figure.id"
        >
          {{ getFigureTitle(figure) }}
        </option>
      </select>

      <div v-if="isFinalizedSwitchVisible" class="form-check form-switch mt-2">
        <input
          type="checkbox"
          class="form-check-input"
          :id="isFinalizedSwitchId"
          :checked="isFinalized"
          @change="handleIsFinalizedChange($event)"
        />
        <label class="form-label" :for="isFinalizedSwitchId"
          >Is it finalised?</label
        >
        <InfoButton
          class="ms-2"
          :info="`The layers in a finalised figure are locked. The operations available to
          the locked layers limit to
          <ul>
            <li>Show/Hide in legend</li>
            <li>Zoom to layer</li>
            <li>Duplicate layer</li>
          </ul>`"
          container="div.layer-manager"
        />
      </div>
    </div>
    <div v-else>
      <label class="form-label" :for="figureInputId">Figure</label>
      <input
        class="form-control mb-3"
        type="text"
        :id="figureInputId"
        :value="selectedFigure.title"
        readonly
      />
    </div>

    <!-- Search box -->
    <div class="input-group mb-3">
      <i class="input-group-text fas fa-search" />
      <input
        class="form-control"
        type="text"
        placeholder="Please input a search keyword here..."
        v-model.trim="searchKeyword"
      />
      <button
        class="btn btn-danger rounded-0"
        type="button"
        :disabled="!searchKeyword"
        @click.prevent="searchKeyword = ''"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <LiquorTree
      class="flex-grow-1 custom-scrollbar"
      ref="tree"
      :options="treeOptions"
      :data="treeData"
      @node:clicked="handleTreeNodeClicked"
    >
      <template #default="{ node }">
        <div
          :class="[
            'w-100 h-100 d-flex justify-content-between align-items-center',
            {
              'basemap-figure-bg':
                !selectedFigure.is_basemap &&
                node.data.isVisibleInBasemapFigure,
            },
          ]"
        >
          <div class="flex-grow-1 d-flex align-items-center me-2">
            <div
              v-if="getTreeNodeIcon(node).icon"
              class="d-flex align-items-center me-2"
              v-html="getTreeNodeIcon(node).icon"
            ></div>
            <div class="flex-grow-1">
              <div class="d-flex flex-column">
                <TextHighlight
                  :queries="searchKeyword"
                  :caseSensitive="false"
                  :class="[
                    'fw-bold text-wrap',
                    { locked: !!getLockedByFigure(node.id) },
                  ]"
                  :title="getLockTitle(node.id)"
                >
                  {{ getShortenedTitle(node) }}
                </TextHighlight>

                <div class="d-flex align-items-center">
                  <small v-if="checkIsSampleScopeBased(node)" class="fw-light">
                    {{ getSampleCount(node) }} samples inside
                  </small>
                  <span
                    v-if="checkIsDefaultSampleGroup(node)"
                    class="badge bg-primary ms-2"
                  >
                    Default
                  </span>
                  <span
                    v-if="checkIsPlainShape(node)"
                    class="badge bg-primary me-2"
                  >
                    Plain Shape
                  </span>
                  <span v-if="checkHasBuffer(node)" class="badge bg-primary">
                    Has buffer
                  </span>
                </div>
              </div>
              <template v-if="checkIsRenderableNonSpatialSampleGroup(node.id)">
                <RenderableNonSpatialSampleGroupFilter
                  v-if="
                    getRenderableNonSpatialSampleGroup(node)
                      .unrenderableAppLinkConfigs.length > 0
                  "
                  :context="UseItemsFromAppTitleContext.Gather"
                  :apps="apps"
                  :sampleGroup="getRenderableNonSpatialSampleGroup(node)"
                  @is-loading-change="handleIsLoadingChange"
                  @selected-item-change="handleSelectedItemChange"
                />
              </template>
            </div>
          </div>

          <div v-if="checkIsTreeNodeVisibilityToggable(node)">
            <i
              v-if="isTogglingTreeNodeVisibility[node.id]"
              class="spinner-border spinner-border-sm"
            />
            <div v-else class="d-flex">
              <div
                v-if="
                  selectedFigure.is_basemap ||
                  !node.data.isVisibleInBasemapFigure
                "
                class="form-check form-switch mb-0"
              >
                <input
                  class="form-check-input h-12"
                  type="checkbox"
                  title="Toggle layer visibility"
                  :checked="getIsTreeNodeVisible(node)"
                  @change="handleTreeNodeVisibilityToggle(node)"
                  @click.stop="() => {}"
                />
              </div>
              <div
                v-if="
                  checkHasBuffer(node) &&
                  !getBufferLayerModel(node)?.is_visible_in_basemap_figure
                "
                class="form-check form-switch mb-0"
              >
                <input
                  class="form-check-input h-12"
                  type="checkbox"
                  title="Toggle buffer visibility"
                  :checked="getIsTreeNodeVisible(node, true)"
                  @change="handleTreeNodeVisibilityToggle(node, true)"
                  @click.stop="() => {}"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </LiquorTree>
  </div>
</template>

<style lang="scss" scoped>
.layer-manager {
  :deep(.layer-image) {
    min-width: 1em;
    max-width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;

    svg,
    img {
      width: 100%;
      height: 100%;
    }
  }

  :deep(.tree-anchor) {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 30px;
  }

  span.locked::after {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    font-size: 0.8em;
    color: #cd7f32;
    content: '\f023';
  }
}
</style>
