<template>
  <div class="layout position-relative">
    <router-view></router-view>
  </div>
</template>

<script>
import Breadcrumb from "@/js/components/Breadcrumb.vue";
export default {
    name: "ProjectLayout",
    component: { Breadcrumb },
};
</script>
