import useMapsApi from '@/js/composables/useMapsApi';
import type { Id, Pid, Sample, SampleScope } from '@maps/lib/olbm/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import useLayerModelStore from './layer-model';

const useSampleStore = defineStore('sample', () => {
  const mapsApi = useMapsApi();
  const layerModelStore = useLayerModelStore();
  const { getSampleLayerModel } = layerModelStore;

  const samples = ref<Sample[]>([]);

  const findSampleById = (id: Id): Sample | undefined => {
    return samples.value.find((sample) => sample.id === id);
  };

  const loadSample = async (id: Id): Promise<Sample | undefined> => {
    return await mapsApi.loadSample(id);
  };

  const findSampleByIdAsync = async (id: Pid): Promise<Sample | undefined> => {
    let s = findSampleById(id);
    if (s) {
      return s;
    }
    s = await loadSample(id);
    if (s) {
      addSample(s);
    }
    return s;
  };

  const findSamplesByAppId = (appId: Pid): Sample[] => {
    return samples.value.filter((s) => s.template_tab_id == appId);
  };

  const findPolySampleByLayerModelId = (
    layerModelId: Id
  ): Sample | undefined => {
    return samples.value.find(
      (sample) => sample.project_figure_layer_id === layerModelId
    );
  };

  const addSamples = (_samples: Sample[]) => {
    _samples.forEach((sample) => {
      addSample(sample);
    });
  };

  const addSample = (sample: Sample) => {
    if (findSampleById(sample.id)) {
      return;
    }

    samples.value.push(sample);
  };

  const removeSample = (id: Id): void => {
    const index = samples.value.findIndex((sample) => sample.id === id);
    if (index !== -1) {
      samples.value.splice(index, 1);
    }
  };

  const clear = (): void => {
    samples.value = [];
  };

  const replaceSamples = (_samples: Sample[]): void => {
    samples.value = _samples;
  };

  const updateSample = (update: Record<string, any> & { id: Id }): void => {
    const sample = findSampleById(update.id);

    if (!sample) {
      return;
    }

    Object.keys(update).forEach((key) => {
      sample![key] = update[key];
    });
  };

  const checkIsSampleInScope = (
    sample: Sample,
    sampleScope: SampleScope
  ): boolean => {
    const layerModel = getSampleLayerModel(sample);
    if ('geojson' in sampleScope) {
      return layerModel?.id === sampleScope.id;
    } else {
      const { sub_folder: subFolder } = sample;
      return (
        layerModel?.id === sampleScope.sampleGroupId &&
        subFolder === sampleScope.name
      );
    }
  };

  const getScopedSamples = (sampleScope: SampleScope): Sample[] => {
    return samples.value.filter((sample) =>
      checkIsSampleInScope(sample, sampleScope)
    );
  };

  return {
    samples,
    addSamples,
    addSample,
    removeSample,
    clear,
    replaceSamples,
    updateSample,
    findSampleById,
    loadSample,
    findSampleByIdAsync,
    findSamplesByAppId,
    getSampleLayerModel,
    getScopedSamples,
    findPolySampleByLayerModelId,
  };
});

export default useSampleStore;
