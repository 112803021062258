<script lang="ts" setup>
import { computed } from 'vue';
import { getResultType } from '../../../business-logic/expression';
import {
  RESULT_TYPE_BOOLEAN,
  RESULT_TYPE_NUMBER,
} from '../../../business-model/expression';
import type { GatherField } from '../../../gather';
import CheckboxTestValueInput from './Checkbox.vue';
import NumberTestValueInput from './Number.vue';
import TextTestValueInput from './Text.vue';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
  value: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['input']);

const resultType = computed(() => {
  return getResultType(props.field as GatherField);
});

const value_ = computed({
  get() {
    switch (resultType.value) {
      case RESULT_TYPE_BOOLEAN:
        return {
          value: JSON.parse(props.value.value) ? 'yes' : 'no',
          value2: props.value.value2,
        };
      default:
        return props.value;
    }
  },
  set(value) {
    switch (resultType.value) {
      case RESULT_TYPE_BOOLEAN:
        emit('input', {
          value: JSON.stringify(value.value === 'yes' ? 1 : 0),
          value2: value.value2,
        });
        return;
      default:
        emit('input', value);
    }
  },
});
</script>

<template>
  <NumberTestValueInput
    v-if="resultType === RESULT_TYPE_NUMBER"
    :field="field"
    v-model="value_"
  />
  <CheckboxTestValueInput
    v-else-if="resultType === RESULT_TYPE_BOOLEAN"
    :field="field"
    v-model="value_"
  />
  <TextTestValueInput v-else :field="field" v-model="value_" />
</template>
