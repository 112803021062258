import moment from 'moment';
import Field from './Field.js';

export default class Section {
  id = null;
  template_tab_id = null;
  system_reference = null;
  label = null;
  order = 0;
  is_public_form = null;
  is_shown_on_new_page = null;
  is_repeatable = null;
  is_permanent = null;
  is_lab_sample = null;
  is_health_safety = null;
  is_soil_log = null;
  is_site_visit = null;
  is_gps_point_metadata = false;
  is_number_used_as_title = false;
  is_photolog = null;
  created_at = null;
  updated_at = null;
  deleted_at = null;
  primary_field_id = null;
  secondary_field_id = null;
  template_fields = [];
  // Status checks
  isUpdating = false;
  isDeleting = false;
  isDuplicating = false;

  constructor({
    id = null,
    order = 0,
    template_tab_id = null,
    primary_field_id = null,
    secondary_field_id = null,
    system_reference = null,
    label = null,
    is_public_form = false,
    is_shown_on_new_page = false,
    is_repeatable = false,
    is_permanent = false,
    is_lab_sample = false,
    is_health_safety = false,
    is_soil_log = false,
    is_site_visit = false,
    is_gps_point_metadata = false,
    is_number_used_as_title = false,
    is_photolog = false,
    created_at = null,
    updated_at = null,
    deleted_at = null,
    template_fields = [],
    isUpdating = false,
    isDeleting = false,
    isDuplicating = false,
  }) {
    this.id = id;
    this.order = order;
    this.template_tab_id = template_tab_id;
    this.system_reference = system_reference;
    this.label = label;
    this.is_public_form = is_public_form;
    this.is_shown_on_new_page = is_shown_on_new_page;
    this.is_repeatable = is_repeatable;
    this.is_permanent = is_permanent;
    this.is_lab_sample = is_lab_sample;
    this.is_health_safety = is_health_safety;
    this.is_soil_log = is_soil_log;
    this.is_site_visit = is_site_visit;
    this.is_gps_point_metadata = is_gps_point_metadata;
    this.is_number_used_as_title = is_number_used_as_title;
    this.is_photolog = is_photolog;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
    this.template_fields = template_fields;
    this.primary_field_id = primary_field_id;
    this.secondary_field_id = secondary_field_id;
    this.isUpdating = isUpdating;
    this.isDeleting = isDeleting;
    this.isDuplicating = isDuplicating;
  }

  isDisabled() {
    return !this.id || this.is_permanent || this.is_health_safety;
  }

  static wrap(section) {
    if (section instanceof Section) {
      return section;
    }

    return new Section(section);
  }

  static async getTabSections(tab_id, progressCallback = (progress) => {}) {
    const { data } = await axios.get(`/api/template/tab/${tab_id}/sections`);
    const sections = data.data.map((item) => Section.wrap(item));
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const { template_fields } = section;
      if (!template_fields.length) {
        const { data } = await axios.get(
          `/api/template/section/${section.id}/fields`
        );
        section.template_fields = data.data.map((item) => Field.wrap(item));

        progressCallback((i + 1) / sections.length);
      }
    }
    progressCallback(1);
    return sections;
  }

  async duplicate(order) {
    try {
      this.isDuplicating = true;
      const duplicate = Section.wrap({
        ...this,
        id: null,
        primary_field_id: null,
        secondary_field_id: null,
        created_at: null,
        updated_at: null,
        deleted_at: null,
        order,
        template_fields: this.template_fields.map((item, index) => ({
          ...item,
          id: null,
          created_at: null,
          updated_at: null,
          order: index,
          template_section_id: null,
        })),
        isUpdating: false,
        isDuplicating: false,
      });
      await duplicate.save();

      return duplicate;
    } finally {
      this.isDuplicating = false;
    }
  }

  async save() {
    if (this.id) {
      try {
        this.isUpdating = true;
        const { data } = await axios.put(`/api/template/section/${this.id}`, {
          id: this.id,
          order: this.order,
          template_tab_id: this.template_tab_id,
          system_reference: this.system_reference,
          label: this.label,
          is_public_form: this.is_public_form,
          is_shown_on_new_page: this.is_shown_on_new_page,
          is_repeatable: this.is_repeatable,
          is_permanent: this.is_permanent,
          is_lab_sample: this.is_lab_sample,
          is_health_safety: this.is_health_safety,
          is_soil_log: this.is_soil_log,
          is_site_visit: this.is_site_visit,
          is_photolog: this.is_photolog,
          is_gps_point_metadata: this.is_gps_point_metadata,
          is_number_used_as_title: this.is_number_used_as_title,
          primary_field_id: this.primary_field_id,
          secondary_field_id: this.secondary_field_id,
        });
        this.updated_at = data.data.updated_at;
      } finally {
        this.isUpdating = false;
      }
      return;
    }

    const { data } = await axios.post(`/api/template/section`, {
      template_tab_id: this.template_tab_id,
      primary_field_id: this.primary_field_id,
      secondary_field_id: this.secondary_field_id,
      system_reference: this.system_reference,
      label: this.label,
      order: this.order,
      is_public_form: this.is_public_form,
      is_shown_on_new_page: this.is_shown_on_new_page,
      is_repeatable: this.is_repeatable,
      is_permanent: this.is_permanent,
      is_lab_sample: this.is_lab_sample,
      is_health_safety: this.is_health_safety,
      is_soil_log: this.is_soil_log,
      is_site_visit: this.is_site_visit,
      is_photolog: this.is_photolog,
      is_gps_point_metadata: this.is_gps_point_metadata,
      is_number_used_as_title: this.is_number_used_as_title,
    });
    this.id = data.data.id;
    this.created_at = data.data.created_at;
    this.deleted_at = data.data.deleted_at;

    const template_fields = [];
    for (let i = 0; i < this.template_fields.length; i++) {
      const field = Field.wrap({
        ...this.template_fields[i],
        template_section_id: this.id,
        order: i,
      });
      await field.save();
      template_fields.push(field);
    }
    this.template_fields = template_fields;

    if (this.is_lab_sample) {
      const {
        data: { data: correctedFields },
      } = await axios.put(`/api/template/section/${this.id}/correct-fields`);
      this.template_fields = correctedFields.map((cf) => Field.wrap(cf));
    }
  }

  async delete() {
    try {
      this.isDeleting = true;
      const { data } = await axios.delete(`/api/template/section/${this.id}`);
      return data;
    } catch ({ response: e }) {
      throw e;
    } finally {
      this.isDeleting = false;
    }
  }

  addField(index, field) {
    this.template_fields.splice(index, 0, Field.wrap(field));
  }

  setField(index, field) {
    this.template_fields.splice(index, 1, Field.wrap(field));
  }

  deleteField(index) {
    this.template_fields.splice(index, 1);
  }

  moveField(fromIndex, toIndex) {
    if (fromIndex === toIndex) {
      return;
    }

    if (fromIndex < toIndex) {
      const part1 = this.template_fields.filter(
        (f, fIndex) => fIndex <= toIndex && fIndex !== fromIndex
      );
      const part2 = this.template_fields.filter(
        (f, fIndex) => fIndex > toIndex
      );
      this.template_fields = [
        ...part1,
        this.template_fields[fromIndex],
        ...part2,
      ];
    } else {
      const part1 = this.template_fields.filter(
        (f, fIndex) => fIndex < toIndex
      );
      const part2 = this.template_fields.filter(
        (f, fIndex) => fIndex >= toIndex && fIndex !== fromIndex
      );
      this.template_fields = [
        ...part1,
        this.template_fields[fromIndex],
        ...part2,
      ];
    }
    this.template_fields.forEach((f, fIndex) => (f.order = fIndex));
  }

  async onFetchData(
    store,
    { index, inputValues, updateInputValue, setIsFetching }
  ) {
    if (this.is_gps_point_metadata) {
      try {
        setIsFetching(index, true);
        const info = await store.dispatch('getGpsPointDataInfo');

        for (let [systemReference, value] of Object.entries(info)) {
          const field = this.template_fields.find(
            (item) => item.system_reference === systemReference
          );
          const inputValue = inputValues.find(
            (item) =>
              item.template_field_id === field.id &&
              item.template_section_index === index
          );

          if (systemReference === 'timestamp') {
            const timestamp = new Date(value);
            const momentTimestamp = moment(timestamp);
            value = momentTimestamp.format();
          }

          const nextInputValue = {
            ...inputValue,
            value,
          };
          updateInputValue({ field, inputValue: nextInputValue });
        }
      } finally {
        setIsFetching(index, false);
      }
    }
  }
}
