<script setup lang="ts">
import * as cl_bm from '@component-library/business-model';
import type { CustomFunction } from '@component-library/business-model/expression';

const props = defineProps<{ customFunctions: CustomFunction[] }>();
const emit = defineEmits(['add']);

function handleAdd(cf: CustomFunction) {
  emit('add', {
    type: cl_bm.expression.INPUT_TYPE_CUSTOM_FUNCTION,
    value: cf.id,
  });
}
</script>

<template>
  <div class="d-flex align-content-start flex-wrap custom-function-picker">
    <button
      class="btn btn-outline-dark"
      v-for="(cf, cfIndex) in customFunctions"
      :key="`customFunction-${cfIndex}`"
      @click="handleAdd(cf)"
    >
      <p class="fs-6 fw-bold mb-0">{{ cf.displayName }}</p>
      <sub>{{ cf.description }}</sub>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.custom-function-picker {
  gap: 0.5rem;

  button {
    width: 200px;
    height: 100px;
  }
}
</style>
