import { checkIsMobile } from '@component-library/utils';
import { Popover } from 'bootstrap';
import Tooltip from 'ol-ext/overlay/Tooltip';
import { Control } from 'ol/control';
import { Draw } from 'ol/interaction';
import { getProjectUnit } from '../common/store-api';
import { formatArea } from '../measurement/area';
import { formatLength } from '../measurement/length';
import { AbsoluteUnit } from '../measurement/types';
import { METER } from '../measurement/unit';

enum MeasureType {
  distance = 1,
  area = 2,
}

export default class MeasureControl extends Control {
  popover: Popover;
  isPopoverVisible: boolean = false;
  draws: {
    line: Draw;
    area: Draw;
  };
  tooltip: Tooltip;

  private static createDraw(measureType: MeasureType): Draw {
    return new Draw({
      type: measureType === MeasureType.distance ? 'LineString' : 'Polygon',
    });
  }

  constructor(opt_options?: { target: HTMLElement | undefined }) {
    const options = opt_options || { target: undefined };

    const button = document.createElement('button');
    button.innerHTML = '<i class="fas fa-ruler"></i>';

    const element = document.createElement('div');
    element.className = 'ol-unselectable ol-control ol-measure-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.handleButtonClick.bind(this), false);
    button.addEventListener(
      'inserted.bs.popover',
      this.handlePopoverInserted.bind(this),
      false
    );
    button.addEventListener(
      'shown.bs.popover',
      this.handlePopupShow.bind(this)
    );
    button.addEventListener(
      'hidden.bs.popover',
      this.handlePopupHidden.bind(this)
    );

    const action = checkIsMobile() ? 'tap' : 'click';
    this.popover = new Popover(button, {
      trigger: 'click',
      html: true,
      sanitize: false,
      content: `
      <div class="form-check">
        <input class="form-check-input" type="radio" name="measureRadio" id="distanceRadio" checked>
        <label class="form-check-label" for="distanceRadio">
          Distance
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="measureRadio" id="areaRadio">
        <label class="form-check-label" for="areaRadio">
          Area
        </label>
      </div>
      <hr class="my-2" />
      <div class="fw-light" style="font-size:12px;">Double ${action} on the map to end the current measuring. Click the button to exit the measurement tool.</div>
      `,
    });

    this.draws = {
      line: MeasureControl.createDraw(MeasureType.distance),
      area: MeasureControl.createDraw(MeasureType.area),
    };
    this.tooltip = new Tooltip({
      formatLength: (length) => {
        const unit = this.getUnit();
        return formatLength(length, unit);
      },
      formatArea: (area) => {
        const unit = this.getUnit();
        return formatArea(area, unit);
      },
    });

    Object.keys(this.draws).forEach((key) => {
      const draw: Draw = this.draws[key];
      // Set feature on drawstart
      draw.on('drawstart', this.tooltip.setFeature.bind(this.tooltip));
      // Remove feature on finish
      draw.on('drawend', this.tooltip.removeFeature.bind(this.tooltip));
    });

    this.on('propertychange', () => {
      this.syncDraws();
    });
  }
  setMap(...args) {
    super.setMap(...args);

    const map = this.getMap();
    if (map) {
      map.addOverlay(this.tooltip);
    }
  }
  getMeasureType(): MeasureType | undefined {
    return this.get('measureType') ?? MeasureType.distance;
  }
  getUnit(): AbsoluteUnit {
    const map = this.getMap();
    return map ? getProjectUnit(map) : METER;
  }
  letTooltipGo() {
    this.tooltip.removeFeature();
    this.tooltip.hide();
  }
  syncDraws() {
    const map = this.getMap()!;

    this.letTooltipGo();

    if (this.getMeasureType() === MeasureType.distance) {
      map.removeInteraction(this.draws.area);
      map.addInteraction(this.draws.line);
    } else if (this.getMeasureType() === MeasureType.area) {
      map.removeInteraction(this.draws.line);
      map.addInteraction(this.draws.area);
    }
  }
  handleButtonClick() {
    if (!this.isPopoverVisible) {
      this.syncDraws();
    } else {
      const map = this.getMap()!;

      this.letTooltipGo();

      if (this.getMeasureType() === MeasureType.distance) {
        map.removeInteraction(this.draws.line);
      } else if (this.getMeasureType() === MeasureType.area) {
        map.removeInteraction(this.draws.area);
      }
    }
  }
  handlePopupShow() {
    this.isPopoverVisible = true;
  }
  handlePopupHidden() {
    this.isPopoverVisible = false;
  }
  handlePopoverInserted() {
    const distanceRadio = document.getElementById(
      'distanceRadio'
    )! as HTMLInputElement;
    const areaRadio = document.getElementById('areaRadio')! as HTMLInputElement;

    distanceRadio.addEventListener('click', () => {
      this.set('measureType', MeasureType.distance);
      this.syncDraws();
    });
    areaRadio.addEventListener('click', () => {
      this.set('measureType', MeasureType.area);
      this.syncDraws();
    });

    const measureType = this.getMeasureType();
    distanceRadio.checked = measureType === MeasureType.distance;
    areaRadio.checked = measureType === MeasureType.area;
  }
}
