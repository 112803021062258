<script lang="ts" setup>
const ShareGroupModal = () => import('./ShareGroupModal.vue');
const ShareGroupListModal = () => import('./ShareGroupListModal.vue');
import { useDialogStore } from '@component-library/store/dialog';
import { useToastStore } from '@component-library/store/toasts';
import { App, ShareableGroup } from '@component-library/gather';
import { computed, onBeforeMount, ref, watch } from 'vue';
import axios from 'axios';

const dialogStore = useDialogStore();
const toastStore = useToastStore();

const props = defineProps<{
  templateTabs: App[];
  templateTab: App & { parent?: App };
  canShare: boolean;
  isAdmin: boolean;
}>();

const showShareDropdown = ref(false);
const showShareGroupModal = ref(false);
const showSharedAppsModal = ref(false);
const editShareGroup = ref<ShareableGroup>();

const user = computed(() => {
  return import.meta.env.VITE_APP_USER;
});

const isClone = computed(() => {
  return (
    props.canShare &&
    props.templateTab &&
    props.templateTab.cloned_from_id &&
    props.templateTab.parent
  );
});

const unapprovedShareable = computed(() => {
  return props.templateTab?.template_tab_shareables?.find(
    (shareable) => !shareable.approved
  );
});

const hasWorkflowShares = ref(false);

watch(() => props.templateTabs, updateHasWorkflowShares);
onBeforeMount(updateHasWorkflowShares);

function updateHasWorkflowShares() {
  hasWorkflowShares.value = props.templateTabs.some((tab) =>
    tab.template_tab_shareables?.some(
      (shareable) => shareable.share_group !== null
    )
  );
}

const isPublicForm = computed(() => {
  return props.templateTab?.public_link;
});

const hasParentLink = computed(() => {
  return props.templateTab.parent?.project_id !== props.templateTab.project_id;
});

function openShareGroupModal() {
  showShareGroupModal.value = true;
}

function openSharedAppsModal() {
  showSharedAppsModal.value = true;
}

function onErrorResponse(error: any) {
  if (error.response?.status == 400 && error.response?.data?.message) {
    toastStore.error(error.response.data.message);
  } else {
    toastStore.error('An error occurred. Please try again later');
  }
}

async function sharePublicForm() {
  const response = await axios
    .post('api/template/share-app/' + props.templateTab.id + '/share-public')
    .catch(onErrorResponse);

  props.templateTab.public_link = response?.data?.public_link;
}

async function unsharePublicForm() {
  dialogStore.confirmDanger(
    'Unshare Public Form?',
    'Are you sure you wish to unshare this Public Form? The current URL will no longer work and cannot be recovered.',
    async () => {
      const response = await axios
        .post(
          'api/template/share-app/' + props.templateTab.id + '/unshare-public'
        )
        .catch(onErrorResponse);

      props.templateTab.public_link = response?.data.public_link;
    }
  );
}

function shareDropdownClicked() {
  showShareDropdown.value = !showShareDropdown.value;
}

function navigateTo(url) {
  window.location.href = url;
  window.location.reload();
}
</script>

<template>
  <div>
    <div class="d-flex align-items-center w-100">
      <div
        class="position-relative flex-grow-1"
        v-click-outside="() => (showShareDropdown = false)"
      >
        <div
          class="btn btn-sm w-100"
          :class="{
            'btn-warning': isClone,
            'btn-outline-secondary': !isClone,
          }"
          @click="shareDropdownClicked"
        >
          <span v-if="isClone">
            <i class="fas fa-fw fa-exclamation-circle"></i>
            Clone of shared template
            <i class="fas fa-angle-down"></i>
          </span>
          <span v-else>
            <i class="fas fa-fw fa-link"></i>
            Share
            <i class="fas fa-angle-down"></i>
          </span>
        </div>
        <div
          class="dropdown-menu w-100"
          :class="{
            'd-block': showShareDropdown,
          }"
          style="right: 0; top: calc(100% + 6px)"
        >
          <div
            v-if="isClone && hasParentLink && templateTab.parent"
            @click="
              templateTab.parent &&
                navigateTo(
                  `/#/template/${templateTab.parent.project_id}/editor/${templateTab.parent.id}`
                )
            "
            class="dropdown-item"
          >
            <small class="text-muted">Go to original App Editor</small>
          </div>
          <div class="dropdown-item" @click="openShareGroupModal">
            <small class="text-muted">
              <i class="fas fa-fw fa-file"></i> Share Apps
            </small>
          </div>
          <div
            v-if="hasWorkflowShares"
            class="dropdown-item"
            @click="openSharedAppsModal"
          >
            <small class="text-muted">
              <i class="fas fa-fw fa-file"></i> Browse Shared Apps
            </small>
          </div>
          <div
            class="dropdown-item"
            v-if="!isPublicForm"
            @click="sharePublicForm"
          >
            <small class="text-muted">
              <i class="fas fa-fw fa-file"></i> Share as Public Form
            </small>
          </div>
          <div class="dropdown-item" v-else @click="unsharePublicForm">
            <small class="text-muted">
              <i class="fas fa-fw fa-file"></i> Unshare as Public Form
            </small>
          </div>
        </div>
      </div>
    </div>

    <div v-if="unapprovedShareable" class="alert alert-warning mt-3">
      This app has been shared but is pending approval by
      {{ isAdmin ? 'you' : 'your Datanest Account Manager' }}.
      <a
        v-if="unapprovedShareable.shared_by_id == user.user_id"
        class="btn btn-warning"
        @click="openSharedAppsModal"
      >
        Browse Shared Apps
      </a>
    </div>

    <ShareGroupModal
      v-if="showShareGroupModal"
      :templateTabId="templateTab.id"
      :projectId="templateTab.project_id"
      :editShareGroup="editShareGroup"
      @close="
        () => {
          showShareGroupModal = false;
          editShareGroup = undefined;
        }
      "
    />

    <ShareGroupListModal
      v-if="showSharedAppsModal"
      @edit="
        (group) => {
          editShareGroup = group;
          showShareGroupModal = true;
          showSharedAppsModal = false;
        }
      "
      @new="
        () => {
          showSharedAppsModal = false;
          showShareGroupModal = true;
        }
      "
      @close="() => (showSharedAppsModal = false)"
    />
  </div>
</template>
