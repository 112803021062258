<template>
  <nav
    v-if="pagination != null && pagination.last_page > 1"
    class="d-flex align-items-center"
    data-cy="align-items"
  >
    <i class="spinner-border spinner-border-sm mr-2" v-if="loading"></i>
    <label v-if="hasTitle" class="mr-2 mb-0">
      {{ title }}: page <b>{{ pagination.current_page }}</b> of
      <b>{{ pagination.last_page }}</b>
    </label>
    <ul class="pagination" data-cy="pagination">
      <li
        class="page-item"
        data-cy="page-item"
        v-if="pagination.current_page > 1 && showArrows"
      >
        <a
          class="page-link previous-link"
          href="javascript:void(0)"
          data-cy="previous-link"
          @click.stop="changePage(pagination.current_page - 1)"
        >
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li
        class="page-item"
        v-for="(page, pageKey) in pagesNumber"
        :class="{ active: page == pagination.current_page }"
        :key="pageKey"
      >
        <a
          class="page-link"
          href="javascript:void(0)"
          @click.stop="changePage(page)"
          :class="{ last: getLast(page), first: getFirst(page) }"
        >
          {{ page }}
        </a>
      </li>
      <li
        class="page-item"
        v-if="pagination.current_page < pagination.last_page && showArrows"
      >
        <a
          class="page-link next-link"
          data-cy="next-link"
          href="javascript:void(0)"
          @click.stop="changePage(pagination.current_page + 1)"
        >
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    pagination: {
      required: true,
    },
    offset: {
      type: Number,
      default: 4,
    },
    title: {
      type: String,
    },
    hasTitle: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showArrows: {
      type: Boolean,
      default: true,
    },
    pageLimit: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    pagesNumber() {
      if (this.isDataBelowLimit) {
        let paginationArray = [];
        for (let i = 0; i < this.pagination.last_page; i++) {
          paginationArray.push(i + 1);
        }
        return paginationArray;
      }

      let currentPage = this.pagination.current_page;
      let lastPage = this.pagination.last_page;
      let visiblePagesCount = this.pageLimit;
      let visiblePagesThreshold = Math.round((visiblePagesCount - 1) / 2);
      let pagintationTriggersArray = Array(this.pageLimit - 1).fill(0);

      if (currentPage <= visiblePagesThreshold + 1) {
        pagintationTriggersArray[0] = 1;

        let pagintationTriggers = pagintationTriggersArray.map(
          (paginationTrigger, index) => {
            return pagintationTriggersArray[0] + index;
          }
        );

        pagintationTriggers.push(lastPage);

        return pagintationTriggers;
      }

      if (currentPage >= lastPage - visiblePagesThreshold + 1) {
        let pagintationTriggers = pagintationTriggersArray.map(
          (paginationTrigger, index) => {
            return lastPage - index;
          }
        );

        pagintationTriggers.reverse().unshift(1);

        return pagintationTriggers;
      }

      pagintationTriggersArray[0] = currentPage - visiblePagesThreshold + 1;

      let pagintationTriggers = pagintationTriggersArray.map(
        (paginationTrigger, index) => {
          return pagintationTriggersArray[0] + index;
        }
      );

      pagintationTriggers.unshift(1);
      pagintationTriggers[pagintationTriggers.length - 1] = lastPage;

      return pagintationTriggers;
    },
    isDataBelowLimit() {
      return this.pagination.last_page < this.pageLimit;
    },
    getHalfwayLimit() {
      return this.pageLimit - Math.round(this.pageLimit / 2);
    },
  },
  methods: {
    changePage(page) {
      if (page == this.pagination.current_page) {
        return;
      }
      this.pagination.current_page = page;
      this.$emit('paginate', page);
    },
    getFirst(page) {
      if (this.isDataBelowLimit) return false;

      return (
        page == 1 &&
        Math.abs(page - this.pagination.current_page) > this.getHalfwayLimit
      );
    },
    getLast(page) {
      if (this.isDataBelowLimit) return false;

      return (
        page == this.pagination.last_page &&
        Math.abs(page - this.pagination.current_page) > this.getHalfwayLimit
      );
    },
  },
};
</script>
<style scoped>
a.first::after {
  content: '...';
}

a.last::before {
  content: '...';
}
</style>
