<script setup lang="ts">
import type {
  ArgGroup,
  CustomFunction,
  FieldOperand,
} from '@component-library/business-model/expression';
import { ParamType } from '@component-library/business-model/expression';
import { getArgDisplayText } from '@component-library/business-logic/expression';
import { Popover } from 'bootstrap';
import { onMounted, ref } from 'vue';

const props = defineProps<{
  value: CustomFunction;
  argGroups: ArgGroup[];
  fields: FieldOperand[];
  checkIsParamGroupSelected(paramGroupIndex: number): boolean;
}>();
const emit = defineEmits(['paramGroupSelect', 'argDelete']);

const paramGroupElements = ref<HTMLDivElement[]>([]);

function selectParamGroup(paramGroupIndex: number) {
  emit('paramGroupSelect', paramGroupIndex);
}

function getArgClass(paramType: ParamType): string {
  switch (paramType) {
    case ParamType.FIELD:
      return 'bg-primary text-white';
    case ParamType.LITERAL_TEXT:
      return 'bg-success text-white';
    default:
      return 'bg-light';
  }
}

function handleParamGroupSelect(paramGroupIndex: number) {
  selectParamGroup(paramGroupIndex);
}

function handleArgDelete(paramGroupIndex: number, argIndex: number) {
  emit('argDelete', { paramGroupIndex, argIndex });
}

onMounted(() => {
  paramGroupElements.value.forEach((pgElement) => {
    const pgIndex = parseInt(
      pgElement.getAttribute('data-pgIndex') as string,
      10
    );
    const pg = props.value.paramGroups[pgIndex];
    new Popover(pgElement, {
      content: pg.tooltip,
    });
  });

  if (props.value.paramGroups.length > 0) {
    selectParamGroup(0);
  }
});
</script>

<template>
  <div class="d-flex align-items-center flex-wrap p-1 custom-function-tag">
    <div>{{ value.displayName }}(</div>
    <div
      v-for="(pg, pgIndex) in value.paramGroups"
      :key="`paramGroupContainer-${pgIndex}`"
      class="d-flex"
    >
      <div
        ref="paramGroupElements"
        class="d-flex justify-content-center flex-wrap p-1 param-group"
        :class="{
          'param-group-selected': checkIsParamGroupSelected(pgIndex),
        }"
        :data-pgIndex="pgIndex"
        data-bs-trigger="hover"
        data-bs-html="true"
        data-bs-container="div.expression-editor"
        data-bs-placement="bottom"
        @click.stop="handleParamGroupSelect(pgIndex)"
      >
        <div
          v-for="(arg, argIndex) in argGroups[pgIndex]"
          :key="`argGroup-${argIndex}`"
          class="d-flex arg-container"
        >
          <div class="d-flex p-1 arg" :class="getArgClass(arg.type)">
            <div>
              {{ getArgDisplayText(arg, props.fields) }}
            </div>
            <div
              @click.stop="handleArgDelete(pgIndex, argIndex)"
              class="mx-1 arg-delete-button"
            >
              <i class="fas fa-trash-alt" />
            </div>
          </div>
          <div v-if="argIndex < argGroups[pgIndex].length - 1" class="me-2">
            ,
          </div>
        </div>
      </div>
      <div
        v-if="pgIndex < value.paramGroups.length - 1"
        class="ms-1 me-2 d-flex align-items-end"
      >
        ,
      </div>
    </div>
    <div>)</div>
  </div>
</template>

<style lang="scss" scoped>
.custom-function-tag {
  row-gap: 10px;

  .param-group {
    border: 1px solid black;
    min-width: 100px;
    min-height: 36px;
    cursor: pointer;
    text-align: center;
    row-gap: 10px;

    &-selected {
      border-bottom: 4px solid black;
    }

    .arg-container {
      .arg {
        white-space: pre;
      }
      .arg-delete-button {
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
</style>
