<script lang="ts" setup>
import makeId from '@component-library/local-id.mjs';
import { computed, watch, onMounted, ref } from 'vue';

const props = defineProps({
  value: {
    type: Object,
    required: true,
  },
});
const shouldUseCurrentDateTimeCheckboxId = ref(makeId());

const emit = defineEmits(['input']);

onMounted(defaultOptions);

const field = computed({
  get() {
    return props.value;
  },
  set(updated) {
    emit('input', updated);
  },
});
watch(field, defaultOptions);

const type = computed({
  get() {
    return props.value?.options?.type || null;
  },
  set(updated) {
    const options = { ...(props.value?.options || { type: null }) };
    options.type = updated;

    if (options.type !== 'date') {
      if (options.format === 'month') {
        options.format = 'DD-MM-YYYY';
      }
      if (options.format === 'year') {
        options.format = 'DD-MM-YYYY';
      }
    }
    emit('input', { ...props.value, options });
  },
});

const format = computed({
  get() {
    return props.value?.options?.format || '';
  },
  set(updated) {
    const options = { ...(props.value?.options || { format: null }) };
    options.format = updated;
    emit('input', { ...props.value, options });
  },
});

const shouldUseCurrentDateTime = computed({
  get() {
    return field.value.options?.shouldUseCurrentDateTime ?? true;
  },
  set(value) {
    const options = { ...field.value.options, shouldUseCurrentDateTime: value };
    emit('input', { ...props.value, options });
  },
});

function defaultOptions() {
  if (!type.value) {
    type.value = 'datetime';
  }
}

function updateType(newType) {
  type.value = newType;
}

function updateFormat(newFormat: Event) {
  format.value = (newFormat.target as HTMLInputElement).value;
}
</script>

<template>
  <div>
    <div class="form-group mb-3">
      <label class="form-label">Type</label>
      <div>
        <div class="btn-group btn-group-toggle w-100 mb-2">
          <button
            type="button"
            class="btn btn-outline-dark"
            :class="{
              active: field.options.type === 'date',
            }"
            @click="updateType('date')"
          >
            Date
          </button>
          <button
            type="button"
            class="btn btn-outline-dark"
            :class="{
              active: field.options.type === 'datetime',
            }"
            @click="updateType('datetime')"
          >
            Date & Time
          </button>
          <button
            type="button"
            class="btn btn-outline-dark"
            :class="{
              active: field.options.type == 'time',
            }"
            @click="updateType('time')"
          >
            Time Only
          </button>
        </div>
      </div>

      <template
        v-if="
          field.options.type === 'date' || field.options.type === 'datetime'
        "
      >
        <label>Date Format</label>
        <select
          class="form-control mb-2"
          :value="field.options.format ?? 'DD-MM-YYYY'"
          @input="updateFormat"
        >
          <option value="DD-MM-YYYY">DD-MM-YYYY</option>
          <option value="MM-DD-YYYY">MM-DD-YYYY</option>
          <option value="YYYY-MM-DD">YYYY-MM-DD</option>
          <option v-if="field.options.type === 'date'" value="month">
            MM-YYYY
          </option>
          <option v-if="field.options.type === 'date'" value="year">
            YYYY
          </option>
        </select>
      </template>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          :id="shouldUseCurrentDateTimeCheckboxId"
          v-model="shouldUseCurrentDateTime"
        />
        <label
          class="form-check-label"
          :for="shouldUseCurrentDateTimeCheckboxId"
        >
          Use the current date and time as the default value
        </label>
      </div>
    </div>
  </div>
</template>
