import { getFieldTypeById } from '@component-library/fields';
import UnknownField from './Unknown.vue';
import DropdownField from './Dropdown.vue';
import CheckboxField from './Checkbox.vue';
import NumberField from './Number.vue';
import DepthField from './Depth.vue';
import DocumentField from './Document.vue';
import DateField from './Date.vue';
import MediaField from './Media.vue';
import TextField from './Text.vue';
import CaptionField from './Caption.vue';
import ExpressionField from './Expression.vue';
import ReferenceField from './Reference.vue';
import LithologyField from './Lithology.vue';
import UserField from './User.vue';
import AddressField from './Address.vue';
import DuplicateField from './LabId.vue';
import TriplicateField from './LabId.vue';
import LabIdField from './LabId.vue';

const types = [
  'dropdown',
  'checkbox',
  'number',
  'depth',
  'document',
  'date',
  'media',
  'text',
  'caption',
  'expression',
  'reference',
  'lithology',
  'user',
  'address',
  'duplicate',
  'triplicate',
  'lab-id',
];

const getComponentName = (type: string) => {
  if (types.includes(type)) {
    return type + '-field';
  }

  return 'unknown-field';
};

const getComponentNameFromId = (field_type_id) => {
  return getComponentName(getFieldTypeById(field_type_id)?.type || '');
};

export default {
  components: {
    UnknownField,
    DropdownField,
    CheckboxField,
    NumberField,
    DepthField,
    DocumentField,
    DateField,
    MediaField,
    TextField,
    ExpressionField,
    CaptionField,
    ReferenceField,
    LithologyField,
    UserField,
    AddressField,
    DuplicateField,
    TriplicateField,
    LabIdField,
  },
  types,
  getComponentName,
  getComponentNameFromId,
};
