import { User } from '../../../user';
import { getUniqueValuesFromArrayByKey } from '../../../utils';

export const getUserTitle = (user: User) => {
  const deviceType = getPushNotificationDevice(user);
  return `${user.name}  | ${user.email} ${deviceType ? `| ${deviceType}` : ''}`;
};

export const getPushNotificationDevice = (user?: User) => {
  if (!user) {
    return null;
  }

  const pushSubscriptions = user?.push_subscriptions || [];

  return pushSubscriptions.length > 0
    ? getUniqueValuesFromArrayByKey(
        pushSubscriptions.filter((s) => s.device !== null),
        'device'
      )
        .map((s) => s.device)
        .join(', ')
    : null;
};
