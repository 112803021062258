import { useStore } from '@/js/store';
import { getAvailableBasemapApis as _getAvailableBasemapApis } from '@maps/lib/olbm/layer/basemap/utils';
import { deleteSampleStylesFromCacheByFigureId } from '@maps/lib/olbm/layer/sample/getSampleStyle';
import type {
  AppStylingRule,
  Figure,
  FigureStylingRule,
  Id,
  Pid,
  Project,
} from '@maps/lib/olbm/types';
import { ProjectType } from '@maps/lib/olbm/types';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import useMapsApi from '../composables/useMapsApi';
import useEvalu8Store from './evalu8';
import useLayerModelStore from './layer-model';
import useSampleStore from './sample';

const useFigureStore = defineStore('figure', () => {
  const store = useStore();

  const sampleStore = useSampleStore();
  const layerModelStore = useLayerModelStore();
  const evalu8Store = useEvalu8Store();
  const { loadScenarios, loadChemicals, loadExceedances, loadChemicalResults } =
    evalu8Store;

  const figures = ref<Figure[]>([]);
  const figureStylingRules = ref<FigureStylingRule[]>([]);
  const appStylingRules = ref<AppStylingRule[]>([]);
  const selectedFigureId = ref<number | undefined>(undefined);

  const getProject = (): Project => {
    return store.state.project!;
  };

  const {
    loadFigures: _loadFigures,
    ensureSampleGroups,
    modifyFigure: _modifyFigure,
    saveSelectedBasemapId: _saveSelectedBasemapId,
  } = useMapsApi();

  const getSelectedFigure = (): Figure | undefined => {
    return selectedFigureId.value
      ? findFigureById(selectedFigureId.value)
      : undefined;
  };

  const setSelectedFigureId = (id?: number): void => {
    selectedFigureId.value = id;
  };

  const loadFigures = async () => {
    const {
      figures: _figures,
      figureStylingRules: _figureStylingRules,
      appStylingRules: _appStylingRules,
      lastFigureId: _lastFigureId,
    } = await _loadFigures();
    figures.value = _figures;
    figureStylingRules.value = _figureStylingRules;
    appStylingRules.value = _appStylingRules;
    selectedFigureId.value = _lastFigureId || undefined;
  };

  const findFigureById = (id: Id): Figure | undefined => {
    return figures.value.find((figure) => figure.id === id);
  };

  const findFigureStylingRulesByFigureId = (
    figureId: Id
  ): FigureStylingRule[] => {
    return [
      ...figureStylingRules.value.filter((fsr) => fsr.figureId === figureId),
      ...(appStylingRules.value.map((asr) => ({
        ...asr,
        figureId,
      })) as FigureStylingRule[]),
    ];
  };

  const getAvailableBasemapApis = () => {
    const {
      location: { country, state },
      address_state: addressState,
    } = getProject();

    return _getAvailableBasemapApis(country, state ?? addressState) ?? [];
  };

  const getLockableLayerModelIds = (figureId: Pid): Pid[] => {
    const otherFigures = figures.value.filter(
      (figure) => figure.id !== figureId
    );
    const lockedLayerModelIds = otherFigures.reduce((accu, otherFigure) => {
      accu.push(...(otherFigure.locked_layer_ids ?? []));
      return accu;
    }, [] as Pid[]);
    return layerModelStore.layerModels
      .filter(
        (layerModel) => !lockedLayerModelIds.includes(layerModel.id as Pid)
      )
      .map((layerModel) => layerModel.id as Pid);
  };

  const getLockedByFigure = (layerModelId: Pid): Figure | undefined => {
    let layerModel = layerModelStore.findLayerModelById(layerModelId);
    while (layerModel?.parent_id) {
      layerModel = layerModelStore.findLayerModelById(layerModel.parent_id);
    }

    if (!layerModel) {
      return undefined;
    }

    const finalizer = figures.value.find((f) =>
      f.locked_layer_ids?.includes(layerModel!.id as number)
    );
    const selectedFigure = selectedFigureId.value
      ? figures.value.find((f) => f.id === selectedFigureId.value)
      : undefined;
    const basemapFigure = figures.value.find((f) => f.is_basemap);
    // Layers locked by the Basemap figure are still editable in the Basemap figure.
    const isLockedByBasemapFigure =
      (!selectedFigure || !selectedFigure.is_basemap) &&
      layerModel.is_visible_in_basemap_figure;
    return finalizer ?? (isLockedByBasemapFigure ? basemapFigure : undefined);
  };

  const modifyFigure = async (figureId: Pid, lockedLayerModelIds) => {
    await _modifyFigure(figureId, lockedLayerModelIds);
    const figure = findFigureById(figureId);
    figure!.locked_layer_ids = lockedLayerModelIds;
  };

  const saveSelectedBasemapId = async (
    figureId: Pid,
    selectedBasemapId: number
  ) => {
    await _saveSelectedBasemapId(figureId, selectedBasemapId);
    const figure = findFigureById(figureId);
    figure!.basemap_index = selectedBasemapId;
  };

  const checkIsAppEnabled = (
    appId: number | null,
    allApps,
    isNonSpatialView = false
  ): boolean => {
    if (!appId) {
      return true;
    }

    if (isNonSpatialView) {
      return true;
    }

    const selectedFigure = getSelectedFigure();
    if (!selectedFigure) {
      return false;
    } else if (selectedFigure.gather_access) {
      return true;
    }

    const app = allApps.find((_app) => _app.id === appId);
    if (!app) {
      return false;
    }
    const { default_figure_ids: defaultFigureIds } = app;
    return (
      defaultFigureIds.includes(0) ||
      defaultFigureIds.includes(selectedFigure.id)
    );
  };

  watch(selectedFigureId, async (newValue) => {
    if (!newValue) {
      return;
    }

    const project = getProject();
    const figure = findFigureById(newValue)!;
    await store.dispatch('setSelectedFigureId', newValue);

    sampleStore.clear();
    await ensureSampleGroups();
    const { loadLayerModels } = layerModelStore;
    await loadLayerModels(figure.id);

    if (project.project_type === ProjectType.ENVIRO) {
      await loadScenarios();
      await loadChemicals();
      await loadExceedances();
      await loadChemicalResults(figure);
    }

    deleteSampleStylesFromCacheByFigureId(newValue);
  });

  return {
    figures,
    figureStylingRules,
    appStylingRules,
    selectedFigureId,
    getProject,
    loadFigures,
    getSelectedFigure,
    setSelectedFigureId,
    findFigureStylingRulesByFigureId,
    getAvailableBasemapApis,
    getLockableLayerModelIds,
    getLockedByFigure,
    modifyFigure,
    saveSelectedBasemapId,
    checkIsAppEnabled,
  };
});

export default useFigureStore;
