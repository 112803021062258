import { OfflineProject, OfflineSample } from '@component-library/offline-data';

import { get, getJSON } from '../helpers/fetch-api';
import { removeCacheValues } from '@/js/helpers/cache-manager.js';
import createAreaFigureLayer from '../modules/project/map/helpers/createAreaFigureLayer';
import { OfflineStorageManagerReturnTypeSW } from './useOfflineStorageManagerSW';

const FILE_REGEX: RegExp = /(?:\.([^.]+))?$/;

export default function useSampleOfflineManager(
  offlineStorageManager: OfflineStorageManagerReturnTypeSW,
  project: OfflineProject
) {
  const cacheSamplesForOffline = async (
    sample_ids: number[]
  ): Promise<OfflineSample[]> => {
    const { data } = await getJSON(`/api/offline/sync/samples`, {
      params: {
        project_id: project.project_id,
        sample_ids: JSON.stringify(sample_ids),
      },
    });

    // Fix the problem that type is missing
    const samples = data.samples.map((s) => {
      if (s.area_figure_layer && !s.area_figure_layer.geojson.properties.type) {
        s.area_figure_layer = createAreaFigureLayer(
          s.area_geojson,
          s.custom_title
        );
      }
      return s;
    });

    for (const sample of samples) {
      await _cacheSampleForOffline(sample, project.project_id);
    }

    return samples;
  };

  const _cacheSampleForOffline = async (
    sample: OfflineSample,
    projectId: number
  ) => {
    const assetValues = sample.values.filter((v) => {
      const match = FILE_REGEX.exec(v.value as string);
      return match && match[1];
    });

    const cache = await caches.open('images');

    const _cacheAssetForOffline = async (url: string) => {
      try {
        let response = await cache.match(url);

        if (!response) {
          response = await get(
            url,
            {
              project_id: projectId,
            },
            {}
          );

          await cache.put(url, response.clone());
        }
      } catch (err: any) {
        const errUrl = err.request.responseURL;

        let response = await cache.match(errUrl);
        if (!response) {
          response = await get(
            errUrl,
            {
              project_id: projectId,
            },
            {}
          );

          await cache.put(url, response.clone());
        }
      }
    };

    for (const value of assetValues) {
      try {
        const multipleAssets = JSON.parse(value.value as string);
        if (Array.isArray(multipleAssets)) {
          for (const asset of multipleAssets) {
            await _cacheAssetForOffline(
              `/api/images/value/${project.project_id}/${asset.src}`
            );
          }
        }
      } catch (e) {
        await _cacheAssetForOffline(
          `/api/images/value/${project.project_id}/${value.value}`
        );
      }
    }

    await offlineStorageManager.createOfflineSample(sample, project.project_id);
  };

  const removeOfflineSample = async (sample: OfflineSample) => {
    const projectId = project.project_id;

    // remove all cached images
    const valuesToRemove = sample.values
      .filter((v) => {
        const match = FILE_REGEX.exec(v.value as string);
        return match && match[1];
      })
      .map(
        (filename) =>
          `${window.location.origin}/api/images/value/${projectId}/${filename}`
      );

    await removeCacheValues('images', valuesToRemove);

    await offlineStorageManager.deleteOfflineSample(sample.id!, projectId);
  };

  return {
    cacheSamplesForOffline,
    removeOfflineSample,
  };
}
