import { normalizeSampleDataToModify } from '@/js/helpers/general';
import { useStore } from '@/js/store';
import useLayerModelStore from '@/js/stores/layer-model';
import { checkIsSampleModal, getTopModal } from '@/js/types/modal-stack';
import { EVENTS, postAnalyticsEvent } from '@component-library/analytics';
import { getSampleTitle } from '@maps/lib/olbm';
import { checkIsValidLatLng } from '@maps/lib/olbm/common/coordinate';
import { LayerType } from '@maps/lib/olbm/layer/types';
import { getModelId } from '@maps/lib/olbm/layer/utils';
import { produce } from 'immer';
import api from '../api';

const store = useStore();

class SampleEvents {
  static eventNames = [
    'setSampleDataToModify',
    'updateSample',
    'updateGeojson',
    'addAreaFigureLayer',
    'modifySampleLocation',
    'startDuplicateSampleMovement',
    'duplicateSample',
    'deleteSample',
    'updateSampleLocation',
    'goToSampleOnMap',
    'onSaveSampleFinish',
    'refreshAllSamples',
    'updateTemplateSampleCount',
  ];

  get map() {
    return this.viewer.getMap();
  }

  constructor(viewer) {
    this.viewer = viewer;

    SampleEvents.eventNames.forEach((event) => {
      this.viewer.$root.$on(event, (data) => {
        this[event](data);
      });
    });
  }

  setSampleDataToModify(sampleToModify) {
    // only used for creation of samples
    if (
      sampleToModify &&
      !sampleToModify.id &&
      !sampleToModify.template_tab_id
    ) {
      sampleToModify.template_to_select = this.viewer.templateTabToSelect;
    }

    this.viewer.sampleDataToModify = sampleToModify;
  }

  async addAreaFigureLayer({ areaFigureLayer, afterLayerId, done }) {
    if (!this.map.findLayerModelById(areaFigureLayer.id)) {
      const { id, geojson, parent_id } = areaFigureLayer;
      const figure = this.map.getSelectedFigure();
      await this.map.addLayerModel(
        figure,
        {
          id,
          geojson,
          visible: true,
          children: [],
          parent_id,
        },
        afterLayerId
      );
    }
    done();
  }

  updateSample(sample) {
    if (this.viewer.isNonSpatialView) {
      this.viewer.$refs['non-spatial'].getFreshEntries();
      return;
    }

    this.viewer.sampleStore.updateSample(sample);
  }

  updateGeojson({ sample, geojson }) {
    this.map.updateGeojson(sample, geojson);
  }

  async modifySampleLocation(sample) {
    if (this.viewer.isEditing || this.viewer.isMoving) {
      return;
    }

    this.viewer.isEditing = true;
    this.viewer.$root.$emit('setSampleDataToModify', sample);

    const im = this.map.getInteractionManager();
    const feature = im.layerManager.findSampleFeatureById(sample.id);
    const layer = im.layerManager.findLayerByFeature(feature);
    const layerModelId = getModelId(layer);
    im.beginSession({
      layerModelId,
      originalPointsOfInterest: sample.points_of_interest,
    });
    const edit = im.requestEdit(feature, false);
    edit.selectFeature(feature);

    const layerModel = this.map.findLayerModelById(layerModelId);
    if (layerModel.geojson.properties.type === LayerType.HEDGE) {
      // Needs re-rendering to get background and outline.
      feature.changed();
    }
  }

  startDuplicateSampleMovement() {
    const sampleDataToDuplicate = this.viewer.sampleDataToModify;
    const sampleGroup = this.map.getSampleLayerModel(sampleDataToDuplicate);

    const lm = this.map.getLayerManager();
    const layer = lm.findLayerByModelId(sampleGroup.id);

    // The sampleGroup takes precedence over tabId.
    this.viewer.$root.$emit('startDrawMarker', {
      sampleGroup,
      tabId: sampleDataToDuplicate.template_tab_id,
      title: `${getSampleTitle(sampleDataToDuplicate)} - copy`,
      position: {
        lat: sampleDataToDuplicate.latitude,
        lng: sampleDataToDuplicate.longitude,
      },
      labelPosition: sampleDataToDuplicate.label_position,
      iconRotation: sampleDataToDuplicate.icon_rotation,
    });

    this.viewer.isDuplicating = true;
  }

  // Only used for point samples.
  duplicateSample() {
    const sampleDataToDuplicate = this.viewer.sampleDataToModify;

    api
      .duplicateSample({
        sample_id: sampleDataToDuplicate.sample_id,
        position: sampleDataToDuplicate.latlng,
        label_position: sampleDataToDuplicate.label_position,
      })
      .then((response) => {
        const sample = response.data.sample;

        this.viewer.sampleStore.addSample(sample);
        this.viewer.$root.$emit('setSampleDataToModify', sample);

        store.dispatch('updatePersistence', {
          sampleData: null,
        });

        this.viewer.openSampleModal();
      })
      .catch((error) => {
        this.viewer.$toastStore.error('Failed to duplicate sample, try again.');
        this.setSampleDataToModify(null);
        throw error;
      });
  }

  async deleteSample() {
    const { payload: sampleDataToDelete } = getTopModal(this.viewer.modalStack);
    let shouldPopSampleModal = true;
    try {
      await api.deleteSample(sampleDataToDelete.id);
      if (this.viewer.isNonSpatialView) {
        this.viewer.$refs['non-spatial'].getFreshEntries();
      } else {
        this.map.removeSample(sampleDataToDelete.id);
      }
      this.updateTemplateSampleCount({
        id: sampleDataToDelete.template_tab_id,
        decrement: true,
      });
    } catch (err) {
      if (err.response?.status == 404) {
        this.viewer.$toastStore.error('Item already deleted.');
        return;
      }

      this.viewer.$toastStore.error('Failed to delete item, try again.');
      shouldPopSampleModal = false;
      console.error(e);
    } finally {
      // Pop the DeleteSampleModal
      this.viewer.popFromModalStack();
      const topModal = getTopModal(this.viewer.modalStack);
      if (shouldPopSampleModal && checkIsSampleModal(topModal)) {
        this.viewer.popFromModalStack();
      }
    }
  }

  async updateSampleLocation() {
    const sampleDataToModify = normalizeSampleDataToModify(
      this.viewer.sampleDataToModify
    );
    const { id, duplicating } = sampleDataToModify;

    try {
      const {
        data: { sample },
      } = await api.modifySampleCoord(sampleDataToModify);
      this.updateSample(sample);
      if (sample.area_figure_layer) {
        this.map.updateLayerModel(sample.area_figure_layer.id, {
          geojson: sample.area_figure_layer.geojson,
        });
      }

      const im = this.map.getInteractionManager();
      const feature = im.layerManager.findSampleFeatureById(id);
      im.layerManager.showFeature(feature);
      im.endSession();

      if (duplicating) {
        await this.viewer.$nextTick();
        this.viewer.$root.$emit(
          'setSampleDataToModify',
          this.viewer.getMap().findSampleById(id)
        );
        this.viewer.updatePersistence({
          sampleData: null,
        });
      }
    } catch (err) {
      this.viewer.$toastStore.error(
        'Failed to update sample, refresh and try again.'
      );
      throw err;
    } finally {
      this.viewer.openSampleModal();
    }
  }

  goToSampleOnMap({ latitude, longitude }) {
    if (!latitude || !longitude) {
      return;
    }

    this.map.animateView({ longitude, latitude }, 21);
  }

  async onSaveSampleFinish({ sample, clickedPoi }) {
    if (!this.map) {
      console.warn(
        'onSaveSampleFinish skipping map events as this should be non-spatial view'
      );
      return;
    }
    const im = this.map.getInteractionManager();
    if (im.hasSession()) {
      im.endSession();
    }
    if (this.viewer.isEditing) {
      this.viewer.isEditing = false;
      this.viewer.isPoiEditingOn = false;
    }

    // The sample is saved successfully.
    if (sample) {
      if (checkIsValidLatLng({ lat: sample.latitude, lng: sample.longitude })) {
        this.map.deleteSampleStyleFromCache(sample);
        const { getSampleLayerModel, toggleLayerModelVisibility } =
          useLayerModelStore();
        const layerModel = getSampleLayerModel(sample);
        if (!layerModel.visible) {
          await toggleLayerModelVisibility(
            this.map.getSelectedFigure().id,
            layerModel.id
          );
          const layers = await im.layerManager.createLayers(layerModel);
          layers.forEach((layer) => im.layerManager.addLayer(layer));
        }
      }

      // Save the point of interes's data form relationship.
      if (clickedPoi) {
        const polySample = this.map.findSampleById(clickedPoi.sampleId);
        const app = this.viewer.templateTabs.find(
          (app) => app.id === polySample.template_tab_id
        );
        const section = app.sections.find(
          (section) => section.system_reference === 'point_of_interest'
        );
        const field = section.template_fields.find(
          (field) => field.system_reference === 'data_form'
        );
        await api.modifySampleValue({
          sample_id: polySample.id,
          template_field_id: field.id,
          template_section_index: clickedPoi.index,
          value: sample.id,
        });
        polySample.points_of_interest = produce(
          polySample.points_of_interest,
          (draft) => {
            draft[clickedPoi.index].dataForm = sample.id;
          }
        );
        const { input_values_for_linking } =
          await this.viewer.sampleStore.loadSample(polySample.id);
        polySample.input_values_for_linking = input_values_for_linking;
        const lm = this.map.getLayerManager();
        const layer = lm.findSampleLayerBySampleId(polySample.id);
        layer.refresh();
      }
      postAnalyticsEvent({
        event: EVENTS.gather,
        properties: {
          action: 'sample_created',
        },
      });
    }
  }

  refreshAllSamples() {
    const lm = this.map.getLayerManager();
    const sampleLayers = lm.findLayersByType(LayerType.SAMPLE);

    sampleLayers.forEach((layer) => {
      layer.refresh();
    });
  }

  updateTemplateSampleCount({ id, samples_count = 0, decrement = false }) {
    const tabToUpdate = this.viewer.templateTabs.find((t) => t.id === id);
    tabToUpdate.samples_count = decrement
      ? tabToUpdate.samples_count - 1
      : samples_count;
  }
}

export default SampleEvents;
