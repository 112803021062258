<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { FieldTypeIds } from '@component-library/fields';
import { getDefaultValuePair } from '@component-library/business-logic/expression';

const props = defineProps<{
  value: string;
}>();

const emit = defineEmits(['input']);

const time = computed<string | null>({
  get() {
    return props.value;
  },
  set(value) {
    emit('input', value);
  },
});
onMounted(() => {
  if (!time.value) {
    const { value2 } = getDefaultValuePair({
      field_type_id: FieldTypeIds.DATE,
    });
    time.value = value2;
  }
});
</script>

<template>
  <input type="time" class="form-control" v-model="time" name="time" />
</template>
