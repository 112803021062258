<template>
  <div>
    <div class="d-flex align-items-center">
      <h5
        class="fas opacity-75 mb-0 me-1"
        :class="{
          'fa-times-circle': !name,
          'fa-check-circle': name,
        }"
      />
      <h6 class="mb-0" v-if="name">{{ type }} has signed</h6>
      <h6 class="mb-0" v-else>Awaiting {{ type }} signature</h6>
    </div>
    <div v-if="name" class="mt-2">
      <img :src="signature" class="mb-2" />
      <div class="d-flex flex-column">
        <b>{{ name }}</b>
        <small class="text-muted"> {{ date }} </small>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: String,
    name: String,
    signature: String,
    date: String,
  },
};
</script>
<style scoped>
img {
  width: 240px;
  height: 100px;
}

h6::first-letter {
  text-transform: capitalize;
}
</style>
