import { getUid } from 'ol';
import type { Sample } from '../layer/sample/types';
import { DEFAULT_LABEL_POSITION } from '../layer/sample/utils';
import { applyLayoutZoomToRead } from '../measurement/layout';
import InteractionManager from './InteractionManager';
import EditableSample from './overlay/EditableSample';

export default function createSampleEdit(
  interactionManager: InteractionManager,
  sample: Sample,
  forNewSample: boolean = true
) {
  const map = interactionManager.getMap();
  const editableSample = new EditableSample(
    map,
    interactionManager.layerManager,
    sample,
    forNewSample
  );
  const handleResolutionChange = () => {
    editableSample.refresh();
  };
  let feature_;
  let layer_;

  return {
    get layer() {
      return layer_;
    },
    set layer(value) {
      const previousLayer = layer_;
      if (previousLayer && value !== previousLayer) {
        interactionManager.changeEditsKey(getUid(previousLayer), getUid(value));
      }
      layer_ = value;
    },
    selectFeature(feature) {
      const position = feature.getGeometry().getCoordinates();
      editableSample.setPosition(position);
      feature_ = feature;
    },
    getFeature() {
      return feature_;
    },
    activate() {
      map.getView().on('change:resolution', handleResolutionChange);
      editableSample.activate(this);
      editableSample.refresh();
    },
    destroy() {
      map.getView().un('change:resolution', handleResolutionChange);
      editableSample.deactivate();
    },
    invalidate() {
      editableSample.invalidate(this);
    },
    setPosition(position) {
      editableSample.setPosition(position);
    },
    getState() {
      const { position, labelPosition, angle } = editableSample;
      const pixel = map.getPixelFromCoordinate(position!);
      const labelPixel = map.getPixelFromCoordinate(labelPosition!);
      const [offsetX, offsetY] = applyLayoutZoomToRead(
        map,
        () => {
          return [labelPixel[0] - pixel[0], labelPixel[1] - pixel[1]];
        },
        (offsets) => {
          return offsets.map((o) => Math.round(o));
        }
      )();

      return {
        sampleId: sample.id,
        position,
        labelPosition: !editableSample.checkIsGlued()
          ? { offsetX, offsetY }
          : DEFAULT_LABEL_POSITION,
        angle,
      };
    },
  };
}
