import {
  checkIsSpecificChemicalPlan,
  getAllSampleIdsFromSample,
} from '@maps/lib/olbm';
import type {
  Chemical,
  Exceedance,
  Figure,
  Id,
  Sample,
  Scenario,
  ScenarioStyle,
} from '@maps/lib/olbm/types';
import { CriteriaType } from '@maps/lib/olbm/types';
import { defineStore } from 'pinia';
import Vue, { ref } from 'vue';
import useMapsApi from '../composables/useMapsApi';
import useFigureStore from './figure';
import { ScenarioSet } from '@component-library/enviro';

const useEvalu8Store = defineStore('evalu8', () => {
  const figureStore = useFigureStore();
  const { getProject } = figureStore;

  const scenarios = ref<Scenario[]>([]);
  const scenarioStyles = ref<ScenarioStyle[]>([]);
  const scenarioSet = ref<ScenarioSet | null>(null);
  const chemicals = ref<Chemical[]>([]);
  const exceedances = ref<Exceedance[]>([]);

  const {
    loadScenarios: _loadScenarios,
    loadChemicals: _loadChemicals,
    loadExceedances: _loadExceedances,
    loadChemicalResults: _loadChemicalResults,
  } = useMapsApi();

  const loadScenarios = async () => {
    const {
      scenarios: _scenarios,
      scenarioStyles: _scenariosStyles,
      scenarioSet: _scenarioSet,
    } = await _loadScenarios();
    scenarios.value = _scenarios;
    scenarioStyles.value = _scenariosStyles;
    scenarioSet.value = _scenarioSet;
  };

  const loadChemicals = async () => {
    const { chemicals: _chemicals } = await _loadChemicals();
    chemicals.value = _chemicals;
  };

  const loadExceedances = async () => {
    const { exceedances: _exceedances } = await _loadExceedances();
    exceedances.value = _exceedances;
  };

  const loadChemicalResults = async (figure: Figure) => {
    if (checkIsSpecificChemicalPlan(figure) && !figure.results) {
      const { results } = await _loadChemicalResults(figure.chemicals);
      Vue.set(figure, 'results', results);
    }
  };

  const getExceedance = (criteriaType: CriteriaType): Exceedance => {
    return exceedances.value.find((e) => e.criteria_type == criteriaType)!;
  };

  const findScenarioById = (id: Id): Scenario | undefined => {
    return scenarios.value.find((scenario) => scenario.id === id);
  };

  const getExceededCriteriaTypes = (
    figure: Figure,
    sample: Sample,
    scenarios: Scenario[],
    chemicalIds: Id[]
  ): CriteriaType[] => {
    const project = getProject();

    const results = figure.results ?? [];
    const hiddenFormattedDepths =
      figure.enviro_callout_filter?.hiddenFormattedDepths ?? [];

    const allSampleIds = getAllSampleIdsFromSample(
      sample,
      hiddenFormattedDepths,
      project
    );

    const sampleItemIds: Id[] = results
      .filter((r) => chemicalIds.includes(r.chemical_id))
      .reduce((accu, r) => {
        accu.push(
          ...r.sample_items
            .filter((si) => allSampleIds.includes(si.sample_id))
            .map((si) => si.id)
        );
        return accu;
      }, [] as Id[]);

    const result: any[] = [];
    scenarios.forEach((s) => {
      const e = exceedances.value.find(
        (cte) => cte.criteria_type == s.criteria_type
      )!;
      const ctes = e.exceedances.filter(
        (cte) =>
          ((s.criteria_type === CriteriaType.Landuse &&
            cte.scenario_id === s.scenario_id) ||
            (s.criteria_type === CriteriaType.Criteria &&
              cte.criteria_set_id === s.criteria_set_id)) &&
          cte.exceedances.findIndex((re) =>
            allSampleIds.includes(re.sample_id)
          ) != -1 &&
          (cte.document_id ? s.document_id === cte.document_id : true)
      );

      if (!ctes.length) {
        return;
      }

      ctes.forEach((cte) => {
        if (
          cte.exceedances.filter((re) => sampleItemIds.includes(re.item_id))
            .length > 0 &&
          !result.includes(s.criteria_type)
        ) {
          result.push(s.criteria_type);
        }
      });
    });

    return result;
  };

  return {
    scenarios,
    scenarioStyles,
    scenarioSet,
    chemicals,
    exceedances,
    loadScenarios,
    loadChemicals,
    loadExceedances,
    loadChemicalResults,
    findScenarioById,
    getExceedance,
    getExceededCriteriaTypes,
  };
});

export default useEvalu8Store;
