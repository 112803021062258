import { Feature } from 'ol';
import type { Coordinate } from 'ol/coordinate';
import BaseEvent from 'ol/events/Event';
import { Geometry } from 'ol/geom';
import { GeolocationResult } from '../common/geolocation';
import { SampleLayer } from '../layer/sample/types';
import { ShapeLayer } from '../layer/shape/types';
import { LonLat } from '../types';

export class Event<P> extends BaseEvent {
  payload: P;

  constructor(type: string, payload: P) {
    super(type);
    this.payload = payload;
  }

  getPayload() {
    return this.payload;
  }
}

export type FeatureCreatedEventPayload = {
  layer: SampleLayer | ShapeLayer;
  activateEdit?: boolean;
};

export type FeatureClickEventPayload = {
  feature: Feature<Geometry>;
  cursorPosition: Coordinate;
};

export type PostFeatureCreatedEventPayload = {
  layer: SampleLayer | ShapeLayer;
};

export type PoiAddEventPayload = {
  longitude: number;
  latitude: number;
};

// The payload is the index of the poi.
export type PoiDeleteEventPayload = number;

export type PoiLonLatChangeEventPayload = {
  index: number;
  lonLat: LonLat;
};

export type GeolocationChangeEventPayload = GeolocationResult;
