<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
  toRight: Boolean,
  onTop: Boolean,
  onRight: Boolean,
  customWidth: {
    type: String,
    default: 'auto',
  },
  customStyle: {
    type: Object,
    default: () => ({}),
  },
  forceOpen: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: String,
    default: 'px-3',
  },
  autoDetermineDirectionH: {
    type: Boolean,
    default: false,
  },
});

const dropdownMenuRef = ref();
const toggled = ref(false);
const showToRight = ref(props.toRight);
const dropdownWidth = ref<string | null>(null);

const emit = defineEmits(['toggled']);

watch(
  () => props.forceOpen,
  (val) => {
    if (!val) return;

    toggled.value = true;
    emit('toggled', true);
  }
);

const toggle = () => {
  toggled.value = !toggled.value;
  emit('toggled', toggled.value);
};

const close = () => {
  if (!toggled.value) {
    return;
  }

  toggle();
};

defineExpose({ toggle, toggled, close });

onMounted(() => {
  if (props.autoDetermineDirectionH) {
    watch(
      [toggled, () => window.innerHeight, () => window.innerWidth],
      () => {
        if (dropdownMenuRef.value) {
          const rect = dropdownMenuRef.value?.getBoundingClientRect();
          if (!rect) {
            throw new Error('Unable to autoDetermineDirectionH: No rect');
          }

          const exceedsLeft = rect.left < 0 || rect.left - rect.width < 0;
          const exceedsRight =
            rect.right > window.innerWidth ||
            rect.right + rect.width > window.innerWidth;

          if (exceedsLeft && exceedsRight) {
            dropdownWidth.value = '100%';
            return;
          }

          if (exceedsRight && !exceedsLeft) {
            showToRight.value = true;
          }

          if (exceedsLeft && showToRight.value && !exceedsRight) {
            showToRight.value = false;
          }
        }
      },
      { immediate: true }
    );
  }
});
</script>

<template>
  <div class="dropdown" :class="{ toggled: toggled }" v-click-outside="close">
    <slot
      name="button"
      :toggle="() => toggle()"
      :toggled="toggled"
      @click="toggle"
      :class="{ dropup: onTop }"
    />

    <Transition name="dropdown">
      <div
        class="dropdown-menu mt-1"
        :class="[
          {
            show: toggled,
            'management__dropdown dropdown-menu-end': showToRight,
            top: onTop,
            onRight: onRight,
          },
          padding,
        ]"
        :style="{
          ...{ width: dropdownWidth || customWidth },
          ...customStyle,
        }"
        v-show="toggled"
        ref="dropdownMenuRef"
      >
        <slot name="items" :toggled="toggled" :close="() => toggle()" />
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.dropdown-menu {
  z-index: 10003;
}

@media only screen and (max-width: 576px) {
  .dropdown-menu {
    width: 100% !important;
    margin: 0px;
  }
}
</style>
