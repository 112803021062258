import { createRequestOptions, proxify } from '../../../common/network';
import type {
  ImageServerFolderLayerModel,
  ImageServerItemLayerModel,
} from './types';

declare const axios: any;

export default async function requestImageServerLayerRenderer(
  folderModel: ImageServerFolderLayerModel,
  itemModel: ImageServerItemLayerModel
) {
  const { properties } = folderModel.geojson;
  const { shouldUseCorsProxy, token } = properties;

  let { url } = properties;

  url += `?f=pjson`;

  if (token) {
    url += '&token=' + token;
  }

  if (shouldUseCorsProxy) {
    url = proxify(url);
  }

  const response = await axios.get(url, createRequestOptions());

  const {
    data: { name: title, drawingInfo },
  } = response;
  return { id: itemModel.id, title, renderer: drawingInfo?.renderer };
}
