<script lang="ts" setup>
import { App } from '@component-library/gather';
import { ref } from 'vue';

defineProps<{
  nonSpatialTabs: App[];
  hasSpatialTabs: boolean;
}>();
const emit = defineEmits<{
  (event: 'startNonSpatial'): void;
  (event: 'changeViewType', isNonSpatial: boolean): void;
  (event: 'showProjectInfoModal'): void;
}>();

const showInfoPopup = ref(false);

function openInfoPopup() {
  showInfoPopup.value = !showInfoPopup.value;
}

function openProjectInfoModal() {
  showInfoPopup.value = false;
  emit('showProjectInfoModal');
}
</script>

<template>
  <div class="mb-2 d-flex">
    <div
      v-if="nonSpatialTabs.length === 0"
      class="alert alert-danger w-100 mb-0"
    >
      You must have a non-spatial app setup to create one.
      <a
        v-if="hasSpatialTabs"
        href=""
        @click.prevent="emit('changeViewType', false)"
        class="link"
      >
        Go to Map Collection
      </a>
    </div>
    <button
      v-else
      type="button"
      class="btn btn-primary w-100 p-3 fw-bold"
      @click="emit('startNonSpatial')"
    >
      <i class="fas fa-fw fa-plus"></i>
      New Item
    </button>

    <div class="legend-popup ms-3">
      <div
        class="map-action-btn"
        style="box-shadow: unset; height: 100%; width: unset"
        @click="openInfoPopup"
      >
        <i
          class="fas fa-fw"
          :class="{
            'fas fa-info': !showInfoPopup,
            'fa-chevron-down': showInfoPopup,
          }"
        />
      </div>

      <transition name="dropdown">
        <div
          class="dropdown-menu box-shadow action-menu"
          style="top: 62px; right: 0px; left: unset"
          v-show="showInfoPopup"
        >
          <div class="dropdown-item" @click="openProjectInfoModal">
            <i class="fas fa-fw fa-info" />
            <small class="text-muted">Project Information</small>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
