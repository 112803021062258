import type { InjectionKey } from 'vue';
import { provide, inject } from 'vue';

export type ShapeProperties = Record<string, any>;

type Context = {
  getShapeProperty: (name: string, defaultValue?: any) => any;
  setShapeProperty: (name: string, value: any) => Promise<ShapeProperties>;
  updateShapeProperties: (update: object) => Promise<ShapeProperties>;
  replaceShapeProperties: (value: object) => Promise<void>;
  checkIsStylingTypeVisible: (stylingType: string) => boolean;
  checkIsRenderableNonSpatialSampleGroup: () => boolean;
  getMarkerPickerAppId: () => number;
};

const key = Symbol.for('styleEditorContext') as InjectionKey<Context>;

export function provideContext(context: Context) {
  provide(key, context);
}

export function injectContext() {
  return inject(key)!;
}
