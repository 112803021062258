import { ref, computed } from 'vue';
import type { Ref } from 'vue';

export type FileUploadResult = {
  assetName: string;
  fileName: string;
};

export default function useFileUpload(api, projectId) {
  const result: Ref<FileUploadResult | null> = ref(null);
  const isUploading = ref(false);
  const uploadingProgress = ref(0);
  const isUploadingComplete = computed(() => {
    return uploadingProgress.value === 100;
  });

  async function uploadFile(file: File) {
    isUploading.value = true;

    try {
      const { fileName, assetName } = await api.asset.uploadAsset(
        projectId,
        file,
        (value) => {
          uploadingProgress.value = value;
        }
      );

      result.value = {
        assetName,
        fileName,
      };
    } finally {
      isUploading.value = false;
      uploadingProgress.value = 0;
    }
  }

  return {
    result,
    isUploading,
    uploadingProgress,
    isUploadingComplete,
    uploadFile,
  };
}
