<script lang="ts" setup>
import { GatherField } from '@component-library/gather';
import { computed } from 'vue';

const props = defineProps<{
  field: GatherField;
}>();
const emit = defineEmits<{
  (event: 'update', payload: { key: string; value: any }): void;
}>();

const restricted_country = computed({
  get() {
    return props.field.options?.restricted_country || null;
  },
  set(updated) {
    emit('update', {
      key: 'restricted_country',
      value: updated,
    });
  },
});
</script>

<template>
  <div class="form-group pt-3">
    <label class="form-label">Restrict look up to country?</label>
    <div class="input-group">
      <select class="form-control" v-model="restricted_country">
        <option :value="null">None - Worldwide</option>
        <option value="AU">Australia</option>
        <option value="CA">Canada</option>
        <option value="NZ">New Zealand</option>
        <option value="US">United States</option>
        <option value="GB">United Kingdom</option>
      </select>
    </div>
  </div>
</template>
