export async function removeCacheValues(key, valuesToRemove) {
  try {
    const cache = await caches.open(key);
    const keys = await cache.keys();

    return keys
      .filter((k) => valuesToRemove.includes(k.url))
      .map(async (request) => {
        await cache.delete(request);
      });
  } catch(e) {}
}
