<template>
  <div
    v-if="!deleting"
    :class="{
      'mb-2': index != groupLength,
      'mb-3': index == groupLength,
    }"
    :key="`group-item-${index}-${groupLength}`"
  >
    <div class="d-flex flex-row bg-light">
      <div
        class="flex-fill ms-2 my-auto fw-bold"
        v-if="!editTitle"
        @click.prevent.stop="toggleEditTitle"
      >
        {{ displayGroupTitle }}
      </div>
      <div v-show="editTitle">
        <input
          type="text"
          class="form-control"
          v-model="groupTitle"
          :ref="`group-item-${index}`"
          placeholder="Set group title"
          @keyup.enter.exact="toggleEditTitle"
          v-click-outside="closeEditTitle"
        />
      </div>
      <button type="button" class="btn btn-outline-danger" @click="removeGroup">
        <i class="fas fa-trash-alt" />
      </button>
    </div>
    <div class="d-flex flex-row flex-grow">
      <div class="wrapper w-100" v-if="group.items">
        <span
          class="input-tag border-4 newline"
          @click.self.prevent
          v-for="(aItem, index) of group.items"
          :key="index"
        >
          <span>{{ aItem }}</span>
          <a class="remove" @click.prevent.stop.self="removeItem(index)"> x</a>
        </span>
        <select
          class="form-control form-control-sm"
          @change="addValue"
          v-model="selectValue"
          v-if="unusedOptions.length > 0"
        >
          <option :value="null" disabled selected hidden>
            Add group value
          </option>
          <option v-for="option in unusedOptions" :key="option" :value="option">
            {{ option }}
          </option>
        </select>
        <p v-if="!options.length">Group has no values</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  computed: {
    groupLength() {
      if (!this.value.options?.groups) return 0;
      return this.value.options?.groups[this.index]?.length - 1 || 0;
    },
    unusedOptions() {
      return this.value.options.filter(
        (aOption) => !this.group.items.includes(aOption)
      );
    },
    options() {
      return this.value.options;
    },
    group: {
      get() {
        return this.value.groups[this.index];
      },
      set(updated) {
        const groups = [...this.value.groups];
        groups[this.index] = updated;
        this.$emit('input', { ...this.value, groups });
      },
    },
    groupDefault: {
      get() {
        return this.value.groups[this.index].default || null;
      },
      set(updated) {
        const groups = [...this.value.groups];
        groups[this.index].default = updated;
        this.$emit('input', { ...this.value, groups });
      },
    },
    groupTitle: {
      get() {
        return this.value.groups[this.index].title || null;
      },
      set(updated) {
        const groups = [...this.value.groups];
        groups[this.index].title = updated;
        this.$emit('input', { ...this.value, groups });
      },
    },
    displayGroupTitle() {
      return this.value.groups[this.index]?.title || `Group ${this.index + 1}`;
    },
  },
  data: () => ({
    selectValue: null,
    deleting: false,
    editTitle: false,
    title: null,
  }),
  methods: {
    closeEditTitle() {
      this.editTitle = false;
    },
    toggleEditTitle() {
      this.editTitle = !this.editTitle;
      this.$nextTick(() => {
        this.$refs[`group-item-${this.index}`].focus();
      });
    },
    removeGroup() {
      this.$emit('remove', this.index);
    },
    removeItem(index) {
      this.group.items.splice(index, 1);
    },
    addValue() {
      this.group = {
        ...this.group,
        items: [...this.group.items, this.selectValue],
      };
      this.selectValue = null;
    },
  },
};
</script>

<style scoped>
.input-tag {
  border-radius: 2px;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 4px;
  margin-right: 4px;
  padding: 3px;
}

.remove {
  font-weight: 700;
  text-decoration: none;
  color: #fff !important;
}
</style>
