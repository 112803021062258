export type ColorOption = {
  is_gradient: boolean;
  fill?: string; //hex
  gradient?: GradientPickerGradient;
};

export type RGBAColor = {
  red: number;
  green: number;
  blue: number;
  alpha: number;
};

export type GradientPickerColor = {
  red: number;
  green: number;
  blue: number;
  alpha: number;
  hue?: number;
  saturation?: number;
  value?: number;
  style: string;
};

type GradientPickerColorPoint = {
  left: number;
  red: number;
  green: number;
  blue: number;
  alpha: number;
};

export type GradientPickerGradient = {
  type: string;
  degree: number;
  points: Array<GradientPickerColorPoint>;
  style?: string;
};

export type UpdateColor = {
  is_gradient: boolean;
  color: string | GradientPickerGradient;
};

export const rgbaToHex = (
  input: string | { red: number; green: number; blue: number; alpha: number }
): string => {
  let red, green, blue, alpha;

  if (typeof input === 'string') {
    let values = input
      .replace(/rgba\(|\)|\s/g, '')
      .split(',')
      .map(Number);
    red = values[0];
    green = values[1];
    blue = values[2];
    alpha = values[3];
  } else {
    red = input.red;
    green = input.green;
    blue = input.blue;
    alpha = input.alpha;
  }

  let hexRed = red.toString(16).padStart(2, '0');
  let hexGreen = green.toString(16).padStart(2, '0');
  let hexBlue = blue.toString(16).padStart(2, '0');
  let hexAlpha = Math.round(alpha * 255)
    .toString(16)
    .padStart(2, '0');

  return '#' + hexRed + hexGreen + hexBlue + hexAlpha;
};

export const hexToRGBA = (hex: string): RGBAColor => {
  let alpha = 1,
    red,
    green,
    blue;

  if (hex.length === 9) {
    // If alpha is provided
    alpha = parseInt(hex.slice(7, 9), 16) / 255;
    hex = hex.slice(0, 7);
  }

  red = parseInt(hex.slice(1, 3), 16);
  green = parseInt(hex.slice(3, 5), 16);
  blue = parseInt(hex.slice(5, 7), 16);

  return { red, green, blue, alpha };
};

export const colorToGradientPickerColor = (
  color: string
): GradientPickerColor => {
  let rgbaColor: RGBAColor | null = null;

  if (color.startsWith('rgba')) {
    const match = color.match(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/)!;
    rgbaColor = {
      red: parseInt(match[1]),
      green: parseInt(match[2]),
      blue: parseInt(match[3]),
      alpha: parseFloat(match[4]),
    };
  } else {
    rgbaColor = hexToRGBA(color);
  }

  const { red, green, blue, alpha } = rgbaColor!;

  return {
    red,
    green,
    blue,
    alpha,
    style: `rgba(${red}, ${green}, ${blue}, ${alpha})`,
  };
};
