import { useStore } from '@/js/store';
import { findFieldByIdFromApp } from '@component-library/business-logic/app';
import { resolveFieldFromValuePairs } from '@component-library/business-logic/expression';
import {
  BLACK,
  FEATURE,
  WHITE,
} from '@component-library/business-logic/mapping/color';
import type {
  CollectionType,
  ConditionOnGatherApp as Condition,
  ConditionDescription,
  Id,
  PolygonStyle,
  PolylineStyle,
  SampleStyle,
  StylingRuleOnGatherApp as StylingRule,
  StylingRuleOnGatherAppStyle as StylingRuleStyle,
} from '@component-library/business-logic/mapping/styling-rule';
import {
  INVALID_CONDITION,
  OperatorLabelMap,
  findStylingRuleById,
} from '@component-library/business-logic/mapping/styling-rule';
import { ACTION_CURRENT } from '@component-library/business-model/expression';
import type { App } from '@component-library/gather';
import { DrawingType } from '@component-library/gather';
import { FEATURES, hasAccess } from '@component-library/feature-manager';
import _lowerFirst from 'lodash/lowerFirst';
import { computed } from 'vue';

export default function useStylingRules(app: App) {
  const store = useStore();

  const shapeProperties = computed(() => store.state.shapeProperties);

  const isAvailable = computed(() => {
    return (
      hasAccess(FEATURES.MAPS_FIGURE_STYLING_RULES) &&
      ![DrawingType.NonSpatial, DrawingType.Hedge].includes(app.drawing_type)
    );
  });

  const stylingRules = computed<StylingRule[]>(() => app.styling_rules ?? []);

  const tempStylingRules = computed<StylingRule[]>(
    () => store.state.tempStylingRules
  );

  const activeTempStylingRule = computed<StylingRule | undefined>(() => {
    const id: Id | undefined = store.state.activeTempStylingRuleId;
    return id !== undefined
      ? findStylingRuleById(tempStylingRules.value, id)
      : undefined;
  });

  const getConditionDescription = (
    condition?: Condition
  ): ConditionDescription => {
    if (!condition) {
      return INVALID_CONDITION;
    }

    const { fieldId } = condition;
    if (!fieldId) {
      return INVALID_CONDITION;
    }

    const field = findFieldByIdFromApp(app, fieldId);
    if (!field) {
      return INVALID_CONDITION;
    }

    const operand = resolveFieldFromValuePairs(
      field,
      ACTION_CURRENT,
      [condition.operand],
      0
    );

    return {
      isValid: true,
      content: `When <b>${field.label}</b> is ${_lowerFirst(
        OperatorLabelMap[condition.operator]
      )} <b>${operand}</b>`,
    };
  };

  function createStylingRuleStyle(
    collectionType?: CollectionType
  ): StylingRuleStyle {
    if (collectionType === DrawingType.Point) {
      const {
        icon = 0,
        color = BLACK,
        iconSize = 22,
        iconRotation = 0,
        iconOpacity = 1,
        hideMarkerLabel: isLabelHidden = false,
        hideDuplicateLabel: isDuplicateLabelHidden = false,
        markerLabelColor: labelColor = BLACK,
        markerLabelShadowColor: labelShadowColor = WHITE,
        labelSize = 16,
        markerLabelUnderlined: isLabelUnderlined = false,
        markerLabelAsteriskAppended: isLabelAsteriskAppended = false,
      } = shapeProperties.value;

      return {
        icon,
        color,
        iconSize,
        iconRotation,
        iconOpacity,
        isLabelHidden,
        isDuplicateLabelHidden,
        labelColor,
        labelShadowColor,
        labelSize,
        isLabelUnderlined,
        isLabelAsteriskAppended,
      };
    } else if (collectionType === DrawingType.Polygon) {
      const {
        color = FEATURE,
        fillStyle = 0,
        fillOpacity = 0.2,
        outlineStyle = 0,
        weight = 3,
        opacity = 1,
        shouldShowLabel = false,
        areaMeasurement = false,
        outlineMeasurement = false,
      } = shapeProperties.value;
      return {
        color,
        fillStyle,
        fillOpacity,
        outlineStyle,
        weight,
        opacity,
        shouldShowLabel,
        areaMeasurement,
        outlineMeasurement,
      };
    } else if (collectionType === DrawingType.Polyline) {
      const {
        color = FEATURE,
        outlineStyle = 0,
        weight = 3,
        opacity = 1,
        arrowHeadSize = 10,
        isArrowHeadBackward = false,
        arrowHeads = [],
        shouldShowLabel = false,
        outlineMeasurement = false,
      } = shapeProperties.value;
      return {
        color,
        outlineStyle,
        weight,
        opacity,
        arrowHeadSize,
        isArrowHeadBackward,
        arrowHeads,
        shouldShowLabel,
        outlineMeasurement,
      };
    }

    return {};
  }

  function createShapeProperties(stylingRule: StylingRule) {
    const {
      target: { collectionType },
      style,
    } = stylingRule;

    if (collectionType === DrawingType.Point) {
      const {
        icon,
        color,
        iconSize,
        iconRotation,
        iconOpacity,
        isLabelHidden: hideMarkerLabel,
        isDuplicateLabelHidden: hideDuplicateLabel,
        labelColor: markerLabelColor,
        labelShadowColor: markerLabelShadowColor,
        labelSize,
        isLabelUnderlined: markerLabelUnderlined,
        isLabelAsteriskAppended: markerLabelAsteriskAppended,
      } = style as SampleStyle;

      return {
        icon,
        color,
        iconSize,
        iconRotation,
        iconOpacity,
        hideMarkerLabel,
        hideDuplicateLabel,
        markerLabelColor,
        markerLabelShadowColor,
        labelSize,
        markerLabelUnderlined,
        markerLabelAsteriskAppended,
      };
    } else if (collectionType === DrawingType.Polygon) {
      const {
        color,
        fillStyle,
        fillOpacity,
        outlineStyle,
        weight,
        opacity,
        shouldShowLabel,
        areaMeasurement,
        outlineMeasurement,
      } = style as PolygonStyle;
      return {
        color,
        fillStyle,
        fillOpacity,
        outlineStyle,
        weight,
        opacity,
        shouldShowLabel,
        areaMeasurement,
        outlineMeasurement,
      };
    } else if (collectionType === DrawingType.Polyline) {
      const {
        color,
        outlineStyle,
        weight,
        opacity,
        arrowHeadSize,
        isArrowHeadBackward,
        arrowHeads,
        shouldShowLabel,
        outlineMeasurement,
      } = style as PolylineStyle;
      return {
        color,
        outlineStyle,
        weight,
        opacity,
        arrowHeadSize,
        isArrowHeadBackward,
        arrowHeads,
        shouldShowLabel,
        outlineMeasurement,
      };
    }

    return {};
  }

  return {
    isAvailable,
    stylingRules,
    tempStylingRules,
    activeTempStylingRule,
    getConditionDescription,
    createStylingRuleStyle,
    createShapeProperties,
  };
}
