import useApi from '@component-library/composables/useApi';
import localforage from 'localforage';

const useRequestOfflineSetup = () => {
  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      throw new Error('Request permission not granted for Notification');
    }
  };

  const subscribeToPushNotificationManager = async () => {
    if (!Notification || Notification.permission !== 'granted') {
      throw new Error('Push manager, permission not granted for Notification');
    }

    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: import.meta.env.VITE_VAPID_PUBLIC_KEY,
    });

    return subscription;
  };

  const subscribeToNotifications = async () => {
    const subscription = await subscribeToPushNotificationManager();
    const device = (await localforage.getItem('device')) || 'Unknown';

    const api = useApi();
    await api.post(
      '/push-subscription/subscribe',
      JSON.stringify({ ...subscription.toJSON(), device })
    );
  };

  return {
    requestNotificationPermission,
    subscribeToPushNotificationManager,
    subscribeToNotifications,
  };
};

export default useRequestOfflineSetup;
