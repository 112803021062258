<template>
  <div v-if="hasFieldType">
    <responsive-or-slide-up
      class="mb-3"
      title="Field Options"
      v-model="showDefaultOptions"
      data-cy="field-options"
      :mobileSize="mobileSize"
    >
      <template #body>
        <template v-if="disableEditingField">
          This field is locked to prevent breaking exports</template
        >
        <template v-else>
          <label
            class="form-label"
            :class="{ 'text-danger': invalidNameError }"
          >
            <InfoButton
              v-if="invalidNameError"
              class="me-2"
              :danger="true"
              backgroundColor="#FF0000"
              :info="invalidNameError"
            />
            Field Name
          </label>
          <div class="form-group mb-3" v-tooltip="invalidNameError">
            <input
              type="text"
              class="form-control w-100"
              :class="{ 'text-danger is-invalid': invalidNameError }"
              v-model="fieldLabel"
              @keypress.enter="clearField"
              ref="field-label"
            />
          </div>

          <field-caption-options
            v-if="isCaption"
            :value="value.template_fields[fieldIndex]"
            @input="updateField"
          />

          <div
            class="form-check"
            :class="{
              'mb-2': fieldComponent != 'unknown-field',
            }"
            v-if="!fieldTypesNonPrimary.includes(field.field_type_id)"
          >
            <input
              class="form-check-input"
              type="checkbox"
              id="is_required"
              v-model="isRequired"
            />
            <label class="form-check-label" for="is_required">
              Is this field required?
            </label>
          </div>

          <div
            v-if="isReadonlyVisible"
            class="form-check"
            :class="{
              'mb-2': fieldComponent != 'unknown-field',
            }"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :id="readOnlyCheckboxId"
              v-model="isReadonly"
            />
            <label class="form-check-label" :for="readOnlyCheckboxId">
              Is this field read only?
            </label>
          </div>

          <div
            v-if="
              ![FieldTypeIds.CAPTION, FieldTypeIds.LITHOLOGY].includes(
                field.field_type_id
              )
            "
            class="form-check"
            :class="{
              'mb-2': fieldComponent != 'unknown-field',
            }"
          >
            <input
              class="form-check-input"
              type="checkbox"
              id="primary_field_id"
              v-model="isPrimary"
            />
            <label class="form-check-label" for="primary_field_id">
              Is this the primary field on this section?
            </label>
          </div>

          <template
            v-if="
              tab.drawing_type === 'polyline' &&
              field.field_type_id === FieldTypeIds.DROPDOWN
            "
          >
            <div class="form-check mb-2">
              <input
                class="form-check-input"
                type="checkbox"
                id="is_start_node_field"
                v-model="isStartNodeField"
              />
              <label class="form-check-label" for="is_start_node_field">
                Is this the start node field?
              </label>
            </div>

            <div class="form-check mb-2">
              <input
                class="form-check-input"
                type="checkbox"
                id="is_end_node_field"
                v-model="isEndNodeField"
              />
              <label class="form-check-label" for="is_end_node_field">
                Is this the end node field?
              </label>
            </div>
          </template>

          <div
            v-if="[FieldTypeIds.LITHOLOGY].includes(field.field_type_id)"
            class="form-check"
            :class="{
              'mb-2': fieldComponent != 'unknown-field',
            }"
          >
            <input
              class="form-check-input"
              type="checkbox"
              id="secondary_field_id"
              v-model="isSecondary"
            />
            <label class="form-check-label" for="secondary_field_id">
              Is this the secondary field on this section?
            </label>
          </div>

          <div
            v-if="templateHasPublicForm"
            class="form-check"
            :class="{
              'mb-2': fieldComponent != 'unknown-field',
            }"
          >
            <input
              class="form-check-input"
              type="checkbox"
              id="is_public_form"
              :value="field.options.is_public_form"
              @input="
                updateOption({
                  key: 'is_public_form',
                  value: $event.target.checked,
                })
              "
              :checked="field.options.is_public_form"
            />
            <label class="form-check-label" for="is_public_form">
              Include on Public Form?
            </label>
          </div>

          <component
            :is="fieldComponent"
            :field="field"
            :value="value.template_fields[fieldIndex]"
            :section="section"
            :options="value.template_fields[fieldIndex].options"
            :tab="tab"
            @updateOptions="updateFieldOptions"
            @input="updateField"
            @blur="clearField"
            @update="updateOption"
            @showExpressionEditor="$emit('showExpressionEditor')"
          />
        </template>
        <div class="my-2">
          <a
            href=""
            @click.prevent="toggleAdvancedOptions"
            class="dropdown-toggle"
          >
            {{ showAdvancedOptions ? 'Hide' : 'Show' }} Advanced Options
          </a>
        </div>
        <div v-if="showAdvancedOptions" class="form-group mb-3">
          <label
            class="form-label"
            :class="{ 'text-danger': invalidNameError }"
          >
            <InfoButton
              v-if="invalidNameError"
              class="me-2"
              :danger="true"
              backgroundColor="#FF0000"
              :info="invalidNameError"
            />
            System Reference
            <InfoButton
              class="ms-2"
              info="This is the system reference used in Auto Doc code snippets.<br/><b>Tip:</b> You can put a short unique name here to avoid clashes with labels."
            />
          </label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              :disabled="disableEditingField"
              v-model="system_reference"
              :class="{ 'text-danger is-invalid': invalidNameError }"
              v-tooltip="invalidNameError"
              :placeholder="systemReferenceDefault"
              @keypress.enter="clearField"
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="clipboardSystemReference"
            >
              <i class="fas fa-copy"></i>
            </button>
          </div>

          <InputCheckbox
            :disabled="!isAdminOrImpersonating"
            label="Lock field from edits"
            v-model="disableEditingField"
          />
        </div>
      </template>
    </responsive-or-slide-up>

    <field-conditional-options
      v-if="
        section &&
        hasMultipleFields &&
        value.template_fields[fieldIndex].options.conditions
      "
      class="mb-3"
      :field="value.template_fields[fieldIndex]"
      :value="value.template_fields[fieldIndex].options"
      @input="updateFieldOptions"
      @add="addCondition"
      :mobileSize="mobileSize"
      :fields="conditionalFields"
    />

    <field-group-options
      v-if="
        (isDropdown || isLithology) &&
        field.options.options &&
        field.options.options.length &&
        hasMultipleFields
      "
      class="mb-3"
      :options="value.template_fields[fieldIndex].options"
      :fields="conditionalFields"
      :mobileSize="mobileSize"
      @updateOptions="updateFieldOptions"
    ></field-group-options>

    <field-default-options
      v-if="
        section &&
        (isDropdown || isLithology || isText || isCheckbox) &&
        hasMultipleFields
      "
      :value="value.template_fields[fieldIndex]"
      :options="value.template_fields[fieldIndex].options"
      :mobileSize="mobileSize"
      :fields="conditionalFields"
      @updateOptions="updateFieldOptions"
    />
  </div>
</template>

<script>
import { FieldTypeIds } from '@component-library/fields';
import slugify from '@component-library/utils/slugify.mjs';
import FieldCaptionOptions from './FieldCaptionOptions.vue';
import FieldConditionalOptions from './FieldConditionalOptions.vue';
import FieldDefaultOptions from './FieldDefaultOptions.vue';
import FieldGroupOptions from './FieldGroupOptions.vue';
import ResponsiveOrSlideUp from './ResponsiveOrSlideUp.vue';
import options from './options';
import InfoButton from '@component-library/components/InfoButton.vue';
import { getFieldReferenceError } from '@component-library/gather';
import InputCheckbox from '@component-library/components/InputCheckbox.vue';
import makeId from '@component-library/local-id.mjs';

export default {
  props: [
    'value',
    'sectionField',
    'sectionIndex',
    'fieldIndex',
    'mobileSize',
    'tab',
  ],
  components: {
    ResponsiveOrSlideUp,
    FieldConditionalOptions,
    FieldDefaultOptions,
    FieldGroupOptions,
    FieldCaptionOptions,
    ...options.components,
    InfoButton,
    InputCheckbox,
  },
  data: () => ({
    showDefaultOptions: true,
    showAdvancedOptions: false,
    readOnlyCheckboxId: makeId(),
  }),
  computed: {
    FieldTypeIds() {
      return FieldTypeIds;
    },
    isAdmin() {
      return this.$auth.user().role == 'admin';
    },
    isAdminOrImpersonating() {
      return this.isAdmin || this.$auth.impersonating();
    },
    hasFieldType() {
      return this.field?.field_type_id;
    },
    invalidNameError() {
      return getFieldReferenceError(this.field, this.section);
    },
    fields: {
      get() {
        return this.value?.template_fields;
      },
      set(updated) {
        this.$emit('input', { ...this.value, template_fields: updated });
      },
    },
    field: {
      get() {
        return this.sectionField;
      },
      set(updated) {
        this.$emit('updateField', {
          sectionIndex: this.sectionIndex,
          fieldIndex: this.fieldIndex,
          value: { ...this.sectionField, ...updated },
        });
      },
    },
    isRequired: {
      get() {
        return this.sectionField.is_required;
      },
      set(is_required) {
        this.$emit('updateField', {
          sectionIndex: this.sectionIndex,
          fieldIndex: this.fieldIndex,
          value: { ...this.sectionField, is_required },
        });
      },
    },
    isReadonly: {
      get() {
        return this.sectionField.options.is_readonly;
      },
      set(value) {
        this.updateFieldOptions({
          ...this.sectionField.options,
          is_readonly: value,
        });
      },
    },
    isReadonlyVisible() {
      const { field_type_id } = this.sectionField;
      if (field_type_id === FieldTypeIds.DOCUMENT) {
        return true;
      } else if (field_type_id === FieldTypeIds.MEDIA) {
        return this.sectionField.options.type === 'video';
      }

      return false;
    },
    disableEditingField: {
      get() {
        return this.sectionField.options.disabled;
      },
      set(value) {
        this.updateFieldOptions({
          ...this.sectionField.options,
          disabled: value,
        });
      },
    },
    isPrimary: {
      get() {
        return (
          this.sectionField.id != null &&
          this.sectionField.id == this.value.primary_field_id
        );
      },
      set(updated) {
        const field = this.sectionField;
        this.$emit('input', {
          ...this.value,
          primary_field_id: updated ? field.id : null,
        });
      },
    },
    isSecondary: {
      get() {
        return (
          this.sectionField.id != null &&
          this.sectionField.id == this.value.secondary_field_id
        );
      },
      set(updated) {
        const field = this.sectionField;
        this.$emit('input', {
          ...this.value,
          secondary_field_id: updated ? field.id : null,
        });
      },
    },
    isStartNodeField: {
      get() {
        return (
          this.tab.start_node_field &&
          this.tab.start_node_field === this.field.id
        );
      },
      set(value) {
        this.$emit('updateTab', {
          key: 'start_node_field',
          value: value ? this.field.id : null,
        });
      },
    },
    isEndNodeField: {
      get() {
        return (
          this.tab.end_node_field && this.tab.end_node_field === this.field.id
        );
      },
      set(value) {
        this.$emit('updateTab', {
          key: 'end_node_field',
          value: value ? this.field.id : null,
        });
      },
    },
    section: {
      get() {
        return this.value;
      },
    },
    conditionalFields() {
      const field = this.sectionField;
      return this.value.template_fields.filter(
        (f) =>
          (f.id != field.id || !field.id) &&
          this.conditionalFieldTypeIds.includes(f.field_type_id)
      );
    },
    hasMultipleFields() {
      return this.fields.length > 1;
    },
    isDropdown() {
      return this.field.field_type_id == FieldTypeIds.DROPDOWN;
    },
    isLithology() {
      return this.field.field_type_id === FieldTypeIds.LITHOLOGY;
    },
    isCaption() {
      return this.field.field_type_id == FieldTypeIds.CAPTION;
    },
    isText() {
      return this.field.field_type_id == FieldTypeIds.TEXT;
    },
    isCheckbox() {
      return this.field.field_type_id === FieldTypeIds.CHECKBOX;
    },
    fieldComponent() {
      return options.getComponentNameFromId(this.field.field_type_id);
    },

    templateHasPublicForm() {
      return this.tab?.public_link;
    },
    system_reference: {
      get() {
        return this.field.system_reference;
      },
      set(value) {
        this.updateFieldProp('system_reference', value);
      },
    },
    systemReferenceDefault() {
      if (!this.field.label) {
        return '';
      }
      return slugify(this.field.label);
    },
    conditionalFieldTypeIds() {
      return [
        FieldTypeIds.TEXT,
        FieldTypeIds.NUMBER,
        FieldTypeIds.DROPDOWN,
        FieldTypeIds.LITHOLOGY,
        FieldTypeIds.CHECKBOX,
        FieldTypeIds.EXPRESSION,
        FieldTypeIds.REFERENCE,
      ];
    },
    fieldTypesNonPrimary() {
      return [FieldTypeIds.CAPTION];
    },
    fieldLabel: {
      get() {
        return this.sectionField.label;
      },
      set(label) {
        this.$emit('updateField', {
          sectionIndex: this.sectionIndex,
          fieldIndex: this.fieldIndex,
          value: { ...this.sectionField, label },
        });
      },
    },
  },
  methods: {
    addCondition(condition) {
      this.$emit('add', {
        sectionIndex: this.sectionIndex,
        fieldIndex: this.fieldIndex,
        condition,
      });
    },
    updateFieldOptions(value) {
      const options = { ...value };
      delete options.isDropdown;
      this.$emit('updateFieldOptions', {
        sectionIndex: this.sectionIndex,
        fieldIndex: this.fieldIndex,
        options,
      });
    },
    updateField(value) {
      this.$emit('updateField', {
        sectionIndex: this.sectionIndex,
        fieldIndex: this.fieldIndex,
        value: { ...this.sectionField, ...value },
      });
    },
    updateFieldProp(prop, value) {
      this.updateField({ [prop]: value });
    },
    toggleAdvancedOptions() {
      this.showAdvancedOptions = !this.showAdvancedOptions;
    },
    clearField() {
      this.$emit('clearField');
      this.$root.$emit('clearField');
    },
    updateOption({ key, value }) {
      this.updateFieldProp('options', {
        ...this.field?.options,
        [key]: value,
      });

      // Field's is_public_form has impact on the is_shown_on_new page
      // of the feild's section.
      if (key === 'is_public_form') {
        this.$emit('input', this.section);
      }
    },
    clipboardSystemReference() {
      const systemReference =
        this.field.system_reference && this.field.system_reference.length
          ? this.field.system_reference
          : this.systemReferenceDefault;

      if (navigator.clipboard) {
        navigator.clipboard.writeText(systemReference);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = systemReference;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('Copy');
        textArea.remove();
      }

      this.$toastStore.success('Copied the reference to clipboard.');
    },
  },
  beforeDestroy() {
    this.$root.$off('highlightFieldLabelInput');
  },
  mounted() {
    this.$root.$on('highlightFieldLabelInput', () => {
      const fieldLabel = this.$refs['field-label'];
      if (fieldLabel) {
        fieldLabel.focus();
        fieldLabel.select();
      }
    });
  },
};
</script>
