<template>
  <div class="d-flex align-items-center justify-content-center w-100 mt-2">
    <div class="progress w-100 position-relative" style="height: 30px">
      <div class="progress-value fw-medium">{{ title }}{{ getMessage }}</div>
      <div
        class="progress-bar bg-warning"
        :class="{ 'bg-warning': progress < 100, 'bg-success': progress == 100 }"
        :style="{
          width: progress + '%',
        }"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    progress: Number,
    title: String,
    message: {
      required: false,
    },
    errorMessage: {
      required: false,
    },
  },
  computed: {
    getMessage() {
      if (this.message) {
        return ` - ${this.message}`;
      }
      return null;
    },
  },
};
</script>
