<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import axios from 'axios';
import Breadcrumb from '@/js/components/Breadcrumb.vue';
import Paginate from '@/js/components/Pagination.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import { captureException } from '@component-library/sentry';
import AppList from './components/AppList.vue';
import AppListFilter from './components/AppListFilter.vue';
import AppManager from './components/AppManager.vue';
import SelectedAppList from './components/SelectedAppList.vue';
import { useRoute } from 'vue-router/composables';
import type { App } from '@component-library/gather';
import { useToastStore } from '@component-library/store/toasts';
import { DATANEST_URL } from '@component-library/env';
import useViewRestriction from '@component-library/composables/useViewRestriction';
import { AVAILABLE_PERMISSIONS } from '@component-library/company-role-profile';

const route = useRoute();
const toastStore = useToastStore();

const loadingSelectedApps = ref(false),
  selectedApps = ref<App[]>([]),
  loadingApps = ref(false),
  apps = ref<{
    data: App[];
    current_page: number;
  } | null>(null),
  appType = ref('all'),
  selectedApp = ref<App | null>(null),
  importing = ref(false),
  orderBy = ref('recent');

const isCreatingApp = ref(false);

const isLoadingApps = computed(() => {
  return !apps.value && loadingApps.value && appType.value == 'all';
});

const { hasPermissionToAccess } = useViewRestriction();
const canCreateApp = computed(() => {
  return hasPermissionToAccess(AVAILABLE_PERMISSIONS.GATHER_APP_EDITOR);
});

onMounted(() => {
  getSelectedApps();
  getApps();
});

function getFreshApps(type) {
  if (apps.value) {
    apps.value.current_page = 1;
  }
  getApps(type);
}

async function getApps(type = null) {
  if (loadingApps.value) {
    return;
  }

  loadingApps.value = true;

  if (type) {
    appType.value = type;
  }

  try {
    const { data } = await axios.get('/api/template/shared-apps', {
      params: {
        app_type: appType.value,
        page: apps.value ? apps.value.current_page : 1,
        order_by: orderBy.value,
      },
    });
    apps.value = {
      ...data,
      data: data.data.map((appGroup) => {
        return {
          ...appGroup,
          updated_at: appGroup.app.updated_at,
        };
      }),
    };
  } catch (e) {
    toastStore.unexpected();
    throw e;
  } finally {
    loadingApps.value = false;
  }
}

async function getSelectedApps() {
  loadingSelectedApps.value = true;

  try {
    const { data } = await axios.get(
      `/api/template/${route.params.project_id}/apps`
    );

    selectedApps.value = data.templates;
  } catch (e) {
    toastStore.unexpected();
    throw e;
  } finally {
    loadingSelectedApps.value = false;
  }
}

async function getChildApps(app) {
  try {
    const { data } = await axios.get(
      `/api/template/tab/${app.id}/custom-template`
    );
    return data.templates;
  } catch (e) {
    toastStore.unexpected();
    captureException(e);
    return [];
  }
}

const handlePaginate = (page) => {
  if (apps.value) {
    apps.value.current_page = page;
    getApps();
  }
};

async function createApp() {
  isCreatingApp.value = true;

  try {
    await axios.post(`/api/template/${route.params.project_id}/update`, {
      template_tabs: [
        {
          title: 'Untitled App',
          is_locked: false,
          prefix: null,
          drawing_type: 'point',
          drawing_colour: null,
          point_icon: 0,
          drawing_properties: null,
        },
      ],
    });
    await getSelectedApps();
  } catch (e) {
    toastStore.unexpected();
    captureException(e);
  } finally {
    isCreatingApp.value = false;
  }
}

async function importApp(share_group) {
  importing.value = true;

  try {
    await axios.post(`/api/template/${route.params.project_id}/import-apps`, {
      share_group,
    });

    if (selectedApp.value) {
      selectedApps.value.push(selectedApp.value);
      selectedApp.value = null;
    }

    await getSelectedApps();
  } catch (e) {
    toastStore.unexpected();
    throw e;
  } finally {
    importing.value = false;
  }
}

async function selectApp(app) {
  if (app.custom_template_id) {
    const childApps = await getChildApps(app);
    app.child_apps = childApps;
  }
  selectedApp.value = app;
}

function openGatherSupportPage() {
  window.open(DATANEST_URL + '/support/gather', '_blank');
}
</script>

<template>
  <div
    class="template-page-content col-12 col-md-10 offset-0 offset-md-1 py-4 px-3 px-md-0 h-100 d-flex flex-column"
  >
    <div
      v-if="isLoadingApps"
      class="d-flex align-items-center justify-content-center flex-column text-center w-100 h-100 flex-fill"
    >
      <i class="spinner-border spinner-border-lg"></i>
      <small class="text-muted mt-2 d-block"> Loading apps... </small>
    </div>
    <template v-else>
      <Breadcrumb :routes="['template_editor', 'app_manager']" class="mb-4" />

      <div class="row flex-fill">
        <div class="col-md-5 col-lg-3 mb-3">
          <div class="sticky-container">
            <div
              class="section-header p-3 d-flex align-items-center justify-content-between bg-light mb-3"
            >
              <h6 class="mb-0">Project Apps</h6>
              <ButtonSpinner
                v-if="canCreateApp"
                class="btn btn-primary btn-sm"
                :is-loading="isCreatingApp"
                @click.native.prevent="createApp"
              >
                <i class="fal fa-plus fa-fw" />
                <span>Create App</span>
              </ButtonSpinner>
            </div>

            <div v-if="selectedApps.length == 0">
              <h6>You have not selected any apps yet.</h6>
              <p class="text-muted mb-0">
                It's very easy to get started, simply select an app you'd like
                to add to your project.
              </p>
            </div>
            <SelectedAppList v-else :selectedApps="selectedApps" />
          </div>
        </div>
        <div
          class="col-md-7 col-lg-6 d-flex flex-column"
          :class="{
            'app-selected': selectedApp,
          }"
        >
          <AppListFilter
            :apps="apps?.data"
            :type="appType"
            @getApps="getFreshApps"
          />
          <div
            class="d-flex align-items-center justify-content-between mb-3"
            v-if="apps && apps.data.length > 0"
          >
            <div>
              <Paginate
                v-if="apps"
                :pagination="apps"
                @paginate="handlePaginate"
                :offset="12"
              />
            </div>

            <select
              class="form-select form-select-sm"
              v-model="orderBy"
              style="width: 200px"
              @change="getApps()"
            >
              <option value="recent">Recently Shared</option>
              <option value="alphabetical">Alphabetical</option>
            </select>
          </div>

          <div
            v-if="loadingApps"
            class="text-center d-flex align-items-center justify-content-center flex-fill"
          >
            <div class="spinner-border spinner-border-lg"></div>
          </div>
          <AppList
            v-else
            :apps="apps?.data ?? []"
            :selectedApps="selectedApps"
            :selectedApp="selectedApp"
            @selectApp="selectApp"
          />
        </div>
        <div class="col-md-5 col-lg-3">
          <div class="sticky-container mb-3">
            <template v-if="selectedApp">
              <div
                class="section-header p-3 d-flex align-items-center justify-content-between bg-light mb-3"
              >
                <h6 class="mb-0">Manager</h6>
                <i
                  class="fas fa-times clickable"
                  @click="() => (selectedApp = null)"
                />
              </div>

              <AppManager
                :app="selectedApp"
                :canCreateApp="canCreateApp"
                :selectedApps="selectedApps"
                :importing="importing"
                @importApp="importApp"
                @getApps="getApps"
              />

              <hr />
            </template>

            <button
              type="button"
              class="btn btn-light w-100 p-3 fw-bold"
              @click="openGatherSupportPage"
            >
              Learn more about Gather Apps?
            </button>
            <router-link
              :to="`/${route.params.project_id}/map`"
              class="btn btn-light w-100 p-3 fw-bold mt-2"
            >
              Go to Collection
            </router-link>
            <router-link
              :to="`/template/${route.params.project_id}/editor`"
              class="btn btn-primary w-100 p-3 fw-bold mt-2"
              v-if="selectedApps.length > 0"
            >
              Go to App Editor
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<style scoped>
.sticky-container {
  position: sticky;
  top: 64px;
}

@media only screen and (max-width: 768px) {
  .app-selected {
    display: none;
  }
}
</style>
