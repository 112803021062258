<template>
  <nav ref="formNavigation" class="navbar navbar-dark bg-extra mb-3">
    <div class="container-fluid flex-column">
      <button
        type="button"
        class="navbar-toggler d-block w-100 m-2 px-5"
        @click="toggleMobile"
      >
        <i
          class="fas"
          :class="{
            'fa-times': showMobile,
            'fa-bars': !showMobile,
          }"
        />
      </button>

      <div
        :class="{
          'd-flex': showMobile,
          'd-none': !showMobile,
        }"
      >
        <ul class="navbar-nav flex-wrap custom-scrollbar me-auto mb-2 mb-lg-0">
          <template v-for="section in sections">
            <li
              class="nav-item"
              :key="section.id"
              v-if="getSectionLength(section.id) == 0"
            >
              <a class="nav-link" href @click.prevent="goToDiv(section)">
                {{ section.label }}
              </a>
              <div class="nav-item__progress">
                <div
                  class="progress__amount"
                  :style="{
                    width: getSectionProgress(section) + '%',
                  }"
                />
              </div>
            </li>
            <li class="nav-item dropdown" :key="section.id" v-else>
              <a
                class="nav-link dropdown-toggle"
                @click="toggleSection(section)"
              >
                {{ section.label }}
              </a>
              <div class="nav-item__progress">
                <div
                  class="progress__amount"
                  :style="{
                    width: getSectionProgress(section) + '%',
                  }"
                />
              </div>
              <ul
                class="dropdown-menu"
                :class="{
                  'd-block': section.toggled,
                }"
                style="top: 51px; left: 0"
              >
                <li
                  v-for="sectionRepeat in getSectionLength(section.id) + 1"
                  :key="section.id + '-' + sectionRepeat"
                >
                  <a
                    class="dropdown-item"
                    :href="'#' + section.id + '-' + sectionRepeat"
                    @click="toggleSection(section)"
                    @click.prevent="goToDiv(section, sectionRepeat)"
                  >
                    {{ getPrimaryFieldTitle(section, sectionRepeat) }}
                  </a>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'FormNavigation',
  props: { sections: Array, inputValues: Array },
  data: () => ({
    showMobile: false,
  }),
  methods: {
    getSectionLength(section_id) {
      let values = this.inputValues
        .filter((v) => v.template_section_id == section_id)
        .map((v) => v.template_section_index);

      return values.length > 0 ? Math.max(...values) : 0;
    },
    toggleMobile() {
      this.showMobile = !this.showMobile;
    },
    toggleSection(section) {
      this.$set(section, 'toggled', !section.toggled);
    },
    goToDiv(section, sectionRepeat) {
      let sectionID = 'section-' + section.id;

      if (sectionRepeat) {
        section.toggled = false;
        sectionID += '-' + sectionRepeat;
      }

      document.getElementById(sectionID).scrollIntoView(true);
      document.getElementById('sampleModal').scrollTop -= 51;
    },
    getPrimaryFieldTitle(section, index) {
      const base64regex =
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      const inputValue = this.inputValues.find(
        (iv) =>
          iv.template_section_id == section.id &&
          iv.template_field_id ==
            (section.primary_field_id || section.template_fields[0].id) &&
          iv.template_section_index == index - 1
      );
      if (
        inputValue?.value instanceof Blob ||
        base64regex.test(inputValue?.value)
      )
        return inputValue?.value2 || `#${index}`;
      return inputValue?.value || `#${index}`;
    },
    getSectionProgress(section) {
      const amountOfRepeatableSections = this.getSectionLength(section.id) + 1;
      const fieldLength =
        section.template_fields.length * amountOfRepeatableSections;
      const valueLength = this.inputValues.filter(
        (v) => v.template_section_id == section.id && (v.value || v.value2)
      ).length;

      return (valueLength / fieldLength) * 100;
    },
  },
  mounted() {
    const { formNavigation } = this.$refs;
    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === this.$refs.formNavigation) {
          const [{ inlineSize: width, blockSize: height }] =
            entry.borderBoxSize;
          this.$emit('size-change', { width, height });
        }
      });
    });
    this.resizeObserver.observe(formNavigation);
  },
  beforeDestroy() {
    this.resizeObserver.disconnect();
  },
};
</script>

<style scoped>
.navbar {
  position: sticky;
  top: -1rem;
  z-index: 9999;
  padding: 0px;
}

.navbar-nav .dropdown-menu {
  position: absolute;
  width: 100%;
}

.nav-item {
  min-width: 120px;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 0.5rem;
}

.nav-link {
  width: 100%;
}

.nav-item__progress {
  position: absolute;
  left: 0;
  bottom: 6px;
  height: 4px;
  width: 100%;
  background: #ffffff50;
}

.progress__amount {
  background: #ffffff;
  height: 4px;
  transition: 0.3s ease-in-out all;
}

@media (min-width: 992px) {
  .navbar-nav {
    max-height: 250px;
    overflow: auto;
  }
}
</style>
