<script lang="ts" setup>
import { getAppIconClass } from '../business-logic/app';
import { App } from '../gather';

withDefaults(
  defineProps<{
    app: App;
    selected?: boolean;
  }>(),
  {
    selected: false,
  }
);

const hedgeIconSrc = '/styled-map-icon/tool-icons/hedge';
</script>

<template>
  <h5
    v-if="!['any', 'hedge'].includes(app.drawing_type)"
    class="me-2 mb-0"
    :class="
      getAppIconClass(app.drawing_type) + ' ' + (selected ? 'fas' : 'fal')
    "
    :style="app.drawing_colour ? { color: app.drawing_colour } : {}"
  />
  <img
    v-else-if="app.drawing_type === 'hedge'"
    :src="hedgeIconSrc"
    width="24"
    height="24"
    class="me-2 mb-0"
  />
</template>
