<template>
  <responsive-or-slide-up
    v-if="this.value"
    title="Section Options"
    v-model="isExpanded"
    :mobileSize="mobileSize"
    @close="clearSection"
  >
    <template #body>
      <div class="alert alert-primary" role="alert">
        This section is not configurable.
      </div>
    </template>
  </responsive-or-slide-up>
</template>

<script>
import ResponsiveOrSlideUp from './ResponsiveOrSlideUp.vue';

export default {
  name: 'GpsPointMetadataSectionOptions',
  props: ['value', 'mobileSize'],
  components: { ResponsiveOrSlideUp },
  data: () => ({
    isExpanded: true,
  }),
  methods: {
    clearSection() {
      this.$root.$emit('clearSection');
    },
  },
};
</script>
