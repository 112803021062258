import { ComputedRef, Ref, ref } from 'vue';
import { Sample } from '../../../enviro';
import { OfflineSample } from '../../../offline-data';

const useTogglableSampleList = (
  samples: Ref<Sample[]> | ComputedRef<Sample[] | OfflineSample[]>,
  selectedSampleIds: ComputedRef<(number | string)[]>,
  selectedAppIds: ComputedRef<number[]>,
  loadSamples: Function,
  setSelectedAppIds: Function,
  setSelectedSampleIds: Function
) => {
  const toggledAppDropdowns = ref<number[]>([...selectedAppIds.value]);

  const isAppDropdownOpen = (id: number) => {
    return toggledAppDropdowns.value.includes(id);
  };

  const toggleAppDropdown = async (id: number) => {
    if (isAppDropdownOpen(id)) {
      closeAppDropdown(id);
    } else {
      openAppDropdown(id);

      if (getSamplesByAppId(id).length === 0) {
        await loadSamples(id);
      }
    }
  };

  const toggleApp = async (id: number) => {
    if (selectedAppIds.value.findIndex((appId) => appId === id) === -1) {
      if (!isAppDropdownOpen(id)) {
        await toggleAppDropdown(id);
      }

      setSelectedAppIds([...selectedAppIds.value, id]);
    } else {
      setSelectedAppIds(selectedAppIds.value.filter((appId) => appId !== id));

      if (hasAppSampleSelected(id)) {
        const appSamples = getSamplesByAppId(id);
        const selectedSamplesByAppId = selectedSampleIds.value.filter((id) => {
          return appSamples.map((s) => s.id).includes(id);
        });

        setSelectedSampleIds(
          selectedSampleIds.value.filter(
            (id) => !selectedSamplesByAppId.includes(id)
          )
        );
      }
    }
  };

  const getSamplesByAppId = (id: number) => {
    return samples.value.filter((s) => s.template_tab_id === id);
  };

  const openAppDropdown = (id: number) => {
    toggledAppDropdowns.value = [...toggledAppDropdowns.value, id];
  };

  const closeAppDropdown = (id: number) => {
    toggledAppDropdowns.value = toggledAppDropdowns.value.filter(
      (appId) => appId !== id
    );
  };

  const hasAppSampleSelected = (id: number) => {
    return (
      samples.value.filter(
        (s) =>
          selectedSampleIds.value.includes(s.id!) && s.template_tab_id === id
      ).length > 0
    );
  };

  const toggleSample = ({ id, template_tab_id }: Sample | OfflineSample) => {
    if (selectedSampleIds.value.includes(id)) {
      setSelectedSampleIds(
        selectedSampleIds.value.filter((sampleId) => sampleId !== id)
      );
    } else {
      setSelectedSampleIds([...selectedSampleIds.value, id]);

      if (
        template_tab_id &&
        selectedAppIds.value.findIndex((appId) => appId === template_tab_id) ===
          -1
      ) {
        setSelectedAppIds([...selectedAppIds.value, template_tab_id]);
      }
    }
  };

  return {
    toggledAppDropdowns,
    toggleApp,
    toggleAppDropdown,
    isAppDropdownOpen,
    toggleSample,
  };
};

export default useTogglableSampleList;
