<script setup lang="ts">
import { getTagDisplayText } from '@component-library/business-logic/expression';
import * as cl_bm from '@component-library/business-model';
import type { FieldOperand } from '@component-library/business-model/expression';
import { computed, ref } from 'vue';

const props = defineProps<{
  tag: any;
  index: number;
  fields: FieldOperand[];
  checkHasMissingField: Function;
}>();
const emit = defineEmits(['moveTag', 'removeTag']);

const draggingOverIndex = ref<number | null>(null);

const tagStyleClasses = computed(() => {
  const { tag } = props;
  if (tag.type === cl_bm.expression.TAG_TYPE_FIELD) {
    return 'bg-primary text-white';
  } else if (tag.type === cl_bm.expression.TAG_TYPE_OPERATOR) {
    return 'bg-dark text-white';
  } else if (
    [
      cl_bm.expression.TAG_TYPE_TEXT,
      cl_bm.expression.TAG_TYPE_NUMBER,
      cl_bm.expression.TAG_TYPE_DATE,
      cl_bm.expression.TAG_TYPE_YEAR_MONTH,
      cl_bm.expression.TAG_TYPE_YEAR,
      cl_bm.expression.TAG_TYPE_DATE_TIME,
      cl_bm.expression.TAG_TYPE_TIME,
      cl_bm.expression.TAG_TYPE_YES_NO,
    ].includes(tag.type)
  ) {
    return 'bg-success text-white';
  }

  return 'bg-light';
});

const hasMissingField = computed(() => {
  const { checkHasMissingField, tag } = props;
  return checkHasMissingField(tag);
});

const tagTitle = computed(() => {
  if (!hasMissingField.value) {
    return '';
  }

  return props.tag.type === cl_bm.expression.TAG_TYPE_CUSTOM_FUNCTION
    ? 'Some fields used by the custom function are missing.'
    : 'The field is missing.';
});

const tagDisplayText = computed(() => {
  const { tag, fields } = props;
  return getTagDisplayText(tag, fields);
});

function handleDragStart(e, index) {
  e.dataTransfer.dropEffect = 'move';
  e.dataTransfer.effectAllowed = 'move';
  e.dataTransfer.setData('indexToMove', index);
}

function handleDrop(e, index) {
  draggingOverIndex.value = null;

  const from = e.dataTransfer.getData('indexToMove');

  emit('moveTag', {
    from,
    to: index,
  });
}

function handleDragOver(index) {
  draggingOverIndex.value = index;
}

function handleDragLeave() {
  draggingOverIndex.value = null;
}

function handleTagRemove(index) {
  emit('removeTag', index);
}
</script>

<template>
  <div
    class="tag"
    :class="[
      tagStyleClasses,
      {
        'drag-over': index == draggingOverIndex,
      },
      {
        'has-missing-field': hasMissingField,
      },
    ]"
    :title="tagTitle"
    draggable
    @dragstart="handleDragStart($event, index)"
    @drop="handleDrop($event, index)"
    @dragover="handleDragOver(index)"
    @dragleave="handleDragLeave()"
  >
    <slot>{{ tagDisplayText }}</slot>
    <span @click.stop="handleTagRemove(index)">
      <i class="fas fa-trash-alt" />
    </span>
  </div>
</template>

<style lang="scss" scoped>
.tag {
  min-height: 30px;
  border: 1px solid #fff;
  background-color: #eee;
  padding: 0 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  font-size: 0.75rem;

  &.drag-over {
    box-shadow: 1px 1px 0px 1px #00000033;
  }

  > span {
    cursor: pointer;
    opacity: 0.6;
    margin-left: 4px;

    &:hover {
      opacity: 1;
    }
  }

  &.has-missing-field {
    background-color: red !important;
    opacity: 0.4;
  }
}
</style>
