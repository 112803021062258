import { OfflineProject } from '@component-library/offline-data';
import { OfflineStorageManagerReturnTypeSW } from './useOfflineStorageManagerSW';

import { get as getProjection, fromLonLat } from 'ol/proj';
import { Circle } from 'ol/geom';
import { XYZ } from 'ol/source';
import { getBasemapApis } from '@maps/business-logic/basemap';
import { get } from '../helpers/fetch-api';

export default function useLayerOfflineManager(
  offlineStorageManager: OfflineStorageManagerReturnTypeSW,
  project: OfflineProject
) {
  const cacheMapTiles = async (basemap: number, radius: number) => {
    const { latitude, longitude } = project;

    const projection = getProjection('EPSG:3857')!;
    const center = fromLonLat([longitude!, latitude!], projection);
    const circle = new Circle(center, radius);
    const extent = circle.getExtent();

    const basemapApi: any = getBasemapApis('NZ')[0]!; //basemap

    const { maxNativeZoom, maxZoom } = basemapApi.options;
    const maxZ = maxNativeZoom ?? maxZoom ?? 19;
    const minZ = estimateZoomLevel(radius);

    function estimateZoomLevel(circleRadius: number) {
      const worldCircumference = 40075016.686; // meters, approximate circumference of the Earth

      const extentWidth = circleRadius * 2;
      let zoomLevel = 0;
      let resolution = worldCircumference / 256;

      while (resolution > extentWidth && zoomLevel < 28) {
        resolution /= 2;
        zoomLevel++;
      }

      return zoomLevel;
    }

    const source = new XYZ({
      maxZoom: maxZ,
      crossOrigin: 'anonymous',
      url: basemapApi.source,
      attributions: [basemapApi.options.attributions],
    });

    const tileGrid = source.getTileGrid();
    const tileUrlFunc = source.getTileUrlFunction();

    let tileUrls: string[] = [];
    for (let z = minZ; z <= maxZ; z++) {
      tileGrid.forEachTileCoord(extent, z, (tileCoord) => {
        const tileUrl = tileUrlFunc(tileCoord, 1, projection);
        if (tileUrl) {
          tileUrls.push(tileUrl);
        }
      });
    }

    const cache = await caches.open('tile-images');

    for (const url of tileUrls) {
      try {
        let response = await cache.match(url);
        if (!response) {
          response = await get(
            url,
            {},
            {
              mode: 'no-cors',
            }
          );

          await cache.put(url, response.clone());
        }
      } catch (e) {
        console.warn(`Failed to fetch tile: ${url}`, e);
      }
    }

    const loadedAreas = [
      ...(project.loaded_areas || []),
      {
        bounds: extent.toString(),
        zoom: minZ,
      },
    ];

    const actualMinimumZoom = Math.min(...loadedAreas.map((area) => area.zoom));

    await offlineStorageManager.updateOfflineProject(project.project_id, {
      loaded_areas: loadedAreas,
      offline_min_zoom: actualMinimumZoom,
    });
  };

  return {
    cacheMapTiles,
  };
}
