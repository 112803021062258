<script setup lang="ts">
import type { ContextMenuModel } from '@component-library/components/ContextMenuTypes';
import IconButton from '@component-library/components/IconButton.vue';
import useLegacyRootBus from '@component-library/composables/useLegacyRootBus';
import { FEATURES, hasAccess } from '@component-library/feature-manager';
import type { App, AppLinkConfigContext } from '@component-library/gather';
import { DrawingType, checkIsNewItem } from '@component-library/gather';
import { useCollectionStore } from '@component-library/store/collection';
import type { Ref } from 'vue';
import { computed, inject, ref } from 'vue';

enum Method {
  StartDrawMarker = 'startDrawMarker',
  StartDrawPoly = 'startDrawPoly',
  StartNonSpatial = 'startNonSpatial',
}

type DrawingOption = {
  type: DrawingType;
  icon: string;
  label: string;
  method: Method;
  isExcluded?: boolean;
};

const DRAWING_OPTIONS: DrawingOption[] = [
  {
    type: DrawingType.Point,
    icon: 'fa-map-marker',
    label: 'Point',
    method: Method.StartDrawMarker,
  },
  {
    type: DrawingType.Polygon,
    icon: 'fa-hexagon',
    label: 'Polygon',
    method: Method.StartDrawPoly,
  },
  {
    type: DrawingType.Polyline,
    icon: 'fa-wave-triangle',
    label: 'Line',
    method: Method.StartDrawPoly,
  },
  {
    type: DrawingType.Arrow,
    icon: 'fa-arrow-right',
    label: 'Arrow',
    method: Method.StartDrawPoly,
  },
  {
    type: DrawingType.Circle,
    icon: 'fa-circle',
    label: 'Circle',
    method: Method.StartDrawPoly,
  },
  {
    type: DrawingType.Rectangle,
    icon: 'fa-rectangle-wide',
    label: 'Rectangle',
    method: Method.StartDrawPoly,
  },
  {
    type: DrawingType.Hedge,
    icon: '/styled-map-icon/tool-icons/hedge',
    label: 'Hedge',
    method: Method.StartDrawPoly,
    isExcluded: !hasAccess(FEATURES.MAPS_HEDGE),
  },
  {
    type: DrawingType.NonSpatial,
    icon: 'fa-chart-bar',
    label: 'Non-spatial',
    method: Method.StartNonSpatial,
  },
];

const props = defineProps<{
  linkedApp: App;
  isExpanded: boolean;
  numberOfItems: number;
  maxNumberOfItems: number;
}>();

const { app, item, iconButtonContainer, hasChangedInputValues } =
  inject<AppLinkConfigContext>('appLinkConfigContext')!;
const collectionStore = useCollectionStore();

const eventBus = useLegacyRootBus();

const isNewItem = computed<boolean>(() => {
  return checkIsNewItem(item);
});
const progress = computed<number>(() => {
  return Math.min(
    100,
    Math.round((props.numberOfItems / props.maxNumberOfItems) * 100)
  );
});
const isFinished = computed<boolean>(() => {
  return progress.value === 100;
});

const isCreateDisabled = computed<boolean>(() => {
  return (
    collectionStore.isBusy ||
    isNewItem.value ||
    hasChangedInputValues.value ||
    isFinished.value
  );
});

const reasonForDisabledCreate = computed<string | undefined>(() => {
  if (collectionStore.isBusy) {
    return `The linkable items are being loaded, please wait.`;
  } else if (isNewItem.value) {
    return `The current ${app.title} item is a new one. Please save it before creating a ${props.linkedApp.title} item.`;
  } else if (hasChangedInputValues.value) {
    return `There are changes in the current ${app.title} item. Please save it before creating a ${props.linkedApp.title} item.`;
  } else if (isFinished.value) {
    return `The number of ${props.linkedApp.title} items has reached the maximum.`;
  }

  return undefined;
});

const contextMenuModelOfCreate = computed<ContextMenuModel>(() => {
  if (props.linkedApp.drawing_type !== 'any') {
    return [];
  }

  return DRAWING_OPTIONS.reduce((accu, drawingOption) => {
    if (!drawingOption.isExcluded) {
      const { type, icon, label } = drawingOption;
      const iconHtml = icon.startsWith('/')
        ? `<div class="me-2"><img src=${icon}/></div>`
        : `<div class="me-2"><i class="fas ${icon}"></i></div>`;
      accu.push({
        label: `<div class="d-flex align-items-center">${iconHtml}<div>${label}</div></div>`,
        click(context) {
          handleCreate(type);
        },
      });
    }
    return accu;
  }, [] as ContextMenuModel);
});

// Required because the context menu position is offset by Sample Modal margins.
function adaptContextMenu(root: HTMLDivElement) {
  if (iconButtonContainer === '#sample-form') {
    const container = document.querySelector(
      'div#sample-modal div[role="document"]'
    )! as HTMLDivElement;
    const marginLeft = Math.round(
      (window.innerWidth - container.offsetWidth) / 2
    );
    const marginTop = Math.round(
      (window.innerHeight - container.offsetHeight) / 2
    );
    const rootStyle = window.getComputedStyle(root);
    const left = Math.round(parseFloat(rootStyle.left));
    const top = Math.round(parseFloat(rootStyle.top));
    root.style.left = `${left - marginLeft}px`;
    root.style.top = `${top - marginTop}px`;
  }
}

async function handleCreate(drawingType: DrawingType) {
  const linkedAppId = props.linkedApp.id;
  let param = {
    tabId: linkedAppId,
    drawingType,
    isPlacedAtCenter: false,
  };
  if (drawingType === DrawingType.Point) {
    param = {
      ...param,
      isPlacedAtCenter: true,
    };
  }
  const { method } = DRAWING_OPTIONS.find(
    (drawingOption) => drawingOption.type === drawingType
  )!;
  eventBus.$emit(method, param);
}
</script>

<template>
  <div
    class="position-relative d-flex justify-content-between align-items-center p-2 bg-light rounded clickable"
  >
    <div
      :class="[
        'position-absolute progress-bar',
        { rounded: isFinished, 'rounded-start': !isFinished },
      ]"
      :style="{
        width: `${progress}%`,
      }"
    />
    <div class="position-relative fw-bold flex-grow-1">
      {{ linkedApp.title }} (
      <span title="Number of linked items">{{ numberOfItems }}</span
      >/<span title="Maximum number of items">{{ maxNumberOfItems }})</span>
    </div>
    <IconButton
      title="Create"
      class="position-relative me-2"
      :action="() => handleCreate(linkedApp.drawing_type)"
      :is-disabled="isCreateDisabled"
      :container="iconButtonContainer"
      :reason="reasonForDisabledCreate"
      :context-menu-model="contextMenuModelOfCreate"
      :adapt-context-menu="adaptContextMenu"
    >
      <i class="fas fa-plus"></i>
    </IconButton>
    <span
      class="position-relative fal mb-0"
      :class="{
        'fa-chevron-down': !isExpanded,
        'fa-chevron-up': isExpanded,
      }"
    />
  </div>
</template>

<style lang="scss" scoped>
.progress-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
  top: 0px;
  left: 0px;
}
</style>
