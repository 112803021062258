<script setup lang="ts">
import { computed, ref } from 'vue';
import { User } from '../../user';
import { getPushNotificationDevice } from './helpers/user';

const props = defineProps<{
  users: User[];
  selectedUsers: User[];
}>();

const emit = defineEmits<{
  (e: 'toggleUser', user: User): void;
}>();

const userQuery = ref<string | null>(null);

const getUsers = computed(() => {
  if (userQuery.value) {
    const query = userQuery.value.toLowerCase();
    return props.users!.filter(
      (u) =>
        u.name.toLowerCase().includes(query) ||
        u.email.toLowerCase().includes(query)
    );
  }

  return props.users;
});
</script>
<template>
  <div>
    <div class="input-group input-group-sm mb-2">
      <span class="input-group-text">
        <i class="fas fa-search" />
      </span>
      <input
        type="text"
        class="form-control w-50"
        :placeholder="`search for a user...`"
        v-model="userQuery"
      />
    </div>

    <div class="custom-scrollbar" style="max-height: 250px">
      <div
        v-for="user in getUsers"
        :key="`user-${user.user_id}`"
        class="dropdown-item py-2 px-2 d-flex align-items-center justify-content-between"
        @click="emit('toggleUser', user)"
        :class="{
          active:
            selectedUsers.findIndex((u) => u.user_id == user.user_id) !== -1,
        }"
      >
        <div class="d-flex align-items-center">
          <div class="me-0 me-md-3">
            <span class="fw-medium mb-0 text-wrap">
              {{ user.name || user.email }}
              {{
                getPushNotificationDevice(user)
                  ? `(${getPushNotificationDevice(user)})`
                  : ''
              }}
            </span>
            <small v-if="user.name" class="d-block opacity-75 text-wrap">
              {{ user.email }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
