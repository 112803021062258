<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

import type { Project } from '../../project';
import {
  type ProjectOfflineRequestWithUser,
  type ProjectOfflineQuickStatistics,
  SWBackEndMessageType,
} from '../../offline-data';

import Modal from '../Modal.vue';
import ProjectDetails from '../ProjectDetails.vue';
import AppCard from '../../switcher/AppCard.vue';
import ButtonSpinner from '../ButtonSpinner.vue';
import AdvancedImportContainer from './AdvancedImportContainer.vue';
import ManageExistingRequestList from './ManageExistingRequestList.vue';
import useManageOfflineData from './composables/useManageOfflineData';
import QuickCardStatistics from './QuickCardStatistic.vue';
import GeneralSettingSelector from './GeneralSettingSelector.vue';

import useApi from '../../composables/useApi';
import { useToastStore } from '../../store/toasts';
const toastStore = useToastStore();
import { useFigureStore } from '../../store/figures';
const figureStore = useFigureStore();
import { useSubscribedUsers } from './composables/useSubscribedUserStore';
const subscribedUsersStore = useSubscribedUsers();
import useAuth from '../../composables/useAuth';
const auth = useAuth();

const props = defineProps<{
  selectedProject: Project;
  selectedFigureId?: number | null;
}>();

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const close = () => {
  emit('close');
};

const api = useApi();

enum ManageTab {
  NEW,
  EXISTING,
}

enum TypeOfImport {
  QUICK,
  ADVANCED,
}

const isLoading = ref(true);
const existingOfflineRequests = ref<ProjectOfflineRequestWithUser[]>([]);
const tabToManage = ref<ManageTab>(ManageTab.NEW);
const importType = ref<TypeOfImport | null>(null);
const offlineProjectQuickStatistics = ref<ProjectOfflineQuickStatistics | null>(
  null
);
const isComponentMounted = ref(false);
const isPushNotificationsEnabled = ref(false);

watch(
  () => existingOfflineRequests.value,
  (val) => {
    if (val.length === 0) {
      tabToManage.value = ManageTab.NEW;
    }
  }
);

const { isImporting, offlineRequest, startQuickImport, startAdvancedImport } =
  useManageOfflineData(
    props.selectedProject,
    () => {
      if (
        navigator.serviceWorker.controller &&
        auth.user().company.is_force_local_gather_offline_mode
      ) {
        navigator.serviceWorker.controller.postMessage({
          data: {
            title: 'A request to download offline data has been made',
            data: {
              type: SWBackEndMessageType.OFFLINE_DOWNLOAD,
              project_id: props.selectedProject.project_id,
            },
          },
        });
      } else {
        toastStore.success(
          'A request has been sent to the users device to download the data for offline.'
        );
      }

      loadExistingData();

      tabToManage.value = ManageTab.EXISTING;
      importType.value = null;
    },
    (e) => {
      toastStore.error('There was an error importing the data, try again.');
      throw e;
    }
  );

let timeoutId: any = null;

const loadExistingData = async () => {
  const { data } = await api.get('/project/offline/existing-data', {
    params: {
      project_id: props.selectedProject.project_id,
    },
  });

  existingOfflineRequests.value = data.offline_requests;

  if (
    existingOfflineRequests.value.filter((r) =>
      ['ready_to_sync', 'is_syncing'].includes(r.status)
    ).length > 0 &&
    isComponentMounted.value
  ) {
    // ping the server every 7.5 seconds to check if the data is finished syncing
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout and store its identifier
    timeoutId = setTimeout(() => {
      loadExistingData();
    }, 7500);
  }
};

const hasOutdatedApps = computed(() => {
  return (
    existingOfflineRequests.value.filter((r) => r.outdated_apps_count).length >
    0
  );
});

const canImport = computed(() => {
  return !!(
    offlineRequest.user_id &&
    offlineRequest.figure_id &&
    (offlineRequest.sample_ids.length > 0
      ? offlineRequest.app_ids.length > 0
      : true)
  );
});

onMounted(async () => {
  try {
    isComponentMounted.value = true;
    isLoading.value = true;

    const figuresPromise = figureStore.fetchProjectFigures(
      props.selectedProject.project_id
    );
    const existingDataPromise = loadExistingData();
    const quickStatsPromise = api.get(`/project/offline/quick-stats`, {
      params: {
        project_id: props.selectedProject.project_id,
      },
    });

    const [figureResponse, _, quickStatsResponse] = await Promise.all([
      figuresPromise,
      existingDataPromise,
      quickStatsPromise,
    ]);

    if (props.selectedFigureId) {
      offlineRequest.figure_id = props.selectedFigureId;
    } else {
      if (figureResponse.last_figure_id) {
        offlineRequest.figure_id = figureResponse.last_figure_id;
      }

      if (
        offlineRequest.figure_id === null &&
        figureResponse.figures &&
        figureResponse.figures.length > 0
      ) {
        offlineRequest.figure_id = figureResponse.figures[0]?.id || null;
      }
    }

    offlineProjectQuickStatistics.value = quickStatsResponse.data;
  } finally {
    isLoading.value = false;
  }
});

onUnmounted(() => {
  isComponentMounted.value = false;
});
</script>

<template>
  <Modal
    :show="true"
    :loading="isLoading || isImporting || subscribedUsersStore.isLoadingUsers"
    @close="close"
    :half="true"
    :shouldCloseOnShadeClick="false"
    :stretch="importType !== null || tabToManage === ManageTab.EXISTING"
    class="offline-manager"
  >
    <template v-slot:header>
      <span>How would you like to manage your offline projects?</span>
    </template>
    <template>
      <ProjectDetails v-if="selectedProject" :project="selectedProject" />
      <div v-if="!importType" class="mt-3">
        <ul
          v-if="existingOfflineRequests.length > 0"
          class="nav nav-underline nav-fill mb-3"
        >
          <li class="nav-item">
            <a
              class="nav-link py-3"
              href="#"
              :class="{
                active: tabToManage === ManageTab.NEW,
              }"
              @click.prevent="tabToManage = ManageTab.NEW"
            >
              Take Data Offline
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link py-3"
              href="#"
              :class="{
                active: tabToManage === ManageTab.EXISTING,
              }"
              @click.prevent="tabToManage = ManageTab.EXISTING"
            >
              Manage Offline Data

              <i
                v-if="hasOutdatedApps"
                class="fal fa-exclamation-circle text-danger ms-2"
              />
            </a>
          </li>
        </ul>

        <template v-if="tabToManage === ManageTab.NEW">
          <GeneralSettingSelector
            v-if="offlineRequest"
            :projectId="selectedProject.project_id"
            :userId="offlineRequest.user_id"
            @selectUserId="(id) => (offlineRequest.user_id = id)"
            :userDevice="offlineRequest.device"
            @selectUserDevice="(device) => (offlineRequest.device = device)"
            :figures="figureStore.projectFigures"
            :figureId="offlineRequest.figure_id"
            @selectFigureId="(id) => (offlineRequest.figure_id = id)"
            @toggleEnabledPushNotifications="
              isPushNotificationsEnabled = $event
            "
          />

          <div
            :class="{
              'offline-disabled':
                offlineRequest.user_id &&
                !isPushNotificationsEnabled &&
                !auth.user().company.is_force_local_gather_offline_mode,
            }"
          >
            <AppCard
              @goToApp="startQuickImport"
              title="Quick Import"
              colour="#32639e"
              icon="fal fa-tachometer-fastest"
              tooltip="Pull through all apps & a small radius of the sites tileset."
              :largeCard="true"
              :isLightStyling="true"
              class="w-100 mb-3"
            >
              <QuickCardStatistics
                v-if="offlineProjectQuickStatistics"
                :statistics="offlineProjectQuickStatistics"
                class="mt-1"
              />
            </AppCard>

            <AppCard
              @goToApp="importType = TypeOfImport.ADVANCED"
              title="Advanced Import"
              colour="#32639e"
              icon="fal fa-cog"
              tooltip="Fine tune your settings and import only what you need"
              :largeCard="true"
              :isLightStyling="true"
              class="w-100"
            />
          </div>
        </template>
        <ManageExistingRequestList
          v-else
          :selectedProject="selectedProject"
          :offlineRequests="existingOfflineRequests"
          @update:requests="
            (requests) => {
              existingOfflineRequests = requests;
              loadExistingData();
            }
          "
        />
      </div>
      <template v-else>
        <hr />
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h6 class="mb-0 d-block">Advanced Import</h6>
        </div>
        <AdvancedImportContainer
          :selectedProject="selectedProject"
          :figures="figureStore.projectFigures"
          :userId="offlineRequest.user_id"
          :userDevice="offlineRequest.device"
          :figureId="offlineRequest.figure_id"
          :basemap="offlineRequest.basemap"
          :radius="offlineRequest.radius"
          :selectedAppIds="offlineRequest.app_ids"
          :selectedSampleIds="offlineRequest.sample_ids"
          @update:offlineRequest="
            ({ key, value }) => (offlineRequest[key] = value)
          "
          @toggleEnabledPushNotifications="isPushNotificationsEnabled = $event"
        />
      </template>
    </template>

    <template v-slot:footer>
      <div v-if="importType" class="w-100">
        <ButtonSpinner
          type="button"
          class="btn btn-primary w-100 py-3 mb-2"
          :is-loading="isImporting"
          :disabled="
            (!auth.user().company.is_force_local_gather_offline_mode
              ? !isPushNotificationsEnabled
              : false) || !canImport
          "
          @click.prevent.native="startAdvancedImport"
        >
          Start Import
        </ButtonSpinner>
        <button class="btn btn-flat w-100 py-2" @click="importType = null">
          <i class="fal fa-arrow-left"></i> Back
        </button>
      </div>
    </template>
  </Modal>
</template>
<style scoped>
.offline-disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.75;
}
</style>
