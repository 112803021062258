<script lang="ts" setup>
import { onMounted, computed } from 'vue';
import AppGrid from './AppGrid.vue';
import { useTaskStore } from '../store/tasks';
import EventBus from '../EventBus';
import { useProjectStore } from '../store/project';
import AppSwitcherModal from './AppModal.vue';

const taskStore = useTaskStore();
const projectStore = useProjectStore();

const shortCuts = {
  p: ['hub', 'project_pathway'],
  n: ['hub', 'project_create'],
  m: ['hub', 'map'],
  f: ['hub', 'map'],
  d: ['hub', 'project_dashboard'],
  e: ['gather', 'template_editor'],
  a: ['gather', 'template_editor'],
  g: ['gather', 'project_map'],
  c: ['gather', 'project_map'],
  h: [null, 'https://app.datanest.earth/support/gather'],
};

defineProps<{
  rightSide?: boolean;
}>();

const emit = defineEmits(['loadProjectData']);

function toggleDropdown() {
  if (projectStore.isFullPagePopup) {
    return;
  }

  projectStore.showSwitcherDropdown = !projectStore.showSwitcherDropdown;

  if (projectStore.showSwitcherDropdown) {
    taskStore.completeTask('open-where-to-next');

    emit('loadProjectData');
  }
}

const isDropdown = computed(() => {
  if (typeof window !== 'undefined') {
    return window.innerWidth > 768;
  }
  return true;
});

function closeDropdown() {
  projectStore.showSwitcherDropdown = false;
  projectStore.isFullPagePopup = false;
}

function clickOutside() {
  if (projectStore.isFullPagePopup || !isDropdown.value) {
    return;
  }
  closeDropdown();
}

onMounted(() => {
  window.addEventListener('keyup', (e) => {
    if (e.key == 'Escape' && projectStore.showSwitcherDropdown) {
      projectStore.isFullPagePopup = false;
      projectStore.showSwitcherDropdown = false;
    } else if (
      document.activeElement &&
      (['INPUT', 'SELECT', 'TEXTAREA'].includes(
        document.activeElement.tagName.toUpperCase()
      ) ||
        (document.activeElement as any).isContentEditable)
    ) {
      return;
    } else if (!projectStore.showSwitcherDropdown) {
      return;
    }

    for (let shortCut of Object.keys(shortCuts)) {
      if (e.key.toLowerCase() == shortCut.toLowerCase()) {
        if (!shortCuts[shortCut][0]) {
          window.open(shortCuts[shortCut][1]);
          projectStore.showSwitcherDropdown = false;
          return;
        }
        EventBus.$emit('goToApp', {
          route: shortCuts[shortCut][1],
          app: shortCuts[shortCut][0],
          isMiddleClick: e.key == shortCut.toUpperCase(),
        });
      }
    }
  });
});
</script>

<template>
  <div>
    <div
      v-if="projectStore.showSwitcherDropdown && projectStore.isFullPagePopup"
      class="switcher-bg"
    />

    <div class="dropdown full-page" v-click-outside="clickOutside">
      <div
        class="btn btn-header"
        data-cy="app-switcher-dropdown-btn"
        @click="toggleDropdown"
      >
        <i class="fas fa-th me-0"></i>
      </div>

      <Transition name="dropdown">
        <div
          class="dropdown-menu p-0 mt-2"
          :class="{
            show: projectStore.showSwitcherDropdown,
            right: rightSide,
          }"
          v-show="projectStore.showSwitcherDropdown && isDropdown"
        >
          <div class="p-3" style="padding-bottom: 0.5rem !important">
            <h6>Where to next?</h6>
          </div>

          <AppGrid
            class="p-3"
            style="padding-top: 0em !important"
            ref="appGrid"
            @closeDropdown="closeDropdown"
          />
        </div>
      </Transition>
    </div>

    <AppSwitcherModal
      v-if="projectStore.showSwitcherDropdown && !isDropdown"
      @close="closeDropdown"
    />
  </div>
</template>

<style scoped>
.dropdown-menu {
  width: 340px;
  z-index: 99999 !important;
}

.switcher-bg {
  position: fixed;
  z-index: 10010 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  display: block;
  overflow: hidden;
}

.full-page {
  z-index: 99999 !important;
}

.dropdown-menu {
  height: auto;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

@media all and (max-width: 992px) {
  .dropdown-menu.right {
    right: 0;
  }
}
</style>
