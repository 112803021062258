<script setup lang="ts">
import {
  FILL_PATTERN_DATA,
  FILL_PATTERN_SOLID,
} from '../business-model/common';
import FillPattern from './FillPattern.vue';
import Tick from './Tick.vue';

withDefaults(
  defineProps<{ selectedFillPattern?: number; fillPatterns: number[] }>(),
  {
    selectedFillPattern: FILL_PATTERN_SOLID,
  }
);

const emit = defineEmits(['change']);

function handleClick(value) {
  emit('change', value);
}
</script>

<template>
  <div class="form-group fill-pattern-chooser">
    <label class="form-label"> Fill pattern</label>
    <div class="d-flex fill-pattern-row">
      <FillPattern
        v-for="fp in fillPatterns"
        :key="`fill-pattern-${fp}`"
        :value="fp"
        :title="FILL_PATTERN_DATA[fp].title"
        :size="FILL_PATTERN_DATA[fp].size"
        :color="FILL_PATTERN_DATA[fp].color"
        @click="handleClick"
      >
        <Tick v-if="selectedFillPattern === fp" />
      </FillPattern>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fill-pattern-chooser {
  .fill-pattern-row {
    gap: 10px;
  }
}
</style>
