import type { Coordinate } from 'ol/coordinate';
import { Projection, toLonLat } from 'ol/proj';
import { radToDeg } from '../measurement/utils';
import type { LonLat } from './types';

export type GeolocationResult = {
  position: Coordinate;
  accuracy: number | undefined;
  heading: number;
  speed: number;
};

export function formatGeolocationResult(
  value: GeolocationResult,
  projection: Projection
): string {
  const items: string[] = [];

  items.push(
    '<div class="address-container"><div class="spinner-border spinner-border-sm"></div></div>'
  );

  const { position, accuracy, heading, speed } = value;

  if (accuracy) {
    items.push(
      `You are within <b>${Math.round(accuracy)}</b> meters from this point`
    );
  }

  const [longitude, latitude] = toLonLat(position, projection);
  items.push(`<b>Longitude</b>: ${longitude.toFixed(7)}`);
  items.push(`<b>Latitude</b>: ${latitude.toFixed(7)}`);

  items.push(`<b>Heading</b>: ${Math.round(radToDeg(heading))}&deg;`);
  items.push(`<b>Speed</b>: ${(speed * 3.6).toFixed(1)} km/h`);

  return items.join('<br/>');
}

export async function getAddressFromLonLat(
  lonLat: LonLat
): Promise<string | undefined> {
  const api = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
    lonLat.latitude
  },${lonLat.longitude}&key=${import.meta.env.VITE_GOOGLE_MAP_API_KEY}`;
  const resp = await fetch(api);
  const json = await resp.json();
  return json.results.length > 0
    ? json.results[0].formatted_address
    : undefined;
}
