<template>
  <div>
    <field-condition-item
      v-for="(condition, conditionIndex) in conditions"
      :key="'condition-' + conditionIndex"
      :value="condition"
      :index="conditionIndex"
      :fields="fields"
      :isDefault="isDefault"
      :groups="groups"
      @update="updateCondition"
      @removeCondition="$emit('removeCondition', conditionIndex)"
    />
  </div>
</template>

<script>
import FieldConditionItem from './FieldConditionItem.vue';

export default {
  components: {
    FieldConditionItem,
  },
  props: {
    value: {
      required: true,
      default: [],
      type: Array,
    },
    fields: {
      required: true,
    },
    isDefault: {
      required: false,
      default: false,
    },
    groups: {
      required: false,
      default: () => [],
    },
  },
  computed: {
    conditions: {
      get() {
        return this.value;
      },
      set(updated) {
        this.$emit('input', updated);
      },
    },
  },
  methods: {
    updateCondition({ condition, index }) {
      const conditions = [...this.value];
      conditions[index] = condition;
      this.$emit('input', conditions);
    },
    removeCondition(conditionIndex) {
      this.$emit('removeCondition', conditionIndex);
    },
  },
};
</script>
