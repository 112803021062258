<template>
  <div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="is_richtext"
        :checked="field.options.is_richtext"
        @keypress.enter="$emit('blur')"
        @input="
          $emit('update', {
            key: 'is_richtext',
            value: $event.target.checked,
          })
        "
      />
      <label class="form-check-label" for="is_richtext">
        Do you want text styling options?
      </label>
    </div>
    <div class="form-group pt-3">
      <label class="form-label">Validation Requirement</label>
      <div class="input-group">
        <select class="form-control" v-model="validation">
          <option :value="null">None</option>
          <option value="email">Email Address</option>
        </select>
      </div>
    </div>
    <div class="form-group pt-3">
      <label class="form-label">Default Value</label>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          v-model="defaultText"
          placeholder="Default text"
          @keypress.enter="$emit('blur')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: Object,
  },
  computed: {
    defaultText: {
      get() {
        return this.field.options?.default || '';
      },
      set(updated) {
        this.$emit('update', {
          key: 'default',
          value: updated,
        });
      },
    },
    validation: {
      get() {
        return this.field.options?.validation || null;
      },
      set(updated) {
        this.$emit('update', {
          key: 'validation',
          value: updated,
        });
      },
    },
  },
};
</script>
