export const SUPPORTED_TYPES = [
  'pdf',
  'doc',
  'docx',
  'ods',
  'odt',
  'txt',
  'xls',
  'xlsx',
  'csv',
];
