<script setup>
const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
});

const ICON_LITHOLOGY = 'fa-lithology';
</script>

<template>
  <svg
    v-if="icon === ICON_LITHOLOGY"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    class="icon-lithology me-2"
  >
    <path
      class="fa-primary"
      d="M54.6 73.4c-9.2-9.2-22.9-11.9-34.9-6.9S0 83.1 0 96V480c0 17.7 14.3 32 32 32H416c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-384-384z"
    />
    <path
      class="fa-secondary"
      d="M192 64a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm60.4 24.2c-2.7 4.9-2.7 10.8 0 15.7l27 48c2.8 5 8.2 8.2 13.9 8.2l53.3 0c5.8 0 11.1-3.1 13.9-8.2l27-48c2.7-4.9 2.7-10.8 0-15.7l-27-48c-2.8-5-8.2-8.2-13.9-8.2H293.4c-5.8 0-11.1 3.1-13.9 8.2l-27 48zM504.2 403.6c4.9 2.7 10.8 2.7 15.7 0l48-27c5-2.8 8.2-8.2 8.2-13.9V309.4c0-5.8-3.1-11.1-8.2-13.9l-48-27c-4.9-2.7-10.8-2.7-15.7 0l-48 27c-5 2.8-8.2 8.2-8.2 13.9v53.3c0 5.8 3.1 11.1 8.2 13.9l48 27zM384 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
    />
  </svg>
  <i v-else class="fal fa-fw me-2" :class="icon"></i>
</template>

<style lang="scss" scoped>
.icon-lithology {
  display: inline-block;
  width: 1.25em;
  .fa-secondary {
    opacity: 0.4;
  }
}
</style>
