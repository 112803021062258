<template>
  <modal :show="show" @close="close">
    <template v-slot:header> Chain of Custody Settings </template>
    <div class="modal-body" v-if="coc.published">
      <h6>
        Your pincode is: <b>{{ coc.pincode }}</b>
      </h6>
      <small class="text-muted d-block mt-2">
        This will be used to access this CoC from the public URL.
      </small>

      <hr />

      <div>
        <publish-signature
          type="courier"
          :name="coc.courier_name"
          :signature="coc.courier_signature_url"
          :date="coc.courier_signed_on"
          class="mb-3"
        />

        <publish-signature
          type="lab"
          :name="coc.lab_name"
          :signature="coc.lab_signature_url"
          :date="coc.lab_signed_on"
        />
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button-spinner
        type="submit"
        :is-loading="publishing"
        class="btn btn-flat text-danger w-100 btn-lg mt-1"
        @click.native="unpublish"
        v-if="coc.published"
      >
        Unpublish CoC
      </button-spinner>
      <button-spinner
        type="submit"
        :is-loading="publishing"
        class="btn btn-primary btn-lg w-100"
        @click.native="publish"
        v-else
      >
        Publish CoC
      </button-spinner>
    </div>
  </modal>
</template>

<script>
import Modal from '@/js/components/Modal.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';

import api from '../api';

import PublishSignature from './PublishSignature.vue';

export default {
  props: { show: Boolean, coc: Object },
  data: () => ({
    publishing: false,
    formErrors: {},
  }),
  components: {
    Modal,
    ButtonSpinner,
    PublishSignature,
  },
  methods: {
    close() {
      this.$emit('close');
    },
    publish() {
      this.update(true);
    },
    unpublish() {
      this.update(false);
    },
    async update(published) {
      this.formErrors = {};
      this.publishing = true;

      try {
        const { data } = await api.updateCocPublished(this.coc.id, {
          published,
        });

        this.$emit('updateCoc', {
          published: published,
          pincode: data.pincode,
        });
        this.publishing = false;
      } catch (e) {
        this.publishing = false;
        this.formErrors = e.response ? e.response.data.errors : {};
        throw e;
      }
    },
    getFormErrorMessage(key) {
      const indexedError = this.formErrors[key];
      return indexedError ? indexedError[0] : null;
    },
  },
};
</script>
