<template>
  <modal :show="show" @close="close" @submit="submit" :isDelete="isDelete">
    <template v-slot:header>
      {{ headerMessage }}
    </template>

    <div v-if="bodyMessage != null" v-html="bodyMessage" />

    <div v-if="requireConfirmation || hasSlot" class="modal-body">
      <slot />

      <div
        v-if="requireConfirmation"
        class="mb-0"
        :class="{
          'mt-3': bodyMessage,
        }"
      >
        <label class="form-label" for="confirmText">
          Type <b>I Accept</b> To Confirm
        </label>
        <input
          class="form-control"
          placeholder="I accept"
          v-model="confirmText"
        />
      </div>
    </div>

    <div class="modal-footer">
      <button
        :class="'btn btn-outline-' + (cancelBtn || 'secondary')"
        @click="close"
        class="flex-grow-1"
      >
        {{ yesOrNoOption ? 'No' : 'Cancel' }}
      </button>

      <button-spinner
        v-if="!hideSubmit"
        type="submit"
        class="btn"
        :class="isDelete || isDanger ? 'btn-danger' : 'btn-primary'"
        :is-loading="loading"
        @click.native.prevent="submit"
        style="flex: 1 0 60%"
        :disabled="isDisabled"
      >
        {{ isDelete ? 'Delete' : yesOrNoOption ? 'Yes' : 'Ok' }}
      </button-spinner>
    </div>
  </modal>
</template>

<script>
import Modal from '@/js/components/Modal.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';

/**
 * @deprecated Use Dialog Store instead
 */
export default {
  name: 'NotifyModal',
  props: [
    'show',
    'headerMessage',
    'bodyMessage',
    'isDelete',
    'isDanger',
    'hideSubmit',
    'yesOrNoOption',
    'loading',
    'cancelBtn',
    'requireConfirmation',
  ],
  data: () => ({
    confirmText: '',
  }),
  components: {
    Modal,
    ButtonSpinner,
  },
  mounted() {
    console.warn(
      'Deprecated: NotifyModal is deprecated. Use Dialog Store instead'
    );
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
    isDisabled() {
      if (!this.requireConfirmation) {
        return false;
      }

      return this.confirmText.toLowerCase().trim() != 'i accept';
    },
  },
  methods: {
    close: function () {
      this.$emit('close');
    },
    submit: function () {
      this.$emit('submit');
    },
  },
};
</script>
