<template>
  <modal :show="show" @close="close" :half="true" :hideHeader="true">
    <div class="modal-body">
      <version-control v-if="sample && show" :sampleId="sample.id" />
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-secondary w-100" @click="close">
        {{ isNonSpatialView ? 'Close' : 'Back' }}
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '@/js/components/Modal.vue';
import VersionControl from '@/js/modules/project/version-control/index.vue';

export default {
  props: {
    show: Boolean,
    sample: Object,
    isNonSpatialView: Boolean,
  },
  components: { Modal, VersionControl },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
