<template>
  <div v-if="!disabled">
    <AppEditorAccordion v-if="hasFieldsSection" title="Fields">
      <Draggable
        class="list-group"
        :key="`field-draggable-${draggableCounter}`"
        :value="draggableFieldTypes"
        :group="fieldsDraggableGroup"
        :sort="false"
        :handle="getHandle"
        @end="draggableCounter++"
      >
        <div
          v-for="(fieldType, fieldIndex) in draggableFieldTypes"
          :key="`field-${fieldIndex}`"
          class="list-group-item list-group-item-action d-flex align-items-center user-select-none"
          @click="() => mobileSize && addField(fieldType)"
        >
          <i
            class="fal fa-grip-lines-vertical grab-item me-2 d-none d-md-block"
          />
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="flex-grow-1">
              <FieldIcon :icon="fieldType.icon" />
              {{ fieldType.label }}
            </div>

            <i
              v-if="selectedSection !== null"
              class="fal fa-plus clickable add-btn me-2"
              @click="addField(fieldType)"
            />
          </div>
        </div>
      </Draggable>
    </AppEditorAccordion>

    <AppEditorAccordion
      title="Sections"
      :class="{
        'mt-3': sections.length > 0 && hasFieldsSection,
        'mb-0': mobileSize,
      }"
    >
      <Draggable
        class="list-group pb-3"
        :key="`section-draggable-${draggableCounter}`"
        :list="sectionTypes"
        :group="sectionsDraggableGroup"
        :sort="false"
        :clone="cloneSection"
        :disabled="disabled"
        :handle="getHandle"
        @end="draggableCounter++"
      >
        <div
          class="list-group-item list-group-item-action d-flex align-items-center user-select-none"
          v-for="section in sectionTypes"
          :key="section.id"
          @click="() => mobileSize && addSection(section)"
        >
          <i
            class="fal fa-grip-lines-vertical grab-item me-2 d-none d-md-block"
          />
          <div class="d-flex align-items-center justify-content-between w-100">
            <div>
              <i
                v-if="section.icon"
                class="fal me-2 fa-fw"
                :class="section.icon"
              />
              {{ section.title }}
            </div>

            <i
              class="fal fa-plus clickable add-btn me-2"
              @click="addSection(section)"
            />
          </div>
        </div>
      </Draggable>
    </AppEditorAccordion>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import sectionTemplates from '@/js/helpers/section-templates';
import { draggableFieldTypes } from '@component-library/fields';
import FieldIcon from './FieldIcon.vue';
import AppEditorAccordion from './AppEditorAccordion.vue';

export default {
  props: {
    fields: Array,
    template: Object,
    value: Array,
    disabled: Boolean,
    selectedSection: Number,
    isTemplateEditorOperating: Boolean,
    mobileSize: Boolean,
  },
  data: () => ({
    sectionTypes: [
      { id: 'regular', title: 'Empty Section', icon: 'fa-folder' },
      { id: 'sample_matrix', title: 'Sample Matrix', icon: 'fa-map-marker' },
      { id: 'sample_lab', title: 'Sample Lab', icon: 'fa-map-marker' },
      { id: 'soil_log', title: 'Soil Log', icon: 'fa-bookmark' },
      { id: 'site_visit', title: 'Site Visit', icon: 'fa-walking' },
      { id: 'photolog', title: 'Photo Log', icon: 'fa-images' },
      { id: 'health_safety', title: 'Health and Safety', icon: 'fa-hospital' },
      {
        id: 'gps_point_metadata',
        title: 'GPS Point Metadata',
        icon: 'fa-map-pin',
      },
    ],
    draggableCounter: 0,
  }),
  components: {
    Draggable,
    FieldIcon,
    AppEditorAccordion,
  },
  computed: {
    sections: {
      get() {
        return this.value;
      },
      set(updated) {
        this.$emit('input', updated);
      },
    },
    section: {
      get() {
        return this.value[this.selectedSection];
      },
      set(updated) {
        const sections = [...this.value];
        sections[this.selectedSection] = { ...updated };

        this.$emit('input', sections);
      },
    },
    hasSampleSection() {
      return this.sections.findIndex((s) => s.is_lab_sample) != -1;
    },
    hasSoilLogSection() {
      return this.sections.findIndex((s) => s.is_soil_log) != -1;
    },
    hasSiteVisitSection() {
      return this.sections.findIndex((s) => s.is_site_visit) != -1;
    },
    draggableFieldTypes() {
      return draggableFieldTypes;
    },
    getHandle() {
      return window.innerWidth < 500 ? '.grab-item' : null;
    },
    fieldsDraggableGroup() {
      return {
        name: 'fields',
        pull: !this.isTemplateEditorOperating ? 'clone' : false,
        put: false,
      };
    },
    sectionsDraggableGroup() {
      return {
        name: 'sections',
        pull: !this.isTemplateEditorOperating ? 'clone' : false,
        put: false,
      };
    },
    hasFieldsSection() {
      return (
        this.sections.length > 0 &&
        (!this.mobileSize || this.selectedSection !== null)
      );
    },
  },
  methods: {
    cloneSection(element) {
      const type = element.id;

      let section = {
        label: 'Section ' + (this.sections.length + 1),
        template_fields: [],
        is_repeatable: false,
        primary_field_id: null,
      };

      if (type != 'regular') {
        const template = sectionTemplates.getTemplateById(type);
        section = {
          label: template.label,
          template_fields: template.fields,
          is_lab_sample: type == 'sample_matrix' || type == 'sample_lab',
          is_soil_log: type == 'soil_log',
          is_site_visit: type == 'site_visit',
          is_health_safety: type == 'health_safety',
          is_gps_point_metadata: type === 'gps_point_metadata',
        };
      }

      return section;
    },
    addField(field) {
      if (!this.section) {
        return;
      }

      const fieldIndex = this.section.template_fields.length;

      this.$emit('addField', {
        sectionIndex: this.selectedSection,
        fieldIndex,
        value: {
          ...field,
          template_section_id: this.section.id,
        },
      });

      this.$root.$emit('selectField', fieldIndex);
    },
    addSection(section) {
      const newSection = this.cloneSection(section);

      this.$emit('addSection', {
        element: newSection,
        index: this.sections.length + 1,
      });
    },
  },
};
</script>
<style scoped>
.list-group-item {
  cursor: grab;
}

.grab-item {
  opacity: 0.2;
}

.list-group-item:hover {
  cursor: all-scroll;
}

.list-group-item:hover .add-btn {
  display: block;
}
</style>
