import { Vector as VectorLayer } from 'ol/layer';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { unByKey } from 'ol/Observable';
import { easeOut } from 'ol/easing';
import { getVectorContext } from 'ol/render';
import { Feature, Map } from 'ol';
import { Point } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { LayerType } from '../types';

function createPulse(
  coord: number[],
  map: Map,
  duration = 350,
  startRadius = 6,
  endRadius = 20
) {
  const tileLayer = map
    .getLayers()
    .getArray()
    .find((layer) => layer.get('type') === LayerType.BASEMAP_SERVICE);

  if (!tileLayer) {
    return;
  }

  const feature = new Feature(new Point(coord));

  const layer = new VectorLayer({
    source: new VectorSource({
      features: [feature],
    }),
    style: new Style({
      image: new CircleStyle({
        radius: startRadius,
        stroke: new Stroke({
          color: 'rgba(78, 6, 149, 0.5)',
          width: 1,
        }),
        fill: new Fill({
          color: 'rgba(78, 6, 149, 0.1)',
        }),
      }),
    }),
  });

  map.addLayer(layer);

  const start = new Date().getTime();
  const listenerKey = (tileLayer as any).on('postrender', (event: any) => {
    const frameState = event.frameState;
    const elapsed = frameState.time - start;
    if (elapsed >= duration) {
      unByKey(listenerKey);
      map.removeLayer(layer);
      return;
    }

    const vectorContext = getVectorContext(event);
    const elapsedRatio = elapsed / duration;

    const radius =
      startRadius + easeOut(elapsedRatio) * (endRadius - startRadius);
    const opacity = easeOut(1 - elapsedRatio);

    vectorContext.setStyle(
      new Style({
        image: new CircleStyle({
          radius: radius,
          stroke: new Stroke({
            color: `rgba(78, 6, 149, ${opacity})`,
            width: 1 + opacity,
          }),
        }),
      })
    );

    const pulseGeometry = feature.getGeometry()!;
    vectorContext.drawGeometry(pulseGeometry);

    map.render();
  });
}

export default createPulse;
