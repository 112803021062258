import { App } from '../gather';

export function getNextItemPlaceholderTitle(
  app: App,
  nextNumber: number
): string {
  const title = app.title || 'Item';
  let prefix = app.prefix;

  if (prefix === null || prefix === undefined) {
    prefix = `${title}-`;
  }

  const suffixNumberMatch = prefix.match(/\d+$/);
  const suffixBaseNumber = suffixNumberMatch ? suffixNumberMatch[0] : null;
  const minimumDigits = suffixBaseNumber
    ? suffixBaseNumber.toString().length
    : 1;
  const suffixBaseNumberFloat = suffixBaseNumber
    ? parseFloat(suffixBaseNumber)
    : 0;
  const number = suffixBaseNumberFloat + nextNumber;

  prefix = prefix.replace(new RegExp(suffixBaseNumber + '$'), '');

  const separatorMatch = prefix.match(/[-=_]$/);
  const separator = separatorMatch ? separatorMatch[0] : '';
  prefix = prefix.replace(new RegExp(separator + '$'), '');

  const paddedNextNumber = number.toString().padStart(minimumDigits, '0');

  return `${prefix}${separator}${paddedNextNumber}`;
}
