<template>
  <td
    :class="{
      'bg-warning': isChanged && !isDeleted,
      'bg-danger': isDeleted,
    }"
  >
    <div
      v-if="version"
      class="d-flex align-items-center"
      tabindex="0"
      :title="title"
    >
      <button
        v-if="showRevertButton"
        class="btn btn-sm me-1"
        @click="
          $emit('revertValuesToVersion', {
            versionCreatedAt,
            value,
          })
        "
      >
        <i class="fas fa-history" />
      </button>
      <component
        :is="fieldComponentName"
        :field="field"
        :value="versionValue"
      />
    </div>
    <template v-else>-</template>
  </td>
</template>
<script>
import fields from '@component-library/gather-table/fields';
import _isEqual from 'lodash/isEqual';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    field: Object,
    value: Object,
    versionCreatedAt: String,
    previousVersionCreatedAt: String,
    versionNumber: Number,
    isLastVersion: Boolean,
    users: Array,
  },
  components: { ...fields.components },
  computed: {
    ...mapGetters({
      get_datetime_format_by_country: 'get_datetime_format_by_country',
    }),
    fieldComponentName() {
      return fields.getComponentNameFromId(this.field.field_type_id);
    },
    position() {
      return this.getPosition(this.versionCreatedAt);
    },
    previousPosition() {
      return this.getPosition(this.previousVersionCreatedAt);
    },
    version() {
      return this.position !== -1
        ? this.value.versions[this.position]
        : undefined;
    },
    versionValue() {
      return this.getVersionValue(this.position);
    },
    isChanged() {
      const previouseVersionValue = this.getVersionValue(this.previousPosition);

      return !_isEqual(
        {
          value: this.versionValue.value,
          value2: this.versionValue.value2,
        },
        {
          value: previouseVersionValue.value,
          value2: previouseVersionValue.value2,
        }
      );
    },
    title() {
      const user = this.users.find((u) => u.user_id == this.version?.user_id);

      if (!user) {
        return '-';
      }

      const timestamp = moment
        .utc(this.version.created_at)
        .local()
        .format(this.get_datetime_format_by_country);

      return `Edited by ${user.name || user.email} on ${timestamp}`;
    },
    isDeleted() {
      return this.versionValue.deleted_at && this.version;
    },
    showRevertButton() {
      return (
        this.isDeleted ||
        !_isEqual(this.value.value, this.versionValue.value) ||
        !_isEqual(this.value.value2, this.versionValue.value2)
      );
    },
  },
  methods: {
    getPosition(versionCreatedAt) {
      if (!versionCreatedAt) {
        return -1;
      }

      const versionCreatedAts =
        this.value?.versions.map((v) => v.created_at).sort() ?? [];
      for (let i = versionCreatedAts.length - 1; i > -1; i--) {
        if (versionCreatedAts[i].localeCompare(versionCreatedAt) <= 0) {
          return i;
        }
      }

      return -1;
    },
    getVersionValue(position) {
      if (!this.value || position < 0) {
        return { ...this.value, value: null, value2: null };
      }

      if (position > this.value.versions.length - 1) {
        return this.value;
      }

      let result = {
        ...this.value,
        value: null,
        value2: null,
        deleted_at: null,
      };

      for (let i = 0; i <= position; i++) {
        const {
          contents: { value, value2 },
        } = this.value.versions[i];
        if (typeof value !== 'undefined') {
          // The value of a dropdown with multiple selection is a stringified array.
          // It needs to be parsed to a normal array.
          let parsedValue;
          try {
            parsedValue = JSON.parse(value);
          } catch (e) {
            // can be ignored.
            parsedValue = value;
          }
          result = {
            ...result,
            value: parsedValue,
          };
        }

        if (typeof value2 !== 'undefined') {
          result = {
            ...result,
            value2,
          };
        }
      }

      if (this.isLastVersion) {
        result = {
          ...result,
          deleted_at: this.value.deleted_at,
        };
      }

      return result;
    },
  },
};
</script>
