<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { FieldTypeIds } from '@component-library/fields';
import { getDefaultValuePair } from '@component-library/business-logic/expression';

const props = defineProps<{
  value: string;
}>();

const emit = defineEmits(['input']);

const date = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit('input', value);
  },
});

onMounted(() => {
  if (!date.value) {
    const { value } = getDefaultValuePair({
      field_type_id: FieldTypeIds.DATE,
    });
    date.value = value;
  }
});
</script>

<template>
  <input type="date" class="form-control" v-model="date" name="date" />
</template>
