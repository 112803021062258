<template>
  <div class="layout position-relative">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.layout {
  width: 100%;
}
</style>
