<template>
  <div class="offset-md-2 col-md-8 offset-0 col-12 card text-center mt-3">
    <div class="card-body">
      <h1
        class="font-weight-bold"
        style="font-size: 8rem; color: #4e0695; font-weight: 700"
      >
        404
      </h1>
      <div class="mb-4"><strong>OOPS!</strong> Something went wrong here.</div>
      <button class="btn btn-outline-secondary" @click.prevent="$router.go(-1)">
        Go Back
      </button>
      <template v-if="$auth.check()">
        <router-link class="btn btn-primary ms-2" to="/">
          Go to Home
        </router-link>
      </template>
      <template v-else>
        <router-link class="btn btn-primary" to="/login">
          Go to Login
        </router-link>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
