<template>
  <modal :show="show" @close="close">
    <template v-slot:header>
      <ul class="nav nav-pills nav-fill">
        <li class="nav-item">
          <a
            href="#"
            :class="[
              'nav-link',
              {
                active: isPointsTabActive,
                'bg-primary': isPointsTabActive,
                'text-white': isPointsTabActive,
              },
            ]"
            @click.prevent="() => (currentTab = 'points')"
          >
            Manage Items
          </a>
        </li>
        <li class="nav-item me-0">
          <a
            href="#"
            :class="[
              'nav-link',
              {
                active: isLayersTabActive,
                'bg-primary': isLayersTabActive,
                'text-white': isLayersTabActive,
              },
            ]"
            @click.prevent="() => (currentTab = 'layers')"
          >
            Manage Layers
          </a>
        </li>
      </ul>
    </template>
    <div class="modal-body d-flex flex-column custom-scrollbar">
      <ManageItemTab
        v-if="isPointsTabActive"
        :templateTabs="templateTabs"
        :editSample="editSample"
        :goToSample="goToSample"
        :openSampleVersionControl="openSampleVersionControl"
      />
      <ManageLayerTab
        v-if="isLayersTabActive"
        :apps="templateTabs"
        :setTab="setTab"
        @hiddenSubFoldersChange="handleHiddenSubFoldersChange"
      />
    </div>
    <div class="modal-footer">
      <button
        v-if="currentTab === 'points'"
        class="btn btn-outline-primary w-100"
        @click="$root.$emit('openAddPopup') && $emit('close')"
      >
        <i class="fas fa-plus fa-fw"></i>
        New Item
      </button>
      <button class="btn w-100" @click="$emit('close')">Close</button>
    </div>
  </modal>
</template>
<script>
import Modal from '@/js/components/Modal.vue';
import useLayerModelStore from '@/js/stores/layer-model';
import useSampleStore from '@/js/stores/sample';
import { isItemNonSpatial } from '@component-library/gather';
import { mapStores } from 'pinia';
import { inject } from 'vue';
import { mapActions } from 'vuex';
import { StackableModalType } from '../../../../types/modal-stack';
import ManageItemTab from './ManageItemTab.vue';
import ManageLayerTab from './ManageLayerTab.vue';

export default {
  name: 'ManagersModal',
  components: {
    Modal,
    ManageLayerTab,
    ManageItemTab,
  },
  props: {
    show: Boolean,
    templateTabs: Array,
    samples: Array,
  },
  setup() {
    const map = inject('map');
    return { map };
  },
  data: () => ({
    currentTab: 'points',
  }),
  computed: {
    ...mapStores(useSampleStore, useLayerModelStore),
    isLayersTabActive() {
      return this.currentTab === 'layers';
    },
    isPointsTabActive() {
      return this.currentTab === 'points';
    },
  },
  methods: {
    ...mapActions(['pushToModalStack']),
    close() {
      this.$emit('close');
    },
    setTab(tab) {
      this.currentTab = tab;
    },
    editSample(sample) {
      if (isItemNonSpatial(sample)) {
        this.sampleStore.addSample(sample);
      }

      this.pushToModalStack({
        type: StackableModalType.SampleModal,
        payload: sample.id,
      });
    },
    goToSample(sample) {
      this.$root.$emit('goToSampleOnMap', sample);
      this.close();
    },
    openSampleVersionControl(sample) {
      this.$root.$emit('setSampleDataToModify', sample);
      this.$emit('showVersionControlModal');
    },
    handleHiddenSubFoldersChange({ sampleGroupId, subFolder }) {
      const sampleGroup =
        this.layerModelStore.findLayerModelById(sampleGroupId);
      const isVisible =
        sampleGroup.hidden_sub_folders.indexOf(subFolder) === -1;
      const samples = this.sampleStore.getScopedSamples({
        sampleGroupId,
        name: subFolder,
      });
      const layerManager = this.map.getLayerManager();
      const sampleLayer = layerManager.findLayerByModelId(sampleGroupId);
      samples.forEach((sample) => {
        if (isVisible) {
          layerManager.addSampleFeature(sampleLayer, sample, false);
        } else {
          layerManager.removeSampleFeature(sampleLayer, sample.id);
        }
      });
    },
  },
};
</script>
<style scoped>
.table {
  border-bottom: hidden;
}

.table tr {
  border-color: #f4f4f4;
}
</style>
