export const supportedLayerTypes = [
  'circle',
  'rectangle',
  'polygon',
  'polyline',
  'image',
  'siteboundary',
  'sample_group',
  'sub_folder',
  'feature_collection',
];

export function getLayerIconByType(type) {
  switch (type) {
    case 'polygon':
      return 'fa-hexagon';
    case 'rectangle':
      return 'fa-square';
    case 'polyline':
      return 'fa-horizontal-rule';
    case 'circle':
      return 'fa-circle';
    case 'image':
      return 'fa-image';
    case 'sample_group':
      return 'fa-map-marker';
  }

  return 'fa-shapes';
}

export type Layer = any;
