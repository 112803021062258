import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x';
import { setAccessList } from '@component-library/feature-manager';
import Vue from 'vue';
import { postAnalyticsUserIdentificationEvent } from '@component-library/analytics';
import makeId from '@component-library/local-id.mjs';

export default function makeAuthConfig() {
  return {
    drivers: {
      auth: driverAuthBearer,
      http: driverHttpAxios,
      router: driverRouterVueRouter,
    },
    options: {
      tokenDefaultKey: 'envirotool',
      stores: ['storage'],
      rolesKey: 'role',
      loginData: {
        url: '/api/auth/login',
        method: 'POST',
        redirect: false,
        fetchUser: false,
      },
      logoutData: {
        url: '/api/auth/logout',
        method: 'POST',
        redirect: false,
        makeRequest: true,
      },
      fetchData: {
        url: '/api/auth/user',
        method: 'POST',
      },
      refreshData: {
        url: '/api/auth/refresh',
        method: 'POST',
        enabled: false,
        interval: 30,
      },
      authRedirect: {
        path: '/login',
      },
      parseUserData: (data) => {
        const user = data.user_data || data.user;

        localStorage.setItem('user', JSON.stringify(user));
        setAccessList(data.access_list || [], user.user_id);
        if (!localStorage.getItem('gather-user-' + user.enad_ref)) {
          localStorage.setItem('gather-user-' + user.enad_ref, makeId());
        }

        user &&
          postAnalyticsUserIdentificationEvent({
            email: user.email,
            enad_ref: user.enad_ref,
            company_name: user.company?.company_name,
            customer_ref: user.customer_ref,
            user_name: user.name,
          });

        return user;
      },
    },
  };
}
