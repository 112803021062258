<script setup>
import makeId from '../local-id.mjs';
import { onBeforeMount, ref, computed } from 'vue';

const props = defineProps({
  label: String,
  placeholder: String,
  name: String,
  modelValue: String | Number,
  value: String | Number,
  errorBag: {
    type: Object,
    default: () => ({}),
  },
  required: {
    type: Boolean,
    default: false,
  },
  isDatalist: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'input']);

const id = ref(props.name);

onBeforeMount(updateId);

const inputError = computed(() => {
  return props.name in props.errorBag
    ? Array.isArray(props.errorBag[props.name])
      ? props.errorBag[props.name][0]
      : props.errorBag[props.name]
    : null;
});

const inputValue = computed({
  get: () => props.modelValue || props.value,
  set: (value) => {
    emit('update:modelValue', value);
    emit('input', value);
  },
});

function updateId() {
  id.value = props.name + '-' + makeId();
}
</script>

<template>
  <div>
    <div class="form-floating">
      <template v-if="isDatalist">
        <input
          class="form-select"
          :class="{ 'is-invalid': inputError }"
          :id="id"
          :required="required"
          :name="name"
          :placeholder="placeholder"
          :disabled="isDisabled"
          v-model="inputValue"
          list="list"
        />
        <slot></slot>
      </template>
      <template v-else>
        <select
          class="form-select"
          :class="{ 'is-invalid': inputError }"
          :id="id"
          :name="name"
          :required="required"
          :placeholder="placeholder"
          :disabled="isDisabled"
          v-model="inputValue"
        >
          <slot></slot>
        </select>
      </template>
      <label :for="id" v-if="label">
        {{ label }} <sup class="text-danger" v-if="required">*</sup>
      </label>
    </div>
    <div class="invalid-feedback" v-if="inputError">
      {{ inputError }}
    </div>
  </div>
</template>
