<template>
  <div>
    <div
      class="modal fade"
      id="cameraModal"
      aria-hidden="true"
      aria-labelledby="cameraModalLabel"
      tabindex="-1"
    >
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-body bg-dark" id="flash">
            <div class="row h-100 align-items-center">
              <div class="col h-100">
                <button
                  class="btn btn-danger top-right"
                  data-bs-dismiss="modal"
                  data-bs-target="#cameraModal"
                  aria-label="Close"
                  @click.prevent="close"
                >
                  <i class="fas fa-times"></i>
                </button>
                <div class="camera">
                  <video id="video" playsinline class="mt-5" width="100%" height="auto" muted>
                    Video stream not available.
                  </video>
                  <span class="dot" @click="takePicture"
                    ><i class="fas fa-camera fa-3x text-white-50 pt-2"></i
                  ></span>
                </div>
                <canvas id="canvas">
                  <div class="output">
                    <img id="photo" />
                  </div>
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal as BootstrapModal } from 'bootstrap';

export default {
  data: () => ({
    width: 1200,
    height: 0,
    streaming: false,
    video: null,
    canvas: null,
    photo: null,
    startButton: null,
    flash: null,
    modal: null,
    stream: null,
    tracks: null,
    trackLength: 0,
    trackIndex: 0,
  }),
  mounted() {
    this.modal = BootstrapModal.getOrCreateInstance('#cameraModal');
    this.modal.show();
    this.flash = document.getElementById('flash');
    this.startup();
    this.$nextTick(() => {
      this.requestCamera();
      this.clearphoto();
    })
  },
  beforeDestroy() {
    this.stream.getTracks()[0].stop();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    requestCamera() {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: 1280,
            height: 720,
            facingMode: 'environment',
          },
          audio: true,
        })
        .then((stream) => {
          this.tracks = stream.getVideoTracks();
          this.trackLength = this.tracks.length - 1;
          this.stream = stream;
          this.stream.removeTrack(this.tracks[0]);
          this.stream.addTrack(this.tracks[this.trackIndex]);
          this.video.srcObject = stream;
          this.video.play();
          this.video.addEventListener(
            'canplay',
            () => {
              if (!this.streaming) {
                this.height =
                  this.video.videoHeight / (this.video.videoWidth / this.width);
                this.canvas.setAttribute('width', this.width);
                this.canvas.setAttribute('height', this.height);
                this.streaming = true;
              }
            },
            false
          );
        })
        .catch((err) => {
          throw err;
        });
    },
    startup() {
      this.video = document.getElementById('video');
      this.canvas = document.getElementById('canvas');
      this.photo = document.getElementById('photo');
    },
    clearphoto() {
      let context = this.canvas.getContext('2d');
      context.fillStyle = '#AAA';
      context.fillRect(0, 0, this.canvas.width, this.canvas.height);

      let data = this.canvas.toDataURL('image/png');
      this.photo.setAttribute('src', data);
    },
    takePicture() {
      this.flash.classList.add('flash');
      this.video.pause();
      const context = this.canvas.getContext('2d');
      this.$emit('isLoading', true);
      if (this.width && this.height) {
        this.canvas.width = this.width;
        this.canvas.height = this.height;
        context.drawImage(video, 0, 0, this.width, this.height);
        this.canvas.toBlob(
          (blob) => {
            photo.setAttribute('src', blob);
            this.$emit('setImage', {
              src: URL.createObjectURL(blob),
              blob,
              type: 'png'
            });
          },
          'image/png',
          0.8
        );
      } else {
        this.clearphoto();
      }
      setTimeout(() => {
        this.video.play();
        this.flash.classList.remove('flash');
      }, 800);
    },
  },
};
</script>

<style scoped>
#video {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  pointer-events: none;
  z-index: 10;
}

#canvas {
  display: none;
}

.camera {
  width: 100%;
  height: 100%;
  max-height: 100%;
  text-align: center;
}

.dot {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  bottom: 7em;
  border-radius: 50%;
  width: 4.5em;
  height: 4.5em;
  background-color: #bbb;
  cursor: pointer;
  z-index: 200 !important;
}

.modal-dialog {
  padding: 0;
}

.modal-content {
  max-height: 100%;
}

.modal-content,
.modal-body {
  max-height: none;
}

.top-right {
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 100;
}

.flash {
  opacity: 1;
  -webkit-animation: flash 1s;
  animation: flash 1s;
}

@-webkit-keyframes flash {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
</style>

