<script>
export default {
  name: 'DepthTestValueInput',
};
</script>

<script setup>
import { checkIsInputValueValueEmpty } from '@component-library/business-logic/input-value';
import { computed, watch } from 'vue';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
  value: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['input']);

const value = computed({
  get() {
    if (!this.value) {
      return '';
    }
    return this.value.value;
  },
  set(value) {
    emit('input', { value, value2: props.value.value2 });
  },
});

const value2 = computed({
  get() {
    if (!this.value) {
      return '';
    }
    return this.value.value2;
  },
  set(value) {
    emit('input', { value: props.value.value, value2: value });
  },
});

watch(
  value,
  () => {
    if (!props.field.options.is_range) {
      return;
    }

    const rangeInterval = parseFloat(props.field.options.range_interval);
    if (!checkIsInputValueValueEmpty(value) && !Number.isNaN(rangeInterval)) {
      value2.value = (Number(props.value.value) + rangeInterval).toFixed(2);
    }
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <div>
    <div class="row">
      <div
        class="form-group mb-2 col-12"
        :class="{
          'col-md-6': field.options.is_range,
        }"
      >
        <label class="form-label" for="startDepth">
          {{ field.options.is_range ? 'Start' : '' }} {{ field.label }}
        </label>
        <input
          type="number"
          :class="{
            'border-danger': field.is_required && (!value || value == ''),
          }"
          :required="field.is_required"
          :step="field.options.range_interval"
          class="form-control"
          v-model="value"
          name="startDepth"
        />
      </div>

      <div
        v-if="field.options.is_range"
        class="form-group mb-2 col-12 col-md-6"
      >
        <label class="form-label" for="endDepth">End {{ field.label }} </label>
        <input
          type="number"
          :class="{
            'border-danger': field.is_required && (!value || value == ''),
          }"
          :required="field.is_required"
          :step="field.options.range_interval"
          class="form-control"
          v-model="value2"
          name="endDepth"
        />
      </div>
    </div>
    <small
      v-if="field.options.unit && field.options.unit != ''"
      class="d-block text-muted"
    >
      All depths are in the unit: <b>{{ field.options.unit }}</b
      >.
    </small>
  </div>
</template>
