import { Map } from 'ol';
import { OSM, XYZ } from 'ol/source';
import createXyzLayer from '../service/createXyzLayer';
import createMapImageServerLayer from '../service/esri/createMapImageServerLayer';
import createVectorTileServerLayer from '../service/esri/createVectorTileServerLayer';
import createGoogleMapsLayer from '../service/google/createGoogleMapsLayer';
import createWmtsLayer from '../service/ogc-opengis/wmts';
import type { Layer } from '../types';
import { getVectorTileServerUrls as config_gb_osvector_getVectorTileServerUrls } from './config/gb/OSVector';
import type {
  BasemapApi,
  BasemapImageServerApi,
  BasemapMapServerApi,
  BasemapVectorTileServerApi,
  BasemapWmtsApi,
  BasemapXyzApi,
  GoogleMapsApi,
  PreprocessBasemapApi,
} from './types';
import { BasemapId } from './types';

declare const google: any;

export default function createBasemapServiceLayer(
  map: Map,
  basemapApi: BasemapApi,
  preprocessBasemapApi: PreprocessBasemapApi = (basemapApi) => basemapApi,
  opacity: number = 1
): Layer {
  let layer;

  basemapApi = preprocessBasemapApi(basemapApi);

  if (
    [BasemapId.GOOGLE_MAPS_ROADMAP, BasemapId.GOOGLE_MAPS_SATELLITE].includes(
      basemapApi.id
    )
  ) {
    const { layerModel } = basemapApi as GoogleMapsApi;
    layer = createGoogleMapsLayer(map, layerModel);
  } else if (
    [
      BasemapId.AU_VIC_CARTOGRAPHIC,
      BasemapId.AU_VIC_OVERLAY,
      BasemapId.AU_VIC_AERIAL,
      BasemapId.AU_SA_AERIAL,
      BasemapId.GB_OS_MAPS_ROAD,
    ].includes(basemapApi.id)
  ) {
    const { layerModel } = basemapApi as BasemapWmtsApi;
    layer = createWmtsLayer(map, layerModel, layerModel.children[0]);
  } else if (
    [
      BasemapId.AU_NSW_AERIAL,
      BasemapId.GB_BRISTOL_AND_SURROUNDING_AREA,
    ].includes(basemapApi.id)
  ) {
    const { layerModel } = basemapApi as BasemapMapServerApi;
    layer = createMapImageServerLayer(map, layerModel);
  } else if (
    [BasemapId.AU_QLD_AERIAL, BasemapId.AU_WA_AERIAL].includes(basemapApi.id)
  ) {
    const { layerModel } = basemapApi as BasemapImageServerApi;
    layer = createMapImageServerLayer(map, layerModel);
  } else if (basemapApi.id === BasemapId.CA_TOPOGRAPHIC) {
    const { layerModel } = basemapApi as BasemapVectorTileServerApi;

    layer = createVectorTileServerLayer(
      map,
      layerModel,
      layerModel.children[0]
    );
  } else if (basemapApi.id === BasemapId.GB_OS_VECTOR) {
    const { layerModel } = basemapApi as BasemapVectorTileServerApi;

    layer = createVectorTileServerLayer(
      map,
      layerModel,
      layerModel.children[0],
      config_gb_osvector_getVectorTileServerUrls
    );
  } else {
    const { layerModel } = basemapApi as BasemapXyzApi;
    const SourceClass = basemapApi.id === BasemapId.OPEN_STREET_MAP ? OSM : XYZ;
    layer = createXyzLayer(map, layerModel, SourceClass);
  }

  layer.setOpacity(opacity);

  return layer;
}
