<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useGatherSchemaStore } from '../../store/gather-schema';
import { useProjectStore } from '../../store/project';

const projectStore = useProjectStore();

const props = defineProps<{
  templateTabId: Number | null;
}>();

const emit = defineEmits<{
  (event: 'setTemplateTabId', value: Number | null): void;
}>();

const gatherAppStore = useGatherSchemaStore();
const { getApps } = gatherAppStore;

const cTemplateTabId = computed({
  get() {
    return props.templateTabId;
  },
  set(value) {
    emit('setTemplateTabId', value);
  },
});

onMounted(() => {
  if (!projectStore.currentProject?.project_id) {
    throw new Error('Project ID is not set');
  }
  getApps(projectStore.currentProject.project_id);
});
</script>
<template>
  <div>
    <label class="d-block mb-1" for="appSelctor">
      Default app for new locations
    </label>

    <select
      class="form-select form-select-sm"
      id="appSelctor"
      v-model="cTemplateTabId"
    >
      <option :value="null">No default app applied</option>
      <option v-for="app in gatherAppStore.apps" :value="app.id">
        {{ app.title }}
      </option>
    </select>
  </div>
</template>
