<script setup lang="ts">
import { computed, nextTick } from 'vue';
import { Sample } from '../../enviro';
import { OfflineSample } from '../../offline-data';
import type { App } from '../../gather';
import { getAppIcon, getAppShareGroupTitle } from '../../business-logic/app';
import SamplePicker from './SamplePicker.vue';

const props = defineProps<{
  app: App;
  samples: (Sample | OfflineSample)[];
  selectedSampleIds: (number | string)[];
  isAppToggled: boolean;
  isAppDropdownToggled: boolean;
  isLoading?: boolean;
  isUpload?: boolean;
}>();

const emit = defineEmits<{
  (e: 'toggleApp', id: number): void;
  (e: 'toggleSample', sample: Sample | OfflineSample): void;
  (e: 'toggleAppDropdown', id: number): void;
}>();

const appSamples = computed(() => {
  return props.samples.filter(
    (s) => s.template_tab_id && s.template_tab_id === props.app.id
  );
});

function toggleAll() {
  if (props.selectedSampleIds.length > 0) {
    const samplesToUntoggled = appSamples.value.filter((s) =>
      props.selectedSampleIds.includes(s.id)
    );

    for (const sample of samplesToUntoggled) {
      emit('toggleSample', sample);
      nextTick();
    }

    return;
  }

  for (const sample of appSamples.value) {
    emit('toggleSample', sample);
    nextTick();
  }
}
</script>

<template>
  <div class="list-group list-group-root">
    <div
      class="list-group-item list-group-item-action d-flex align-items-center justify-content-between"
      @click="emit('toggleAppDropdown', app.id)"
    >
      <span class="d-block fw-medium" @click.stop="emit('toggleApp', app.id)">
        <input
          v-if="!isUpload"
          class="form-check-input me-2 mt-0"
          type="checkbox"
          :checked="isAppToggled"
        />
        <label>
          <div>
            <img v-if="getAppIcon(app)" :src="getAppIcon(app)" class="me-1" />
            <span class="fw-medium">
              {{ getAppShareGroupTitle(app) }}
            </span>
          </div>
        </label>
        <small v-if="isAppDropdownToggled">
          <a
            href="#"
            class="d-inline-block ms-2"
            @click.prevent.stop="toggleAll"
          >
            {{ selectedSampleIds.length > 0 ? 'Untoggle' : 'Toggle' }} All
          </a>
        </small>
      </span>

      <i class="fal fa-chevron-down clickable" />
    </div>

    <SamplePicker
      v-if="isAppDropdownToggled"
      :selectedSampleIds="selectedSampleIds"
      :samples="appSamples"
      :isLoading="isLoading"
      :isUpload="isUpload"
      @toggleSample="emit('toggleSample', $event)"
    />
  </div>
</template>
