<script setup lang="ts">
import { ref } from 'vue';
import { UploadStatus } from '@component-library/file-utils';
import Modal from '@component-library/components/Modal.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import FileUploader from '@component-library/components/FileUploader.vue';
import useAdminOfflineUpload from '@/js/composables/useAdminOfflineUpload';
import { useOfflineStorageManagerStore } from '@/js/composables/useOfflineStorageManager';

const emit = defineEmits(['close']);

const jsonUploadStatus = ref({
  progress: 0,
  pending: false,
  complete: false,
  error: null,
} as UploadStatus);

const adminOfflineUpload = useAdminOfflineUpload();

const jsonFile = ref<File | null>(null);

function setUploadFile(file: File | null) {
  jsonFile.value = file;
}

function close() {
  emit('close');
}

function upload() {
  if (!jsonFile.value) {
    return;
  }
  const reader = new FileReader();

  reader.onload = async (event: any) => {
    const jsonData = JSON.parse(event.target.result);

    const offlineProjects = await adminOfflineUpload.uploadProjectsFromJSON(
      jsonData
    );

    const offlineStorageManager = useOfflineStorageManagerStore();
    offlineStorageManager.setProjects(offlineProjects);

    (window.location as any).reload();
  };

  reader.onerror = (error) => {
    console.error('Error reading file:', error);
  };

  reader.readAsText(jsonFile.value);
}
</script>

<template>
  <Modal :half="true" @close="close" :loading="false" class="offline-manager">
    <template #header>
      <span>What file would you like to import?</span>
    </template>
    <template>
      <FileUploader
        labelText="Offline project file"
        :supportedType="['json']"
        containerId="jsonUpload"
        :pending="jsonUploadStatus.pending"
        :progress="jsonUploadStatus.progress"
        :complete="jsonUploadStatus.complete"
        :error="jsonUploadStatus.error"
        @uploader="setUploadFile"
        class="mb-0"
        :hideIcon="true"
      />
    </template>

    <template #footer>
      <ButtonSpinner
        type="submit"
        class="btn btn-primary w-100 py-3"
        :is-loading="false"
        @click.native.prevent="upload"
      >
        Upload
      </ButtonSpinner>
    </template>
  </Modal>
</template>
