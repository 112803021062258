<script setup lang="ts">
import { ref, useListeners } from 'vue';
import ResponsiveOrSlideUp from './ResponsiveOrSlideUp.vue';
import AutoAssignByConditions from './options/AutoAssignByConditions.vue';
import AutoAssignByLookup from './options/AutoAssignByLookup.vue';
import AutoAssignByLinkedApp from './options/AutoAssignByLinkedApp.vue';
import AutoAssignByExpression from './options/AutoAssignByExpression.vue';
import { GatherField, GatherFieldOptions } from '@component-library/gather';
import { FieldTypeIds } from '@component-library/fields';

const props = defineProps<{
  value: GatherField;
  options: GatherFieldOptions;
  fields: GatherField[];
  mobileSize: boolean;
}>();

const showDefaultOptions = ref((props.options?.defaults?.length || 0) > 0);

const listeners = useListeners() as {
  updateOptions: (options: any[]) => void;
};
</script>

<template>
  <ResponsiveOrSlideUp
    title="Auto Assign"
    v-model="showDefaultOptions"
    :mobileSize="mobileSize"
  >
    <template #body>
      <div v-if="fields.length > 0">
        <AutoAssignByConditions
          :field="props.value"
          :fields="props.fields"
          @updateOptions="listeners.updateOptions"
        />
        <AutoAssignByLookup
          :field="value"
          :fields="fields"
          @updateOptions="listeners.updateOptions"
        />
        <AutoAssignByLinkedApp
          :field="props.value"
          :fields="props.fields"
          @updateOptions="listeners.updateOptions"
        />
        <AutoAssignByExpression
          :field="value"
          :fields="fields"
          @updateOptions="listeners.updateOptions"
        />
      </div> </template
  ></ResponsiveOrSlideUp>
</template>
