<template>
  <div>
    <div
      class="modal fade"
      id="imagePreviewModal"
      aria-hidden="true"
      aria-labelledby="imageModelLabel"
      tabindex="-1"
    >
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-body bg-dark">
            <div class="row h-100">
              <div class="col h-100 d-flex justify-content-center">
                <button
                  class="btn btn-danger top-right"
                  data-bs-dismiss="modal"
                  data-bs-target="#imagePreviewModal"
                  aria-label="Close"
                  @click.prevent="close"
                >
                  <i class="fas fa-times"></i>
                </button>
                <div
                  v-if="loading"
                  class="text-center py-4 d-flex align-items-center"
                >
                  <span class="icon icon-datanest fa-spin"></span>
                </div>
                <img
                  class="align-self-center img-fluid"
                  loading="lazy"
                  decoding="sync"
                  fetchpriority="high"
                  :src="src"
                  @load="loading = false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal as BootstrapModal } from 'bootstrap';

export default {
  props: {
    src: {
      required: true,
    },
  },
  data: () => ({
    loading: true,
  }),
  mounted() {
    this.modal = BootstrapModal.getOrCreateInstance('#imagePreviewModal');
    this.modal.show();
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
#imagePreviewModal {
  top: 56px;
}

.modal-dialog {
  padding: 0;
}

.modal-content {
  max-height: 100%;
}

.modal-content,
.modal-body {
  max-height: none;
}

.top-right {
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 1;
}

.img-fluid {
  max-height: 100% !important;
}

.icon-datanest {
  width: 5em;
  height: 5em;
  mask: url('/images/datanest-logo.svg');
}
.icon {
  display: inline-block;
  background-size: cover;
  background: white;
}
</style>
