<template>
  <div>
    <div class="mb-3">
      <div class="d-flex flex-column h-100">
        <label id="file">
          {{ labelText }}
          <br v-if="labelText" />
          <small class="text-muted">
            Supported types:
            <span class="fw-medium">{{ supportedType.join(', ') }}</span>
          </small>
        </label>

        <form
          class="file_box d-flex align-items-center justify-content-center flex-grow-1 has-dragger mt-2"
          :ref="getActionType('DragRef')"
          :class="{ 'is-dragover': isDraggingOver }"
          :style="{ 'border-color': uploadBorderColour }"
        >
          <div class="dragger-inner w-100">
            <div
              class="upload-icon"
              :class="{ spin: animateIcon }"
              v-show="!hideIcon"
            >
              <i :class="uploadIcon" :style="{ color: uploadIconColour }"></i>
            </div>

            <h6 class="uploader-text mt-1" data-cy="uploader-text">
              {{ uploadText }}
            </h6>

            <div
              class="progress upload-progress mt-3"
              v-if="pending || completedUpload"
            >
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                :style="{ width: progress + '%' }"
              >
                {{ progress }}%
              </div>
            </div>

            <div class="mt-3 mb-3" v-if="isImageUpload && imageUrl != null">
              <img v-if="imageUrl" loading="lazy" :src="imageUrl" width="60%" />
            </div>

            <div
              class="optional mt-3"
              v-if="
                !pending &&
                !completedUpload &&
                (supportsMultiple ? files.length <= 1 : true)
              "
            >
              <span class="me-1">{{ optionalText }}</span>

              <label
                class="btn btn-sm btn-primary browse-files"
                :for="getActionType('Id')"
                @click="removeFile"
                v-show="showBrowseButton"
                :disabled="disableButton"
              >
                browse
              </label>

              <label
                class="btn btn-sm btn-danger remove-file"
                @click.prevent="clearFile"
                v-show="!showBrowseButton"
                :disabled="disableButton"
              >
                {{ deleteButtonText }}
              </label>

              <input
                type="file"
                :id="getActionType('Id')"
                :multiple="supportsMultiple"
                class="form-control-range d-none"
                :ref="getActionType('ClickRef')"
                @change="handleFileUpload($event)"
              />
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="mt-2" v-if="uploads && uploads.length > 0">
      <div class="table-responsive uploads-table custom-scrollbar">
        <table class="table table-condensed">
          <tbody>
            <tr v-for="(upload, uploadIndex) in uploads" :key="uploadIndex">
              <td>
                <div class="d-flex align-items-center justify-content-center">
                  <h6
                    class="fad mb-0"
                    :class="getUploadIconFromType(upload.type)"
                  />
                </div>
              </td>
              <td style="word-break: break-all">
                <span
                  v-if="!upload.is_editing"
                  @dblclick="setEditingUpload(upload)"
                >
                  {{ upload.display_name || upload.file_name }}
                </span>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  :value="upload.display_name || upload.file_name"
                  @keyup.enter="updateFileName($event, upload)"
                  @blur="updateFileName($event, upload)"
                  v-else
                />
              </td>
              <td class="text-center">
                <button
                  class="btn btn-sm btn-danger"
                  @click="$emit('deleteUpload', upload.id)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="supportsMultiple && files.length > 0" class="mt-2">
      <span class="d-block fw-medium mb-2"> Uploads: </span>

      <div class="list-group">
        <div
          v-for="(file, fileIndex) in files"
          class="list-group-item d-flex align-items-center justify-content-between bg-light border-0"
          :class="{
            'mb-2': fileIndex !== files.length - 1,
          }"
        >
          <div class="d-flex align-items-center">
            <h4
              class="fal mb-0 me-3"
              :class="getUploadIconFromType(file.type)"
            />
            <div class="d-flex flex-column">
              <span class="fw-medium">{{ file.name }}</span>
              <small class="text-muted">
                {{ formatFileSize(file.size) }}
              </small>
            </div>
          </div>

          <button
            class="btn btn-sm btn-danger"
            @click.prevent="removeFileByIndex(fileIndex)"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import makeId from '../local-id.mjs';
import { getUploadIconFromType, formatBytes } from '../store/files';

export default {
  name: 'FileUploader',
  props: [
    'supportedType',
    'labelText',
    'pending',
    'error',
    'errorMessage',
    'complete',
    'progress',
    'customCompleteMessage',
    'maxFileSize',
    'uploads',
    'hideIcon',
    'placeholderImage',
    'isImageUpload',
    'dontAutoResetUpload',
    'supportsMultiple',
  ],
  data: () => ({
    dragAndDropCapable: false,
    file: null,
    files: [],
    hasError: false,
    hasUpload: false,
    uploadIcon: 'fad fa-file-upload',
    uploadText: 'Drag & Drop',
    optionalText: 'a file here or',
    showBrowseButton: true,
    disableButton: false,
    deleteButtonText: 'remove',
    animateIcon: false,
    uploadBorderColour: '#c2cdda',
    uploadIconColour: '#3366a2',
    completedUpload: false,
    isDraggingOver: false,
    imageUrl: null,
    containerId: makeId(),
  }),
  watch: {
    pending(newVal) {
      if (!newVal) return;

      this.updateState('file-uploading');
    },
    complete(newVal) {
      if (!newVal) return;

      this.completedUpload = true;

      if (this.customCompleteMessage) {
        this.updateState('file-uploaded-custom');
        this.resetProgress();
        return;
      }

      setTimeout(() => {
        this.updateState('file-uploaded');
        this.resetProgress();
      }, 1500);

      if (!this.dontAutoResetUpload) {
        setTimeout(() => {
          if (this.resetUpload) {
            this.resetUpload = false;
            return;
          }

          this.updateState('awaiting-upload');
        }, 5000);
      }
    },
    error(newVal) {
      if (!newVal) {
        return;
      }

      this.updateState('error', this.errorMessage || this.error);
      this.resetProgress();
    },
    placeholderImage(newVal) {
      this.imageUrl = newVal;
    },
  },
  computed: {
    getMaxFileSize() {
      return this.maxFileSize || 10;
    },
  },
  methods: {
    handleFileUpload(e) {
      this.updateDragAndDrop(this.$refs[this.getActionType('ClickRef')].files);
    },
    updateState(state, message) {
      this.animateIcon = false;
      this.disableButton = false;

      switch (state) {
        case 'awaiting-upload':
          this.showBrowseButton = true;
          this.uploadBorderColour = '#c2cdda';
          this.uploadIconColour = '#3366a2';
          this.uploadText = 'Drag & Drop';
          this.deleteButtonText = 'remove';
          this.uploadIcon = 'fad fa-file-upload';
          break;
        case 'file-ready':
          this.showBrowseButton = false;
          this.uploadBorderColour = '#3366a2';
          this.uploadIconColour = '#3366a2';
          if (this.files.length > 1) {
            this.uploadText =
              [...this.files].map((f) => f.name).join(', ') + ' is ready.';
          } else {
            this.uploadText = this.file.name + ' is ready.';
          }
          this.deleteButtonText = 'Remove File';
          this.uploadIcon = this.getFileTypeIcon(this.file.name);
          break;
        case 'file-uploading':
          this.showBrowseButton = false;
          this.uploadBorderColour = '#3366a2';
          this.uploadIconColour = '#3366a2';
          this.uploadText = 'Uploading';
          this.uploadIcon = 'fad fa-spinner';
          this.animateIcon = true;
          this.disableButton = true;
          break;
        case 'file-uploaded':
          this.showBrowseButton = false;
          this.uploadBorderColour = '#3366a2';
          this.uploadIconColour = '#3366a2';
          this.uploadText = 'File has been uploaded.';
          this.deleteButtonText = 'clear';
          this.uploadIcon = 'fad fa-check';
          break;
        case 'file-uploaded-custom':
          this.showBrowseButton = false;
          this.uploadBorderColour = '#138496';
          this.uploadIconColour = '#138496';
          this.uploadText = this.customCompleteMessage;
          this.deleteButtonText = 'clear';
          this.uploadIcon = 'fad fa-check';
          break;
        case 'error':
          this.showBrowseButton = true;
          this.uploadBorderColour = '#dc3545';
          this.uploadIconColour = '#dc3545';
          this.uploadText = message;
          this.deleteButtonText = 'remove';
          this.uploadIcon = 'fad fa-file-upload';
          break;
      }

      this.optionalText =
        this.hasUpload && !this.hasError
          ? 'use'
          : this.showBrowseButton
          ? 'a file here or'
          : 'or';
    },
    clearFile() {
      this.resetUpload = true;

      this.removeFile();
    },
    removeFile() {
      if (!this.file) {
        this.updateState('awaiting-upload');
        return;
      }

      this.files = [];
      this.file = null;

      this.$emit('uploader', this.file);

      this.$refs[this.getActionType('ClickRef')].files = null;
      this.$refs[this.getActionType('ClickRef')].value = null;
      this.$refs[this.getActionType('DragRef')].files = null;
      this.imageUrl = null;

      this.hasUpload = false;

      this.updateState('awaiting-upload');
    },
    removeFileByIndex(index) {
      this.files.splice(index, 1);

      if (this.files.length > 0) {
        this.$emit('uploader', null);
        this.file = this.files[0];
        for (let i = 0; i < this.files.length; i++) {
          this.$emit('uploader', this.files[i]);
        }
        this.updateState('file-ready');
      } else {
        this.removeFile();
      }
    },
    updateDragAndDrop(files) {
      if (files.length == 0) {
        return;
      }

      if (!this.supportsMultiple && files.length > 1) {
        this.hasError = true;
        this.updateState(
          'error',
          'You must only upload one file at a time, try again.'
        );
        return;
      }

      if (!this.supportsMultiple) {
        this.files = [];
      }

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > this.getMaxFileSize * 1000000) {
          // Convert MB to B
          this.updateState(
            'error',
            'File too large. Maximum supported size: ' +
              this.getMaxFileSize +
              'MB'
          );
          return;
        }

        if (!this.validateFileType(files[i])) {
          this.updateState(
            'error',
            'You must use one of these file types "' +
              this.supportedType +
              '", try again.'
          );
          return;
        }
      }

      this.files = [...this.files, ...files];
      this.file = files[0];

      this.updateState('file-ready');

      this.imageUrl = URL.createObjectURL(this.file);

      for (let i = 0; i < files.length; i++) {
        this.$emit('uploader', files[i]);
      }

      this.hasUpload = true;
    },
    hasDragOver(dragOver) {
      if (!dragOver) {
        this.isDraggingOver = false;
        return;
      }
      this.isDraggingOver = true;
    },
    validateFileType(file) {
      let fileExtension = file.name
        .substr(file.name.lastIndexOf('.') + 1)
        .toLowerCase();

      if (this.supportedType.indexOf(fileExtension) == -1) {
        return false;
      }

      return true;
    },
    getFileTypeIcon(filename) {
      let type =
        filename.substring(filename.lastIndexOf('.') + 1, filename.length) ||
        filename;

      switch (type) {
        case 'csv':
          return 'fas fa-file-csv';
        case 'xlsx':
        case 'xls':
          return 'fas fa-file-excel';
        case 'png':
        case 'jpeg':
        case 'jpg':
          return 'fas fa-file-image';
        case 'zip':
          return 'fas fa-file-archive';
      }
      return 'fas fa-file';
    },
    getActionType(type) {
      return this.containerId + '' + type;
    },
    resetProgress() {
      this.completedUpload = false;
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div');

      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    },
    setEditingUpload(upload) {
      this.$set(upload, 'is_editing', true);
    },
    updateFileName(event, upload) {
      this.$emit('updateFileName', {
        upload,
        file_name: event.target.value.replace(/\s/g, ''),
      });
    },
    getUploadIconFromType(type) {
      return getUploadIconFromType(type);
    },
    formatFileSize(bytes) {
      return formatBytes(bytes);
    },
  },
  mounted() {
    this.imageUrl = this.placeholderImage;

    this.dragAndDropCapable = this.determineDragAndDropCapable();

    let draggedCounter = 0;

    if (this.dragAndDropCapable) {
      [
        'drag',
        'dragstart',
        'dragend',
        'dragover',
        'dragenter',
        'dragleave',
        'drop',
      ].forEach((event) => {
        this.$refs[this.getActionType('DragRef')].addEventListener(
          event,
          (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
          false
        );
      });

      this.$refs[this.getActionType('DragRef')].addEventListener(
        'drop',
        (e) => {
          if (this.pending) {
            return;
          }

          if (this.file) {
            this.resetUpload = true;
          }

          let files = e.dataTransfer.files;
          draggedCounter = 0;

          this.updateDragAndDrop(files);
          this.hasDragOver(false);
        }
      );

      this.$refs[this.getActionType('DragRef')].addEventListener(
        'dragenter',
        (e) => {
          if (this.pending) {
            return;
          }

          draggedCounter++;

          this.hasDragOver(true);
        }
      );

      this.$refs[this.getActionType('DragRef')].addEventListener(
        'dragleave',
        (e) => {
          if (this.pending) {
            return;
          }

          draggedCounter--;

          if (draggedCounter === 0) {
            this.hasDragOver(false);

            if (this.hasError) {
              this.hasError = false;
            }
          }
        }
      );

      if (this.complete) {
        this.updateState('file-uploaded');
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.uploads-table {
  max-height: 88px;
  overflow-y: auto;
}
</style>
