<template>
  <div
    v-if="loadingCocs"
    class="d-flex align-items-center justify-content-center flex-column text-center w-100 h-100"
  >
    <i class="spinner-border spinner-border-lg"></i>
    <small class="text-muted mt-2 d-block">Loading chain of custodies...</small>
  </div>
  <div class="col-12 py-4 h-100" v-else>
    <template v-if="!hasSamples">
      <div
        class="text-center h-100 d-flex align-items-center justify-content-center flex-column col-12 col-lg-8 offset-lg-2"
      >
        <h1 class="fad fa-chart-bar"></h1>
        <h6>It appears that no enviro samples exist.</h6>
        <p class="text-muted mb-0">
          It's very easy to get started, simply go to your template and add the
          sample section.
        </p>
        <router-link
          :to="`/template/${project.project_id}/editor`"
          class="btn btn-primary mt-3"
        >
          Go To App Editor
        </router-link>
      </div>
    </template>
    <template v-else-if="coc">
      <div class="col-12 col-xl-10 offset-xl-1 pb-3 h-100">
        <Breadcrumb :routes="['coc_editor']" class="mb-4" />

        <coc-selector
          :cocs="cocs"
          :selectedCoc="coc"
          :selectCoc="selectCoc"
          :createCoc="createCoc"
          :deleteCoc="(id) => (cocIdToDelete = id)"
          class="mb-3"
        />

        <div
          v-if="loadingCoc"
          class="py-4 d-flex align-items-center justify-content-center flex-column text-center w-100 h-100"
        >
          <i class="spinner-border spinner-border-lg"></i>
          <small class="text-muted mt-2 d-block">
            Loading chain of custody...
          </small>
        </div>
        <template v-else>
          <div class="row">
            <div class="col-12 col-md-4 mb-3 mb-md-0">
              <h6>General</h6>
              <p class="mb-0 text-muted">
                These fields will be used to populate your chain of custody
                form, these can be edited manually in the PDF on export.
              </p>
            </div>
            <div class="col-12 col-md-8">
              <coc-form
                :coc="coc"
                :laboratories="laboratories"
                :users="companyUsers"
              />
            </div>
          </div>

          <hr class="mb-4" />

          <coc-table ref="coc-table" :samples="coc.samples" />

          <hr />

          <div class="d-flex justify-content-end pb-3">
            <button-spinner
              type="submit"
              :is-loading="updating"
              class="btn btn-secondary me-2"
              @click.native="updateCoc"
            >
              Update CoC
            </button-spinner>
            <button
              :disabled="!coc.laboratory_id"
              type="submit"
              class="btn btn-primary"
              @click="() => (showGenerateCocModal = true)"
            >
              <i class="fas fa-print"></i> Generate CoC
            </button>
            <button
              v-if="coc && coc.pdf.length > 0"
              class="btn btn-dark ms-2"
              @click="showPublishModal = true"
            >
              <i class="fas fa-link" />
              {{ coc.published ? 'Manage Published' : 'Publish' }} CoC
            </button>
          </div>
        </template>
      </div>

      <generate-coc-modal
        :show="showGenerateCocModal"
        @close="() => (showGenerateCocModal = false)"
        :generating="generatingCoc"
        @generateCoc="generateCoc"
      />

      <publish-modal
        :show="showPublishModal"
        :coc="coc"
        @close="() => (showPublishModal = false)"
        @updateCoc="updateCocPublished"
      />

      <notify-modal
        :show="cocIdToDelete != null"
        :loading="deleting"
        @close="() => (cocIdToDelete = null)"
        @submit="deleteCoc"
        headerMessage="Are you sure you would like to delete this COC?"
        :isDelete="true"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import api from './api/index.js';

import Breadcrumb from '@/js/components/Breadcrumb.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import NotifyModal from '@/js/components/NotifyModal.vue';

import CocSelector from './components/CocSelector.vue';
import CocForm from './components/CocForm.vue';
import CocTable from './components/CocTable.vue';
import GenerateCocModal from './components/GenerateCocModal.vue';
import PublishModal from './components/PublishModal.vue';

export default {
  data: () => ({
    loadingCocs: false,
    loadingCoc: false,
    updating: false,
    creating: false,

    cocs: [],
    hasSamples: false,
    coc: null,
    laboratories: [],
    companyUsers: [],

    generatingCoc: false,
    showGenerateCocModal: false,
    showPublishModal: false,

    cocIdToDelete: null,
    deleting: false,
  }),
  components: {
    Breadcrumb,
    ButtonSpinner,
    NotifyModal,
    CocSelector,
    CocForm,
    CocTable,
    GenerateCocModal,
    PublishModal,
  },
  computed: {
    ...mapState({
      project: (state) => state.project,
    }),
  },
  methods: {
    async getCoc(coc) {
      this.loadingCoc = true;

      try {
        const { data } = await api.getCoc(coc.id);

        this.coc = data.coc;

        this.laboratories = data.laboratories;
        this.companyUsers = data.users;
      } catch (e) {
        this.$toastStore.error('Failed to load chain of custody, try again.');
        throw e;
      } finally {
        this.loadingCoc = false;
      }
    },
    selectCoc(coc) {
      if (this.coc.id == coc.id) {
        return;
      }

      this.coc = coc;
      this.getCoc(coc);
    },
    async createCoc() {
      this.creating = true;

      try {
        const { data } = await api.createCoc();

        this.cocs.unshift(data.coc);
        this.coc = data.coc;

        this.getCoc(data.coc);
      } catch (e) {
        this.$toastStore.error('Failed to create chain of custody, try again.');
        throw e;
      } finally {
        this.creating = false;
      }
    },
    async updateCoc() {
      this.updating = true;

      return await api
        .updateCoc(this.coc)
        .catch((e) => {
          this.$toastStore.error(
            'Failed to update chain of custody, try again.'
          );

          throw e;
        })
        .finally(() => {
          this.updating = false;
        });
    },
    async deleteCoc() {
      this.deleting = true;

      try {
        await api.deleteCoc(this.cocIdToDelete);

        this.cocs.splice(
          this.cocs.findIndex((c) => c.id == this.cocIdToDelete),
          1
        );

        this.coc = this.cocs[0] || null;
        if (this.coc) {
          this.getCoc(this.coc);
        }

        this.cocIdToDelete = null;
      } catch (e) {
        this.$toastStore.error('Failed to delete chain of custody, try again.');
        throw e;
      } finally {
        this.deleting = false;
      }
    },
    sendCocToLaboratory() {
      this.generatingCoc = true;

      api
        .sendCocToLab(this.coc.id, {
          rows_to_use: this.$refs['coc-table'].samplesToGenerate,
        })
        .then((response) => {
          window.open(response.data.url);

          this.showGenerateCocModal = false;
        })
        .catch(() => {
          this.$toastStore.error(
            'Failed to send email to laboratory, try again.'
          );
        })
        .finally(() => {
          this.generatingCoc = false;
        });
    },
    async generateCoc(sendToLab = false) {
      await this.updateCoc();

      if (sendToLab) {
        this.sendCocToLaboratory();
        return;
      }

      this.generatingCoc = true;

      api
        .generateCoc(this.coc.id, {
          rows_to_use: this.$refs['coc-table'].samplesToGenerate,
        })
        .then(({ data }) => {
          this.coc.pdf.push(data.pdf);
          window.open(data.url);

          this.showGenerateCocModal = false;
        })
        .catch(() => {
          this.$toastStore.error('Failed to print chain of custody.');
        })
        .finally(() => {
          this.generatingCoc = false;
        });
    },
    updateCocPublished(data) {
      this.coc = {
        ...this.coc,
        ...data,
      };
    },
  },
  async mounted() {
    this.loadingCocs = true;

    try {
      const { data } = await api.getCocs();

      this.cocs = data.cocs;
      this.hasSamples = data.has_samples;

      if (this.hasSamples && this.cocs.length > 0) {
        await this.getCoc(this.cocs[0]);
        return;
      }

      if (this.hasSamples && this.cocs.length == 0) {
        await this.createCoc();
      }
    } catch (e) {
      this.$toastStore.error('Failed to get Chain of Custody template.');
      throw e;
    } finally {
      this.loadingCocs = false;
    }
  },
};
</script>
