<script setup lang="ts">
import { computed } from 'vue';

import { OfflineProject } from '@component-library/offline-data';
import { FailedSampleUpload } from './Modal.vue';

const props = defineProps<{
  project: OfflineProject;
  currentSample: number;
  totalSamples: number;
  failedSampleUploads: FailedSampleUpload[];
  isUploading: boolean;
  isFinished: boolean;
}>();

const uploadProgress = computed(() => {
  return Math.round((props.currentSample / props.totalSamples) * 100);
});
</script>
<template>
  <div>
    <div class="d-flex align-items-center w-100 mb-2">
      <div class="pe-3 flex-fill overflow-hidden">
        <span class="d-block fw-medium overflow-hidden mb-0">
          {{
            project.project_number +
            (project.project_name ? ' - ' + project.project_name : '')
          }}
        </span>
        <small class="d-block mb-1">{{ project.project_client }}</small>
      </div>
    </div>

    <div class="progress position-relative" style="height: 40px">
      <div
        class="progress-bar"
        :style="{
          width: uploadProgress + '%',
        }"
      >
        <div
          class="d-flex align-items-center justify-content-center fw-medium position-absolute w-100"
        >
          <template v-if="isFinished">
            <i class="fas fa-fw fa-check me-2"></i>
            Finished Uploading
          </template>
          <template v-else>
            <i class="spinner-border spinner-border-sm me-2" />
            {{ currentSample }}/{{ totalSamples }} samples
          </template>
        </div>
      </div>
    </div>

    <template v-if="failedSampleUploads.length > 0">
      <hr />

      <h6 class="mb-3">Issues recognized on upload:</h6>

      <ul class="text-danger mb-3">
        <li v-for="{ sample, error } in failedSampleUploads">
          <span class="fw-medium">
            {{ sample.custom_title }} failed to be uploaded.
          </span>
          {{ error }}
        </li>
      </ul>
    </template>
  </div>
</template>
