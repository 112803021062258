export default function useAdminOfflineUpload() {
  function base64ToBlob(base64: string, mimeType: string) {
    const byteCharacters = atob(base64);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
  }

  // Recursive function to replace Base64 strings with Blob objects
  async function convertBase64ToBlobs(obj) {
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        obj[i] = await convertBase64ToBlobs(obj[i]);
      }
    } else if (obj && typeof obj === 'object') {
      if (obj.base64 && obj.mimeType) {
        return base64ToBlob(obj.base64, obj.mimeType);
      } else {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            obj[key] = await convertBase64ToBlobs(obj[key]);
          }
        }
      }
    }
    return obj;
  }

  // Function to upload projects from JSON to localForage
  const uploadProjectsFromJSON = async (jsonData: any) => {
    const projectsWithBlobs = await convertBase64ToBlobs(jsonData);
    return projectsWithBlobs;
  };

  return {
    uploadProjectsFromJSON,
  };
}
