<script setup lang="ts">
import Modal from '@/js/components/Modal.vue';
import type { Dictionary } from '@component-library/business-model/auto-assign';
import { useToastStore } from '@component-library/store/toasts';
import { defineAsyncComponent, ref, watch } from 'vue';
import { GatherField } from '@component-library/gather';

const ExcelWorksheetReader = defineAsyncComponent(
  () => import('../../ExcelWorksheetReader.vue')
);

const props = defineProps<{ field: GatherField; fields: GatherField[] }>();

const emit = defineEmits(['close', 'OK']);

const toastStore = useToastStore();

const ewr = ref();

const FIELD_ID_PLACEHOLDER = 0;
const selectedFieldIds = ref<number[]>([]);

function getColumnFields(headerIndex: number): GatherField[] {
  const allFields = [...props.fields, props.field];
  const selectedField = allFields.find(
    (f) => f.id === selectedFieldIds.value[headerIndex]
  );
  const unusedFields = allFields.filter(
    (f) => !selectedFieldIds.value.includes(f.id)
  );
  return selectedField ? [selectedField, ...unusedFields] : unusedFields;
}

function checkIsFieldSelected(headerIndex: number): boolean {
  return selectedFieldIds.value[headerIndex] !== FIELD_ID_PLACEHOLDER;
}

function checkIsTargetField(fieldId: number): boolean {
  return props.field.id === fieldId;
}

function checkIsTargetFieldSelected(headerIndex: number): boolean {
  return selectedFieldIds.value[headerIndex] === props.field.id;
}

function handleClose() {
  emit('close');
}

function handleOK() {
  if (!selectedFieldIds.value.includes(props.field.id)) {
    toastStore.error(
      `Please select the '${props.field.label}' field for a target column.`
    );
    return;
  }

  if (
    selectedFieldIds.value.filter((sfId) => sfId !== FIELD_ID_PLACEHOLDER)
      .length < 2
  ) {
    toastStore.error('Please select a field for a source column.');
    return;
  }

  const sourceColumnIndices = selectedFieldIds.value.reduce(
    (accu, sfId, sfIdIndex) => {
      if (sfId !== FIELD_ID_PLACEHOLDER && sfId !== props.field.id) {
        accu.push(sfIdIndex);
      }
      return accu;
    },
    [] as number[]
  );
  const sourceFieldIds = sourceColumnIndices.map(
    (scIndex) => selectedFieldIds.value[scIndex]
  );
  const targetColumnIndex = selectedFieldIds.value.findIndex(
    (sfId) => sfId === props.field.id
  );
  const dictionary: Dictionary = [];
  for (let i = 0; i < ewr.value!.dataRows.length; i++) {
    const dr = ewr.value!.dataRows[i];
    const entry = sourceColumnIndices.map((scIndex) => dr[scIndex]);
    entry.push(dr[targetColumnIndex]);
    dictionary.push(entry);
  }
  emit('OK', { sourceFieldIds, dictionary });
}

function handleFieldChange(evt: Event, headerIndex: number) {
  const fieldId = parseInt((evt.target as HTMLSelectElement).value, 10);
  selectedFieldIds.value.splice(headerIndex, 1, fieldId);
}

function handleFieldUnselect(headerIndex: number) {
  selectedFieldIds.value.splice(headerIndex, 1, FIELD_ID_PLACEHOLDER);
}

watch(
  () => ewr.value?.headerRow ?? [],
  (newValue) => {
    selectedFieldIds.value = newValue.map(() => FIELD_ID_PLACEHOLDER);
  }
);
</script>

<template>
  <Modal
    class="auto-assign-by-lookup-config-modal"
    :class="{
      'auto-assign-by-lookup-config-modal-with-rows': ewr?.dataRows.length,
    }"
    :show="true"
    :half="true"
    @close="handleClose"
  >
    <template #header>Configure the dictionary</template>
    <ExcelWorksheetReader
      ref="ewr"
      class="flex-grow-1 d-flex flex-column p-2"
      :style="{ 'min-height': '0px', overflow: 'auto' }"
    >
      <template #worksheetContainer>
        <div
          class="flex-grow-1 d-flex flex-column mb-2"
          :style="{ 'min-height': '0px', overflow: 'auto' }"
        >
          <label class="form-label">Columns</label>
          <div class="flex-grow-1 table-container">
            <table class="table table-bordered">
              <colgroup>
                <col style="width: 40%" />
                <col style="width: 60%" />
              </colgroup>
              <thead>
                <tr>
                  <th class="header">Column</th>
                  <th class="header">Field</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(header, hIndex) in ewr?.headerRow"
                  :key="`header-${hIndex}`"
                  :class="{
                    'bg-primary': checkIsFieldSelected(hIndex),
                    'bg-success': checkIsTargetFieldSelected(hIndex),
                  }"
                  :style="
                    checkIsFieldSelected(hIndex)
                      ? {
                          '--bs-bg-opacity': 0.2,
                        }
                      : {}
                  "
                >
                  <td>{{ header }}</td>
                  <td>
                    <div class="input-group">
                      <select
                        class="form-control"
                        :value="
                          selectedFieldIds[hIndex] ?? FIELD_ID_PLACEHOLDER
                        "
                        :style="{ 'font-family': 'FontAwesome, Poppins' }"
                        :title="
                          checkIsTargetFieldSelected(hIndex)
                            ? 'This field is the target field.'
                            : ''
                        "
                        @change="handleFieldChange($event, hIndex)"
                      >
                        <option :value="FIELD_ID_PLACEHOLDER" disabled>
                          --Please select a field--
                        </option>
                        <option
                          v-for="f in getColumnFields(hIndex)"
                          :key="`field-${f.id}`"
                          :value="f.id"
                        >
                          {{ f.label }}
                          <template v-if="checkIsTargetField(f.id)">
                            &#xf140;
                          </template>
                        </option>
                      </select>

                      <button
                        class="btn btn-danger"
                        type="button"
                        @click.prevent="handleFieldUnselect(hIndex)"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary me-2" @click="handleClose">
            Cancel
          </button>
          <button class="btn btn-primary" @click="handleOK">OK</button>
        </div>
      </template>
    </ExcelWorksheetReader>
  </Modal>
</template>

<style lang="scss" scoped>
.auto-assign-by-lookup-config-modal {
  .table-container {
    min-height: 0px;
    overflow: auto;
    cursor: pointer;

    .header {
      position: sticky;
      top: 0;
      z-index: 3;
    }
  }

  &-with-rows {
    :deep(.modal-content) {
      max-height: 80%;
    }
  }
}
</style>
