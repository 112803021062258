<script setup lang="ts">
import { computed, watch } from 'vue';
import type { ArrowHead } from '../../../business-logic/mapping/arrow';
import { RED } from '../../../business-logic/mapping/color';
import ColorChooser from '../ColorChooser.vue';
import IconButton from '../IconButton.vue';
import Slider from '../../Slider.vue';
import Toggle from '../Toggle.vue';
import { injectContext } from './context';

const { getShapeProperty, setShapeProperty } = injectContext();

const arrowHeadSize = computed<number>(() => {
  const arrowHeadSize = getShapeProperty('arrowHeadSize', 10);
  return typeof arrowHeadSize === 'string'
    ? parseInt(arrowHeadSize, 10)
    : arrowHeadSize;
});
const isArrowHeadBackward = computed<boolean>(() =>
  getShapeProperty('isArrowHeadBackward', false)
);
const arrowHeads = computed<ArrowHead[]>(() =>
  getShapeProperty('arrowHeads', [])
);

function formatPosition(position: number): string {
  return (position * 100).toFixed(0);
}

function handleArrowHeadAdd() {
  const newArrowHeads: ArrowHead[] = [
    ...arrowHeads.value,
    {
      size: arrowHeadSize.value,
      isBackward: isArrowHeadBackward.value,
      color: RED,
      position: 0.2,
    },
  ];
  setShapeProperty('arrowHeads', newArrowHeads);
}

function handleArrowHeadDelete(index) {
  const newArrowHeads = [...arrowHeads.value];
  newArrowHeads.splice(index, 1);
  setShapeProperty('arrowHeads', newArrowHeads);
}

function handleArrowHeadChange(index, name, value) {
  const newArrowHeads = [...arrowHeads.value];
  newArrowHeads.splice(index, 1, {
    ...arrowHeads.value[index],
    [name]: value,
  });
  setShapeProperty('arrowHeads', newArrowHeads);
}

watch(arrowHeadSize, (newValue) => {
  const newArrowHeads = arrowHeads.value.map((ah) => ({
    ...ah,
    size: newValue,
  }));
  setShapeProperty('arrowHeads', newArrowHeads);
});

watch(isArrowHeadBackward, (newValue) => {
  const newArrowHeads = arrowHeads.value.map((ah) => ({
    ...ah,
    isBackward: newValue,
  }));
  setShapeProperty('arrowHeads', newArrowHeads);
});
</script>

<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <label class="form-label">Arrow Heads</label>
      <IconButton @click.native="handleArrowHeadAdd"
        ><i class="fas fa-plus"
      /></IconButton>
    </div>

    <div class="card p-2">
      <Slider
        name="arrowheadsize"
        label="Size"
        :value="arrowHeadSize"
        units="pt"
        :min="4"
        :max="16"
        :step="1"
        @input="(value) => setShapeProperty('arrowHeadSize', value)"
      />

      <Toggle
        name="isArrowHeadBackward"
        label="Is backward?"
        :checked="isArrowHeadBackward"
        @change="(checked) => setShapeProperty('isArrowHeadBackward', checked)"
      />

      <div v-if="arrowHeads.length > 0" class="list-group">
        <div
          v-for="(arrowHead, ahIndex) in arrowHeads"
          :key="`arrow-head-${ahIndex}`"
          class="list-group-item d-flex gap-3 align-items-center"
        >
          <div>
            <ColorChooser
              class="mb-3"
              :value="arrowHead.color"
              @input="(value) => handleArrowHeadChange(ahIndex, 'color', value)"
            />
            <Slider
              name="position"
              label="Position"
              units="% of length"
              :value="arrowHead.position"
              :formatValue="formatPosition"
              :min="0"
              :max="1"
              :step="0.01"
              @input="
                (value) =>
                  handleArrowHeadChange(ahIndex, 'position', parseFloat(value))
              "
            />
          </div>
          <IconButton
            class="flex-shrink-0"
            @click.native="handleArrowHeadDelete(ahIndex)"
            ><i class="fas fa-minus"
          /></IconButton>
        </div>
      </div>
    </div>
  </div>
</template>
