<script>
export default {
  name: 'TextValueContentInput',
};
</script>

<script setup>
import { computed, onMounted } from 'vue';

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(['input']);

const text = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit('input', value);
  },
});

onMounted(() => {
  if (!text.value) {
    text.value = '';
  }
});
</script>

<template>
  <input type="text" class="form-control form-control-sm" v-model="text" />
</template>
