import type { Item } from '@component-library/gather';
import type { Pid } from '@maps/lib/olbm/types';

export enum StackableModalType {
  SampleModal = 1,
  ManagersModal = 2,
  VersionControlModal = 3,
  DeleteSampleModal = 4,
  DeletePlainShapeModal = 5,
  AlertUnsavedItemModal = 6,
}
export type StackableModal<T extends StackableModalType, P> = {
  type: T;
  payload: P;
};
export type SampleModal = StackableModal<
  StackableModalType.SampleModal,
  // Item id or a function to create a new item
  number | Function
>;
export type ManagersModal = StackableModal<
  StackableModalType.ManagersModal,
  null
>;
export type VersionControlModal = StackableModal<
  StackableModalType.VersionControlModal,
  Item
>;
export type DeleteSampleModal = StackableModal<
  StackableModalType.DeleteSampleModal,
  Item
>;
export type DeletePlainShapeModal = StackableModal<
  StackableModalType.DeletePlainShapeModal,
  Pid
>;
export type AlertUnsavedItemModalPayload = {
  projectId: number;
  projectTitle: string;
  unsavedItem: {
    title: string;
    numberOfValues: number;
  };
  isAuthorized: boolean;
};
export type AlertUnsavedItemModal = StackableModal<
  StackableModalType.AlertUnsavedItemModal,
  AlertUnsavedItemModalPayload
>;
export type ModalStackItem =
  | SampleModal
  | ManagersModal
  | VersionControlModal
  | DeleteSampleModal
  | DeletePlainShapeModal
  | AlertUnsavedItemModal;
export type ModalStack = ModalStackItem[];

export function checkIsSampleModal(
  modal: ModalStackItem
): modal is SampleModal {
  return modal.type === StackableModalType.SampleModal;
}

export function checkIsManagersModal(
  modal: ModalStackItem
): modal is ManagersModal {
  return modal.type === StackableModalType.ManagersModal;
}

export function checkIsVersionControlModal(
  modal: ModalStackItem
): modal is VersionControlModal {
  return modal.type === StackableModalType.VersionControlModal;
}

export function checkIsDeleteSampleModal(
  modal: ModalStackItem
): modal is DeleteSampleModal {
  return modal.type === StackableModalType.DeleteSampleModal;
}

export function checkIsDeletePlainShapeModal(
  modal: ModalStackItem
): modal is DeletePlainShapeModal {
  return modal.type === StackableModalType.DeletePlainShapeModal;
}

export function checkIsAlertUnsavedItemModal(
  modal: ModalStackItem
): modal is AlertUnsavedItemModal {
  return modal.type === StackableModalType.AlertUnsavedItemModal;
}

export function checkIsAtTop(
  stack: ModalStack,
  modal: ModalStackItem
): boolean {
  if (stack.length === 0) {
    return false;
  }

  const topModal = stack[stack.length - 1];
  if (modal.type !== topModal.type) {
    return false;
  }

  if (checkIsSampleModal(modal)) {
    return (topModal as SampleModal).payload === modal.payload;
  } else if (checkIsManagersModal(modal)) {
    return true;
  } else if (checkIsVersionControlModal(modal)) {
    return (topModal as VersionControlModal).payload.id === modal.payload.id;
  } else if (checkIsDeleteSampleModal(modal)) {
    return (topModal as DeleteSampleModal).payload.id === modal.payload.id;
  } else if (checkIsDeletePlainShapeModal(modal)) {
    return (topModal as DeletePlainShapeModal).payload === modal.payload;
  } else if (checkIsAlertUnsavedItemModal(modal)) {
    return (
      (topModal as AlertUnsavedItemModal).payload.projectId ===
      modal.payload.projectId
    );
  } else {
    return modal;
  }
}

export function getTopModal(stack: ModalStack): ModalStackItem | undefined {
  return stack.length > 0 ? stack[stack.length - 1] : undefined;
}

export function getPreviousModal(
  stack: ModalStack,
  modal: ModalStackItem
): ModalStackItem | undefined {
  const index = stack.findLastIndex(
    (msi) => msi.type === modal.type && msi.payload === modal.payload
  );
  const previousIndex = index - 1;
  return previousIndex >= 0 ? stack[previousIndex] : undefined;
}
