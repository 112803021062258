<template>
  <div style="display: contents; max-width: 100%; max-height: 100%">
    <ViewHeader v-if="!isNonSpatialView" :project="project" />

    <NonSpatialEditor
      v-if="isNonSpatialView"
      ref="non-spatial"
      :templateTabs="templateTabs"
      @showProjectInfoModal="() => (showProjectInfoModal = true)"
      @showDeleteSampleModal="handleDeleteSampleModalShow"
      @showVersionControlModal="handleVersionControlModalShow"
      @startNonSpatial="startNonSpatial"
      @changeViewType="changeViewType"
      class="mt-3"
    />

    <div v-if="!isNonSpatialView" class="flex-grow-1 d-flex flex-column">
      <IndeterminateProgressBar v-if="collectionStore.isBusy" />

      <Map
        ref="map"
        class="flex-grow-1"
        :center="figureCenter"
        :basemap-id="this.basemapId"
        :apps="templateTabs"
        :projection="projection"
        @mounted="handleMapMounted"
        @unmounted="handleMapUnmounted"
        @feature-click="handleFeatureClick"
        @geolocation-enabled="handleGeolocationEnabled"
        @geolocation-disabled="handleGeolocationDisabled"
        @geolocation-error="handleGeolocationError"
        @poi-add="handlePoiAdd"
        @poi-lonlat-change="handlePoiLonLatChange"
        @poi-delete="handlePoiDelete"
      />
    </div>

    <template v-if="project">
      <MapActionMenu
        v-if="!isNonSpatialView"
        :isModifying="isMoving || isDrawing || isEditing || isDuplicating"
        :templateTabs="templateTabs"
        :samples="sampleStore.samples"
        :isTrackingAvailable="isTrackingAvailable"
        :drawingType="drawingType"
        :isAllowCollectionOnPoiAvailable="isAllowCollectionOnPoiAvailable"
        :isPoiEditingOn="isPoiEditingOn"
        :selected-basemap-id="basemapId"
        @showProjectInfoModal="() => (showProjectInfoModal = true)"
        @showAddressLookupModal="() => (showAddressLookupModal = true)"
        @showOfflineManagerModal="() => (showOfflineManagerModal = true)"
        @scale-toggle="handleScaleToggle"
        @basemap-change="handleBasemapChange"
      />

      <SampleModal
        ref="sampleModal"
        v-model="showSampleModal"
        v-bind:sampleData.sync="sampleDataToModify"
        :templateTabs="templateTabs"
        :isNonSpatialView="isNonSpatialView"
        :updateField="updateField"
        :isAllowCollectionOnPoiAvailable="isAllowCollectionOnPoiAvailable"
        :isEditing="isEditing"
        @close="closeSampleModal"
        @open="openSampleModal"
        @showDeleteSampleModal="handleDeleteSampleModalShow"
        @showDeletePlainShapeModal="handleDeletePlainShapeModalShow"
        @pois-change="handlePoisChange"
        @sample-loaded="handleSampleLoaded"
      />

      <ProjectInfoModal
        :show="showProjectInfoModal"
        @close="() => (showProjectInfoModal = false)"
      />

      <ManagersModal
        v-if="showManagersModal && templateTabs"
        :show="showManagersModal"
        :samples="sampleStore.samples"
        :templateTabs="templateTabs"
        @showVersionControlModal="handleVersionControlModalShow"
        @close="handleManagersModalClose"
      />

      <NotifyModal
        headerMessage="Are you sure you want to delete this item?"
        :show="showDeleteSampleModal"
        :isDelete="true"
        @close="handleDeleteSampleModalClose"
        @submit="$root.$emit('deleteSample')"
      />

      <NotifyModal
        headerMessage="Are you sure you want to delete this plain shape?"
        :show="layerModelIdToDelete"
        :isDelete="true"
        @close="handleDeletePlainShapeModalClose"
        @submit="$root.$emit('deletePlainShapeConfirmed')"
      />

      <AlertUnsavedItemModal
        v-if="showAlertUnsavedItemModal && alertUnsavedItemModalPayload"
        :payload="alertUnsavedItemModalPayload"
      />

      <UnauthorizedModal
        v-if="!userAuthorized"
        :users="project.users || []"
        @joinTeam="joinTeam"
      />

      <AddressLookupModal
        :show="showAddressLookupModal"
        :map="getMap()"
        @close="() => (showAddressLookupModal = false)"
      />

      <VersionControlModal
        :show="showVersionControlModal"
        :sample="sampleDataToModify"
        :isNonSpatialView="isNonSpatialView"
        @close="handleVersionControlModalClose"
      />

      <OfflineManagementModal
        v-if="showOfflineManagerModal"
        :selectedProject="project"
        :selectedFigureId="selectedFigure?.id || null"
        @close="showOfflineManagerModal = false"
      />

      <ImageModal
        v-if="getImageURL"
        :imageUrl="getImageURL"
        @close="closeImageModal"
      />

      <TrackingActivityModal
        v-if="showTrackingActivityModal"
        @start="handleTrackingActivityModalStart"
        @close="handleTrackingActivityModalClose"
      />

      <LayerStylingOffcanvas v-if="isMapMounted" ref="layerStylingOffcanvas" />
    </template>
  </div>
</template>

<script>
import Field from '@/js/classes/Field';
import NotifyModal from '@/js/components/NotifyModal.vue';
import { useOfflineStorageManagerStore } from '@/js/composables/useOfflineStorageManager';
import Map from '@/js/modules/project/map/components/Map.vue';
import useFigureStore from '@/js/stores/figure';
import useLayerModelStore from '@/js/stores/layer-model';
import useSampleStore from '@/js/stores/sample';
import {
  StackableModalType,
  checkIsAlertUnsavedItemModal,
  checkIsDeletePlainShapeModal,
  checkIsDeleteSampleModal,
  checkIsManagersModal,
  checkIsSampleModal,
  checkIsVersionControlModal,
  getTopModal,
} from '@/js/types/modal-stack';
import ImageModal from '@component-library/components/ImageModal.vue';
import IndeterminateProgressBar from '@component-library/components/IndeterminateProgressBar.vue';
import OfflineManagementModal from '@component-library/components/offline-management/SingleModal.vue';
import { useProjectStore } from '@component-library/store/project';
import UnauthorizedModal from '@component-library/team/UnauthorizedModal.vue';
import { waitFor } from '@component-library/utils/wait-for';
import {
  LoadManager,
  checkIsValidLatLng,
  lonLatToLegacyLatLng,
} from '@maps/lib/olbm';
import { mergeExtents } from '@maps/lib/olbm/common/extent';
import { getUid } from '@maps/lib/olbm/common/utils';
import { LayerUsageByType } from '@maps/lib/olbm/layer/constants';
import { createRectangleFromExtent } from '@maps/lib/olbm/layer/shape/rectangle';
import { LayerType } from '@maps/lib/olbm/layer/types';
import { getModelId, getUsage } from '@maps/lib/olbm/layer/utils';
import { FillStyle, OutlineStyle } from '@maps/lib/olbm/style/types';
import { BasemapId } from '@maps/lib/olbm/types';
import { Feature } from 'ol';
import { transformExtent } from 'ol/proj';
import { mapStores } from 'pinia';
import { provide, ref } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import NonSpatialEditor from '../non-spatial-editor/index.vue';
import api from './api/index.js';
import AddressLookupModal from './components/AddressLookupModal.vue';
import AlertUnsavedItemModal from './components/AlertUnsavedItemModal.vue';
import LayerStylingOffcanvas from './components/LayerStylingOffcanvas.vue';
import ManagersModal from './components/ManagersModal.vue';
import MapActionMenu from './components/MapActionMenu.vue';
import ProjectInfoModal from './components/ProjectInfoModal.vue';
import SampleModal from './components/SampleModal.vue';
import TrackingActivityModal from './components/TrackingActivityModal.vue';
import VersionControlModal from './components/VersionControlModal.vue';
import ViewHeader from './components/ViewHeader.vue';
import ActionEvents from './events/ActionEvents';
import SampleEvents from './events/SampleEvents';
import createAreaFigureLayer from './helpers/createAreaFigureLayer';
import useViewRestriction from '@component-library/composables/useViewRestriction';
import { AVAILABLE_PERMISSIONS } from '@component-library/company-role-profile';
import { goToApp } from '@component-library/switcher/handover';
import makeId from '@component-library/local-id.mjs';
import { useGatherSchemaStore } from '@component-library/store/gather-schema';
import { useCollectionStore } from '@component-library/store/collection';

export default {
  components: {
    ViewHeader,
    SampleModal,
    ProjectInfoModal,
    ManagersModal,
    MapActionMenu,
    NotifyModal,
    UnauthorizedModal,
    AddressLookupModal,
    VersionControlModal,
    ImageModal,
    TrackingActivityModal,
    NonSpatialEditor,
    IndeterminateProgressBar,
    Map,
    LayerStylingOffcanvas,
    OfflineManagementModal,
    AlertUnsavedItemModal,
  },
  setup() {
    const map = ref(undefined);
    provide('map', map);

    const isMapMounted = ref(false);
    provide('isMapMounted', isMapMounted);

    const collectionStore = useCollectionStore();
    const gatherSchema = useGatherSchemaStore();

    return {
      map,
      isMapMounted,
      collectionStore,
      gatherSchema,
    };
  },
  data: () => ({
    isNonSpatialView: false,
    offlineZoomLevel: null,
    isMoving: false,
    isDrawing: false,
    isEditing: false,
    isDuplicating: false,
    sampleDataToModify: null,
    showSampleModal: false,
    showProjectInfoModal: false,
    showDeleteSampleModal: false,
    showManagersModal: false,
    showOfflineManagerModal: false,
    showAddressLookupModal: false,
    showVersionControlModal: false,
    showTrackingActivityModal: false,
    showAlertUnsavedItemModal: false,
    templateTabs: [],
    templateTabToSelect: null,
    imageURL: null,
    drawingType: undefined,
    isTrackingAvailable: false,
    isPoiEditingOn: false,
    // Used to delete a plain shape
    layerModelIdToDelete: undefined,
  }),
  watch: {
    isNonSpatialView: {
      async handler(newValue) {
        if (!newValue) {
          await waitFor(() => this.isMapMounted);
          const map = this.getMap();
          if (this.isScaleVisible) {
            map.addScaleLine();
          }
          map.enableGeolocation();

          if (this.isOffline) {
            map.setMinZoom(this.offlineZoomLevel);
          }
        }
      },
      immediate: true,
    },
    selectedFigure(newValue) {
      if (newValue?.basemap_index === null) {
        this.basemapId = BasemapId.OPEN_STREET_MAP;
      }
    },
    basemapId(newValue) {
      this.map?.useBasemapApi(newValue);
    },
    isScaleVisible(newValue) {
      const map = this.getMap();
      newValue ? map.addScaleLine() : map.removeScaleLine();
    },
    isPoiEditingOn(newValue) {
      const im = this.map.getInteractionManager();

      if (!im.hasSession()) {
        return;
      }

      const data = im.getData();
      const { layerModelId } = data;
      const layer = im.layerManager.findLayerByModelId(layerModelId);
      const feature = layer.getFirstFeature();

      // End existing session
      im.endSession();
      // Begin a new session
      im.beginSession(data);

      const edit = newValue
        ? im.requestPoiEdit(feature)
        : im.requestEdit(feature, false);
      edit.selectFeature(feature);
    },
    async modalStack(newValue, oldValue) {
      const previousModal = getTopModal(oldValue);
      if (previousModal) {
        if (checkIsSampleModal(previousModal)) {
          this.$refs.sampleModal.clear();
          this.showSampleModal = false;
        } else if (checkIsManagersModal(previousModal)) {
          this.showManagersModal = false;
        } else if (checkIsVersionControlModal(previousModal)) {
          this.$refs.sampleModal.clear();
          this.showVersionControlModal = false;
        } else if (checkIsDeleteSampleModal(previousModal)) {
          this.$refs.sampleModal.clear();
          this.showDeleteSampleModal = false;
        } else if (checkIsDeletePlainShapeModal(previousModal)) {
          this.$refs.sampleModal.clear();
          this.layerModelIdToDelete = undefined;
        } else if (checkIsAlertUnsavedItemModal(previousModal)) {
          this.showAlertUnsavedItemModal = false;
        }

        await new Promise((resolve) => {
          this.$nextTick(resolve);
        });
      }

      const currentModal = getTopModal(newValue);
      if (currentModal) {
        if (checkIsSampleModal(currentModal)) {
          const {
            project_id: projectId,
            project_number: projectNumber,
            project_name: projectName,
          } = this.project;
          this.updatePersistence({
            projectId,
            projectTitle: `${projectNumber} - ${projectName}`,
          });
          const { payload } = currentModal;
          if (typeof payload === 'number' || typeof payload === 'string') {
            const itemId = payload;
            const item = await this.sampleStore.findSampleByIdAsync(itemId);
            if (item) {
              this.$root.$emit('setSampleDataToModify', item);
              this.openSampleModal();
            } else {
              this.$toastStore.error('The item is missing.');
            }
          } else if (typeof payload === 'function') {
            payload();
          }
        } else if (checkIsManagersModal(currentModal)) {
          this.showManagersModal = true;
        } else if (checkIsVersionControlModal(currentModal)) {
          this.showVersionControlModal = true;
        } else if (checkIsDeleteSampleModal(currentModal)) {
          this.showDeleteSampleModal = true;
        } else if (checkIsDeletePlainShapeModal(currentModal)) {
          this.layerModelIdToDelete = currentModal.payload;
        } else if (checkIsAlertUnsavedItemModal(currentModal)) {
          this.showAlertUnsavedItemModal = true;
        }
      }
    },
  },
  computed: {
    ...mapState({
      project: (state) => state.project,
      isOnline: (state) => state.isOnline,
      persistence: (state) => state.persistence,
      isScaleVisible: (state) => state.isScaleVisible,
      modalStack: (state) => state.modalStack,
    }),
    ...mapGetters({
      isModalStackEmpty: 'isModalStackEmpty',
    }),
    ...mapStores(
      useFigureStore,
      useSampleStore,
      useLayerModelStore,
      useOfflineStorageManagerStore
    ),
    selectedFigure() {
      return this.figureStore.getSelectedFigure();
    },
    projection() {
      return this.selectedFigure?.view_projection ?? 'EPSG:3857';
    },
    figureCenter() {
      const { longitude: projectLongitude, latitude: projectLatitude } =
        this.figureStore.getProject();
      const projectLonLat = {
        longitude: parseFloat(projectLongitude),
        latitude: parseFloat(projectLatitude),
      };

      if (!this.selectedFigure) {
        return projectLonLat;
      }

      const { view_lng: viewLongitude, view_lat: viewLatitude } =
        this.selectedFigure;
      const viewLonLat = {
        longitude: parseFloat(viewLongitude),
        latitdue: parseFloat(viewLatitude),
      };

      return !checkIsValidLatLng(lonLatToLegacyLatLng(viewLonLat))
        ? projectLonLat
        : viewLonLat;
    },
    basemapId: {
      get() {
        return this.selectedFigure?.basemap_index ?? null;
      },
      set(value) {
        if (this.selectedFigure) {
          this.figureStore.saveSelectedBasemapId(this.selectedFigure.id, value);
        }
      },
    },
    isTracking: {
      get() {
        return this.persistence.isTracking;
      },
      set(value) {
        this.updatePersistence({
          isTracking: value,
        });
      },
    },
    offlineProject() {
      return this.offlineStorageManagerStore.getProjectById(
        this.project.project_id
      );
    },
    isOffline() {
      return !this.isOnline && this.offlineProject !== null;
    },
    userAuthorized() {
      if (!this.project || !this.project.users) {
        return true;
      }

      const authUser = this.$auth.user();
      return (
        authUser.role == 'admin' ||
        this.project.user_id == authUser.user_id ||
        this.project.users.findIndex((u) => u.user_id == authUser.user_id) != -1
      );
    },
    getImageURL() {
      const base64regex =
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      if (!this.imageURL) {
        return;
      }

      if (this.imageURL instanceof Blob)
        return URL.createObjectURL(this.imageURL);
      if (base64regex.test(this.imageURL)) return this.imageURL;
      return (
        '/api/images/value/' + this.project.project_id + '/' + this.imageURL
      );
    },
    isAllowCollectionOnPoiAvailable() {
      if (!this.sampleDataToModify) {
        return false;
      }

      const { template_tab_id: appId } = this.sampleDataToModify;
      const app = appId && this.templateTabs.find((app) => app.id === appId);
      return app?.allow_collection_on_poi ?? false;
    },
    alertUnsavedItemModalPayload() {
      const topModal = getTopModal(this.modalStack);
      return topModal && checkIsAlertUnsavedItemModal(topModal)
        ? topModal.payload
        : undefined;
    },
  },
  methods: {
    ...mapActions([
      'updatePersistence',
      'setIsScaleVisible',
      'pushToModalStack',
      'popFromModalStack',
      'setHasUnsavedItem',
    ]),
    getLocateControl() {
      return locateControl;
    },
    getMap() {
      return this.map;
    },
    handleMapMounted() {
      this.isMapMounted = true;
    },
    handleMapUnmounted() {
      this.isMapMounted = false;
    },
    handleFeatureClick({ feature, cursorPosition }) {
      const map = this.getMap();
      const sampleId = feature.getId();
      const sample = sampleId ? map.findSampleById(sampleId) : undefined;

      let payload;
      if (sample) {
        // The area_figure _layer's styling could have been changed.
        if (sample.area_figure_layer) {
          const layerModel = map.findLayerModelById(
            sample.area_figure_layer.id
          );
          sample.area_figure_layer = layerModel;
        }

        const clickedPoi = map.getClickedPoi();
        if (clickedPoi && clickedPoi.sampleId === sample.id) {
          const poi = sample.points_of_interest[clickedPoi.index];
          const app = this.templateTabs.find(
            (app) => app.id === sample.template_tab_id
          );
          const section = app.sections.find(
            (section) => section.system_reference === 'point_of_interest'
          );
          const field = section.template_fields.find(
            (field) => field.system_reference === 'data_form'
          );
          const dataFormApp = this.templateTabs.find(
            (app) => app.title === field.options.template_tab_title
          );
          payload = () => {
            this.$root.$emit('setSampleDataToModify', {
              id: poi.dataForm,
              template_tab_id: dataFormApp.id,
            });
            this.openSampleModal();
          };
        } else {
          payload = sample.id;
        }
      } else {
        payload = () => {
          const layerManager = map.getLayerManager();
          const layer = layerManager.findLayerByFeature(feature);
          const layerUsage = getUsage(layer);

          if (
            [LayerUsageByType[LayerType.RECTANGLE].CACHING_BOUNDARY].includes(
              layerUsage
            )
          ) {
            return;
          }

          const layerModelId = map.getLayerModelId(layer);
          const layerModel = map.findLayerModelById(layerModelId);
          const latlng = map.toLegacyLatLng(layerManager.getLayerCenter(layer));
          this.$root.$emit('setSampleDataToModify', {
            latlng,
            geojson: {
              ...layerModel.geojson,
              properties: {
                ...layerModel.geojson.properties,
                layerId: layerModel.id,
              },
            },
          });
          this.openSampleModal();
        };
      }
      this.pushToModalStack({ type: StackableModalType.SampleModal, payload });
    },
    handleDeleteSampleModalShow() {
      this.pushToModalStack({
        type: StackableModalType.DeleteSampleModal,
        payload: this.sampleDataToModify,
      });
    },
    handleDeleteSampleModalClose() {
      this.popFromModalStack();
    },
    handleDeletePlainShapeModalShow(id) {
      this.pushToModalStack({
        type: StackableModalType.DeletePlainShapeModal,
        payload: id,
      });
    },
    handleDeletePlainShapeModalClose() {
      this.popFromModalStack();
    },
    handleVersionControlModalShow() {
      this.pushToModalStack({
        type: StackableModalType.VersionControlModal,
        payload: this.sampleDataToModify,
      });
    },
    handleVersionControlModalClose() {
      this.popFromModalStack();
    },
    loadAllViewerEvents() {
      new ActionEvents(this);
      new SampleEvents(this);

      this.$root.$on('createNewSample', (sample) => {
        this.sampleStore.addSample(sample);
        this.$root.$emit('updateSample', sample);
      });

      this.$root.$on('viewImage', (url) => {
        this.showVersionControlModal = false;
        this.imageURL = url;
      });

      this.$root.$on('changeViewType', (isNonSpatialView) => {
        this.changeViewType(isNonSpatialView);
      });
    },
    handleBasemapChange(basemapId) {
      this.basemapId = basemapId;
    },
    checkGatherAccessRestriction() {
      const restrictions = useViewRestriction();
      if (!restrictions.hasPermissionToAccess(AVAILABLE_PERMISSIONS.GATHER)) {
        goToApp({
          isGather: true,
          app: 'hub',
          project: this.project,
          route: 'project_dashboard',
          $router: this.$router,
          $store: this.$store,
        });
      }
    },
    async loadTemplate() {
      if (!this.isOnline) {
        return;
      }

      try {
        const { data } = await axios.get(
          '/api/template/' + this.project.project_id
        );

        this.templateTabs = data.template_tabs;
      } catch (e) {
        this.$toastStore.unexpected();
        throw e;
      }
    },
    joinTeam(role_id) {
      api
        .joinTeam(role_id)
        .then(({ data }) => {
          const projectStore = useProjectStore();
          projectStore.updateProject({
            ...data.project,
            users: data.users,
          });

          this.checkGatherAccessRestriction();
        })
        .catch((err) => {
          throw err;
        });
    },
    closeImageModal() {
      this.imageURL = null;
      this.showVersionControlModal = true;
    },
    openSampleModal() {
      this.showSampleModal = true;
    },
    closeSampleModal(clear = false) {
      if (clear) {
        this.popFromModalStack();
      } else {
        this.showSampleModal = false;
      }
    },
    startNonSpatial(param) {
      this.pushToModalStack({
        type: StackableModalType.SampleModal,
        payload: () => {
          if (param) {
            this.templateTabToSelect = param.tabId;
          }
          this.$root.$emit('setSampleDataToModify', {});
          this.openSampleModal();
        },
      });
    },
    // refactor
    resetAreasToLoad() {
      const layerManager = this.map.getLayerManager();
      const layers = layerManager.findLayersByType(
        LayerType.FEATURE_COLLECTION
      );
      const cachingBoundariesLayer = layers.find(
        (layer) =>
          getUsage(layer) ===
          LayerUsageByType[LayerType.FEATURE_COLLECTION].CACHING_BOUNDARIES
      );
      if (cachingBoundariesLayer) {
        this.map.removeLayerModel(getModelId(cachingBoundariesLayer));
      }

      if (this.areaBoundaryUid) {
        this.handleAreaBoundaryRemove();
      }
    },
    handleAreaBoundaryRemove() {
      this.map.getInteractionManager().endSession();
      this.areaBoundaryUid = undefined;
      this.isOfflineManagerModalVisible = true;
    },
    async drawAreasToLoad(areas) {
      const extents = areas.map((area) => {
        const { bounds } = area;
        const extentIn4326 = bounds.split(',');
        return transformExtent(
          extentIn4326,
          'EPSG:4326',
          this.map.getProjection()
        );
      });

      if (!extents.length) {
        return;
      }

      const features = extents.map(
        (extent) => new Feature(createRectangleFromExtent(extent))
      );
      const layerModel = {
        id: makeId(),
        geojson: {
          ...this.map.toGeoJSON(features),
          properties: {
            type: LayerType.FEATURE_COLLECTION,
            usage:
              LayerUsageByType[LayerType.FEATURE_COLLECTION].CACHING_BOUNDARIES,
            title: '',
            outlineStyle: OutlineStyle.Solid,
            fillStyle: FillStyle.Transparent,
            color: '#4e0695',
          },
        },
        visible: true,
        children: [],
      };
      this.map.addLayerModel(this.selectedFigure, layerModel);

      const fitTarget = mergeExtents(...extents);
      this.map.fit(fitTarget);
    },
    setupOfflineProject() {
      const {
        template_tabs: templateTabs = [],
        samples,
        offline_min_zoom: offlineMinZoom = 0,
      } = this.offlineProject;

      this.templateTabs = templateTabs;

      this.sampleStore.replaceSamples(
        samples.map((s) => {
          // Fix the problem that type is missing
          if (
            s.area_figure_layer &&
            !s.area_figure_layer.geojson.properties.type
          ) {
            s.area_figure_layer = createAreaFigureLayer(
              s.area_geojson,
              s.custom_title
            );
          }
          return s;
        })
      );

      this.offlineZoomLevel = offlineMinZoom;
    },
    async loadFromSession() {
      const { projectId } = this.persistence;
      if (projectId && projectId !== this.project.project_id) {
        const { projectTitle, sampleIdentifier, inputValues } =
          this.persistence;
        let isAuthorized = true;
        const projectStore = useProjectStore();
        try {
          await projectStore.loadProjectDetails(projectId, false);
        } catch {
          isAuthorized = false;
        }
        this.pushToModalStack({
          type: StackableModalType.AlertUnsavedItemModal,
          payload: {
            projectId,
            projectTitle,
            unsavedItem: {
              title: sampleIdentifier,
              numberOfValues: inputValues.length,
            },
            isAuthorized,
          },
        });
        return;
      }

      const { sampleData } = this.persistence;
      if (sampleData) {
        this.setHasUnsavedItem(true);
        this.pushToModalStack({
          type: StackableModalType.SampleModal,
          payload: () => {
            this.$root.$emit('setSampleDataToModify', sampleData);
            this.openSampleModal();
          },
        });
      }
    },
    async changeViewType(isNonSpatialView) {
      this.isNonSpatialView = isNonSpatialView;
      this.collectionStore.clearBusy();

      await this.$nextTick();

      api.updateNonSpatialView({
        isNonSpatialView: this.isNonSpatialView,
      });

      const projectStore = useProjectStore();
      projectStore.updateProject({
        ...this.project,
        is_gather_non_spatial_view: this.isNonSpatialView,
      });
    },
    async updateField(fieldId, prop, value) {
      for (let i = 0; i < this.templateTabs.length; i++) {
        const tt = this.templateTabs[i];
        for (let j = 0; j < tt.sections.length; j++) {
          const s = tt.sections[j];
          for (let k = 0; k < s.template_fields.length; k++) {
            const f = s.template_fields[k];
            if (f.id === fieldId) {
              const nextField = Field.wrap({ ...f, [prop]: value });
              try {
                await nextField.save();
              } catch (e) {
                console.warn('Form was unable to update field');
              }
              s.template_fields.splice(k, 1, nextField);
            }
          }
        }
      }
    },
    handleTrackingActivityModalStart() {
      this.handleTrackingActivityModalClose();
      this.$root.$emit('startTracking');
    },
    handleTrackingActivityModalClose() {
      this.$root.$emit('closeTrackingActivityModal');
    },
    handleScaleToggle() {
      this.setIsScaleVisible(!this.isScaleVisible);
    },
    handleGeolocationEnabled() {
      this.$root.$emit('hasLocation', true);
    },
    handleGeolocationDisabled() {
      this.$root.$emit('hasLocation', false);
    },
    handleGeolocationError() {
      this.$root.$emit('hasLocation', false);
      this.$toastStore.error(
        `The location service went wrong, please refresh the page to try it again.`
      );
    },
    handleAreaBoundaryDraw() {
      const interactionManager = this.map.getInteractionManager();
      interactionManager.beginSession();
      interactionManager.requestDraw({
        layerType: LayerType.RECTANGLE,
        layerUsage: LayerUsageByType[LayerType.RECTANGLE].CACHING_BOUNDARY,
        outlineStyle: OutlineStyle.Solid,
        fillStyle: FillStyle.Transparent,
        color: '#4e0695',
        activateEdit: false,
      });
      interactionManager.once('post-feature-created', (event) => {
        const { layer } = event.getPayload();
        this.areaBoundaryUid = getUid(layer);
        this.isOfflineManagerModalVisible = true;
      });
    },
    handlePoiAdd(poi) {
      this.$refs.sampleModal.addPointOfInterest(poi);
    },
    handlePoiLonLatChange({ index, lonLat }) {
      this.$refs.sampleModal.changeLonLatOfPointOfInterest(index, lonLat);
    },
    handlePoiDelete(index) {
      this.$refs.sampleModal.deletePointOfInterest(index);
    },
    handlePoisChange({ sampleId, pois }) {
      this.sampleStore.updateSample({
        id: sampleId,
        points_of_interest: pois,
      });

      const im = this.map.getInteractionManager();
      if (im.hasSession()) {
        const layer = im.layerManager.findSampleLayerBySampleId(sampleId);
        const edit = this.map.getInteractionManager().getEdit(layer);
        if (typeof edit.updatePoiFeatures === 'function') {
          edit.updatePoiFeatures();
        }
      }
    },
    handleSampleLoaded() {
      this.$refs.sampleModal.saveToSession();
    },
    handleManagersModalClose() {
      this.popFromModalStack();
    },
  },
  created() {
    this.isNonSpatialView = this.project.is_gather_non_spatial_view == true;
  },
  async mounted() {
    this.checkGatherAccessRestriction();
    this.loadAllViewerEvents();
    await this.gatherSchema.getPhases(this.project.project_id);
    await this.loadTemplate();
    await this.loadFromSession();
    if (this.isOffline) {
      this.setupOfflineProject();
    }
  },
  beforeDestroy() {
    [
      ...ActionEvents.eventNames,
      ...SampleEvents.eventNames,
      'createNewSample',
      'viewImage',
      'changeViewType',
    ].forEach((eventName) => this.$root.$off(eventName));

    LoadManager.destroy();
  },
};
</script>
