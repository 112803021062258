import { Map } from 'ol';
import type { Figure } from '../figure/types';
import { getLayoutZoom } from '../measurement/layout';
import { Color } from '../style/color';
import { LayerUsageByType } from './constants';
import type { SampleGroup } from './sample/types';
import { checkIsSampleGroup, getSampleIcon } from './sample/utils';
import getShapeStyle from './shape/getShapeStyle';
import {
  checkIsShapeLayerModel,
  checkIsSingleShapeLayerModel,
} from './shape/utils';
import type { LayerModel } from './types';
import { LayerType } from './types';

const DEFAULT_ICON_SIZE = '1.6em';

const fillStyleOptions = [
  { identifier: 0, class: 'overlay' },
  { identifier: 1, class: 'noverlay' },
  { identifier: 2, class: 'hstripe' },
  { identifier: 3, class: 'vstripe' },
  { identifier: 4, class: 'dots' },
  { identifier: 5, class: 'circle' },
  { identifier: 6, class: '45degcross' },
  { identifier: 7, class: '45degstripe' },
];

const outlineStyleOptions = [
  { identifier: 0, class: 'solid' },
  { identifier: 1, class: 'dashed' },
  { identifier: 2, class: 'dotted' },
];

const layerIconByType = {
  [LayerType.RECTANGLE]: 'fal fa-square',
  [LayerType.CIRCLE]: 'fal fa-circle',
  [LayerType.POLYGON]: 'fal fa-hexagon',
  [LayerType.SAMPLE]: 'fal fa-map-marker-question',
  [LayerType.POLYLINE]: 'fal fa-horizontal-rule',
  [LayerType.ARROW]: 'fal fa-arrow-right',
  [LayerType.CALL_OUT]: 'far fa-comment-alt',
  [LayerType.TEXT]: 'fal fa-text',
  [LayerType.SITE_BOUNDARY]: 'far fa-square',
  [LayerType.SERVICE]: 'fal fa-map',
  [LayerType.FOLDER]: 'fal fa-folder',
  [LayerType.SAMPLE_GROUP]: 'fal fa-vials',
  [LayerType.IMAGE]: 'fal fa-image',
  [LayerType.FEATURE_COLLECTION]: 'fal fa-shapes',
  [LayerType.CHAINAGE]: 'fal fa-ruler',
};

export default function getLayerModelIcon(
  map: Map,
  figure: Figure,
  model: LayerModel
) {
  if (!model.children.length || checkIsSampleGroup(model)) {
    return getLayerModelIconInternal(map, figure, model);
  }

  if (checkHasMistachedChildren(model)) {
    return getLayerModelIconInternal(map, figure, model);
  }

  return getLayerModelIconInternal(map, figure, model.children[0]);
}

function getLayerModelIconInternal(
  map: Map,
  figure: Figure,
  model: LayerModel
) {
  const layoutZoom = getLayoutZoom(map);

  let { properties } = model.geojson;
  const { type, usage } = properties;

  if (type === LayerType.SAMPLE_GROUP) {
    const { icon, color } = getMarkerPropertiesByIdentifier(
      model as SampleGroup
    );

    return {
      icon: getMarkerIcon(icon, color),
    };
  }

  if (type === LayerType.SERVICE) {
    const renderer = 'renderer' in properties ? properties.renderer : undefined;
    return getServiceLayerStyle(renderer, layoutZoom);
  }

  if (
    type === LayerType.IMAGE &&
    usage === LayerUsageByType[LayerType.IMAGE].HEATMAP
  ) {
    return {
      icon: `
               <img src="${getStyledMapIconUrl(
                 '/images/map_icons/tool-icons/heatmap.svg'
               )}" style="width:${DEFAULT_ICON_SIZE}; height:${DEFAULT_ICON_SIZE};"/>
            `,
    };
  }

  if (
    type === LayerType.FEATURE_COLLECTION &&
    !!usage &&
    [
      LayerUsageByType[LayerType.FEATURE_COLLECTION].CONTOURS,
      LayerUsageByType[LayerType.FEATURE_COLLECTION].BASEMAP_POLYLINES,
    ].includes(usage) &&
    checkIsShapeLayerModel(model)
  ) {
    return {
      icon: getShapeIcon({
        type: LayerType.POLYLINE,
        color: model.geojson.properties.color,
      }),
    };
  }

  if (
    type === LayerType.FEATURE_COLLECTION &&
    !!usage &&
    [LayerUsageByType[LayerType.FEATURE_COLLECTION].BASEMAP_POLYGONS].includes(
      usage
    ) &&
    checkIsShapeLayerModel(model)
  ) {
    return {
      icon: getShapeIcon({
        type: LayerType.POLYGON,
        color: model.geojson.properties.color,
      }),
    };
  }

  if (checkIsSingleShapeLayerModel(model)) {
    properties = {
      ...properties,
      ...getShapeStyle(map, figure, model),
    };
  }

  const shapeIcon = getShapeIcon(properties);

  if (shapeIcon) {
    return {
      icon: shapeIcon,
    };
  }

  return {
    icon: `<i
      class="${getLayerIconByType(type)} fa-fw"
      style="color: ${Color.Black};"
    ></i>`,
  };
}

function getMarkerPropertiesByIdentifier(sampleGroup: SampleGroup): {
  icon: number;
  color: string;
} {
  const identifierProperties = sampleGroup.marker_identifier.split('_');
  const icon = parseInt(identifierProperties[0], 10);
  const color = identifierProperties[1];
  return {
    icon,
    color,
  };
}

function getMarkerIcon(
  icon: number,
  color: string,
  markerSize = DEFAULT_ICON_SIZE
) {
  const { src } = getSampleIcon(icon, color);
  return `
    <img src=${src} style="width: ${markerSize}; height: ${markerSize};"/>
  `;
}

function checkHasMistachedChildren(model: LayerModel) {
  const { children } = model;

  if (!children.length) {
    return false;
  }

  const base = children[0].geojson.properties;

  if (!base) {
    return true;
  }

  const baseType = base.type;
  const baseUsage = base.usage;
  const baseColor = 'color' in base ? base.color : undefined;
  const baseOutlineStyle =
    'outlineStyle' in base ? base.outlineStyle : undefined;
  const baseFillStyle = 'fillStyle' in base ? base.fillStyle : undefined;

  if (
    [LayerType.SERVICE, LayerType.SAMPLE_GROUP, LayerType.LEGEND_NOTE].includes(
      baseType
    )
  ) {
    return true;
  }

  if (
    baseType === LayerType.IMAGE &&
    baseUsage === LayerUsageByType[LayerType.IMAGE].HEATMAP
  ) {
    return true;
  }

  const matchedChildren = children.filter((child) => {
    const { properties } = child.geojson;

    return (
      (properties.type
        ? properties.type.toLowerCase() == baseType.toLowerCase()
        : true) &&
      ('color' in properties ? properties.color === baseColor : true) &&
      ('outlineStyle' in properties
        ? properties.outlineStyle === baseOutlineStyle
        : true) &&
      ('fillStyle' in properties
        ? properties.fillStyle === baseFillStyle
        : true)
    );
  });

  return children.length != matchedChildren.length;
}

function getServiceLayerStyle(
  renderer: any,
  layoutZoom: number
): { icon: any } {
  if (!renderer) {
    return {
      icon: null,
    };
  }

  if (renderer.type === 'simple') {
    return {
      icon: getEsriLayerStyle(
        {
          symbol: renderer.symbol,
          label: renderer.label,
          description: renderer.description,
          value: renderer.value,
        },
        layoutZoom
      ),
    };
  }

  if (renderer.type === 'uniqueValue') {
    const result = {
      icon: null,
      subLayers: [] as any[],
    };

    if (renderer.uniqueValueInfos.length == 1) {
      const data = renderer.uniqueValueInfos[0];
      return {
        icon: getEsriLayerStyle(data, layoutZoom),
      };
    }

    renderer.uniqueValueInfos.forEach((data, index) => {
      result.subLayers.push({
        icon: getEsriLayerStyle(data, layoutZoom),
        data,
        index,
      });
    });

    if (renderer.defaultSymbol) {
      const data = {
        symbol: renderer.defaultSymbol,
        label: renderer.defaultLabel,
        description: '',
        value: null,
      };
      result.subLayers.push({
        icon: getEsriLayerStyle(data, layoutZoom),
        data,
        index: null,
      });
    }

    return result;
  }

  if (renderer.type === 'ogc_opengis_wfs') {
    const { type } = renderer.properties;

    if (type !== 'point') {
      return {
        icon: getShapeIcon(renderer.properties),
      };
    }

    const { icon, color } = renderer.properties;

    return {
      icon: getMarkerIcon(icon, color),
    };
  }

  return {
    icon: null,
  };
}

function getEsriLayerStyle(data, layoutZoom) {
  if (!data) {
    return;
  }

  if (data.symbol) {
    data = {
      ...data,
      ...data.symbol,
    };
  }

  if (data.imageData) {
    return (
      '<img src="data:' +
      data.contentType +
      ';base64,' +
      data.imageData +
      '" width="' +
      data.width * layoutZoom +
      '" height="' +
      data.height * layoutZoom +
      '" />'
    );
  }

  let outline = '';

  if (data.outline) {
    outline = `border: 1px solid ${getRgbaFromColor(data.outline.color)};`;
  }

  const color = data.color;
  const size = (data.size || 6) * layoutZoom;
  if (color) {
    return `<div style="background: ${getRgbaFromColor(
      color
    )}; width: ${size}px; height: ${size}px; ${outline}"></div>`;
  }
}

function getRgbaFromColor(color) {
  const r = color[0],
    g = color[1],
    b = color[2],
    a = color[3];

  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

function getShapeIcon({
  type,
  color = Color.Black,
  fillStyle = 0,
  outlineStyle = 0,
  multiDirectional = false,
}: {
  type: LayerType;
  color?: string;
  fillStyle?: number;
  outlineStyle?: number;
  multiDirectional?: boolean;
}) {
  const supportedTypes = [
    LayerType.RECTANGLE,
    LayerType.CIRCLE,
    LayerType.POLYGON,
    LayerType.POLYLINE,
    LayerType.ARROW,
    LayerType.SITE_BOUNDARY,
    LayerType.HEDGE,
  ];

  if (type === LayerType.BUFFER) {
    type = LayerType.POLYGON;
  }

  if (!supportedTypes.includes(type)) {
    return;
  }

  const subType = type === LayerType.ARROW && multiDirectional ? 'double' : '';
  const icon =
    '/images/map_icons/shapes/' +
    getShapeSvg(type, fillStyle, outlineStyle, subType);

  return `
    <img src="${getStyledMapIconUrl(
      icon,
      color
    )}" style="width:${DEFAULT_ICON_SIZE}; height:${DEFAULT_ICON_SIZE};"/>
  `;
}

function getShapeSvg(type, fillStyle, outlineStyle, subType = '') {
  const fillStyleClass =
    fillStyleOptions.find((o) => o.identifier == fillStyle)?.class || 'overlay';

  const outlineStyleClass =
    outlineStyleOptions.find((o) => o.identifier == outlineStyle)?.class ||
    'solid';

  return (
    type +
    (subType ? '_' + subType : '') +
    '_' +
    ([LayerType.POLYLINE, LayerType.ARROW].includes(type)
      ? 'overlay'
      : fillStyleClass) +
    '_' +
    outlineStyleClass +
    '.svg'
  );
}

function getLayerIconByType(type: LayerType) {
  return layerIconByType[type] ?? 'fas fa-layer-group';
}

function getStyledMapIconUrl(
  mapIconUrl: string,
  color: string = Color.Black
): string {
  const pattern = /^\/images\/map_icons\/([^\/]+)\/(.+)\.svg/;
  const match = mapIconUrl.match(pattern);

  if (!match) {
    throw `Unsupported map icon URL: ${mapIconUrl}`;
  }

  const folder = match[1];
  const name = match[2];
  const colorParam = encodeURIComponent(color);

  return `${
    import.meta.env.VITE_APP_URL
  }/styled-map-icon/${folder}/${name}?fill=${colorParam}&stroke=${colorParam}`;
}
