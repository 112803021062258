<script setup lang="ts">
import { findSectionByIdFromApp } from '@component-library/business-logic/app';
import type { App } from '@component-library/gather';
import type { StylingRuleOnGatherApp as StylingRule } from '@component-library/business-logic/mapping/styling-rule';
import { computed } from 'vue';

const props = defineProps<{ app: App; stylingRule: StylingRule }>();

const section = computed(
  () => findSectionByIdFromApp(props.app, props.stylingRule.target.sectionId)!
);
</script>

<template>
  <div>
    <div>{{ section.label }}</div>
    <div class="text-muted">{{ stylingRule.target.collectionType }}</div>
  </div>
</template>
