<template>
  <div class="form-group mb-3">
    <label class="form-label" for="caption-content-editor">Content</label>
    <vue-editor
      ref="captionContentEditor"
      id="caption-content-editor"
      class="w-100"
      v-model="content"
      :editor-toolbar="customToolbar"
      useCustomImageHandler
      @image-added="handleImageAdded"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
const VueEditor = () => import('@component-library/utils/vue2-async-editor');

export default {
  components: {
    VueEditor,
  },
  props: {
    value: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      project: 'get_project_data',
    }),
    field: {
      get() {
        return this.value;
      },
      set(updated) {
        this.$emit('input', { ...updated });
      },
    },
    content: {
      get() {
        return this.value.options.content;
      },
      set(updated) {
        const { captionContentEditor } = this.$refs;
        const captionContentText = captionContentEditor.quill.getText().trim();
        const { length } = captionContentText;
        let label = null;
        if (this.previousLabel == this.value.label || !this.value.label) {
          if (length > 30) {
            label = `${captionContentText.substring(0, 30)}...`;
          } else if (length > 0) {
            label = captionContentText;
          } else {
            label = 'Caption';
          }
          this.previousLabel = label;
        } else {
          label = this.value.label;
        }

        this.$emit('input', {
          ...this.value,
          label,
          options: { ...this.value.options, content: updated },
        });
      },
    },
  },
  data: () => ({
    previousLabel: null,
    customToolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', { color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
    ],
  }),
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData();
      formData.append('file', file);
      formData.append('project_id', this.project.project_id);
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      axios({
        url: `/api/project/asset`,
        method: 'POST',
        data: formData,
        config,
      })
        .then((result) => {
          const filename = result.data.src; // Get url from response
          const url = `/api/images/value/${this.project.project_id}/${filename}`;
          Editor.insertEmbed(cursorLocation, 'image', url);
          resetUploader();
        })
        .catch((err) => {
          this.$toastStore.warning('Failed to upload image, please try again.');
          console.error(err);
          throw err;
        });
    },
  },
};
</script>
