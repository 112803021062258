<script setup lang="ts">
import Modal from '@/js/components/Modal.vue';
import { useStore } from '@/js/store';
import InfoButton from '@component-library/components/InfoButton.vue';
import { useToastStore } from '@component-library/store/toasts';
import { computed, onMounted, ref } from 'vue';

const emit = defineEmits(['close', 'start']);
const store = useStore();

const vertexCreationInterval = ref<number>(0);
const info = computed(() => {
  if (vertexCreationInterval.value === 0) {
    return `When a new location is found it is added immediately as a new vertex.
    <br/>
    <br/>
    A value greater than 0 can reduce the frequency of vertex creation so there
    will be fewer vertexs on the final shape.`;
  } else if (vertexCreationInterval.value > 0) {
    return `If the time at which a new location is found is ${vertexCreationInterval.value} seconds later
    than the time at which the last vertex was added, then the new location is added as a new vertex.
    <br/>
    <br/>
    A value of 0 here causes the newly found location to be added as a vertex immediately.`;
  } else {
    return 'A negative number is invalid here.';
  }
});

function handleVertexCreationIntervalInput(evt) {
  vertexCreationInterval.value = parseInt(evt.target.value, 10);
  if (Number.isNaN(vertexCreationInterval.value)) {
    vertexCreationInterval.value = 0;
  }
}

function handleCancel() {
  emit('close');
}

function handleOK() {
  if (vertexCreationInterval.value < 0) {
    const toastStore = useToastStore();
    toastStore.error('Please input a positive number.');
    return;
  }

  store.dispatch('updatePersistence', {
    vertexCreationInterval: vertexCreationInterval.value,
  });
  emit('start');
}

onMounted(() => {
  vertexCreationInterval.value = store.state.persistence.vertexCreationInterval;
});
</script>

<template>
  <Modal :show="true" @close="handleCancel">
    <template #header>Start Tracking</template>
    <div class="p-2">
      <label class="form-label me-2">Vertex creation interval</label>
      <InfoButton :info="info" />
      <div class="input-group mb-2">
        <input
          type="text"
          class="form-control"
          :value="vertexCreationInterval"
          @input="handleVertexCreationIntervalInput"
        />
        <div class="input-group-append">
          <span class="input-group-text" id="basic-addon2">Seconds</span>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button class="btn btn-secondary me-2" @click="handleCancel">
          Cancel
        </button>
        <button class="btn btn-primary" @click="handleOK">OK</button>
      </div>
    </div>
  </Modal>
</template>
