<template>
  <div>
    <div class="input-group">
      <select class="form-control" v-model="field_id">
        <option
          v-for="fieldOption in fields"
          :key="fieldOption.id"
          :value="fieldOption.id"
        >
          {{ fieldOption.label }}
        </option>
      </select>

      <select class="form-control" v-model="operator">
        <option
          v-for="(operator, operatorIndex) in operators"
          :key="operatorIndex"
          :value="operator"
        >
          {{ operator }}
        </option>
      </select>

      <select v-if="hasOptions()" class="form-control" v-model="conditionValue">
        <option
          v-for="fieldValue in getValues(value)"
          :key="fieldValue.value"
          :value="fieldValue.value"
        >
          {{ fieldValue.displayValue }}
        </option>
      </select>
      <input v-else type="text" class="form-control" v-model="conditionValue" />

      <button
        type="button"
        class="btn btn-outline-danger"
        @click="removeCondition"
      >
        <i class="fas fa-trash-alt" />
      </button>
    </div>
    <div v-if="isDefault" class="mb-2" :key="'selection-' + index">
      <select
        class="form-control d-flex flex-grow"
        v-model="conditionDefault"
        placeholder="Select values"
      >
        <option :value="null" selected>All values</option>
        <option
          v-for="(group, groupKey) in groups"
          :key="groupKey"
          :value="groupKey"
        >
          {{ group.title || `Group ${groupKey + 1}` }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import {
  checkHasNumberValue,
  checkIsSelectSupported,
  getSelectOptions,
} from '@component-library/fields';

export default {
  props: {
    value: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
    },
    fields: {
      required: true,
    },
    isDefault: {
      required: false,
      default: false,
    },
    groups: {
      required: false,
      default: () => [],
    },
  },
  computed: {
    operators() {
      const field = this.fields.find((f) => f.id === this.value.field_id);
      if (field && checkHasNumberValue(field)) {
        return ['=', '!=', '<', '<=', '>', '>='];
      }

      return ['=', '!='];
    },
    field_id: {
      get() {
        return this.value.field_id;
      },
      set(field_id) {
        this.$emit('update', {
          condition: { ...this.value, field_id },
          index: this.index,
        });
      },
    },
    operator: {
      get() {
        return this.value.operator;
      },
      set(operator) {
        this.$emit('update', {
          condition: { ...this.value, operator },
          index: this.index,
        });
      },
    },
    conditionValue: {
      get() {
        return this.value.value;
      },
      set(value) {
        this.$emit('update', {
          condition: { ...this.value, value },
          index: this.index,
        });
      },
    },
    conditionDefault: {
      get() {
        return this.value.default;
      },
      set(aDefault) {
        this.$emit('update', {
          condition: { ...this.value, default: aDefault },
          index: this.index,
        });
      },
    },
  },
  methods: {
    getValues(condition) {
      const field = this.fields.find((f) => f.id == condition.field_id);

      if (!field) {
        return [];
      }

      return getSelectOptions(field);
    },
    hasOptions() {
      const conditionField = this.fields.find(
        (f) => f.id === this.value.field_id
      );
      return !!conditionField && checkIsSelectSupported(conditionField);
    },
    removeCondition() {
      this.$emit('removeCondition');
    },
  },
  watch: {
    async field_id() {
      this.conditionValue = null;
      await this.$nextTick();
      this.operator = this.operators[0];
    },
  },
  mounted() {
    if (this.value.field_id == null && this.value.field_label) {
      const foundField = this.fields.find(
        (aField) => aField.label == this.value.field_label
      );
      if (foundField) {
        this.field_id = foundField.id;
      }
    }
  },
};
</script>
