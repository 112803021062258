<script setup>
import _omit from 'lodash/omit';
import { useAttrs, ref, computed } from 'vue';
import FillPatternChooser from '@component-library/components/FillPatternChooser.vue';
import Tick from '@component-library/components/Tick.vue';
import FillPattern from '@component-library/components/FillPattern.vue';
import * as cl_bl from '@component-library/business-logic';
import * as cl_bm from '@component-library/business-model';
import Dropdown from './Dropdown.vue';
import ImportLithologyOptionsModal from './ImportLithologyOptionsModal.vue';
import makeId from '@component-library/local-id.mjs';

const emit = defineEmits(['updateOptions']);

const colorInputId = makeId();
const attrs = useAttrs();

// The updateOptions should not be invoked by the Dropdown field.
function takeOver(listeners) {
  return _omit(listeners, 'updateOptions');
}

function extendOption(field, option, extension) {
  const nextOptions = cl_bl.lithology.extendOption(field, option, extension);
  emit('updateOptions', nextOptions);
}

const selectedOption = ref(null);
const color = computed({
  get() {
    return cl_bl.lithology.getColor(attrs.field, selectedOption.value);
  },
  set(value) {
    extendOption(attrs.field, selectedOption.value, { color: value });
  },
});
const selectedFillPattern = computed({
  get() {
    return cl_bl.lithology.getFillPattern(attrs.field, selectedOption.value);
  },
  set(value) {
    extendOption(attrs.field, selectedOption.value, { fillPattern: value });
  },
});

function handleUpdateOptions(nextOptions) {
  const optionExtensions = nextOptions.options.reduce((accu, o) => {
    accu[o] = cl_bl.lithology.getOptionExtension(attrs.field, o);
    return accu;
  }, {});
  emit('updateOptions', { ...nextOptions, optionExtensions });
}

function handleSelectOption(value) {
  selectedOption.value = value;
}

function handleChangeFillPattern(value) {
  selectedFillPattern.value = value;
}

function handleImport(lithologyOptions, fieldOptions, closeImportModal) {
  const { options, optionExtensions } = fieldOptions;
  const differentLos = lithologyOptions.filter(
    (lo) => !options.includes(lo.name)
  );
  const newOptions = [...options, ...differentLos.map((dlo) => dlo.name)];
  const nextOptionExtensions = differentLos.reduce(
    (accu, dlo) => {
      const { color, fillPatternValue: fillPattern } = dlo;
      accu[dlo.name] = { color, fillPattern };
      return accu;
    },
    { ...optionExtensions }
  );
  emit('updateOptions', {
    ...fieldOptions,
    options: newOptions,
    optionExtensions: nextOptionExtensions,
  });
  closeImportModal();
}

function handleImportModalClose(closeImportModal) {
  closeImportModal();
}
</script>

<template>
  <div>
    <Dropdown
      v-bind="$attrs"
      v-on="takeOver($listeners)"
      :visibility="{ optionType: false, multiple: false, addOptions: false }"
      @updateOptions="handleUpdateOptions"
      @selectOption="handleSelectOption"
    >
      <template #extensionContainer="{ setSelectedOption }">
        <div class="d-flex flex-column extension-container">
          <div
            v-for="(oe, option) in $attrs.options.optionExtensions"
            :key="`option-${option}`"
          >
            <FillPattern
              :value="oe.fillPattern"
              :size="[24, 24]"
              :color="oe.color"
              :title="option"
              @click.native.stop="setSelectedOption(option)"
            >
              <Tick v-if="option === selectedOption" />
            </FillPattern>
          </div>
        </div>
      </template>

      <template #importModal="{ isImportModalVisible, closeImportModal }">
        <ImportLithologyOptionsModal
          v-if="isImportModalVisible"
          @import="handleImport($event, $attrs.options, closeImportModal)"
          @close="handleImportModalClose(closeImportModal)"
        />
      </template>
    </Dropdown>

    <div v-if="selectedOption" class="d-flex">
      <div class="d-flex flex-column">
        <div class="form-group mb-3">
          <label class="form-label" :for="colorInputId"> Colour</label>
          <input
            class="form-control"
            type="color"
            :id="colorInputId"
            v-model="color"
          />
        </div>

        <FillPatternChooser
          :fill-patterns="[
            cl_bm.common.FILL_PATTERN_SOLID,
            cl_bm.common.FILL_PATTERN_TRANSPARENT,
            cl_bm.common.FILL_PATTERN_HORIZONTAL_STRIPE,
            cl_bm.common.FILL_PATTERN_VERTICAL_STRIPE,
            cl_bm.common.FILL_PATTERN_DOT,
          ]"
          :selectedFillPattern="selectedFillPattern"
          @change="handleChangeFillPattern"
        />
      </div>

      <!-- Fill pattern preview -->
      <div class="flex-grow-1 d-flex justify-content-center align-items-center">
        <FillPattern
          :value="selectedFillPattern"
          :size="[72, 72]"
          :color="color"
        />
      </div>
    </div>
  </div>
</template>
