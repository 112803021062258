<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';

import AppItemPicker from './AppItemPicker.vue';
import SamplePicker from './SamplePicker.vue';

import useApi from '../../composables/useApi';
import type { App } from '../../gather';
import { Project } from '../../project';
import { Sample } from '../../enviro';

import useTogglableSampleList from './composables/useTogglableSampleList';
import { getUniqueValuesFromArrayByKey } from '../../utils';

const props = defineProps<{
  project: Project;
  selectedAppIds: number[];
  selectedSampleIds: number[];
}>();

const emit = defineEmits<{
  (e: 'setSelectedAppIds', appIds: number[]): void;
  (e: 'setSelectedSampleIds', sampleIds: number[]): void;
}>();

watch(
  () => props.project.project_id,
  () => {
    loadAll();
  }
);

const api = useApi();

const isLoading = ref(false);
const isLoadingSamplesInApp = ref<number | null>(null);
const isLoadingNonGroupedSamples = ref(false);
const apps = ref<App[]>([]);
const samples = ref<Sample[]>([]);

const selectedSampleIds = computed(() => {
  return props.selectedSampleIds;
});

const selectedAppIds = computed(() => {
  return props.selectedAppIds;
});

const loadSamples = async (appId: number | null) => {
  try {
    isLoadingSamplesInApp.value = appId;

    const { data } = await api.get('/project/offline/samples', {
      params: {
        project_id: props.project.project_id,
        app_id: appId,
      },
    });

    samples.value = getUniqueValuesFromArrayByKey(
      [...samples.value, ...(data.samples || [])],
      'id'
    );
  } finally {
    isLoadingSamplesInApp.value = null;
  }
};

const { toggleAppDropdown, toggleApp, toggleSample, isAppDropdownOpen } =
  useTogglableSampleList(
    samples,
    selectedSampleIds,
    selectedAppIds,
    loadSamples,
    (selectedAppIds: number[]) => emit('setSelectedAppIds', selectedAppIds),
    (selectedSampleIds: number[]) =>
      emit('setSelectedSampleIds', selectedSampleIds)
  );

const loadApps = async () => {
  const projectId = props.project.project_id;

  const { data } = await api.get(`/project/offline/apps`, {
    params: {
      project_id: projectId,
    },
  });

  apps.value = data.templates;
};

const loadAllSelected = async () => {
  samples.value = [];

  for (const appId of props.selectedAppIds) {
    await loadSamples(appId);
  }

  isLoadingNonGroupedSamples.value = true;
  await loadSamples(null);
  isLoadingNonGroupedSamples.value = false;
};

const nonGroupedSamples = computed(() => {
  return samples.value.filter((s) => !s.template_tab_id);
});

const loadAll = async () => {
  isLoading.value = true;

  await loadApps();
  await loadAllSelected();

  isLoading.value = false;
};

onMounted(() => {
  loadAll();
});
</script>
<template>
  <div v-if="isLoading">
    <i class="spinner-border spinner-border-sm"></i>
  </div>
  <div v-else>
    <AppItemPicker
      v-for="app in apps"
      :app="app"
      :samples="samples"
      :selectedSampleIds="selectedSampleIds"
      :isAppToggled="selectedAppIds.includes(app.id)"
      :isAppDropdownToggled="isAppDropdownOpen(app.id)"
      :isLoading="isLoadingSamplesInApp === app.id"
      @toggleApp="toggleApp"
      @toggleAppDropdown="toggleAppDropdown"
      @toggleSample="toggleSample"
    />

    <template v-if="nonGroupedSamples.length > 0">
      <span class="fw-medium d-block my-2">Items not in apps</span>
      <SamplePicker
        :selectedSampleIds="selectedSampleIds"
        :samples="nonGroupedSamples"
        @toggleSample="toggleSample"
        class="list-group-root"
      />
    </template>

    <div
      v-if="apps.length === 0 && samples.length === 0"
      class="alert alert-warning mt-2"
    >
      Only items that exist in your selected apps will be available.
    </div>
  </div>
</template>
<style scoped>
.form-check-input {
  width: 18px;
  height: 18px;
}
</style>
