<script>
export default {
  name: 'YearValueContentInput',
};
</script>

<script setup>
import { computed, onMounted } from 'vue';

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['input']);

const yearRange = computed(() => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear - 20; i < currentYear + 19; i++) {
    years.push(i);
  }
  return years;
});

const year = computed({
  get() {
    return props.value ? parseInt(props.value, 10) : null;
  },
  set(value) {
    emit('input', String(value));
  },
});

onMounted(() => {
  const now = new Date();
  if (!year.value) {
    year.value = now.getFullYear();
  }
});
</script>

<template>
  <div class="form-group mb-2 d-flex flex-fill">
    <select v-model.number="year" class="form-control flex-fill">
      <option v-for="item of yearRange" :key="item" :value="item">
        {{ item }}
      </option>
    </select>
  </div>
</template>
